// hook to use locker action : openDoor, getPackage, etc

import { useCallback } from "react"
import { LockerDoorReservationSessionThingTypeMrn } from "shared/lockerDoorReservationSessionData"
import { useAppSelector } from "src/app/hooks"
import { selectMe } from "src/features/me/meSlice"
import { v4 as uuid } from "uuid"
import { ActionReq } from "../../../shared/actionReq"
import {
  ActionReqExecutionModeEnum,
  ActionReqThingTypeMrn,
  ActionReqTypeEnum,
} from "../../../shared/actionReqData"
import {
  useCreateThingsMutation,
  useDeleteThingsMutation,
} from "../features/things/thingsApi"
type UseLockerAction = {
  lockerId: string
  sstDoorId: string
  sstLockerId: string
  sstReservationId?: string
  reservationId?: string
  tenantId?: string
}

export const useLockerActions = ({
  lockerId,
  sstLockerId,
  sstDoorId,
  tenantId,
  sstReservationId,
  reservationId,
}: UseLockerAction) => {
  const me = useAppSelector(selectMe)
  const [createThings, { isLoading }] = useCreateThingsMutation()
  const [deleteThings] = useDeleteThingsMutation()

  if (!tenantId || !lockerId || !sstDoorId) {
    console.error("tenantId, lockerId, sstDoorId are required", {
      tenantId,
      lockerId,
      sstDoorId,
    })
  }

  const openDoor = async (employeeCode?: string) => {
    const f = async () => {
      console.log("openDoor")
      try {
        const openDoorActionReq: Partial<ActionReq> = {
          thingTypeMrn: ActionReqThingTypeMrn,
          executionMode: ActionReqExecutionModeEnum.sync,
          tenantId,
          idempotencyKey: uuid(),
          actionType: ActionReqTypeEnum.SstLockerOpenDoor,
          inputData: {
            tenantId,
            lockerId,
            sstLockerId,
            sstDoorId,
            reservationId,
            employeeCode,
          },
        }
        await createThings({
          body: { things: [{ ...openDoorActionReq }] },
          params: { tenantId },
        }).unwrap()
      } catch (error) {
        console.error("openDoor error", error)
      }
    }
    await f()
  }

  const getPackageWrapper = async (employeeCode?: string) => {
    const getPackageActionReq: Partial<ActionReq> = {
      thingTypeMrn: ActionReqThingTypeMrn,
      executionMode: ActionReqExecutionModeEnum.sync,
      tenantId,
      idempotencyKey: uuid(),
      actionType: ActionReqTypeEnum.SstLockerGetPackage,
      inputData: {
        tenantId,
        lockerId,
        sstLockerId,
        sstDoorId,
        sstReservationId,
        reservationId,
        employeeCode,
      },
    }
    await createThings({
      body: { things: [getPackageActionReq] },
      params: { tenantId },
    }).unwrap()
  }

  const cancelReservation = async (employeeCode?: string) => {
    try {
      if (!sstDoorId) {
        throw new Error("sstDoorId is required to cancelReservation")
      }
      const cancelReservationActionReq: Partial<ActionReq> = {
        thingTypeMrn: ActionReqThingTypeMrn,
        executionMode: ActionReqExecutionModeEnum.sync,
        tenantId,
        idempotencyKey: uuid(),
        actionType: ActionReqTypeEnum.SstLockerCancelReservation,
        inputData: {
          tenantId,
          lockerId,
          sstLockerId,
          sstDoorId,
          sstReservationId,
          reservationId,
          employeeCode,
        },
      }

      await createThings({
        body: { things: [cancelReservationActionReq] },
        params: { tenantId },
      }).unwrap()
    } catch (error) {
      console.error("cancelReservation error", error)
    }
  }

  // const reserveDoorActionReq: Partial<ActionReq> = {
  //   thingTypeMrn: ActionReqThingTypeMrn,
  //   tenantId,
  //   idempotencyKey: uuid(),
  //   actionType: ActionReqTypeEnum.LockerReservingDoorChange,
  //   inputData: {
  //     tenantId,
  //     lockerId,
  //     sstDoorId,
  //     isReserving: true,
  //   },
  // }

  const reserveDoor = useCallback(async () => {
    const session = {
      thingTypeMrn: LockerDoorReservationSessionThingTypeMrn,
      tenantId,
      lockerId,
      sstLockerId,
      sstDoorId,
      sessionStart: new Date().getTime(),
      userId: me.user?.id,
      tuId: me.tu?.id,
    }
    await createThings({
      body: { things: [session] },
      params: { tenantId },
    }).unwrap()
  }, [
    createThings,
    lockerId,
    me.tu?.id,
    me.user?.id,
    sstDoorId,
    sstLockerId,
    tenantId,
  ])

  // const releaseDoorActionReq: Partial<ActionReq> = {
  //   thingTypeMrn: ActionReqThingTypeMrn,
  //   tenantId,
  //   idempotencyKey: uuid(),
  //   actionType: ActionReqTypeEnum.LockerReservingDoorChange,
  //   inputData: {
  //     tenantId,
  //     lockerId,
  //     sstDoorId,
  //     isReserving: false,
  //   },
  // }

  const releaseDoor = async (sessionId: string) => {
    const toDelete = {
      thingTypeMrn: LockerDoorReservationSessionThingTypeMrn,
      tenantId,
      lockerId,
      id: sessionId,
    }
    await deleteThings({
      body: { things: [toDelete] },
      params: { tenantId },
    }).unwrap()
  }
  const simulateExpiration = async (reservationId: string) => {
    const actionReq = {
      thingTypeMrn: ActionReqThingTypeMrn,
      executionMode: ActionReqExecutionModeEnum.sync,
      tenantId,
      idempotencyKey: uuid(),
      actionType: ActionReqTypeEnum.SimulateExpiredReservation,
      inputData: {
        tenantId,
        reservationId,
      },
    }
    await createThings({
      body: { things: [actionReq] },
      params: { tenantId },
    }).unwrap()
  }

  const simulatePayment = async (reservationId: string, amountPaid: number) => {
    const actionReq = {
      thingTypeMrn: ActionReqThingTypeMrn,
      executionMode: ActionReqExecutionModeEnum.sync,
      tenantId,
      idempotencyKey: uuid(),
      actionType: ActionReqTypeEnum.SimulatePaymentReservation,
      inputData: {
        tenantId,
        reservationId,
        amountPaid,
      },
    }
    await createThings({
      body: { things: [actionReq] },
      params: { tenantId },
    }).unwrap()
  }

  return {
    openDoor: async (employeeCode?: string) => {
      await openDoor(employeeCode)
    },
    getPackage: async (employeeCode?: string) => {
      await getPackageWrapper(employeeCode)
    },
    cancelReservation: (employeeCode?: string) => {
      cancelReservation(employeeCode)
    },
    reserveDoor: () => {
      reserveDoor()
    },
    releaseDoor: (sessionId: string) => {
      releaseDoor(sessionId)
    },
    simulateExpiration: (reservationId: string) =>
      simulateExpiration(reservationId),
    simulatePayment: (reservationId: string, amountPaid: number) =>
      simulatePayment(reservationId, amountPaid),
  }
}
