import { Close } from "@mui/icons-material"
import GroupIcon from "@mui/icons-material/Group"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { noop, pick } from "lodash"
import { FC, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { Group } from "shared/group"
import { GroupTU } from "shared/groupTU"
import { GroupTUThingTypeMrn } from "shared/groupTUData"
import { User } from "shared/user"
import { useDeleteThingsMutation } from "../features/things/thingsApi"
import { Sl2Btn } from "./Sl2Btn"

const defaultGroupTuDelete = {
  thingTypeMrn: GroupTUThingTypeMrn,
}
export type OsIamGroupMemberDeleteDialogProps = {
  group: Group
  groupTU: GroupTU
  user: User
  onClose?: () => void
  onComplete?: () => void
}
export const OsIamGroupMemberDeleteDialog: FC<
  OsIamGroupMemberDeleteDialogProps
> = ({ onClose = noop, onComplete = noop, group, user, groupTU }) => {
  const { t } = useTranslation()
  const [deleteThings, { isLoading: isDeleteThings }] =
    useDeleteThingsMutation()

  const onClick = useCallback(async () => {
    try {
      await deleteThings({
        body: {
          things: [
            pick(groupTU, [
              "id",
              "thingTypeMrn",
              "tenantId",
              "tenantUserId",
              "groupId",
            ]),
          ],
        },
      }).unwrap()

      onComplete()
    } catch (e: any) {
      console.log(e)
    }
  }, [deleteThings, groupTU, onComplete])

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          minWidth={400}
        >
          <Stack direction="row" alignItems="center">
            <Typography> {t("removeAMember")}</Typography>
          </Stack>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 3 }}>
          <GroupIcon />
          <Typography variant="h6">{group.name}</Typography>
        </Stack>
        <Box
          sx={{
            mb: 2,
          }}
        >
          <Typography variant="body2">
            {t("removeMemberDescription")}
          </Typography>
        </Box>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            // left padding & border
            borderLeft: 2,
            borderColor: "primary.main",
            pl: 1,
          }}
        >
          {user.firstName !== user.email && (
            <Typography variant="body2" sx={{ mt: 1, fontWeight: "bold" }}>
              {`${user.firstName} ${user.lastName}`}
            </Typography>
          )}
          <Typography variant="body2" sx={{ mt: 1, fontWeight: "bold" }}>
            {user.email}
          </Typography>
          {/* member since */}
          <Typography variant="caption">{`${t("memberSince")} ${new Date(
            groupTU.createdAt,
          ).toLocaleString()}`}</Typography>
        </Stack>

        <DialogActions sx={{ mt: 2 }}>
          <Sl2Btn
            onClick={onClose}
            disabled={isDeleteThings}
            variant="outlined"
          >
            {t("cancel")}
          </Sl2Btn>
          <Sl2Btn
            onClick={onClick}
            disabled={isDeleteThings}
            variant="contained"
          >
            {t("removeMember")}
          </Sl2Btn>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
