import { Box, Paper, Stack, Typography } from "@mui/material"

import { noop } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { useAnyKeyPress } from "src/hooks/useAnyKeyPress"
import { Sl2Btn } from "./Sl2Btn"

export const LockerNumericPad: React.FC<{
  onPress: (value: string) => void
  onEnter: () => void
  dialWidth?: number
  highlightedDial?: string
  lockerId: string
  onChange?: (value: string) => void
}> = ({
  onPress,
  onEnter,
  dialWidth = 50,
  highlightedDial,
  lockerId,
  onChange = noop,
}) => {
  const keys = [
    ["1", "2", "3"],
    ["4", "5", "6"],
    ["7", "8", "9"],
    ["0", "#", "*"],
  ]
  const onDialPress = useCallback(
    (value: string) => {
      setInputPut((prev) => [...prev, value])
      onPress(value)
    },
    [onPress],
  )

  const [inputPut, setInputPut] = useState<string[]>([])

  useEffect(() => {
    onChange(inputPut.join(""))
  }, [inputPut, onChange])

  useEffect(() => {
    setInputPut([])
  }, [lockerId])

  useAnyKeyPress((char) => {
    // if numeric, set input
    if (char.match(/[0-9]/)) {
      onDialPress(char)
    }

    // if enter, submit
    if (char === "Enter") {
      onEnter()
      setInputPut([])
    }
  })

  return (
    <Stack direction="column" spacing={1}>
      {/* // LCD screen */}
      <Paper
        variant="outlined"
        sx={{
          height: 50,
          width: 200,
          display: "flex",
          p: 1,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            overflowX: "scroll",
            bgcolor: "#f5f5f5",
            p: 1,
          }}
        >
          {inputPut.length === 0 ? (
            <Typography variant="h6">Enter PIN</Typography>
          ) : (
            <Typography
              variant="h6"
              sx={{
                // mono space
                fontFamily: "monospace",
              }}
            >
              <Stack direction="row" spacing={1}>
                {inputPut.map((char, k) => {
                  return <span key={`input-${char}-${k}`}>{char}</span>
                })}
              </Stack>
            </Typography>
          )}
        </Box>
      </Paper>

      <Stack direction="column" spacing={1} alignItems="center">
        {keys.map((key, i) => {
          return (
            <Stack direction="row" spacing={1} key={`dial${i}`}>
              {key.map((k) => {
                return (
                  <Sl2Btn
                    key={k}
                    variant="outlined"
                    onClick={() => onDialPress(k)}
                    sx={{
                      height: dialWidth,
                      width: dialWidth,
                      minWidth: dialWidth,
                      minHeight: dialWidth,
                      maxHeight: dialWidth,
                      maxWidth: dialWidth,
                      bgcolor: highlightedDial === k ? "lightblue" : "inherit",
                    }}
                  >
                    {k}
                  </Sl2Btn>
                )
              })}
            </Stack>
          )
        })}
      </Stack>
      <Sl2Btn
        fullWidth
        variant="contained"
        onClick={() => {
          onEnter()
          setInputPut([])
        }}
      >
        Enter
      </Sl2Btn>
      {/* align on thr giht */}
      <Stack direction="row" spacing={1} justifyContent="flex-end">
        <Sl2Btn variant="text" onClick={() => setInputPut([])}>
          Clear
        </Sl2Btn>
      </Stack>
    </Stack>
  )
}
