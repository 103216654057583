import CloseIcon from "@mui/icons-material/Close"
import InfoIcon from "@mui/icons-material/Info"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import Joi from "joi"
import { noop } from "lodash"
import { FC, useCallback, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  ActionReqExecutionModeEnum,
  ActionReqThingTypeMrn,
  ActionReqTypeEnum,
} from "shared/actionReqData"
import { Group } from "shared/group"
import { GroupThingTypeMrn } from "shared/groupData"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { selectThingsByThingTypeMrnFilter } from "src/features/things/thingsSlice"
import { v4 as uuidv4 } from "uuid"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import {
  selectTenantId,
  setNotificationPopupOpen,
} from "../features/me/meSlice"
import {
  useCreateThingsMutation,
  useGetThingsQuery,
} from "../features/things/thingsApi"
import { useCurrentTenant } from "../hooks/useCurrentTenant"
import FormFields, { FormField } from "./FormFields"
import { Sl2Btn } from "./Sl2Btn"
import { Sl2FormContainer } from "./Sl2FormContainer"

const schema = Joi.object({
  groupId: Joi.string().required(),
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
})

export type OsAddOrgMemberProps = {
  onClose?: () => void
  onComplete?: () => void
}
export const OsAddOrgMember: FC<OsAddOrgMemberProps> = ({
  onClose = noop,
  onComplete = noop,
}) => {
  const { t } = useTranslation()
  const [createThings, { isLoading: isCreatingThings }] =
    useCreateThingsMutation()

  const { tenant } = useCurrentTenant()

  const tenantId = useAppSelector(selectTenantId)
  const { refetch } = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetGroupsByTenant,
        "ap.tenantId": tenantId,
        tenantId,
      },
    },
    {
      skip: !tenantId,
    },
  )
  useEffect(() => {
    if (tenantId) refetch()
  }, [refetch, tenantId])
  const dispatch = useAppDispatch()

  const onSubmit = useCallback(
    async (data: any) => {
      if (!tenant?.id) throw new Error("tenantId is not defined")
      const toCreate = {
        thingTypeMrn: ActionReqThingTypeMrn,
        executionMode: ActionReqExecutionModeEnum.sync,
        tenantId: tenant?.id,
        idempotencyKey: uuidv4(),
        actionType: ActionReqTypeEnum.AddOrgMember,
        inputData: {
          ...data,
          tenantId: tenant?.id,
        },
      }

      await createThings({
        body: { things: [toCreate] },
        params: { tenantId },
      }).unwrap()

      onComplete()

      dispatch(setNotificationPopupOpen(true))
    },
    [createThings, dispatch, onComplete, tenant?.id, tenantId],
  )

  const tenantFilter = useCallback(
    (t: Group) => {
      return t.tenantId === tenantId
    },
    [tenantId],
  )
  const groups = useAppSelector((s) =>
    selectThingsByThingTypeMrnFilter(s, GroupThingTypeMrn, tenantFilter),
  ) as Group[]

  const fieldsGeneral = useMemo(() => {
    const options = groups.map((g) => ({
      id: g.id,
      label: <Typography variant="body2">{g.name}</Typography>,
    }))

    const noEmptyOptions = [{ id: "", label: t("noGroup") }]
    return [
      {
        name: "firstName",
        fieldType: "text",
        label: t("firstName"),
        required: true,
        size: "small",
        fullWidth: true,
        InputProps: { type: "text" },
      },

      {
        name: "lastName",
        fieldType: "text",
        label: t("lastName"),
        required: true,
        size: "small",
        fullWidth: true,
        InputProps: { type: "text" },
      },
      {
        name: "email",
        fieldType: "text",
        label: t("email"),
        required: true,
        size: "small",
        fullWidth: true,
        InputProps: { type: "email" },
      },
      {
        name: "groupId",
        fieldType: "select",
        options: options.length ? options : noEmptyOptions,
        label: t("group"),
        placeholder: t("selectGroup"),
        required: true,
        size: "small",
        fullWidth: true,
        disabled: false,
        valueKey: "id",
      },
    ] as FormField[]
  }, [groups, t])

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          minWidth={400}
        >
          <Typography> {t("addMember")}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="row"
          mb={3}
          pl={1}
          pr={1}
          p={4}
          border="1px solid #e0e0e0"
          bgcolor="#f5f5f5"
        >
          <InfoIcon sx={{ mr: 3 }} color="success" />
          <Typography variant="body1">{t("addMemberDescription")}</Typography>
        </Stack>
        <Sl2FormContainer
          onError={(errors: any) => console.log(errors)}
          onSuccess={onSubmit}
          joiSchema={schema}
        >
          <FormFields fields={fieldsGeneral} />
          <Box sx={{ mt: 4 }} />
          <DialogActions>
            <Sl2Btn
              onClick={onClose}
              disabled={isCreatingThings}
              variant="outlined"
            >
              {t("cancel")}
            </Sl2Btn>
            <Sl2Btn
              type="submit"
              disabled={isCreatingThings}
              variant="contained"
            >
              {t("addMember")}
            </Sl2Btn>
          </DialogActions>
          {/* <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) => (
              <Typography variant="caption" color="red">
                {message}
              </Typography>
            )}
          /> */}
        </Sl2FormContainer>
      </DialogContent>
    </Dialog>
  )
}
