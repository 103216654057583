import { fetchBaseQuery } from "@reduxjs/toolkit/query"
import { v4 as uuidv4 } from "uuid"
import { sl2ApiUrl } from "../useSl2Api"

export const baseQuery = fetchBaseQuery({
  baseUrl: sl2ApiUrl,
  prepareHeaders: async (headers) => {
    try {
      // @ts-ignore
      const token = await window.Clerk?.session?.getToken()
      if (token) {
        headers.set("authorization", `Bearer ${token}`)
        headers.set("idempotency-key", uuidv4())
      } else {
        console.error("No token available")
      }
    } catch (error) {
      console.error("Failed to retrieve token", error)
    }
    return headers
  },
})

// native fetch version with prepared headers
export const fetchSl2Api = async (input: RequestInfo, init?: RequestInit) => {
  try {
    // @ts-ignore
    const token = await window.Clerk?.session?.getToken()
    if (token) {
      init = {
        ...init,
        headers: {
          ...init?.headers,
          authorization: `Bearer ${token}`,
          "idempotency-key": uuidv4(),
        },
      }
    } else {
      console.error("No token available")
    }
  } catch (error) {
    console.error("Failed to retrieve token", error)
  }
  // add base url : sl2ApiUrl
  if (typeof input === "string") {
    input = sl2ApiUrl + input
  }
  return fetch(input, init)
}
