import { Refresh } from "@mui/icons-material"
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import moment from "moment"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { ReportDataset } from "shared/report-datasets"

type DsRecord = {
  created_at_week: number
  created_at_month: number
  created_at_year: number
  nb_reservations: number
  locker_id: string
  sst_status: string
  tenant_id: string
}

export type OsReportReservationSummaryPickupHodProps = {
  ds?: ReportDataset<any>
  isLoading: boolean
  refresh: () => void
  hideHeader?: boolean
  hideRefresh?: boolean
  width?: number
}
export const OsReportReservationSummaryPickupHod: FC<
  OsReportReservationSummaryPickupHodProps
> = ({ ds, isLoading, refresh, hideHeader, hideRefresh, width = 300 }) => {
  const { t } = useTranslation()

  return (
    <Stack
      direction="column"
      spacing={4}
      p={2}
      sx={{
        width,
      }}
    >
      {!hideHeader && (
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="column" spacing={1}>
            <Typography variant="body1" fontWeight={"bold"}>
              {t("reporting.reservationSummaryPickupHod.title")}
            </Typography>
            <Typography variant="body2">
              {t("reporting.reservationSummaryPickupHod.description")}
            </Typography>
          </Stack>
          {!hideRefresh && (
            <Stack direction="column" spacing={1} alignItems="flex-end">
              <IconButton onClick={refresh} disabled={isLoading}>
                <Refresh />
              </IconButton>
              <Typography variant="caption">
                {ds?.lastUpdated &&
                  `${t("lastUpdated")}: ${moment(ds.lastUpdated).format(
                    "YYYY-MM-DD HH:mm:ss",
                  )}`}
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
      <Box
        sx={{
          width: "100%",
          height: 300,
        }}
      >
        {ds?.data?.length && ds?.data?.length > 0 ? (
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              // width='100%'
              height={300}
              data={ds?.data}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="hour_of_day" name="Heure de la journée" />
              <YAxis />
              <Tooltip />
              <Legend />

              <Area dataKey={`nb_reservations`} type="monotone" />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <Box
            sx={{
              //grey box
              width: "100%",
              height: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: "grey.100",
            }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Typography>{t("noData")}</Typography>
            )}
          </Box>
        )}
      </Box>
    </Stack>
  )
}

export default OsReportReservationSummaryPickupHod
