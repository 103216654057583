import { Close } from "@mui/icons-material"
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { FC } from "react"
import CopyToClipboard from "react-copy-to-clipboard"

interface ObjectDisplayProps {
  data?: Record<string, any>
  title?: string
  colWidth?: number
  noBorder?: boolean
  flatten?: boolean
}
export const ObjectDisplay: FC<ObjectDisplayProps> = ({
  data,
  title,
  colWidth = 400,
  noBorder,
  flatten = false,
}) => {
  if (flatten) {
    // create a new object with flattened keys
    const flatData = {}
    const flattenObject = (obj: any, prefix = "") => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key]
        if (typeof value === "object" && value !== null) {
          flattenObject(value, `${prefix}${key}.`)
        } else {
          // @ts-ignore
          flatData[`${prefix}${key}`] = value
        }
      })
    }
    flattenObject(data)
    data = flatData
  }
  let fieldsLayout = Object.keys(data || {}).sort()

  const technicalFields = [
    "id",
    "thingTypeMrn",
    "mrn",
    "traceId",
    "createdAt",
    "updatedAt",
    // "pk",
    // "sk",
  ]
  // remove technical fields from layout
  fieldsLayout = fieldsLayout.filter(
    (field) => !technicalFields.includes(field),
  )
  if (!data) {
    return <Typography variant="body1">No data</Typography>
  }

  return (
    <Paper
      sx={{
        p: 2,
        width: 900,
        border: noBorder ? "none" : "1px solid rgba(0, 0, 0, 0.12)",
      }}
      variant="outlined"
    >
      <Typography variant="body1" fontWeight="bold">
        {title || data.id}
      </Typography>
      <Divider />
      <Stack
        direction={"row"}
        spacing={2}
        mt={3}
        // justifyContent={"space-evenly"}
      >
        <Stack direction="column" spacing={2} width={colWidth}>
          {technicalFields.map((key) => (
            <Stack key={key} direction="column" spacing={1}>
              <Typography variant="body2" fontWeight="bold">
                {key}
              </Typography>
              {/* // @ts-ignore */}
              <ObjectDisplayValue value={data && data[key]} />
            </Stack>
          ))}
        </Stack>
        <Divider />
        <Stack direction="column" spacing={2} width={colWidth}>
          {fieldsLayout.map((key) => (
            <Stack key={key} direction="column" spacing={1}>
              <Typography variant="body2" fontWeight="bold">
                {key}
              </Typography>
              {/* // @ts-ignore */}
              <ObjectDisplayValue value={data && data[key]} />
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Paper>
  )
}

export default ObjectDisplay

const ObjectDisplayValue: FC<{ value: any }> = ({ value }) => {
  if (value === null) {
    return <Typography variant="body2">null</Typography>
  }
  if (typeof value === "object") {
    return <ObjectDisplay data={value} />
  }
  if (Array.isArray(value)) {
    const columns: GridColDef[] = Object.keys(value[0] || {}).map((col) => ({
      field: col,
      headerName: col,
    }))

    return (
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid rows={value} columns={columns} />
      </Box>
    )
  }
  // copy to clipboard on hover
  return (
    <CopyToClipboard text={String(value) || ""}>
      <Stack direction="row" spacing={1} alignItems="center">
        {/* <ContentPaste fontSize="small" /> */}

        <Typography
          sx={{
            cursor: "pointer",

            maxWidth: "300px",
          }}
          variant="body2"
        >
          {String(value)}
        </Typography>
      </Stack>
    </CopyToClipboard>
  )
}
export const ObjectDisplayDrawer: FC<
  ObjectDisplayProps & {
    onClose?: () => void
  }
> = ({ onClose, ...rest }) => {
  return (
    <Drawer
      open={true}
      onClose={onClose}
      anchor="right"
      variant="persistent"
      sx={{
        height: "100vh",
        overflowY: "auto",
        width: 1500,
      }}
    >
      <Box mt={"64px"} />
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent={"end"}
      >
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>
      <ObjectDisplay {...rest} noBorder />
    </Drawer>
  )
}
