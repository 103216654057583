import { Preview, Refresh } from "@mui/icons-material"
import {
  Chip,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import moment from "moment"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { JsonView } from "react-json-view-lite"
import { useLocation } from "react-router-dom"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { useGetThingsQuery } from "src/features/things/thingsApi"
import { useSearchParamsState } from "src/hooks/useSearchParamsState"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady } from "../features/me/meSlice"
import AuditTrailEventFilter from "./AuditTrailEventFilter"
import SaLayout from "./SaLayout"

const defaultPaginationModel = {
  page: 0,
  pageSize: 100,
}
const SubpageTabMap: Record<string, number> = {
  byEvent: 0,
  byEmployeeCode: 1,
}
export const SaReservationAudPage: FC = () => {
  const { t } = useTranslation()
  const isReady = useAppSelector(selectMeIsReady)

  const location = useLocation()

  const [nextToken, setNextToken] = useState<string | undefined | null>()

  const [paginationModel, setPaginationModel] = useState(defaultPaginationModel)

  const [searchParamsString, setSearchParamsString] = useSearchParamsState(
    "searchParams",
    "{}",
  )

  const setSearchParams = useCallback(
    (searchParams: Record<string, any>) => {
      setNextToken(undefined)
      setPaginationModel(defaultPaginationModel)
      setSearchParamsString(JSON.stringify(searchParams))
    },
    [setSearchParamsString],
  )

  const searchParams = useMemo(() => {
    try {
      return JSON.parse(searchParamsString)
    } catch (e) {
      console.error("Error parsing searchParams", e)
      return {}
    }
  }, [searchParamsString])

  // if no subpage in url, redirect to byEvent
  useCallback(() => {
    if (!location.search.includes("searchParams")) {
      // navigate(`/sa/reservation-audit/byEvent${location.search}`)
      setSearchParams({ type: "byEvent", event: "updated" })
    }
  }, [location.search, setSearchParams])

  const [localEmployeeCode, setLocalEmployeeCode] = useState<string>(
    searchParams.employeeCode || "",
  )

  const qByEvent = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternSaEnum.SaGetReservationAudsByEvent,
        "ap.event": searchParams?.event,
        "ap.limit": 300,
        "ap.nextToken": nextToken,
      },
    },
    { skip: !isReady || searchParams.type !== "byEvent" },
  )
  const qByEmployeeCode = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternSaEnum.SaGetReservationAudsByEmployeeCode,
        "ap.employeeCode": searchParams?.employeeCode,
        "ap.limit": 300,
        "ap.nextToken": nextToken,
      },
    },
    { skip: !isReady || searchParams.type !== "byEmployeeCode" },
  )

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      // get subpage from map with newValue
      const subpage = Object.keys(SubpageTabMap).find(
        (key) => SubpageTabMap[key] === newValue,
      )

      setSearchParams({ ...searchParams, type: subpage, event: "updated" })
    },
    [searchParams, setSearchParams],
  )

  const [previewDetails, setPreviewDetails] = useState<Record<
    string,
    any
  > | null>(null)

  const columns = useMemo(
    () => [
      // action "preview" icon column
      {
        field: "id",
        headerName: "",
        width: 50,
        renderCell: (params: any) => {
          return (
            <Stack direction="row" spacing={1}>
              <IconButton
                size="small"
                onClick={() => setPreviewDetails(params.row || null)}
              >
                <Preview />
              </IconButton>
            </Stack>
          )
        },
      },
      {
        field: "createdAt",
        headerName: "Created At",
        width: 150,
        renderCell: (params: any) => {
          return (
            <Stack direction="column" spacing={1}>
              <span>{moment(params.value).format("YYYY-MM-DD HH:mm:ss")}</span>
              <span>{moment(params.value).fromNow()}</span>
            </Stack>
          )
        },
      },
      {
        field: "event",
        headerName: "Event",
        width: 150,

        //  render as Chip
        renderCell: (params: any) => {
          return <Chip label={params.value} />
        },
      },
      {
        field: "employeeCode",
        headerName: "Employee Code",
        width: 150,

        renderCell: (params: any) => {
          return params?.value ? (
            <Chip label={params.value} color="primary" variant="outlined" />
          ) : (
            "n/a"
          )
        },
      },
      { field: "tenantId", headerName: "Tenant ID", width: 300 },
      { field: "lockerId", headerName: "Locker ID", width: 300 },
      { field: "reservationId", headerName: "reservation ID", width: 300 },
    ],
    [],
  )
  const [rowCount, setRowCount] = useState<number>(Number.MAX_VALUE)

  const onPaginationModelChange = useCallback(
    (newPaginationModel: any) => {
      // newPaginationModel.page++
      setPaginationModel(newPaginationModel)
      setNextToken(qByEvent.data?.meta?.nextToken)
    },
    [qByEvent.data?.meta?.nextToken],
  )

  return (
    <SaLayout selectedMenuItemLabelKey="reservationAudit">
      <Stack direction="column" spacing={2} sx={{ p: 2 }}>
        <Stack direction="row" spacing={2} sx={{}}>
          <Stack direction="column" spacing={2}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
               <Typography variant="h6">{t("reservationAudit")}</Typography> 
            </Stack>
            <Typography variant="body2">
              {t("reservationAuditDescription")}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Tabs
          value={SubpageTabMap[searchParams.type || "byEvent"]}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={
              <Typography variant="body2" fontWeight={"bold"}>
                {t("byEvent")}
              </Typography>
            }
            sx={{
              textTransform: "none",
            }}
          />
          <Tab
            label={
              <Typography variant="body2" fontWeight={"bold"}>
                {t("byEmployeeCode")}
              </Typography>
            }
            sx={{
              textTransform: "none",
            }}
          />
        </Tabs>
        {searchParams.type === "byEvent" && (
          <Stack direction="column" spacing={2}>
            <Stack direction="column" spacing={2}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <AuditTrailEventFilter
                  value={searchParams.event}
                  onChange={(event) => {
                    setSearchParams({ ...searchParams, event })
                  }}
                />
                <IconButton
                  size="small"
                  onClick={() => qByEvent.refetch()}
                  disabled={qByEvent.isLoading || qByEvent.isFetching}
                >
                  {qByEvent.isLoading || qByEvent.isFetching ? (
                    <CircularProgress />
                  ) : (
                    <Refresh />
                  )}
                </IconButton>
              </Stack>
              <Typography variant="body1">{`${
                qByEvent.data?.things?.length
              } ${t("events")}`}</Typography>
              <DataGrid
                rows={qByEvent.data?.things || []}
                columns={columns}
                autoHeight
                // paginationMode="server"
                // rowCount={
                //   qByEvent.data?.things?.length <
                //   defaultPaginationModel.pageSize
                //     ? qByEvent.data?.things?.length
                //     : Number.MAX_VALUE
                // }
                // paginationModel={paginationModel}
                // onPaginationModelChange={onPaginationModelChange}
                // localeText={{
                //   MuiTablePagination: {
                //     labelDisplayedRows: ({ from, to, count }) =>
                //       `${from} - ${to} of ${
                //         count === Number.MAX_VALUE ? "many" : count
                //       }`,
                //   },
                // }}
                // pageSize={3}
                // page={page}
                // onPageChange={handlePageChange}
              />
              {/* <OsReservationAuditTrailList
                  data={(qByEvent.data?.things || []) as ReservationAud[]}
                  isFetching={qByEvent.isFetching}
                  isLoading={qByEvent.isLoading}
                  refetch={qByEvent.refetch}
                /> */}
            </Stack>
          </Stack>
        )}
        {searchParams.type === "byEmployeeCode" && (
          <Stack direction="column" spacing={2}>
            <Stack direction="column" spacing={2}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent={"space-between"}
              >
                {/* <Paper sx={{ p: 1, width: "50%" }} variant="outlined"> */}
                <Stack direction="row" spacing={1} alignItems="center">
                  <TextField
                    label="Employee Code"
                    value={localEmployeeCode}
                    onChange={(e) => setLocalEmployeeCode(e.target.value)}
                    onBlur={(e) =>
                      setSearchParams({
                        ...searchParams,
                        employeeCode: e.target.value,
                      })
                    }
                  />
                </Stack>
                {/* </Paper> */}
                <IconButton
                  size="small"
                  onClick={() => qByEmployeeCode.refetch()}
                  disabled={
                    qByEmployeeCode.isLoading || qByEmployeeCode.isFetching
                  }
                >
                  {qByEmployeeCode.isLoading || qByEmployeeCode.isFetching ? (
                    <CircularProgress />
                  ) : (
                    <Refresh />
                  )}
                </IconButton>
              </Stack>
              <Typography variant="body1">{`${
                qByEmployeeCode.data?.things?.length || 0
              } ${t("events")}`}</Typography>
              <DataGrid
                rows={qByEmployeeCode.data?.things || []}
                columns={columns}
                autoHeight
              />
            </Stack>
          </Stack>
        )}
      </Stack>
      <Dialog
        open={!!previewDetails}
        onClose={() => setPreviewDetails(null)}
        fullWidth
        maxWidth="md"
        sx={{
          maxHeight: "100%",
        }}
      >
        <Stack direction="column" spacing={1} p={2}>
          <Typography variant="body1">{`${t("preview")} : ${
            previewDetails?.id || "-"
          }`}</Typography>
          <JsonView data={previewDetails || {}} />
        </Stack>
      </Dialog>
    </SaLayout>
  )
}

export default SaReservationAudPage
