import { Box, Divider, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { ReservationAudDetailsUpdated } from "shared/reservationAud"
import { selectMe } from "src/features/me/meSlice"

type OsReservationAuditTrailDetailsUpdatedProps = {
  detailsUpdated: ReservationAudDetailsUpdated
}

export const OsReservationAuditTrailDetailsUpdated: FC<
  OsReservationAuditTrailDetailsUpdatedProps
> = ({ detailsUpdated }) => {
  const { user } = useSelector(selectMe)

  const isSuperAdmin = user?.isSuperAdmin

  const { t } = useTranslation()

  let { newFields, removedFields, updatedFields, previousValues, newValues } =
    detailsUpdated
  //validate that the newFields, removedFields and updatedFields an array
  //validate that the previousValues and newValues are objects
  if (
    !Array.isArray(newFields) ||
    !Array.isArray(removedFields) ||
    !Array.isArray(updatedFields) ||
    typeof previousValues !== "object" ||
    typeof newValues !== "object"
  ) {
    return null
  }

  // filter out "traceId" and "meta_data" fields for non-super-admins
  if (!isSuperAdmin) {
    newFields = newFields.filter(
      (field) => !["traceId", "meta_data"].includes(field),
    )
    removedFields = removedFields.filter(
      (field) => !["traceId", "meta_data"].includes(field),
    )
    updatedFields = updatedFields.filter(
      (field) => !["traceId", "meta_data"].includes(field),
    )
  }

  return (
    <Stack direction="column" spacing={1} p={1}>
      <Typography variant="body2" fontWeight="bold">
        {t("reservationDetailsUpdated")}
      </Typography>
      <Divider />
      <Stack direction="column" spacing={2} pt={2}>
        {newFields.length > 0 && (
          <Box>
            <Typography variant="caption">{t("newFields")}</Typography>
            <Stack direction="column" spacing={1}>
              {newFields.map((field) => (
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2" fontWeight="bold">
                    {t(getLastFragment(field) || "field")}:
                  </Typography>
                  <Stack key={field} direction="row" spacing={1}>
                    <Typography variant="body2">{newValues[field]}</Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Box>
        )}
        {removedFields.length > 0 && (
          <Box>
            <Typography variant="caption">{t("removedFields")}</Typography>
            <Stack direction="column" spacing={1}>
              {removedFields.map((field) => (
                <Stack key={field} direction="row" spacing={1}>
                  <Typography variant="body2" fontWeight="bold">
                    {t(getLastFragment(field) || "field")}:
                  </Typography>
                  <Typography variant="body2">
                    {JSON.stringify(previousValues[field])}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Box>
        )}
        {updatedFields.length > 0 && (
          <Box>
            <Typography variant="caption">{t("updatedFields")}</Typography>
            <Stack direction="column" spacing={1}>
              {updatedFields.map((field) => (
                <Stack key={field} direction="column" spacing={1}>
                  <Typography variant="body2" fontWeight="bold">
                    {t(getLastFragment(field) || "field")}:
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="caption">
                      {JSON.stringify(previousValues[field])}
                    </Typography>
                    <Typography variant="caption">{t("to")}</Typography>
                    <Typography variant="caption">
                      {JSON.stringify(newValues[field])}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Box>
        )}
      </Stack>
    </Stack>
  )
}

const getLastFragment = (str: string) => {
  const lastFragment = str.split(".").pop()
  return lastFragment
}
