import { createApi } from "@reduxjs/toolkit/query/react"
import { SstReservation } from "../../../../shared/reservation"
import { SstLocker } from "../../../../shared/sst"
import { baseQuery } from "../baseQuerySl2"

export const partnersApi = createApi({
  reducerPath: "partnersApi",
  baseQuery,
  endpoints: (builder) => ({
    getPartnerProxy: builder.query<
      { data: SstLocker[] },
      { params?: Record<string, any>; urlFragment: string }
    >({
      query: ({ params, urlFragment }) => ({
        url: "proxy" + urlFragment,
        params,
      }),
    }),
    getSstLockers: builder.query<
      { data: SstLocker[] },
      { params: Record<string, any> | undefined }
    >({
      query: ({ params }) => ({
        url: "proxy/sirsteward",
        params: {
          ...params,
          resourceType: "lockers",
        },
      }),
    }),
    // by id
    getSstLocker: builder.query<{ data: SstLocker }, { id: string }>({
      query: ({ id }) => ({
        url: `proxy/sirsteward`,
        params: {
          resourceType: "lockers",
          resourceId: id,
        },
      }),
    }),
    getSstReservations: builder.query<
      { data: SstReservation[] },
      { params: Record<string, any> }
    >({
      query: ({ params }) => ({
        url: "proxy/sirsteward",
        params: {
          ...params,
          resourceType: "reservations",
          // "status_filters[0]": "DELIVERED",
          // "status_filters[1]": "EXPIRED",
          // "status_filters[2]": "DRAFT",
        },
      }),
    }),
    // get reservation by id
    getSstReservation: builder.query<{ data: SstReservation }, { id: string }>({
      query: ({ id }) => ({
        url: `proxy/sirsteward`,
        params: {
          resourceType: "reservations",
          resourceId: id,
        },
      }),
    }),
    // get webhooks
    getSstWebhooks: builder.query<{ data: any }, unknown>({
      query: ({ params }) => ({
        url: "proxy/sirsteward",
        params: {
          resourceType: "webhooks",
        },
      }),
    }),

    // get apiInfo
    getSstApiInfo: builder.query<{ data: any }, unknown>({
      query: () => ({
        url: "proxy/sirsteward",
        params: {
          resourceType: "apiInfo",
        },
      }),
    }),
  }),
})

export const {
  useGetPartnerProxyQuery,
  useGetSstLockerQuery,
  useGetSstLockersQuery,
  useGetSstReservationsQuery,
  useGetSstReservationQuery,
  useGetSstWebhooksQuery,
  useGetSstApiInfoQuery,
} = partnersApi
