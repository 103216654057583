import { map } from "lodash"
import { useCallback, useEffect, useRef, useState } from "react"
import { AttributeFilter } from "shared/report"
import { attributeFilterSchema } from "shared/reportValidators"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import {
    selectClientFilters,
    selectLockerIdFilters,
    selectReservationFilters,
    updateClientFilters,
    updateLockerIdFilters,
    updateReservationFilters,
} from "src/features/report/reportSlice"
import { useCombinedSearchParamsState } from "./useCombinedSearchParamsState"

type FilterType = {
  reservationFilters: AttributeFilter[]
  lockerIdFilters: string[]
  clientFilters: AttributeFilter[]
}

export const useReportFilter = () => {
  const dispatch = useAppDispatch()
  const isInitialMount = useRef(true)

  // for apply action
  const [isDirty, setIsDirty] = useState(false)

  const reservationFilters = useAppSelector(selectReservationFilters)
  const lockerIdFilters = useAppSelector(selectLockerIdFilters)
  const clientFilters = useAppSelector(selectClientFilters)

  const [searchParams, setSearchParams] = useCombinedSearchParamsState({
    reservationFilters: "[]",
    lockerIdFilters: "[]",
    clientFilters: "[]",
  })

  const [localFilters, _setLocalFilters] = useState<FilterType>({
    reservationFilters,
    lockerIdFilters,
    clientFilters,
  })
  // wrap  setLocalFilters with setIsDirty
  const setLocalFilters = useCallback(
    (filters: FilterType | ((prev: FilterType) => FilterType)) => {
      if (!isInitialMount.current) {
        setIsDirty(true)
      }
      _setLocalFilters(filters)
    },
    [],
  )

  // Load filters from URL and update local state and Redux state
  useEffect(() => {
    try {
      const reservationData = JSON.parse(searchParams.reservationFilters)
      dispatch(updateReservationFilters(reservationData))
      setLocalFilters((prev) => ({
        ...prev,
        reservationFilters: reservationData,
      }))
    } catch (e) {
      console.error("Error parsing reservationFilters", e)
    }
  }, [searchParams.reservationFilters, dispatch, setLocalFilters])

  useEffect(() => {
    try {
      const lockerData = JSON.parse(searchParams.lockerIdFilters)
      dispatch(updateLockerIdFilters(lockerData))
      setLocalFilters((prev) => ({
        ...prev,
        lockerIdFilters: lockerData,
      }))
    } catch (e) {
      console.error("Error parsing lockerIdFilters", e)
    }
  }, [searchParams.lockerIdFilters, dispatch, setLocalFilters])

  useEffect(() => {
    try {
      const clientData = JSON.parse(searchParams.clientFilters)
      dispatch(updateClientFilters(clientData))
      setLocalFilters((prev) => ({
        ...prev,
        clientFilters: clientData,
      }))
    } catch (e) {
      console.error("Error parsing clientFilters", e)
    }
  }, [searchParams.clientFilters, dispatch, setLocalFilters])

  useEffect(() => {
    isInitialMount.current = false
  }, [])

  const [errors, setErrors] = useState<{
    reservationFilters: (string | null | undefined)[]
    clientFilters: (string | null | undefined)[]
  }>({
    reservationFilters: [],
    clientFilters: [],
  })
  const [hasErrors, setHasErrors] = useState(false)

  const validateFilters = useCallback(() => {
    // reset errors
    // setErrors((prev) => ({
    //   reservationFilters: [],
    //   clientFilters: [],
    // }))

    const reservationFiltersErrors = map(
      localFilters.reservationFilters,
      (filter) => {
        return attributeFilterSchema.validate(filter).error?.message
      },
    )

    const clientFiltersErrors = map(localFilters.clientFilters, (filter) => {
      return attributeFilterSchema.validate(filter).error?.message
    })
    const newErrors = {
      reservationFilters: reservationFiltersErrors,
      clientFilters: clientFiltersErrors,
    }
    console.log("Errors found in filters", newErrors)
    setErrors((prev) => ({
      clientFilters: clientFiltersErrors,
      reservationFilters: reservationFiltersErrors,
    }))
    const hasErrors =
      newErrors.reservationFilters.some(Boolean) ||
      newErrors.clientFilters.some(Boolean)
    setHasErrors(hasErrors)
    return {
      hasErrors,
    }
  }, [localFilters])

  // Apply filters: update URL query params and Redux state
  const applyFilters = useCallback(() => {
    console.log("Applying filters", { localFilters })
    const { hasErrors } = validateFilters()
    if (hasErrors) {
      console.log("Errors found in filters", errors)
      return
    }
    setSearchParams({
      reservationFilters: JSON.stringify(localFilters.reservationFilters),
      lockerIdFilters: JSON.stringify(localFilters.lockerIdFilters),
      clientFilters: JSON.stringify(localFilters.clientFilters),
    })

    dispatch(updateReservationFilters(localFilters.reservationFilters))

    dispatch(updateLockerIdFilters(localFilters.lockerIdFilters))

    dispatch(updateClientFilters(localFilters.clientFilters))

    setIsDirty(false)
  }, [localFilters, validateFilters, setSearchParams, dispatch, errors])

  return {
    addReservationFilter: (filter: AttributeFilter) =>
      setLocalFilters((prev) => ({
        ...prev,
        reservationFilters: [...prev.reservationFilters, filter],
      })),
    removeReservationFilter: (index: number) =>
      setLocalFilters((prev) => ({
        ...prev,
        reservationFilters: prev.reservationFilters.filter(
          (_, i) => i !== index,
        ),
      })),
    addLockerIdFilter: (lockerId: string) =>
      setLocalFilters((prev) => ({
        ...prev,
        lockerIdFilters: [...prev.lockerIdFilters, lockerId],
      })),
    removeLockerIdFilter: (lockerId: string) =>
      setLocalFilters((prev) => ({
        ...prev,
        lockerIdFilters: prev.lockerIdFilters.filter((id) => id !== lockerId),
      })),
    addClientFilter: (filter: AttributeFilter) =>
      setLocalFilters((prev) => ({
        ...prev,
        clientFilters: [...prev.clientFilters, filter],
      })),
    removeClientFilter: (index: number) =>
      setLocalFilters((prev) => ({
        ...prev,
        /// remove by filter
        clientFilters: prev.clientFilters.filter((_, i) => i !== index),
      })),
    localFilters,
    setLocalFilters,
    applyFilters,
    isDirty,
    errors,
    hasErrors,
    resetErrors: () => setErrors({ reservationFilters: [], clientFilters: [] }),
  }
}
