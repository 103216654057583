import Joi from "joi"

interface AddressComponent {
  long_name: string
  short_name: string
  types: string[]
}

interface Geometry {
  location: {
    lat: number
    lng: number
  }
}

interface OneLineStringWithoutAppartNumber {
  id: number
  address_components: AddressComponent[]
  formatted_address: string
  geometry: Geometry
}

interface Address {
  label: string
  address: string
  appartNumber: string
  new: boolean
  dirty: boolean
  deleted: boolean
  clientSource: boolean
  orderSource: boolean
  selected: boolean
  oneLineStringWithoutAppartNumber: OneLineStringWithoutAppartNumber
  distance?: number
  time?: number
  valid: boolean
  touched: boolean
}

interface PhoneNumber {
  label: string
  number: string
  new: boolean
  dirty: boolean
  deleted: boolean
  valid: boolean
}

interface Client {
  tags: any[]
  phoneNumbers: PhoneNumber[]
  addresses: Address[]
  lastName: string
  firstName: string
  selectedAddress: Address
  notes: string
  language: string
  email?: string
}

interface Input {
  firstName: string
  lastName: string
  mblsAddress: OneLineStringWithoutAppartNumber
  addressLabel: string
  appartNumber: string
  phoneLabel: string
  phoneNumber: string
  notes: string
  language: string
  email?: string
}

const joiSchemaInput = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  mblsAddress: Joi.object({
    id: Joi.number().required(),
    address_components: Joi.array()
      .items(
        Joi.object({
          long_name: Joi.string().required(),
          short_name: Joi.string().required(),
          types: Joi.array().items(Joi.string()).required(),
        }),
      )
      .required(),
    formatted_address: Joi.string().required(),
    geometry: Joi.object({
      location: Joi.object({
        lat: Joi.number().required(),
        lng: Joi.number().required(),
      }).required(),
    }).required(),
  }).required(),
  addressLabel: Joi.string().required(),
  appartNumbert: Joi.string().required(),
  phoneLabel: Joi.string().required(),
  phoneNumber: Joi.string().required(),
  notes: Joi.string().required(),
  language: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .optional(),
})
/**
 * 
 * @param input 
 * @returns 
 * 
 * 
// Example usage:
```ts
const input: Input = {
  firstName: "John",
  lastName: "Doe",
  mblsAddress: {
    id: 0.17705096465546766,
    address_components: [
      {
        long_name: "1200",
        short_name: "1200",
        types: ["street_number"],
      },
      {
        long_name: "François Casey St, Sherbrooke, QC, J1G 5L4, CAN",
        short_name: "François Casey St",
        types: ["route"],
      },
      {
        long_name: "Sherbrooke",
        short_name: "Sherbrooke",
        types: ["locality", "political"],
      },
      {
        long_name: "Québec",
        short_name: "QC",
        types: ["administrative_area_level_1", "political"],
      },
      {
        long_name: "Canada",
        short_name: "CA",
        types: ["country", "political"],
      },
      {
        long_name: "J1G 5L4",
        short_name: "J1G 5L4",
        types: ["postal_code"],
      },
      {
        long_name: "",
        short_name: "",
        types: ["district"],
      },
    ],
    formatted_address: "1200 François Casey St, Sherbrooke, QC J1G 5L4, Canada",
    geometry: {
      location: {
        lat: 45.387125720916,
        lng: -71.868839660598,
      },
    },
  },
  addressLabel: "Home",
  appartNumber: "",
  phoneLabel: "Mobile",
  phoneNumber: "4388370133",
  notes: "this is a note",
}

const clientObject = createClientObject(input)
console.log(clientObject)

 */
export const createClientObject = (input: Input): { client: Client } => {
  // validate input
  //   const { error } = joiSchemaInput.validate(input)
  //   if (error) {
  //     throw new Error(`Invalid input: ${error.message}`)
  //   }
  const address: Address = {
    label: input.addressLabel,
    address: "",
    appartNumber: input.appartNumber,
    new: true,
    dirty: true,
    deleted: false,
    clientSource: true,
    orderSource: false,
    selected: true,
    oneLineStringWithoutAppartNumber: input.mblsAddress,
    // distance: 3.5684414,
    // time: 8,
    valid: true,
    touched: false,
  }

  const phoneNumber: PhoneNumber = {
    label: input.phoneLabel,
    number: input.phoneNumber,
    new: true,
    dirty: true,
    deleted: false,
    valid: true,
  }

  const client: Client = {
    tags: [],
    phoneNumbers: [phoneNumber],
    addresses: [address],
    lastName: input.lastName,
    firstName: input.firstName,
    selectedAddress: address,
    notes: input.notes,
    language: input.language,
    email: input.email,
  }

  return { client }
}
