import CloseIcon from "@mui/icons-material/Close"
import EmailIcon from "@mui/icons-material/Email"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { FC, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Credential } from "shared/credential"
import { CredentialThingTypeMrn } from "shared/credentialData"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { User } from "shared/user"
import { useAppSelector } from "../app/hooks"
import { appConfig } from "../appConfig"
import defaultAvatar from "../assets/default_avatar.png"
import { selectTenantId } from "../features/me/meSlice"
import { useGetThingsQuery } from "../features/things/thingsApi"
import { selectThingsByThingTypeMrnFilter } from "../features/things/thingsSlice"
import { Sl2Btn } from "./Sl2Btn"

export type OsMemberPreviewDrawerProps = {
  onClose: () => void
  user: User
}

export const OsMemberPreviewDrawer: FC<OsMemberPreviewDrawerProps> = ({
  user,
  onClose,
}) => {
  const tenantId = useAppSelector(selectTenantId)

  const { isLoading, refetch } = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetCredentialsByUser,
        "ap.tenantId": tenantId,
        "ap.userId": user.id,
        tenantId,
      },
    },
    {
      skip: !tenantId || !user.id,
    },
  )
  const filterFn = useCallback(
    (t: Credential) => t.userId === user.id,
    [user.id],
  )
  const creds = useAppSelector((s) =>
    selectThingsByThingTypeMrnFilter(s, CredentialThingTypeMrn, filterFn),
  ) as Credential[]

  const [showCredId, setShowCredId] = useState<string | null>(null)
  const { t } = useTranslation()
  // sanity check
  if (!user) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    )
  }
  return (
    <Paper
      variant="outlined"
      sx={{
        p: 2,
        width: appConfig.itemPreviewDrawerWidth,
        height: "100%",
      }}
    >
      <Stack direction="column">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">{t("profile")}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            mt: 2,
          }}
        >
          <img
            src={user.profilePictureUrl || defaultAvatar}
            alt="user"
            width={230}
            height={230}
            style={{
              borderRadius: "10px",
            }}
          />
        </Box>
        <Typography variant="h6" mt={4}>
          {user.firstName} {user.lastName}
        </Typography>
        <Stack direction="row" alignItems="center" mt={1} spacing={1}>
          <EmailIcon
            sx={{
              color: "grey",
              fontSize: "1.2em",
            }}
          />
          <Typography variant="body1">{user.email}</Typography>
        </Stack>
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Stack direction="column" spacing={2} mt={8}>
          {creds.length > 0 && (
            <>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1" fontWeight="bold">
                  {t("credentials")}
                </Typography>
                <Sl2Btn size="small" variant="contained" disabled>
                  {t("add")}
                </Sl2Btn>
              </Stack>
              <Divider />
            </>
          )}

          {creds.map((c) => (
            <Stack
              direction="row"
              key={c.id}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="column" spacing={1}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="body1">{t("issuanceDate")}</Typography>
                  <Box>
                    <Chip label={c.type} variant="outlined" size="small" />
                  </Box>
                </Stack>
                <Typography variant="caption">
                  {new Date(c.issuanceDate).toLocaleString()}
                </Typography>
              </Stack>

              <TextField
                variant="outlined"
                size="small"
                type={showCredId === c.id ? "text" : "password"}
                value={showCredId === c.id ? c.val : "********"}
                sx={{
                  // monospcae
                  "& .MuiInputBase-input": {
                    fontFamily: "monospace",
                    // fontSize: "1.1em",
                    width: "150px",
                    minWidth: "150px",
                    maxWidth: "150px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <Typography variant="h6" mr={2}>
                      🔑
                    </Typography>
                  ),
                  endAdornment: (
                    <IconButton
                      onClick={() =>
                        setShowCredId(showCredId === c.id ? null : c.id)
                      }
                    >
                      {showCredId === c.id ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </Stack>
          ))}
          <Divider />
        </Stack>
      </Stack>
    </Paper>
  )
}

export default OsMemberPreviewDrawer
