import { Refresh } from "@mui/icons-material"
import ArticleIcon from "@mui/icons-material/Article"
import EventNoteIcon from "@mui/icons-material/EventNote"
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest"
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material"
import { chain, pick } from "lodash"
import { FC, useCallback, useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Configuration } from "shared/configuration"
import { ConfigurationThingTypeMrn } from "shared/configurationData"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { ModeContext } from "src/ModeContext"
import {
  useGetThingsQuery,
  useUpdateThingsMutation,
} from "src/features/things/thingsApi"
import {
  selectThingsByThingTypeMrnFilter,
  thingsActions,
} from "src/features/things/thingsSlice"
import { useSearchParamsState } from "src/hooks/useSearchParamsState"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { selectTenantId } from "../features/me/meSlice"
import { OsConfigurationItem } from "./OsConfigurationItem"
import { OsConfigurationTemplateList } from "./OsConfigurationTemplateList"
import OsLayout from "./OsLayout"
import { Sl2Btn } from "./Sl2Btn"

const configsToHide = [
  "configDraftReservationExpirationMinutes",
  "configReminderPeriodDays",
]

const TabCategoryMap: Record<string, string> = {
  reservation: "0",
  preferences: "1",
  template: "2",
}

export const OsConfigurationPage: FC = () => {
  const { t } = useTranslation()
  const tenantId = useAppSelector(selectTenantId)
  const [selectedTab, setSelectedTab] = useSearchParamsState(
    "tab",
    TabCategoryMap.reservation,
  )
  // useState<number>(
  //   TabCategoryMap.reservation,
  // )
  const filter = useCallback(
    // filter configurations by selected tab && filter out config contaning 'simulate' in refName
    (t: Configuration) =>
      TabCategoryMap[t.category] === selectedTab &&
      !t.refName.includes("simulate"),
    [selectedTab],
  )
  const configs = useAppSelector((s) =>
    selectThingsByThingTypeMrnFilter(s, ConfigurationThingTypeMrn, filter),
  ) as Configuration[] | undefined

  const [dirtyConfigIds, setDirtyConfigIds] = useState<string[]>([])

  const dispatch = useAppDispatch()

  const { isLoading, refetch } = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetConfigurationsByTenant,
        "ap.tenantId": tenantId,
        tenantId,
      },
    },
    {
      skip: !tenantId,
    },
  )

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setSelectedTab(newValue + "")
    },
    [setSelectedTab],
  )

  const [updateThings, { isLoading: isUpdatingThings }] =
    useUpdateThingsMutation()

  const handleConfigChange = (newConfig: Configuration) => {
    setDirtyConfigIds([...dirtyConfigIds, newConfig.id])
    dispatch(thingsActions.updateOne({ id: newConfig.id, changes: newConfig }))
  }

  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = useCallback(async () => {
    setSnackMessage({
      message: t("savingConfigs"),
      severity: "info",
    })
    setIsSubmitting(true)

    const toUpdate = chain(configs)
      .map((config) => {
        const isDirty = dirtyConfigIds.includes(config.id)
        if (!isDirty) return null
        return pick(config, [
          "id",
          "tenantId",
          "thingTypeMrn",
          "name",
          "nVal",
          "sVal",
          "bVal",
          "oVal",
          "nVals",
          "sVals",
          "bVals",
          "oVals",
          "description",
          "objectTemplate",
          "bodyTemplate",
        ])
      })
      .compact()
      .value()

    try {
      await updateThings({
        body: {
          things: toUpdate,
        },
        params: { tenantId },
      }).unwrap()
      setDirtyConfigIds([])
      setSnackMessage({
        message: t("configsSaved"),
        severity: "success",
      })
    } catch (e) {
      console.error(e)
      setSnackMessage({
        message: t("errorWhileSavingConfigs"),
        severity: "error",
      })
    } finally {
      setIsSubmitting(false)
    }
  }, [configs, dirtyConfigIds, t, tenantId, updateThings])

  const mode = useContext(ModeContext)

  const configsToShow = useMemo(() => {
    return configs
      ?.filter((config) => !configsToHide.includes(config.refName))
      ?.sort((a, b) => a.order - b.order)
  }, [configs])

  const [snackMessage, setSnackMessage] = useState({
    message: "",
    severity: "success" as "success" | "error" | "warning" | "info" | undefined,
  })

  return (
    <OsLayout
      selectedMenuItemLabelKey="configurationModule"
      hideMenu={mode === "embedded"}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column" spacing={1} p={2} pl={4} mt={4}>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="h6">{t("configurationModule")}</Typography>
              {isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <IconButton
                  onClick={() => refetch()}
                  color="primary"
                  size="small"
                >
                  <Refresh />
                </IconButton>
              )}
            </Stack>
            <Typography variant="body2">
              {t("configurationModuleDescription")}
            </Typography>
          </Stack>
          <Box>
            <Tabs
              value={Number(selectedTab)}
              onChange={handleTabChange}
              sx={{
                height: 60,
              }}
            >
              <Tab
                label={t("reservation")}
                icon={<EventNoteIcon />}
                iconPosition="start"
              />
              {/* preferenes */}
              <Tab
                label={t("preferences")}
                icon={<SettingsSuggestIcon />}
                iconPosition="start"
              />
              <Tab
                label={t("templates")}
                icon={<ArticleIcon />}
                iconPosition="start"
              />
            </Tabs>
            <Divider />
          </Box>
          <Box p={4} />

          {selectedTab === TabCategoryMap["template"] ? (
            <OsConfigurationTemplateList
              templates={configsToShow || []}
              onChange={handleConfigChange}
            />
          ) : (
            configsToShow &&
            configsToShow?.length > 0 &&
            configsToShow.map((config, index) => (
              <OsConfigurationItem
                key={config.id}
                config={config}
                onChange={handleConfigChange}
              />
            ))
          )}
          {configs && configs?.length === 0 && !isLoading && (
            <Typography variant="body2">{t("noConfigurations")}</Typography>
          )}
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{
              pt: 2,
              pb: 4,
            }}
          >
            <Sl2Btn
              variant="contained"
              disabled={isSubmitting}
              onClick={onSubmit}
            >
              {t("save")}
            </Sl2Btn>
            <Sl2Btn variant="outlined">{t("cancel")}</Sl2Btn>
          </Stack>
        </Stack>
      </Stack>
      <Snackbar
        open={!!snackMessage?.message}
        autoHideDuration={6000}
        onClose={() => setSnackMessage({ message: "", severity: undefined })}
      >
        <Alert severity={snackMessage.severity}>{snackMessage.message}</Alert>
      </Snackbar>
    </OsLayout>
  )
}

export default OsConfigurationPage
