import {
  Alert,
  Box,
  Button,
  Divider,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material"
import Joi from "joi"
import { pick } from "lodash-es"
import React, { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { selectMe } from "../features/me/meSlice"
import { useUpdateThingsMutation } from "../features/things/thingsApi"
import FormFields, { FormField } from "./FormFields"
import { Sl2FormContainer } from "./Sl2FormContainer"

const joiSchema = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),

  profilePictureUrl: Joi.string().uri().optional(),
  bio: Joi.string().optional(),
  phoneNumber: Joi.string().pattern(
    /^(\+\d{1,2}\s?)?((\(\d{3}\))|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}$/,
  ),
  address: Joi.string().optional(),
  language: Joi.string().optional(),
  city: Joi.string().optional(),
  state: Joi.string().optional(),
  country: Joi.string().optional(),
  postalCode: Joi.string().optional(),
  newPassword: Joi.string().min(1).optional(),
  confirmPassword: Joi.string().min(1).optional(),
}).unknown(true)

export const UserProfileForm: FC = () => {
  const { user } = useSelector(selectMe)

  const { t } = useTranslation()
  const patch = useMemo(() => {
    return pick(user, [
      "id",
      "thingTypeMrn",
      "firstName",
      "lastName",
      "email",
      "phoneNumber",
      "language",
      "bio",
      "address",
      "city",
      "postalCode",
      "country",
    ])
  }, [user])
  // snack bar usestate
  const [snackbarData, setSnackbarData] = React.useState({
    severity: "success" as "success" | "error" | "info" | "warning",
    message: "",
  })

  const [updateThings, { isLoading: isUpdatingThings }] =
    useUpdateThingsMutation()
  const onSubmit = useCallback(
    async (data: any) => {
      try {
        const res = await updateThings({ body: { things: [data] } }).unwrap()

        setSnackbarData({
          severity: "success",
          message: "Profile updated successfully",
        })
      } catch (e: any) {
        console.log(e)
        setSnackbarData({
          severity: "error",
          message: `${e?.message || "Something went wrong"}`,
        })
      }
    },
    [updateThings],
  )

  const fieldsGeneral: FormField[] = [
    {
      name: "firstName",
      fieldType: "text",
      label: t("firstName"),
      required: true,
      size: "small",
      fullWidth: true,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: "lastName",
      fieldType: "text",
      label: t("lastName"),
      required: true,
      size: "small",
      fullWidth: true,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: "email",
      fieldType: "text",
      label: t("email"),
      required: true,
      size: "small",
      fullWidth: true,
      disabled: true,
      InputProps: {
        type: "email",
      },
      gridProps: { xs: 12, sm: 12 },
    },
    {
      name: "phoneNumber",
      label: t("phoneNumber"),
      fieldType: "text",
      size: "small",
      fullWidth: true,
      gridProps: { xs: 6 },
    },
    {
      name: "language",
      label: t("language"),
      fieldType: "text",
      size: "small",
      fullWidth: true,
      gridProps: { xs: 6 },
    },
    {
      name: "bio",
      label: t("bio"),
      size: "small",
      fieldType: "text",
      fullWidth: true,
      inputProps: { maxLength: 100 },
      multiline: true,
      rows: 4,
      gridProps: { xs: 12 },
    },
  ]
  const fieldsAddress: FormField[] = [
    {
      name: "address",
      label: t("address"),
      fieldType: "text",
      size: "small",
      fullWidth: true,
      gridProps: { xs: 12 },
    },
    {
      name: "city",
      label: t("city"),
      fieldType: "text",
      size: "small",
      fullWidth: true,
      gridProps: { xs: 6 },
    },
    {
      name: "postalCode",
      label: t("postalCode"),
      fieldType: "text",
      size: "small",
      fullWidth: true,
      gridProps: { xs: 6 },
    },
    {
      name: "state",
      label: t("state"),
      fieldType: "text",
      size: "small",
      fullWidth: true,
      gridProps: { xs: 6 },
    },
    {
      name: "country",
      label: t("country"),
      fieldType: "text",
      size: "small",
      fullWidth: true,
      gridProps: { xs: 6 },
    },
  ]
  const fieldsPassword: FormField[] = [
    {
      name: "newPassword",
      label: t("newPassword"),
      fieldType: "text",
      size: "small",
      fullWidth: true,
      gridProps: { xs: 12 },
      inputProps: { type: "password" },
      required: false,
    },
    // confirm password
    {
      name: "confirmPassword",
      label: t("confirmPassword"),
      fieldType: "text",
      size: "small",
      fullWidth: true,
      gridProps: { xs: 12 },
      inputProps: { type: "password" },
      required: false,
    },
  ]
  return (
    <Box>
      <Sl2FormContainer
        defaultValues={patch}
        onError={(errors: any) => console.log(errors)}
        onSuccess={onSubmit}
        joiSchema={joiSchema}
      >
        <Stack direction="column" spacing={6}>
          <FormFields fields={fieldsGeneral} />
          <Stack direction="column" spacing={1}>
            <Divider />
            <Typography variant="subtitle1" noWrap>
              {`🏠 ${t("address")}`}
            </Typography>

            <FormFields fields={fieldsAddress} />
          </Stack>
          <Stack direction="column" spacing={1}>
            <Divider />
            <Typography variant="subtitle1" noWrap>
              {`🔒 ${t("password")}`}
            </Typography>
            <Alert severity="info">{t("changePasswordDescription")}</Alert>
            <Typography variant="caption"></Typography>
            <FormFields fields={fieldsPassword} />
            {user?.lastPasswordChangeDate && (
              <Typography variant="caption">
                {t("lastPasswordChangeDate")} :{" "}
                {`${new Date(user?.lastPasswordChangeDate)}`}
              </Typography>
            )}
          </Stack>
          <Box width={"100%"}>
            <Divider />
            <Button
              variant="contained"
              size="small"
              type="submit"
              sx={{
                textTransform: "none",
                mt: 4,
              }}
              disabled={isUpdatingThings}
            >
              {t("updateProfile")}
            </Button>
          </Box>
        </Stack>
      </Sl2FormContainer>
      <Snackbar
        open={snackbarData.message !== ""}
        onClose={() =>
          setSnackbarData({
            severity: "success",
            message: "",
          })
        }
        autoHideDuration={5000}
        message={snackbarData?.message}
      >
        <Alert severity={snackbarData.severity} variant="filled">
          {snackbarData?.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default UserProfileForm
