import { Business, Close, Refresh } from "@mui/icons-material"
import {
  Divider,
  Drawer,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { Tenant } from "shared/tenant"
import { TenantThingTypeMrn } from "shared/tenantData"
import { useAppSelector } from "src/app/hooks"
import { useGetThingsQuery } from "src/features/things/thingsApi"
import { selectThingsByThingTypeMrn } from "src/features/things/thingsSlice"
import { useSearchParamsState } from "src/hooks/useSearchParamsState"
import ObjectDisplayTable from "./ObjectDisplayTable"
import SaLayout from "./SaLayout"
import SaReservationsByTenant from "./SaReservationsByTenant"
import SaTenantList from "./SaTenantList"

export const SaReservationsPage: FC = () => {
  const { t } = useTranslation()
  const qTenants = useGetThingsQuery({
    params: {
      "ap.name": SharedAccessPatternSaEnum.SaGetTenants,
    },
  })
  const tenants = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, TenantThingTypeMrn),
  ) as Tenant[]

  const refresh = useCallback(() => {
    qTenants.refetch()
  }, [qTenants])

  const isLoading = useMemo(
    () => qTenants.isLoading || qTenants.isFetching,
    [qTenants],
  )

  // const [selectedTenantId, setSelectedTenantId] = useState<string | undefined>()
  const [selectedTenantId, setSelectedTenantId] = useSearchParamsState(
    "selectedTenantId",
    "",
  )

  const selectedT = useMemo(
    () => tenants.find((t) => t.id === selectedTenantId),
    [tenants, selectedTenantId],
  )

  const [openPreviewSelectedTenant, setOpenPreviewSelectedTenant] =
    useState<boolean>(false)

  return (
    <SaLayout selectedMenuItemLabelKey="reservations">
      {/* list of tenant with menuitem */}
      <Stack direction="column" spacing={2} p={2} width="100%">
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <Typography variant="h5">{t("reservations")}</Typography>
          <IconButton onClick={refresh} disabled={isLoading}>
            <Refresh />
          </IconButton>
        </Stack>

        <Divider />
        <Stack direction="row" spacing={1} alignItems="flex-start">
          <SaTenantList
            tenants={tenants}
            isLoading={isLoading}
            selectedTenantId={selectedTenantId}
            onSelectTenant={(tenant) => setSelectedTenantId(tenant.id)}
          />
          <Stack
            direction="column"
            spacing={1}
            sx={{
              width: "100%",
            }}
          >
            {!selectedT?.id && (
              <Stack
                direction="row"
                spacing={1}
                justifyContent="center"
                sx={{
                  pt: 16,
                }}
              >
                <Paper
                  sx={{
                    p: 2,
                    width: "400px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "background.default",
                  }}
                  variant="outlined"
                >
                  <Stack direction="column" spacing={1} alignItems="center">
                    <Business fontSize="large" />
                    <Typography variant="body1">{t("selectTenant")}</Typography>
                  </Stack>
                </Paper>
              </Stack>
            )}
            {selectedT?.id && <SaReservationsByTenant tenant={selectedT} />}
          </Stack>
        </Stack>
      </Stack>
      <Drawer
        anchor="right"
        open={!!openPreviewSelectedTenant}
        onClose={() => setOpenPreviewSelectedTenant(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "80vw",
            maxWidth: "1500px",
            maxHeight: "calc(100vh - 64px)",
            height: "calc(100vh - 64px)",
            paddingTop: "64px",
          },
        }}
      >
        <Stack direction="column" spacing={1} p={2}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{
                  color: "primary.main",
                }}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Typography variant="body1" fontWeight="bold">
                  {selectedT?.name}
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {selectedT?.id}
                </Typography>
              </Stack>
            </Stack>
            <IconButton onClick={() => setOpenPreviewSelectedTenant(false)}>
              <Close />
            </IconButton>
          </Stack>
          <Divider />

          <ObjectDisplayTable data={selectedT} />
        </Stack>
      </Drawer>
    </SaLayout>
  )
}

export default SaReservationsPage
