import CloseIcon from "@mui/icons-material/Close"
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Drawer,
  FormLabel,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material"
import { FC } from "react"
import { appConfig } from "../appConfig"

import { useUser } from "@clerk/clerk-react"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import GroupIcon from "@mui/icons-material/Group"
import SettingsIcon from "@mui/icons-material/Settings"
import SupportAgentIcon from "@mui/icons-material/SupportAgent"
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser"
import { SelectInputProps } from "@mui/material/Select/SelectInput"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { User } from "shared/user"
import i18n from "src/i18n/i18n"
import { useAppSelector } from "../app/hooks"
import defaultAvatar from "../assets/default_avatar.png"
import { selectMe, selectOnlinePresence } from "../features/me/meSlice"
import { UnstyledLink } from "./UnstyledLink"

export type UsMenuItem = {
  labelKey: string
  icon: JSX.Element
  url: string
  divider: boolean
  disabled: boolean
  visible?: boolean
}
export const usMenuItems = (user: User) => {
  return [
    {
      labelKey: "profile",
      icon: <AccountCircleIcon />,
      url: "/us/profile",
      divider: false,
      disabled: false,
      visible: true,
    },
    {
      labelKey: "organizations",
      icon: <GroupIcon />,
      url: "/us/organizations",
      divider: false,
      disabled: false,
      visible: true,
    },
    {
      labelKey: "adminApp",
      icon: <VerifiedUserIcon />,
      url: "/sa/home",
      divider: false,
      disabled: false,
      visible: !!user.isSuperAdmin,
    },
    {
      labelKey: "settings",
      icon: <SettingsIcon />,
      url: "/us/settings",
      divider: false,
      disabled: true,
      visible: true,
    },
    {
      labelKey: "support",
      icon: <SupportAgentIcon />,
      url: "/support",
      divider: true,
      disabled: true,
      visible: true,
    },
    {
      labelKey: "signout",
      icon: <ExitToAppIcon />,
      url: "/signout",
      divider: false,
      disabled: false,
      visible: true,
    },
  ] as UsMenuItem[]
}

export type UserDrawerProps = {
  onClose: () => void
}

export const UserDrawer: FC<UserDrawerProps> = ({ onClose }) => {
  const { t } = useTranslation()
  const clerkIdentity = useUser()
  const { user } = useSelector(selectMe)
  const onlinePresence = useAppSelector(selectOnlinePresence)

  const handleLanguageChange: SelectInputProps<unknown>["onChange"] = (
    event,
  ) => {
    const newLanguage = event.target.value as string
    localStorage.setItem("language", newLanguage)
    i18n.changeLanguage(newLanguage)
  }

  const fullName = `${user?.firstName} ${user?.lastName}`
  return (
    <Drawer
      open={true}
      onClose={onClose}
      anchor="right"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
    >
      <Stack
        direction="column"
        sx={{ width: appConfig.userDrawerWidth }}
        p={1}
        pt={3}
        alignContent="space-between"
      >
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
              sx={{
                "& .MuiBadge-dot": {
                  backgroundColor: onlinePresence ? "green" : "grey",
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                },
              }}
              // color={isOnline ? "success" :
            >
              <Avatar
                alt="User"
                src={clerkIdentity?.user?.imageUrl || defaultAvatar}
                sx={{
                  width: 48,
                  height: 48,
                }}
              />
            </Badge>

            <Stack direction="column">
              <Typography fontWeight="bold">{fullName}</Typography>
              <Typography fontSize="0.9em">{user?.email}</Typography>
            </Stack>
          </Stack>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider sx={{ my: 3 }} />
        {user && (
          <List>
            {usMenuItems(user)
              .filter((i) => i.visible)
              .map((item, index) => (
                <UnstyledLink to={item.url} key={index}>
                  <ListItemButton
                    divider={item.divider}
                    disabled={item.disabled}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <ListItemIcon
                        // black
                        sx={{ color: "rgba(0, 0, 0, 0.74)" }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={t(item.labelKey)} />
                    </Stack>
                  </ListItemButton>
                </UnstyledLink>
              ))}
          </List>
        )}
        <Box p={1} mt={3}>
          <FormLabel> {t("preferedLang")}</FormLabel>
          <Select
            variant="outlined"
            value={localStorage.getItem("language") || "fr"}
            size="small"
            onChange={handleLanguageChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            fullWidth
          >
            <MenuItem value="en">{t("english")}</MenuItem>
            <MenuItem value="fr">{t("french")}</MenuItem>
          </Select>
        </Box>
      </Stack>
    </Drawer>
  )
}
