import { Delete } from "@mui/icons-material"
import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  SxProps,
  TextField,
} from "@mui/material"
import { t } from "i18next"
import { isArray } from "lodash"
import { FC, useState } from "react"
import {
  Attribute,
  AttributeFilter,
  DateOperand,
  MultiSelectOption,
} from "shared/report"
import { dateOperands, filterOperators } from "shared/reportData"

type AttributeFilterProps = {
  value: AttributeFilter
  onChange: (value: AttributeFilter) => void
  onDelete: () => void
  attributes: Attribute[]
  error: string | null | undefined
}

const commonSx: SxProps = {
  fontSize: "0.9em",
  height: 30,
  minWidth: 150,
  // width: 300,
  boxShadow: "none",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  // vertical align center
  display: "flex",
  alignItems: "center",
}

export const AttributeFilterControllers: FC<AttributeFilterProps> = ({
  value,
  onChange,
  onDelete,
  attributes,
  error,
}) => {
  const [selectedAttribute, setSelectedAttribute] = useState<Attribute>(
    attributes.find((attr) => attr.name === value.attribute) || attributes[0],
  )
  const [selectedDateOperand, setSelectedDateOperand] =
    useState<DateOperand | null>(
      dateOperands.find((op: DateOperand) => op.name === value?.value0) || null,
    )

  const handleAttributeChange = (event: any) => {
    const attribute = attributes.find(
      (attr) => attr.name === event.target.value,
    )
    if (attribute) {
      setSelectedAttribute(attribute)
      setSelectedDateOperand(null)

      onChange({ attribute: attribute.name, operator: "", value0: "" })
    }
  }

  const handleOperatorChange = (event: any) => {
    onChange({ ...value, operator: event.target.value as string })
  }

  const handleValue0Change = (event: any) => {
    const dateOperand = dateOperands.find(
      (op: DateOperand) => op.name === event.target.value,
    )
    setSelectedDateOperand(dateOperand || null)
    console.log("event.target.value", event.target.value)
    onChange({ ...value, value0: event.target.value as string })
  }
  const handleValue1Change = (event: any) => {
    onChange({ ...value, value1: event.target.value as string })
  }
  const handlesValsChange = (event: any) => {
    onChange({
      ...value,
      sVals: event.target.value as string[],
      //  value required for validation
      value0: "multiSelect",
    })
  }

  return (
    <Stack direction="column" spacing={0}>
      <Stack direction="row" spacing={0} alignItems="center">
        <Stack
          direction="row"
          spacing={0}
          alignItems="center"
          sx={{
            border: "1px solid #e0e0e0",
          }}
        >
          {/* 4 possible input/select : attribute, operator, value0, value1 */}
          <Select
            value={value?.attribute || ""}
            onChange={handleAttributeChange}
            size="small"
            placeholder="Attribute"
            sx={{
              ...commonSx,
              borderRight: "1px solid #e0e0e0",
              // width: 400,
            }}
          >
            {attributes.map((attr) => (
              <MenuItem
                key={attr.name}
                value={attr.name}
                sx={{
                  fontSize: "0.9em",
                }}
              >
                {t(attr.name)}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={value?.operator}
            onChange={handleOperatorChange}
            size="small"
            sx={{
              ...commonSx,
              borderRight: "1px solid #e0e0e0",
            }}
          >
            {isArray(filterOperators[selectedAttribute.type]) &&
              filterOperators[selectedAttribute.type]?.map(
                (operator: string) => (
                  <MenuItem
                    key={operator}
                    value={operator}
                    sx={{
                      fontSize: "0.9em",
                    }}
                  >
                    {t(operator)}
                  </MenuItem>
                ),
              )}
          </Select>
          <Box sx={commonSx}>
            {
              {
                boolean: (
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{ width: 100 }}
                    justifyContent={"center"}
                  >
                    <Checkbox
                      checked={value?.value0 === "true"}
                      onChange={handleValue0Change}
                      size="small"
                    />
                  </Stack>
                ),

                date: (
                  <Select
                    value={value?.value0}
                    onChange={handleValue0Change}
                    size="small"
                    sx={{
                      ...commonSx,
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    {dateOperands.map((op: DateOperand) => (
                      <MenuItem
                        key={op.name}
                        value={op.name}
                        sx={{
                          fontSize: "0.9em",
                        }}
                      >
                        {t(op.name)}
                      </MenuItem>
                    ))}
                  </Select>
                ),
                text: (
                  <TextField
                    sx={{
                      ...commonSx,
                      ".MuiInputBase-input": {
                        fontSize: "0.9em",
                        margin: 0,
                        py: 0.5,
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    // label="Value"
                    placeholder="Value"
                    value={value?.value0}
                    onChange={handleValue0Change}
                    type={"text"}
                    size="small"
                  />
                ),
                multiSelect: (
                  <Select
                    value={value?.sVals || []}
                    onChange={handlesValsChange}
                    variant="outlined"
                    size="small"
                    multiple
                    sx={{
                      ...commonSx,
                      borderRight: "1px solid #e0e0e0",
                    }}
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected?.map((value: string) => (
                          <Chip
                            key={value}
                            label={t(
                              selectedAttribute?.options?.find(
                                (op: MultiSelectOption) => op.value === value,
                              )?.label || value,
                            )}
                            size="small"
                            sx={{
                              fontSize: "0.9em",
                            }}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {selectedAttribute?.options?.map((op) => (
                      <MenuItem
                        key={op.value}
                        value={op.value}
                        sx={{
                          fontSize: "0.9em",
                        }}
                      >
                        {t(op.label)}
                      </MenuItem>
                    ))}
                  </Select>
                ),
                number: (
                  <TextField
                    sx={{
                      ...commonSx,
                      ".MuiInputBase-input": {
                        fontSize: "0.9em",
                        margin: 0,
                        py: 0.5,
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    // label="Value"
                    placeholder="Value"
                    value={value?.value0}
                    onChange={handleValue0Change}
                    type={"number"}
                    size="small"
                    // integer
                    inputProps={{ min: 0 }}
                  />
                ),
                integer: (
                  <TextField
                    sx={{
                      ...commonSx,
                      ".MuiInputBase-input": {
                        fontSize: "0.9em",
                        margin: 0,
                        py: 0.5,
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    // label="Value"
                    placeholder="Value"
                    value={value?.value0}
                    onChange={handleValue0Change}
                    type={"number"}
                    size="small"
                    // integer
                    inputProps={{ min: 0 }}
                  />
                ),
                unknown: <div></div>,
              }[(selectedAttribute.type || "unknown") as string]
            }
          </Box>
          {selectedAttribute?.type === "date" &&
            selectedDateOperand?.associatedFieldType && (
              <Box sx={commonSx}>
                {
                  {
                    text: (
                      <TextField
                        sx={{
                          ...commonSx,
                          ".MuiInputBase-input": {
                            fontSize: "0.9em",
                            margin: 0,
                            py: 0.5,
                          },
                        }}
                        fullWidth
                        variant="outlined"
                        placeholder="Value"
                        value={value?.value1}
                        onChange={handleValue1Change}
                        type={"text"}
                        size="small"
                      />
                    ),
                    date: (
                      <TextField
                        sx={{
                          ...commonSx,
                          ".MuiInputBase-input": {
                            fontSize: "0.9em",
                            margin: 0,
                            py: 0.5,
                          },
                        }}
                        fullWidth
                        variant="outlined"
                        placeholder="Value"
                        value={value?.value1}
                        onChange={handleValue1Change}
                        type={"date"}
                        size="small"
                      />
                    ),
                    number: (
                      <TextField
                        sx={{
                          ...commonSx,
                          ".MuiInputBase-input": {
                            fontSize: "0.9em",
                            margin: 0,
                            py: 0.5,
                          },
                        }}
                        fullWidth
                        variant="outlined"
                        // label="Value"
                        placeholder="Value"
                        value={value?.value1}
                        onChange={handleValue1Change}
                        type={"number"}
                        size="small"
                        // integer

                        inputProps={{ min: 0 }}
                      />
                    ),
                    unknown: <div></div>,
                  }[
                    (selectedDateOperand?.associatedFieldType ||
                      "unknown") as string
                  ]
                }
              </Box>
            )}
        </Stack>
        <IconButton
          size="small"
          sx={{
            borderRadius: 0,
            borderLeft: "1px solid #e0e0e0",
          }}
          onClick={onDelete}
        >
          <Delete />
        </IconButton>
      </Stack>
      {error && (
        <Box sx={{ color: "error.main", fontSize: "0.8em" }}>
          {t("attributeFilterInvalidPleaseFix")}
        </Box>
      )}
    </Stack>
  )
}
