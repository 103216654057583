import RefreshIcon from "@mui/icons-material/Refresh"
import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import {
  DataGrid,
  DataGridProps,
  GridCloseIcon,
  GridSearchIcon,
} from "@mui/x-data-grid"
import { TFunction } from "i18next"
import { noop } from "lodash"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { TenantUser } from "shared/tenantUser"
import { TenantUserThingTypeMrn } from "shared/tenantUserData"
import { User } from "shared/user"
import { UserThingTypeMrn } from "shared/userData"
import { useAppSelector } from "../app/hooks"
import {
  selectCurrentTenant,
  selectMe,
  selectMeIsReady,
  selectTenantId,
} from "../features/me/meSlice"
import { useGetThingsQuery } from "../features/things/thingsApi"
import { selectThingsByThingTypeMrnFilter } from "../features/things/thingsSlice"
import { OsAddOrgMember } from "./OsAddOrgMemberDialog"
import { Sl2Btn } from "./Sl2Btn"

const defaultColWidth = 200

const columns = (
  t: TFunction,
  currentUserId: string,
  tenantOwnerId: string,
): DataGridProps["columns"] => [
  { field: "firstName", headerName: t("firstName"), width: defaultColWidth },
  { field: "lastName", headerName: t("lastName"), width: defaultColWidth },
  {
    field: "email",
    headerName: t("email"),
    width: 300,
    renderCell: (params) => {
      const isTenantOwner = params?.row?.id === tenantOwnerId
      const isCurrentUser =
        currentUserId !== "" && params?.row?.id === currentUserId

      return (
        <Stack direction="column" mb={1}>
          <Box>
            {isCurrentUser && (
              <Chip
                label="Current User"
                variant="outlined"
                size="small"
                sx={{
                  mt: 1,
                  mr: 2,
                }}
              />
            )}
            {isTenantOwner && (
              <Chip
                label="Owner"
                variant="outlined"
                size="small"
                sx={{
                  mt: 1,
                }}
              />
            )}
          </Box>
          <a href={`mailto:${params.value}`} target="_blank" rel="noreferrer">
            {params.value}
          </a>
        </Stack>
      )
    },
  },
  {
    field: "phoneNumber",
    headerName: t("phoneNumber"),
    width: defaultColWidth,
  },
  {
    field: "status",
    headerName: t("status"),
    width: defaultColWidth,
    renderCell: (params) => {
      return <Chip label={params.value} size="small" />
    },
  },
  // created at
  {
    field: "createdAt",
    headerName: t("createdAt"),
    width: defaultColWidth,
    renderCell: (params) => {
      return (
        <Typography>
          {new Date(params.value as string).toLocaleDateString()}
        </Typography>
      )
    },
  },
]
export type OsIamMembersProps = {
  onMemberClick?: (userId: string) => void
}
export const OsIamMembers: FC<OsIamMembersProps> = ({
  onMemberClick = noop,
}) => {
  const { t } = useTranslation()
  const { user } = useAppSelector(selectMe)
  const tenantId = useAppSelector(selectTenantId)
  const currentTenant = useAppSelector(selectCurrentTenant)

  const isReady = useAppSelector(selectMeIsReady)
  const { isLoading, refetch } = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetUsersByTenant,
        "ap.tenantId": tenantId,
        tenantId,
      },
    },
    {
      skip: !tenantId || !isReady,
    },
  )
  const tenantUsersFilter = useCallback(
    (tu: TenantUser) => {
      return tu.tenantId === tenantId
    },
    [tenantId],
  )
  const tenantUsers = useAppSelector((s) =>
    selectThingsByThingTypeMrnFilter(
      s,
      TenantUserThingTypeMrn,
      tenantUsersFilter,
    ),
  ) as TenantUser[]

  const allUsersFilter = useCallback(
    (u: User) => {
      return tenantUsers.some((tu) => tu.userId === u.id)
    },
    [tenantUsers],
  )

  const allUsers = useAppSelector((s) =>
    selectThingsByThingTypeMrnFilter(s, UserThingTypeMrn, allUsersFilter),
  ) as User[]

  const [isAddMemberDialogOpen, setIsAddMemberDialogOpen] = useState(false)

  const [searchToken, setSearchToken] = useState("")

  const handleSearchTokenChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchToken(event.target.value)
  }

  // filter users by searchToken in useMemo
  const users = useMemo(() => {
    return allUsers.filter((u) => {
      return (
        u.firstName?.toLowerCase().includes(searchToken.toLowerCase()) ||
        u.lastName?.toLowerCase().includes(searchToken.toLowerCase()) ||
        u.email?.toLowerCase().includes(searchToken.toLowerCase()) ||
        u.phoneNumber?.toLowerCase().includes(searchToken.toLowerCase())
      )
    })
  }, [allUsers, searchToken])

  if (isLoading || !isReady) {
    return (
      <Stack direction="column" spacing={2}>
        <Skeleton variant="rectangular" width="100%" height={118} />
        <Skeleton variant="rectangular" width="100%" height={118} />
        <Skeleton variant="rectangular" width="100%" height={118} />
        <Skeleton variant="rectangular" width="100%" height={118} />
      </Stack>
    )
  }

  return (
    <Box
      sx={{
        width: "fit-content",
      }}
    >
      <Stack direction="row" spacing={2} justifyContent="space-between" mb={3}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          // width="100%"
          mb={3}
        >
          <Typography variant="h6">{`${t("members")} (${
            users.length
          })`}</Typography>
          <IconButton onClick={refetch}>
            <RefreshIcon />
          </IconButton>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Sl2Btn
            variant="contained"
            onClick={() => setIsAddMemberDialogOpen(true)}
            sx={{ width: 300 }}
          >
            {t("addMember")}
          </Sl2Btn>
          <TextField
            variant="outlined"
            placeholder={t("search")}
            value={searchToken}
            onChange={handleSearchTokenChange}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <GridSearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearchToken("")}>
                    <GridCloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              mt: 2,
              mb: 2,
              minWidth: 300,
            }}
          />
        </Stack>
      </Stack>
      <Box
        sx={{
          width: "fit-content",
          // height: "100%",
          // maxWidth: "100%",
          // overflowX: "scroll",
        }}
      >
        <DataGrid
          columns={columns(t, user?.id || "", currentTenant?.ownerId || "")}
          rows={users}
          localeText={{
            noRowsLabel: t("noMembersFound"),
          }}
          autoHeight
          onRowClick={(params) => onMemberClick(params?.row?.id as string)}
          rowSelection={false}
          sx={{
            // disable cell selection style
            ".MuiDataGrid-cell:focus": {
              outline: "none",
            },
            // pointer cursor on ALL rows
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
        />
      </Box>
      {isAddMemberDialogOpen && (
        <OsAddOrgMember
          onClose={() => {
            setIsAddMemberDialogOpen(false)
          }}
          onComplete={() => {
            setIsAddMemberDialogOpen(false)
          }}
        />
      )}
    </Box>
  )
}

export default OsIamMembers
