import { useSearchParams } from "react-router-dom"

export function useSearchParamsState(
  searchParamName: string,
  defaultValue: string,
): readonly [
  searchParamsState: string,
  setSearchParamsState: (newState: string, clearAllOther?: boolean) => void,
] {
  const [searchParams, setSearchParams] = useSearchParams()

  const acquiredSearchParam = searchParams.get(searchParamName)
  const searchParamsState = acquiredSearchParam ?? defaultValue

  const setSearchParamsState = (newState: string, clearAllOther?: boolean) => {
    let next: Record<string, string>

    if (clearAllOther) {
      next = { [searchParamName]: newState }
    } else {
      next = Object.assign(
        {},
        [...searchParams.entries()].reduce(
          (o, [key, value]) => ({ ...o, [key]: value }),
          {},
        ),
        { [searchParamName]: newState },
      )
    }

    setSearchParams(next, {
      replace: true,
    })
  }

  return [searchParamsState, setSearchParamsState] as const
}
