import NotificationsIcon from "@mui/icons-material/Notifications"
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard"
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser"
import { Badge, Box, IconButton, Popover, Stack } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { FC, useCallback, useContext, useRef } from "react"
import { useSelector } from "react-redux"
import { ActionReq } from "shared/actionReq"
import { ActionReqThingTypeMrn } from "shared/actionReqData"
import { ActionReqStep } from "shared/actionReqStep"
import { ActionReqStepThingTypeMrn } from "shared/actionReqStepData"
import { ModeContext } from "src/ModeContext"
import { selectThingsByThingTypeMrn } from "src/features/things/thingsSlice"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { RootState } from "../app/store"
import { appConfig } from "../appConfig"
import {
  selectIsNotificationPopupOpen,
  selectMeErrorMessage,
  selectOnlinePresence,
  setNotificationPopupOpen,
} from "../features/me/meSlice"
import { useCurrentTenant } from "../hooks/useCurrentTenant"
import { OsActionReqList } from "./OsActionReqList"
import TraceTooltip from "./TraceTooltip"
import { UserAvatar } from "./UserAvatar"
export type MainAppbarProps = {
  showTenant?: boolean
  isSuperAdmin?: boolean
}
const MainAppbar: FC<MainAppbarProps> = ({
  showTenant = true,
  isSuperAdmin = false,
}) => {
  const errorMessage = useSelector(selectMeErrorMessage)

  const mode = useContext(ModeContext)

  const dispatch = useAppDispatch()

  const notifPopoverAnchorEl = useRef<HTMLButtonElement>(null)

  const notifPopoverOpen = useAppSelector(selectIsNotificationPopupOpen)

  const handlePopoverClick = useCallback(() => {
    dispatch(setNotificationPopupOpen(true))
  }, [dispatch])

  const handlePopoverClose = useCallback(() => {
    dispatch(setNotificationPopupOpen(false))
  }, [dispatch])

  const { tenant } = useCurrentTenant()

  const onlinePresence = useAppSelector(selectOnlinePresence)

  const actionReqs = useAppSelector((s: RootState) =>
    selectThingsByThingTypeMrn(s, ActionReqThingTypeMrn),
  ) as ActionReq[]

  //actionReqSteps
  const actionReqSteps = useAppSelector((s: RootState) =>
    selectThingsByThingTypeMrn(s, ActionReqStepThingTypeMrn),
  ) as ActionReqStep[]

  if (mode === "embedded") {
    return (
      <Box
        sx={{
          // make it invisible
          display: "none",
        }}
      >
        <UserAvatar isOnline={onlinePresence} />
      </Box>
    )
  }
  return (
    <AppBar
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "white",
        color: isSuperAdmin ? "black" : "white",
        height: appConfig.mainAppBarHeight,
      }}
      variant={"outlined"}
      elevation={0}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          {isSuperAdmin && (
            <>
              <VerifiedUserIcon color="primary" />
              <Typography variant="h6" noWrap component="div" color="primary">
                SL2 Admin
              </Typography>
            </>
          )}
          {!isSuperAdmin && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                variant="h6"
                noWrap
                fontWeight={"bold"}
                color="primary"
              >
                {appConfig.appName}
              </Typography>
              <SpaceDashboardIcon
                sx={{
                  color: "secondary.main",
                }}
              />
            </Stack>
          )}
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <IconButton onClick={handlePopoverClick} ref={notifPopoverAnchorEl}>
            <Badge badgeContent={actionReqs.length} color="error">
              <NotificationsIcon
                sx={{
                  color: "primary.main",
                }}
              />
            </Badge>
          </IconButton>
          {tenant?.id && showTenant && (
            <TraceTooltip
              text={tenant.traceId || ""}
              tooltipTitle={
                <Box
                  sx={{
                    backgroundColor: "grey.200",
                    borderRadius: "5px",
                    p: 1,
                    minWidth: "150px",
                    height: "20px",
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "center",
                    flexDirection: "row",
                    // border: "1px solid grey",
                  }}
                >
                  <Box sx={{ mr: 2 }}>🏢</Box>
                  <Typography variant="subtitle1">{tenant.name}</Typography>
                </Box>
              }
            />
          )}
          <UserAvatar isOnline={onlinePresence} />
        </Stack>
      </Toolbar>
      <Popover
        id={"notif-popover"}
        open={notifPopoverOpen}
        anchorEl={notifPopoverAnchorEl.current}
        // onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right", // Aligns the right side of the popover with the right side of the anchor element
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right", // Aligns the right side of the popover with the right side of the anchor element
        }}
      >
        <OsActionReqList
          onClose={handlePopoverClose}
          actionReqs={actionReqs}
          actionReqSteps={actionReqSteps}
        />
      </Popover>
      {errorMessage && (
        <Box
          sx={{
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "white !important",
            color: "red",
          }}
        >
          {errorMessage}
        </Box>
      )}
    </AppBar>
  )
}

export default MainAppbar
