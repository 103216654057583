import { Tab, TabProps } from "@mui/material"
import { FC } from "react"

export const Sl2Tab: FC<TabProps> = (props) => {
  const { sx, ...rest } = props
  return (
    <Tab
      sx={{
        ...sx,
        textTransform: "none",
        fontSize: "1rem",
        height: "2rem",
        maxHeight: "2rem",
        lineHeight: "2rem",
      }}
      {...rest}
    />
  )
}
