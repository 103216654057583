import RefreshIcon from "@mui/icons-material/Refresh"
import {
  Box,
  IconButton,
  InputAdornment,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import {
  DataGrid,
  DataGridProps,
  GridCloseIcon,
  GridSearchIcon,
} from "@mui/x-data-grid"
import { TFunction } from "i18next"
import { noop } from "lodash"
import { FC, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Group } from "shared/group"
import { GroupThingTypeMrn } from "shared/groupData"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { useAppSelector } from "../app/hooks"
import { selectTenantId } from "../features/me/meSlice"
import { useGetThingsQuery } from "../features/things/thingsApi"
import { selectThingsByThingTypeMrn } from "../features/things/thingsSlice"
import { Sl2Btn } from "./Sl2Btn"

const defaultColWidth = 200

const columns = (t: TFunction): DataGridProps["columns"] => [
  { field: "name", headerName: t("name"), width: defaultColWidth * 2 },
  {
    field: "createdAt",
    headerName: t("createdAt"),
    width: defaultColWidth,
    renderCell: (params) => {
      return <Box>{new Date(params.value as string).toLocaleString()}</Box>
    },
  },
]
export type OsIamGroupsProps = {
  onGroupClick?: (groupId: string) => void
  onCreate?: () => void
}
export const OsIamGroups: FC<OsIamGroupsProps> = ({
  onGroupClick = noop,
  onCreate = noop,
}) => {
  const { t } = useTranslation()
  const tenantId = useAppSelector(selectTenantId)
  const { isLoading, refetch } = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetGroupsByTenant,
        "ap.tenantId": tenantId,
        tenantId,
      },
    },
    {
      skip: !tenantId,
    },
  )
  const allGroups = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, GroupThingTypeMrn),
  ) as Group[]

  // useState for searchToken
  const [searchToken, setSearchToken] = useState("")

  const handleSearchTokenChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchToken(event.target.value)
  }

  // filter users by searchToken in useMemo

  const groups = useMemo(() => {
    return allGroups.filter((u) => {
      return u.name?.toLowerCase().includes(searchToken.toLowerCase())
    })
  }, [searchToken, allGroups])

  if (isLoading) {
    return (
      <Stack direction="column" spacing={2}>
        <Skeleton variant="rectangular" width="100%" height={118} />
        <Skeleton variant="rectangular" width="100%" height={118 / 2} />
        <Skeleton variant="rectangular" width="100%" height={118} />
        <Skeleton variant="rectangular" width="100%" height={118 / 2} />
      </Stack>
    )
  }
  if (!allGroups || allGroups?.length === 0) {
    return (
      <Paper sx={{ p: 2 }} variant="outlined">
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          {/* refrech icon on the right */}
          <Stack
            direction="row"
            spacing={2}
            alignItems="flex-end"
            justifyContent="flex-end"
            width="100%"
            mb={3}
          >
            <IconButton onClick={refetch}>
              <RefreshIcon />
            </IconButton>
          </Stack>
          <Typography variant="h2">🤷‍♂️</Typography>
          <Typography variant="h6">{t("noGroups")}</Typography>
          <Typography variant="subtitle1">
            {t("noGroupsDescription")}
          </Typography>
          {/* <Sl2Btn variant="outlined" onClick={onCreate}>
            {t("createGroup")}
          </Sl2Btn> */}
        </Stack>
      </Paper>
    )
  }

  return (
    <Box
      sx={{
        width: "100%",
        minWidth: 900,
      }}
    >
      <Sl2Btn
        variant="outlined"
        onClick={onCreate}
        sx={{
          mb: 6,
        }}
      >
        {t("createGroup")}
      </Sl2Btn>
      <Stack direction="row" spacing={2} justifyContent="space-between" mb={3}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          width="100%"
          mb={3}
        >
          <Typography variant="h6">{t("groups")}</Typography>
          <IconButton onClick={refetch}>
            <RefreshIcon />
          </IconButton>
        </Stack>

        <TextField
          variant="outlined"
          placeholder={t("search")}
          value={searchToken}
          onChange={handleSearchTokenChange}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <GridSearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearchToken("")}>
                  <GridCloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            mt: 2,
            mb: 2,
            minWidth: 300,
          }}
        />
      </Stack>
      <Box>
        <DataGrid
          columns={columns(t)}
          rows={groups}
          localeText={{
            noRowsLabel: t("noGroupsFound"),
          }}
          autoHeight
          onRowClick={(params) => onGroupClick(params?.row?.id as string)}
          rowSelection={false}
          sx={{
            // disable cell selection style
            ".MuiDataGrid-cell:focus": {
              outline: "none",
            },
            // pointer cursor on ALL rows
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
        />
      </Box>
    </Box>
  )
}

export default OsIamGroups
