import { Box, CircularProgress, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useSelector } from "react-redux"
import { selectMe } from "src/features/me/meSlice"

import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import logo from "../assets/mobilus_logo.png"
import { Sl2Btn } from "./Sl2Btn"

export const PublicHomePage: FC = () => {
  const { t } = useTranslation()
  const { errorMessage } = useSelector(selectMe)
  const [searchParams] = useSearchParams()
  const _postSignup = searchParams.get("postSignup") === "true"
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        padding: 2,
      }}
    >
      <img src={logo} alt="Mobilus Logo" />
      <Typography variant="h2" component="div" gutterBottom mt={10}>
        Smart Locker made easy
      </Typography>
      <Typography variant="body1" gutterBottom>
        Powered by Mobilus Technologies inc.
      </Typography>
      <Stack direction="column" spacing={2} mt={2} alignItems={"center"}>
        <Sl2Btn
          variant="contained"
          color="primary"
          href="/login"
          sx={{ width: 200 }}
        >
          {t("login")}
        </Sl2Btn>
      </Stack>
      {errorMessage && !_postSignup && (
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          sx={{ mt: 4 }}
        >
          <Typography variant="body1" color="error">
            {errorMessage}
          </Typography>
          <Typography variant="body1">Please try again</Typography>
        </Stack>
      )}
      {_postSignup && <CircularProgress />}
    </Box>
  )
}

export default PublicHomePage
