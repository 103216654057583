import { useAuth } from "@clerk/clerk-react"
import { Settings } from "@mui/icons-material"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import DashboardIcon from "@mui/icons-material/Dashboard"
import EventNoteIcon from "@mui/icons-material/EventNote"
import LockIcon from "@mui/icons-material/Lock"
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"
import SpeedIcon from "@mui/icons-material/Speed"
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import Toolbar from "@mui/material/Toolbar"
import { mqtt5 } from "aws-iot-device-sdk-v2"

import { get } from "lodash"
import moment from "moment"
import * as React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { DefaultGroups } from "shared/groupData"
import { iamActionsMap } from "shared/iamActionData"
import { Locker } from "shared/locker"
import { LockerThingTypeMrn } from "shared/lockerData"
import { ReservationAttempt } from "shared/reservationAttempt"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { ModeContext } from "src/ModeContext"
import { useGetMblsConnectorClientsQuery } from "src/features/mblsConnector/mblsConenctorApi"
import { useGetMeQuery } from "src/features/me/meApi"
import { thingsApi, useGetThingsQuery } from "src/features/things/thingsApi"
import useClearMblsReservation from "src/hooks/useClearMblsReservation"
import { useCurrentTenant } from "src/hooks/useCurrentTenant"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { appConfig } from "../appConfig"
import {
  selectMe,
  selectMeIsReady,
  selectTenantId,
  setTenantId,
} from "../features/me/meSlice"
import {
  selectMblsReservationClientId,
  selectThingsByThingTypeMrn,
  selectUnauthorizedMessage,
  thingsActions,
} from "../features/things/thingsSlice"
import { MrtConnection } from "../iot/MrtConnection"
import MainAppbar from "./MainAppbar"
import { MblsReservationBanner } from "./MblsReservationBanner"
import { Sl2Btn } from "./Sl2Btn"
import { UnstyledLink } from "./UnstyledLink"

const isNullish = (value: any) =>
  value === null ||
  value === undefined ||
  value === "null" ||
  value === "undefined"
export const osMenuItems = ({
  mode,
}: {
  mode: string
}): {
  labelKey: string
  icon?: JSX.Element
  url: string
  divider?: boolean
  disabled?: boolean
  requiredGroups: string[]
  requiredIamActions?: string[]
}[] => {
  return [
    {
      labelKey: "lockers",
      icon: <LockIcon />,
      url: mode === "embedded" ? "/os/embedded/lockers" : "/os/lockers",
      // disabled: true,
      requiredGroups: [DefaultGroups.UserGroup],
      requiredIamActions: [iamActionsMap.ModLockerAccess],
    },
    {
      labelKey: "reservations",
      icon: <EventNoteIcon />,
      url: "/os/reservations",
      // disabled: true,
      requiredGroups: [DefaultGroups.UserGroup],
      requiredIamActions: [iamActionsMap.ModLockerAccess],
    },
    {
      labelKey: "clients",
      icon: <PeopleAltIcon />,
      url: "/os/clients",
      requiredGroups: [DefaultGroups.UserGroup],
      requiredIamActions: [iamActionsMap.ModLockerAccess],
    },
    {
      labelKey: "reporting",
      icon: <DashboardIcon />,
      url: "/os/reporting/reservations",
      // disabled: true,
      requiredGroups: [DefaultGroups.UserGroup],
      requiredIamActions: [iamActionsMap.ModLockerAccess],
    },
    {
      labelKey: "KPIs",
      icon: <SpeedIcon />,
      url: "/os/reporting/kpis",
      // disabled: true,
      requiredGroups: [DefaultGroups.UserGroup],
      requiredIamActions: [iamActionsMap.ModLockerAccess],
    },
    {
      labelKey: "iamModule",
      icon: <AccountCircleIcon />,
      url: "/os/iam",
      requiredGroups: [DefaultGroups.AdminGroup],
      requiredIamActions: [iamActionsMap.ModIamAccess],
    },
    {
      labelKey: "configurationModule",
      icon: <Settings />,
      url: "/os/configuration",
      requiredGroups: [DefaultGroups.AdminGroup],
      requiredIamActions: [iamActionsMap.ModConfigAccess],
    },
  ]
}

const OsMenu: React.FC<{
  selectedMenuItemLabelKey?: string
  isCollapsed: boolean
}> = ({ selectedMenuItemLabelKey, isCollapsed }) => {
  const { t } = useTranslation()

  const mode = React.useContext(ModeContext)

  const { tu } = useSelector(selectMe)

  const dispatch = useAppDispatch()

  const tenantId = useSelector(selectTenantId)

  // get tenantId from search params
  const [search] = useSearchParams()
  const urlTenantId = search.get("tenantId")

  const lockers = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, LockerThingTypeMrn),
  ) as Locker[]

  useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetLockers,
        "ap.tenantId": tenantId,
        tenantId,
      },
    },
    {
      skip: !tenantId,
    },
  )

  React.useEffect(() => {
    if (urlTenantId && tenantId !== urlTenantId) {
      dispatch(setTenantId(urlTenantId))
    }
  }, [dispatch, tenantId, urlTenantId])

  const items = React.useMemo(
    () =>
      osMenuItems({ mode })
        .filter(
          // requiredGroups must overlap with user?.groups
          (item) => {
            let isVisible = tu?.iamActionRefNames?.some((a: string) =>
              item.requiredIamActions?.includes(a),
            )
            if (
              mode === "embedded" &&
              ["iamModule", "clients", "reservations"].includes(item.labelKey)
            ) {
              isVisible = false
            }
            return isVisible
          },
        )
        .map((item) => {
          const item0 = {
            // add tenantId to url
            ...item,
            url: `${item.url}?tenantId=${tenantId}`,
          }
          if (["reporting", "KPIs"].includes(item0.labelKey)) {
            // add lockerIdFilters to url
            const lockerIdFilters = lockers.map((l) => l.id)
            item0.url = `${item0.url}&lockerIdFilters=${encodeURIComponent(
              JSON.stringify(lockerIdFilters),
            )}`
            // config
          } else if (item0.labelKey === "configurationModule") {
            item0.url = `${item0.url}&tab=0`
          }
          return item0
        }),

    [mode, tu?.iamActionRefNames, tenantId, lockers],
  )

  return (
    <List>
      {items.map((item, index) => (
        <UnstyledLink to={item.url} key={index} disabled={item.disabled}>
          <ListItemButton
            divider={item.divider}
            disabled={item.disabled}
            selected={selectedMenuItemLabelKey === item.labelKey}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              height="45px"
            >
              <ListItemIcon
                sx={{
                  color: "secondary.main",
                }}
              >
                {item.icon}
              </ListItemIcon>
              {!isCollapsed && (
                <ListItemText
                  primary={t(item.labelKey)}
                  primaryTypographyProps={{
                    color: "primary",
                  }}
                />
              )}
            </Stack>
          </ListItemButton>
        </UnstyledLink>
      ))}
    </List>
  )
}

interface OsLayoutProps {
  children: React.ReactNode
  selectedMenuItemLabelKey?: string
  hideMenu?: boolean
}

const OsLayout: React.FC<OsLayoutProps> = ({
  children,
  selectedMenuItemLabelKey,
  hideMenu = false,
}) => {
  const { t } = useTranslation()

  const { isSignedIn, isLoaded } = useAuth()

  const isReady = useAppSelector(selectMeIsReady)

  const tenantId = useSelector(selectTenantId)

  useCurrentTenant()

  const unauthorizedMessage = useSelector(selectUnauthorizedMessage)

  const dispatch = useAppDispatch()

  const mode = React.useContext(ModeContext) || "standalone"

  useGetMeQuery(tenantId, {
    skip: !isLoaded || !isSignedIn || !tenantId,
  })

  const { user, isLoaded: isLoadedMe } = useSelector(selectMe)

  // get tenantId from search params
  const [search] = useSearchParams()

  const urlTenantId = search.get("tenantId")

  React.useEffect(() => {
    if (urlTenantId && tenantId !== urlTenantId) {
      dispatch(setTenantId(urlTenantId))
    }
  }, [dispatch, tenantId, urlTenantId])

  const selectedItem = osMenuItems({ mode }).filter(
    (item) => item.labelKey === selectedMenuItemLabelKey,
  )[0]

  // usestate for collapsed menu
  const [isMenuCollapsed, setIsMenuCollapsed] = React.useState(
    mode === "embedded",
  )

  const mblsReservationClientId = useAppSelector(selectMblsReservationClientId)

  useGetMblsConnectorClientsQuery(
    {
      params: {
        tenantId,
        id: mblsReservationClientId,
      },
    },
    {
      skip: !mblsReservationClientId,
    },
  )

  const { clearMblsReservation } = useClearMblsReservation()

  const [existingReservationAttempt, setExistingReservationAttempt] =
    React.useState<undefined | ReservationAttempt>()

  const checkExternalOrderIdUniquess = React.useCallback(
    async (externalOrderId: string) => {
      if (!tenantId) {
        console.error("checkExternalOrderIdUniquess: tenantId is not set")
        return
      }
      if (!externalOrderId) {
        console.error(
          "checkExternalOrderIdUniquess: externalOrderId is not set",
        )
        return
      }
      try {
        const result = await dispatch(
          thingsApi.endpoints.getThings.initiate({
            params: {
              "ap.name":
                SharedAccessPatternOsEnum.GetReservationAttemptByExternalId,
              "ap.tenantId": tenantId,
              "ap.externalOrderId": externalOrderId,
              tenantId,
            },
          }),
        )

        if (result.error) {
          throw result.error
        }

        if (get(result, "data.things", []).length > 0) {
          setExistingReservationAttempt(result?.data?.things[0])
        }
      } catch (error) {
        console.error("Error fetching order:", error)
        throw error
      }
    },
    [dispatch, tenantId],
  )

  React.useEffect(() => {
    const mblsReservationClientId = search.get("clientId")
      ? decodeURIComponent(search.get("clientId") as string)
      : null
    const mblsReservationDeliveryDate = search.get("clientId")
      ? decodeURIComponent(search.get("deliveryDate") as string)
      : ""
    const mblsReservationTimeConstraintStart = search.get("timeConstraintStart")
      ? decodeURIComponent(search.get("timeConstraintStart") as string)
      : ""
    const mblsReservationTimeConstraintEnd = search.get("timeConstraintEnd")
      ? decodeURIComponent(search.get("timeConstraintEnd") as string)
      : ""
    const mblsReservationSmartLockerNotificationPhone = search.get(
      "smartLockerNotificationPhone",
    )
      ? decodeURIComponent(search.get("smartLockerNotificationPhone") as string)
      : ""
    const mblsReservationSmartLockerNotificationEmail = search.get(
      "smartLockerNotificationEmail",
    )
      ? decodeURIComponent(search.get("smartLockerNotificationEmail") as string)
      : ""
    const mblsReservationOrderThirdPartyID = search.get("orderThirdPartyID")
      ? decodeURIComponent(search.get("orderThirdPartyID") as string)
      : ""
    const mblsReservationNotes = search.get("notes")
      ? decodeURIComponent(search.get("notes") as string)
      : ""
    if (mblsReservationOrderThirdPartyID) {
      checkExternalOrderIdUniquess(mblsReservationOrderThirdPartyID)
    }
    if (mblsReservationClientId) {
      dispatch(
        thingsActions.setMblsReservation({
          mblsReservationClientId,
          mblsReservationDeliveryDate,
          mblsReservationTimeConstraintStart: isNullish(
            mblsReservationTimeConstraintStart,
          )
            ? ""
            : mblsReservationTimeConstraintStart,
          mblsReservationTimeConstraintEnd: isNullish(
            mblsReservationTimeConstraintEnd,
          )
            ? ""
            : mblsReservationTimeConstraintEnd,
          mblsReservationSmartLockerNotificationPhone: isNullish(
            mblsReservationSmartLockerNotificationPhone,
          )
            ? ""
            : mblsReservationSmartLockerNotificationPhone,
          mblsReservationSmartLockerNotificationEmail: isNullish(
            mblsReservationSmartLockerNotificationEmail,
          )
            ? ""
            : mblsReservationSmartLockerNotificationEmail,
          mblsReservationOrderThirdPartyID,
          mblsReservationNotes,
        }),
      )
    }
  }, [checkExternalOrderIdUniquess, dispatch, search])

  const onBannerClose = React.useCallback(() => {
    // postmessage to parent
    if (window.parent) {
      window.parent.postMessage({ event: "closeModal" }, "*")
    }
  }, [])

  useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetConfigurationsByTenant,
        "ap.tenantId": tenantId,
        tenantId,
      },
    },
    {
      skip: !tenantId,
    },
  )

  return (
    <Stack
      direction="column"
      sx={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        overflowX: "scroll",
        overflowY: "scroll",
      }}
    >
      {!["embedded", "RxPro"].includes(mode) && <MainAppbar />}
      {!["embedded", "RxPro"].includes(mode) && <Toolbar />}

      {unauthorizedMessage && (
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            bgcolor: "rgba(255, 152, 0, 0.4)",
          }}
        >
          <Typography variant="body1" fontWeight="bold" noWrap>
            {t("unauthorizedMessage")}
          </Typography>
          <Box></Box>
          <Sl2Btn
            variant="outlined"
            onClick={() => dispatch(thingsActions.setUnauthorizedMessage(""))}
          >
            {t("close")}
          </Sl2Btn>
        </Toolbar>
      )}

      {!(isLoadedMe || !isReady) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Stack
        direction="row"
        sx={{
          // make whole page blur if not authorized and disable pointer events
          filter: !!unauthorizedMessage ? "blur(5px)" : "none",
          pointerEvents: !!unauthorizedMessage ? "none" : "all",
          // overflowY: "scroll",
          // overflowX: "auto",
          // height: "100%",
        }}
      >
        {mode !== "RxPro" && !hideMenu && (
          <Paper
            variant="outlined"
            sx={{
              width: isMenuCollapsed ? 60 : appConfig.mainMenuWidth,
              height: `calc(100vh - ${appConfig.mainAppBarHeight}px)`,
              flexShrink: 0,
            }}
          >
            <Stack
              direction="column"
              spacing={1}
              justifyContent={"space-between"}
              height={"100%"}
            >
              <Stack direction="column" spacing={0}>
                <Stack
                  direction="row"
                  p={1}
                  ml={1}
                  spacing={1}
                  alignItems="center"
                >
                  <Typography
                    variant="body1"
                    noWrap
                    p={1}
                    color="primary.main"
                    fontWeight={"bold"}
                  >
                    {isMenuCollapsed
                      ? ""
                      : t(selectedItem?.labelKey || "lockers")}
                  </Typography>
                </Stack>
                <Divider />
                <OsMenu
                  selectedMenuItemLabelKey={selectedMenuItemLabelKey}
                  isCollapsed={isMenuCollapsed}
                />
              </Stack>
              <Box
                sx={{
                  pb: 4,
                  //center
                  display: "flex",
                  justifyContent: isMenuCollapsed ? "center" : "end",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
                >
                  {isMenuCollapsed ? (
                    <ArrowForwardIosIcon />
                  ) : (
                    <ArrowBackIosIcon />
                  )}
                </IconButton>
              </Box>
            </Stack>
          </Paper>
        )}
        <Stack direction="column">
          {mblsReservationClientId && (
            <MblsReservationBanner onClose={onBannerClose} />
          )}
          {existingReservationAttempt && (
            <Dialog open={true}>
              <Box p={2}>
                <Typography variant="body1" noWrap>
                  {t("externalOrderIdAlreadyExistsTitle")}
                </Typography>
                <Divider />
              </Box>
              <Stack
                direction="column"
                spacing={1}
                p={2}
                alignItems="center"
                justifyContent={"center"}
                minHeight="300px"
              >
                <Stack direction="column" spacing={1}>
                  <Alert
                    severity="warning"
                    sx={{
                      p: 5,
                    }}
                  >
                    <AlertTitle>{t("warning")}</AlertTitle>
                    <Stack direction="column" spacing={1}>
                      <Typography variant="h6">
                        {t("externalOrderIdAlreadyExists")}
                      </Typography>
                      <Typography variant="body1">
                        {t("externalOrderIdAlreadyExistsDescription")}
                      </Typography>
                    </Stack>
                  </Alert>
                  <Box></Box>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    divider={<Divider orientation="vertical" />}
                  >
                    <Typography variant="caption">
                      {moment(existingReservationAttempt.createdAt).format(
                        "YYYY-MM-DD HH:mm:ss",
                      )}
                    </Typography>
                    <Typography variant="caption">
                      {moment(existingReservationAttempt.createdAt).fromNow()}
                    </Typography>
                    <Typography variant="caption">
                      {existingReservationAttempt.externalOrderId}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent={"flex-end"}
                  >
                    <Sl2Btn
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        clearMblsReservation(false)
                        setExistingReservationAttempt(undefined)
                      }}
                    >
                      {t("close")}
                    </Sl2Btn>
                  </Stack>
                </Stack>
              </Stack>
            </Dialog>
          )}
          {children}
        </Stack>
      </Stack>
      {isReady && tenantId && user?.id && (
        <MrtConnection
          tenantId={tenantId}
          subscribePacket={{
            subscriptions: [
              {
                topicFilter: `mrt/dsync/${tenantId}/#`,
                qos: mqtt5.QoS.AtLeastOnce,
              },
              {
                topicFilter: `mrt/app/sl2/partners/sst/${tenantId}/#`,
                qos: mqtt5.QoS.AtLeastOnce,
              },
              {
                topicFilter: `mrt/us/${user?.id}/#`,

                qos: mqtt5.QoS.AtLeastOnce,
              },
            ],
          }}
        />
      )}
    </Stack>
  )
}

export default OsLayout
