import { Box, Button, Typography } from "@mui/material"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

export const SignupInfo: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        padding: 2,
      }}
    >
      <Typography variant="body1" gutterBottom>
        {`${t("signupDescription")} `}{" "}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <a href="mailto:product@mobilus.ca">product@mobilus.ca </a>
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => navigate("/")}
        sx={{ mt: 6 }}
      >
        {t("backHome")}
      </Button>
    </Box>
  )
}

export default SignupInfo
