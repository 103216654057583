import { useUser } from "@clerk/clerk-react"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material"
import Toolbar from "@mui/material/Toolbar"
import { mqtt5 } from "aws-iot-device-sdk-v2"
import { t } from "i18next"
import * as React from "react"
import { useTranslation } from "react-i18next"
import { User } from "shared/user"
import { useAppAuth } from "src/auth/AuthProvider"
import { useAppSelector } from "../app/hooks"
import defaultAvatar from "../assets/default_avatar.png"
import { selectMe } from "../features/me/meSlice"
import { MrtConnection } from "../iot/MrtConnection"
import MainAppbar from "./MainAppbar"
import { UnstyledLink } from "./UnstyledLink"
import { usMenuItems } from "./UserDrawer"

const UsMenu: React.FC<{ selectedMenuItemLabelKey?: string; user: User }> = ({
  selectedMenuItemLabelKey,
  user,
}) => {
  const { t } = useTranslation()

  return (
    <List sx={{ mt: 4 }}>
      {usMenuItems(user)
        .filter((i) => i.visible)
        .map((item, index) => (
          <UnstyledLink to={item.url} key={index}>
            <ListItemButton
              divider={item.divider}
              disabled={item.disabled}
              selected={selectedMenuItemLabelKey === item.labelKey}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <ListItemIcon
                  // black
                  sx={{ color: "rgba(0, 0, 0, 0.74)" }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={t(item.labelKey)} />
              </Stack>
            </ListItemButton>
          </UnstyledLink>
        ))}
    </List>
  )
}

interface UsLayoutProps {
  children: React.ReactNode
  selectedMenuItemLabelKey?: string
}

const UsLayout: React.FC<UsLayoutProps> = ({
  children,
  selectedMenuItemLabelKey,
}) => {
  const { user: clerkUser } = useUser()
  const { isReady } = useAppAuth()
  const { user } = useAppSelector(selectMe)

  const fullName = `${user?.firstName} ${user?.lastName}`

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100vw",
      }}
    >
      <MainAppbar showTenant={false} />

      <Container maxWidth="lg">
        <Toolbar />
        {isReady && (
          <Stack p={1} mt={4} direction="column">
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1}
                alignItems="center"
              >
                <Avatar
                  alt="User"
                  src={clerkUser?.imageUrl || defaultAvatar}
                  sx={{
                    width: 60,
                    height: 60,
                  }}
                />
                <Stack direction="column">
                  <Typography fontWeight="bold">{fullName}</Typography>
                  <Typography fontSize="0.9em">{user?.email}</Typography>
                </Stack>
              </Stack>
              <UnstyledLink to="/signout">
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                  }}
                >
                  <ExitToAppIcon />
                  <Typography ml={2}> {t("signout")}</Typography>
                </Button>
              </UnstyledLink>
            </Stack>
            <Stack p={1} mt={4} direction="row" spacing={3}>
              <Stack direction="column" spacing={2} sx={{ width: "300px" }}>
                {user && (
                  <UsMenu
                    selectedMenuItemLabelKey={selectedMenuItemLabelKey}
                    user={user}
                  />
                )}
              </Stack>
              <Stack direction="column" spacing={2} width="100%">
                {children}
              </Stack>
            </Stack>
          </Stack>
        )}
        {!isReady && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {isReady && user?.id && (
          <MrtConnection
            tenantId="noId"
            subscribePacket={{
              subscriptions: [
                {
                  topicFilter: `mrt/us/${user?.id}/#`,

                  qos: mqtt5.QoS.AtLeastOnce,
                },
              ],
            }}
          />
        )}
      </Container>
    </Box>
  )
}

export default UsLayout
