import { useAuth } from "@clerk/clerk-react"
import { useCallback } from "react"

export const sl2ApiUrl = import.meta.env.VITE_SL2_API_URL
console.log("sl2ApiUrl:", sl2ApiUrl)

export type Sl2ApiContext = {
  options?: RequestInit
  searchParams?: Record<string, string>
}
/**
 *
 * @returns sl2-api fetch function with auth header
 */
export const useSl2Api = () => {
  const { getToken, isSignedIn } = useAuth()

  const fetchWithAuth = useCallback(
    async (endpoint: string, ctx: Sl2ApiContext) => {
      if (!isSignedIn) {
        // throw new Error("User is not signed in")
      }
      const url = new URL(`${sl2ApiUrl}${endpoint}`)
      url.search = new URLSearchParams(ctx?.searchParams || {}).toString()
      try {
        const token = await getToken()

        return fetch(url, {
          ...ctx?.options,
          headers: {
            ...ctx?.options?.headers,
            Authorization: `Bearer ${token}`,
          },
        })
      } catch (error: any) {
        console.error(error)
        // throw new Error(error?.message)
      }
    },
    [getToken, isSignedIn],
  )

  return fetchWithAuth
}
