import Joi from "joi";
import { sharedCreateThingsSchema } from "./sharedValidators";

export const sharedClientCreateSchema = sharedCreateThingsSchema
  .append({
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    email: Joi.string()
      .email({
        tlds: { allow: false },
      })
      .optional(),
    phone: Joi.string().optional(),
    mblsClientId: Joi.string().optional(),
    notes: Joi.string().optional().empty(""),
    tenantId: Joi.string().required(),
  })
  .or("email", "phone");

export const sharedValidateClientToCreate = (data: any) => {
  return sharedClientCreateSchema.validate(data);
};
