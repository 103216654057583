import { PersonAddAlt1 } from "@mui/icons-material"
import { IconButton, Paper, Stack, Typography } from "@mui/material"
import { useHover } from "@uidotdev/usehooks"
import { some } from "lodash"
import { FC } from "react"
import { useTranslation } from "react-i18next"

type OsReservationClientSelectorItemProps = {
  firstName: string
  lastName: string
  email: string
  phone: string
  id: string
  onSelectClient: () => void
}

export const OsReservationClientSelectorItem: FC<
  OsReservationClientSelectorItemProps
> = ({ firstName, lastName, email, phone, id, onSelectClient }) => {
  const { t } = useTranslation()

  const [ref, hovering] = useHover()
  return (
    <Paper
      variant={hovering ? "elevation" : "outlined"}
      elevation={hovering ? 2 : 0}
      key={id}
      sx={{
        p: 1,
        px: 3,
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        ref={ref}
        justifyContent="space-between"
        sx={{
          // hovered color`
          //   "&:hover": {
          //     color: "primary.main",
          //   },
          color: hovering ? "primary.main" : "inherit",
        }}
      >
        <Stack direction="column" spacing={1}>
          <Typography variant="body1" fontWeight={"bold"}>
            {firstName} {lastName}
          </Typography>
          <Typography variant="body2">{`✉️ ${
            email || t("noEmail")
          }`}</Typography>
          <Typography variant="body2">{`📞 ${
            phone || t("noPhone")
          }`}</Typography>
        </Stack>

        {some([!!email, !!phone]) && (
          <IconButton
            onClick={() => onSelectClient()}
            sx={{
              color: hovering ? "secondary.main" : "inherit",
            }}
          >
            <PersonAddAlt1 />
          </IconButton>
        )}
      </Stack>
    </Paper>
  )
}
