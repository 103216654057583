export const CredentialTypeList = ["pin"] as const;

export const CredentialStatusList = [
  "active",
  "inactive",
  "suspended",
] as const;

export const CredentialIssuerList = ["sl2"] as const;

export const CredentialThingTypeMrn = "mrn::::credential";
