export const ChartsYearColorMap: Record<string, string> = {
  2020: "#003f5c",
  2021: "#F0AB00",
  2022: "#004B95",
  2023: "#38812F",
  2024: "#009596",

  2025: "#A30000",
  2026: "#C46100",
  2027: "#8481DD",
  2028: "#ffff00",
  2029: "#ff00ff",
  2030: "#00ffff",
}
export const ChartsMonthNameMap: Record<number, string> = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
}
export const ChartsDayOfWeekNameMap: Record<number, string> = {
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
  7: "Sun",
}

//  day of week color map
export const ChartsDayOfWeekColorMap: Record<number, string> = {
  1: "#003f5c",
  2: "#F0AB00",
  3: "#004B95",
  4: "#38812F",
  5: "#009596",
  6: "#A30000",
  7: "#C46100",
}
export type DsRecord = {
  created_at_week: number
  created_at_month: number
  created_at_year: number
  nb_reservations: number
  locker_id: string
  sst_status: string
  tenant_id: string
}

export const DefaultColorMap: Record<number, string> = {
  1: "#003f5c",
  2: "#F0AB00",
  3: "#f23e00",
  4: "#38812F",
  5: "#009596",
  6: "#A30000",
  7: "#C46100",
  8: "#8481DD",
  9: "#ffff00",
  10: "#ff00ff",
  11: "#00ffff",
  12: "#ff0000",
  13: "#00ff00",
  14: "#0000ff",
  15: "#ff00ff",
  16: "#00ffff",
  17: "#0000ff",
  18: "#ff0000",
  19: "#00ff00",
  20: "#0000ff",
}
