import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import Joi from "joi"
import { isEmpty } from "lodash"
import { FC, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Sl2Btn } from "./Sl2Btn"

type OsLockerShowAdminCodeDialogProps = {
  isOpen?: boolean

  onClose: () => void
  onShow: (employeeCode?: string) => void
  adminCode: string
  employeeCodeRequired?: boolean
}
const defaultJoiSchema = Joi.string().required().min(1).max(1000)

export const OsLockerShowAdminCodeDialog: FC<
  OsLockerShowAdminCodeDialogProps
> = ({
  isOpen = true,
  adminCode,
  onClose,
  onShow,

  employeeCodeRequired = true,
}) => {
  const { t } = useTranslation()

  const [code, setCode] = useState<string | undefined>()

  const [error, setError] = useState(false)

  const [touched, setTouched] = useState(false)

  const [showCode, setShowCode] = useState(!employeeCodeRequired)

  const onShowClick = useCallback(() => {
    if (error) {
      console.error("Error in code ", error)
    }
    onShow(code)
    setShowCode(true)
  }, [code, error, onShow])

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("showAdminCode")}</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack direction="column" spacing={4}>
          <DialogContentText id="alert-dialog-description">
            {t("showAdminCodeText")}
          </DialogContentText>
          {showCode ||
            (employeeCodeRequired && (
              <Stack direction="column" spacing={3}>
                <Typography variant="body1">
                  {t("employeeCodeNeeded")}
                </Typography>
                <TextField
                  autoFocus
                  label={t("employeeCode")}
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value)

                    if (!touched) {
                      setTouched(true)
                    } else {
                      const { error: err } = defaultJoiSchema.validate(
                        e.target.value,
                      )

                      setError(isEmpty(err) ? false : true)
                    }
                  }}
                  error={touched && error}
                  helperText={
                    touched && error ? t("pleaseProviderEmployeeCode") : ""
                  }
                />
              </Stack>
            ))}
          {showCode && (
            <Box
              sx={{
                // center
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography
                variant="h6"
                color="primary.main"
                fontFamily={"monospace"}
                sx={{
                  py: 1,
                  px: 3,
                  bgcolor: "grey.200",
                }}
              >
                {adminCode}
              </Typography>
            </Box>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Sl2Btn onClick={onClose} color="primary">
          {t("close")}
        </Sl2Btn>
        {!showCode && (
          <Sl2Btn
            onClick={onShowClick}
            color="primary"
            autoFocus
            variant="contained"
            disabled={employeeCodeRequired ? !!error || !touched : false}
          >
            {t("continue")}
          </Sl2Btn>
        )}
      </DialogActions>
    </Dialog>
  )
}
