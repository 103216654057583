import { createTheme } from "@mui/material/styles"

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#234285",
      // main: "#3A5163",
      //   main: "#F9556D",
    },
    secondary: {
      main: "#94D2C2",
    },
    mode: "light",
  },
  typography: {
    fontFamily: 'Inter, "Helvetica", "Arial", sans-serif',
  },
})
export const darkTheme = createTheme({
  palette: {
    primary: {
      // white
      main: "#F9F9F9",
    },
    mode: "dark",
  },
  typography: {
    fontFamily: 'Inter, "Helvetica", "Arial", sans-serif',
    // whitesmoke
  },
})
