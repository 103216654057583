import { Box, Button, Typography } from "@mui/material"
import { FC } from "react"
import { useNavigate } from "react-router-dom"

export const NotFound: FC = () => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        padding: 2,
        color: "white",
        backgroundImage: "linear-gradient(60deg, #29323c 0%, #485563 100%)",
      }}
    >
      <Typography variant="h2" component="div" gutterBottom>
        Oops! We've lost the page!
      </Typography>
      <Typography variant="body1" gutterBottom>
        The page you're looking for is on vacation. But don't worry, our
        developers are on the case!
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/us/profile")}
      >
        Back to Home
      </Button>
    </Box>
  )
}

export default NotFound
