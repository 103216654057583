import { Refresh } from "@mui/icons-material"
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { t } from "i18next"
import { map, noop } from "lodash-es"
import moment from "moment"
import { FC, useMemo, useState } from "react"
import { SharedAccessPatternUsEnum } from "shared/sharedAccessPatternsData"
import { Tenant } from "shared/tenant"
import { TenantThingTypeMrn } from "shared/tenantData"
import { TenantUser } from "shared/tenantUser"
import { TenantUserThingTypeMrn } from "shared/tenantUserData"
import { useAppSelector } from "../app/hooks"
import { selectMe, selectMeIsReady } from "../features/me/meSlice"
import { useGetThingsQuery } from "../features/things/thingsApi"
import { selectThingsByThingTypeMrn } from "../features/things/thingsSlice"
import TraceTooltip from "./TraceTooltip"
import { UnstyledLink } from "./UnstyledLink"
import UsLayout from "./UsLayout"

const TenantUserCard: FC<{ tu: TenantUser; tenant: Tenant }> = ({
  tu,
  tenant,
}) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="column" spacing={1}>
            <TraceTooltip
              text={tu.id}
              tooltipTitle={
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box>🏢 </Box>
                  <Typography>{tenant.name}</Typography>
                  {/* <Chip label={tu.status} /> */}
                </Stack>
              }
            />
            <Typography variant="caption" color="textSecondary">
              {`${t("created")} ${moment(
                tenant.createdAt,
              ).fromNow()} - ${moment(tenant.createdAt).format(
                "YYYY-MM-DD HH:mm",
              )} `}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            {/* vertical dot icon button */}
            <UnstyledLink
              to={`/os/lockers?tenantId=${tenant.id}`}
              reloadDocument
            >
              <Button variant="contained" sx={{ textTransform: "none" }}>
                {t("access")}
              </Button>
            </UnstyledLink>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
type ContentProps = {
  displayState: "loading" | "empty" | "list"
  onRefetch?: () => void
  tus: { tu: TenantUser; tenant: Tenant }[]
}
const Content: FC<ContentProps> = ({ displayState, tus, onRefetch = noop }) => {
  const [searchInput, setSearchInput] = useState("")

  const filteredTus = useMemo(
    () =>
      tus
        .filter((t) =>
          t.tenant.name.toLowerCase().includes(searchInput.toLowerCase()),
        )
        // sort bu tu.createdAt desc
        .sort(
          (a, b) =>
            new Date(b.tu.createdAt).getTime() -
            new Date(a.tu.createdAt).getTime(),
        ),
    [searchInput, tus],
  )

  switch (displayState) {
    case "loading":
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      )

    case "list":
      return (
        <Stack direction="column" spacing={2} mt={2}>
          <TextField
            label={t("search")}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            size="small"
          />
          {map(filteredTus, ({ tu, tenant }) => (
            <TenantUserCard tu={tu} tenant={tenant} key={tu.id} />
          ))}
        </Stack>
      )
  }
}

export const UserOrgsPage: FC = () => {
  const { user } = useAppSelector(selectMe)
  const isReady = useAppSelector(selectMeIsReady)
  const { isLoading, isFetching, refetch } = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternUsEnum.GetMyTenants,
      },
    },
    {
      skip: !isReady,
    },
  )

  const tenantUsers = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, TenantUserThingTypeMrn),
  ) as TenantUser[]

  const tenants = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, TenantThingTypeMrn),
  ) as Tenant[]

  const tus = useMemo(() => {
    // make tenantUsers unique

    const uniqTenantUsers = tenantUsers.reduce<TenantUser[]>((acc, tu) => {
      if (acc.find((t) => t?.tenantId === tu.tenantId)) {
        return acc
      }
      return [...acc, tu]
    }, [])
    const _tus = map(uniqTenantUsers, (tu: TenantUser) => {
      const tenant = tenants.find((t) => t.id === tu.tenantId)
      if (!tenant) {
        return null
      }
      return {
        tu,
        tenant,
      } as { tu: TenantUser; tenant: Tenant }
    }).filter(Boolean) as { tu: TenantUser; tenant: Tenant }[]

    // sort by tenant createdAt desc
    return _tus.sort((a, b) => {
      if (!a?.tenant) {
        throw new Error("a.tenant is undefined")
      }
      if (!b?.tenant) {
        throw new Error("b.tenant is undefined")
      }
      return (
        new Date(b?.tenant?.createdAt).getTime() -
        new Date(a?.tenant?.createdAt).getTime()
      )
    })
  }, [tenantUsers, tenants])

  let displayState: "loading" | "empty" | "list" = isLoading
    ? "loading"
    : tus.length === 0
    ? "empty"
    : "list"

  return (
    <UsLayout selectedMenuItemLabelKey="Organizations">
      <Stack direction="column" width="100%">
        <Stack direction="column">
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1}
            p={1}
            alignItems="center"
          >
            {/* circula progress */}
            <Stack
              direction="row"
              alignContent={"center"}
              alignItems={"center"}
              justifyContent="space-between"
              spacing={1}
              // p={1}
            >
              <Typography variant="h5" noWrap>
                {t("organizations")}
              </Typography>
              {isLoading || isFetching ? (
                <CircularProgress />
              ) : (
                <IconButton onClick={refetch}>
                  <Refresh />
                </IconButton>
              )}
            </Stack>
          </Stack>
          <Divider sx={{ mb: 2 }} />
        </Stack>
        <Stack direction="column" spacing={2} mt={2}>
          <Content displayState={displayState} tus={tus} onRefetch={refetch} />
        </Stack>
      </Stack>
    </UsLayout>
  )
}

export default UserOrgsPage
