import { Email, Sms } from "@mui/icons-material"
import { Divider, Stack, Tabs, Typography } from "@mui/material"
import { t } from "i18next"
import { FC } from "react"
import { Configuration } from "shared/configuration"
import { useSearchParamsState } from "src/hooks/useSearchParamsState"
import { OsConfigurationItemTemplate } from "./OsConfigurationItemTemplate"
import { Sl2Tab } from "./Sl2Tab"

type OsConfigurationTemplateListProps = {
  templates: Configuration[]
  onChange: (config: Configuration) => void
}

const langs = ["fr", "en"]

const templateNames = [
  "orderReadyForPickup",
  "paymentReceipt",
  "orderPickupReminder",
  "orderExpired",
  "lockerFullCapacity",
]

export const OsConfigurationTemplateList: FC<
  OsConfigurationTemplateListProps
> = ({ templates, onChange }) => {
  const [tName, setTName] = useSearchParamsState("tName", "orderReadyForPickup")
  const [lang, setLang] = useSearchParamsState("lang", "fr")

  const emailTemplate = templates.find(
    (t) => t.refName === `${tName}EmailTemplate_${lang}`,
  )

  const smsTemplate = templates.find(
    (t) => t.refName === `${tName}SmsTemplate_${lang}`,
  )

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={1} alignItems="flex-start">
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tName}
          onChange={(_, value) => setTName(value)}
          sx={{
            borderRight: 2,
            borderColor: "divider",
          }}
        >
          {templateNames.map((name) => (
            <Sl2Tab
              key={name}
              label={t(`${name}`)}
              value={name}
              sx={{
                alignItems: "flex-end",
              }}
            />
          ))}
        </Tabs>
        <Stack
          direction="column"
          spacing={1}
          minWidth={"400px"}
          sx={{
            px: 2,
          }}
        >
          <Typography variant="body1" fontWeight="bold">
            {t(tName)}
          </Typography>
          <Tabs
            variant="scrollable"
            value={lang}
            onChange={(_, value) => setLang(value)}
            sx={{
              pb: 3,
            }}
          >
            {langs.map((lang) => (
              <Sl2Tab
                key={lang}
                label={t(`${lang}`)}
                value={lang}
                sx={{
                  alignItems: "flex-start",
                }}
              />
            ))}
          </Tabs>
          <Stack direction="column" spacing={1} alignItems="center">
            {emailTemplate && (
              <Stack direction="column" spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Email color="primary" />
                  <Typography variant="body1" fontWeight="bold">
                    {t("email")}
                  </Typography>
                </Stack>
                <Divider />
                <OsConfigurationItemTemplate
                  config={emailTemplate}
                  onChange={onChange}
                  // showVars={false}
                />
              </Stack>
            )}
            {smsTemplate && (
              <Stack direction="column" spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Sms color="primary" />
                  <Typography variant="body1" fontWeight="bold">
                    {t("sms")}
                  </Typography>
                </Stack>
                <Divider />

                <OsConfigurationItemTemplate
                  config={smsTemplate}
                  onChange={onChange}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
