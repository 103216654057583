import { KeyboardArrowDown } from "@mui/icons-material"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import RefreshIcon from "@mui/icons-material/Refresh"
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard"
import WifiTetheringIcon from "@mui/icons-material/WifiTethering"
import WifiTetheringOffIcon from "@mui/icons-material/WifiTetheringOff"
import {
  Box,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import { isUndefined, map } from "lodash"
import moment from "moment"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Locker } from "shared/locker"
import { LockerThingTypeMrn } from "shared/lockerData"
import { LockerDoorReservationSession } from "shared/lockerDoorReservationSession"
import { LockerDoorReservationSessionThingTypeMrn } from "shared/lockerDoorReservationSessionData"
import { Reservation } from "shared/reservation"
import { ReservationThingTypeMrn } from "shared/reservationData"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import useLockerDisplayValues from "src/hooks/useLockerDisplayValues"
import { v4 as uuid } from "uuid"
import {
  ActionReqExecutionModeEnum,
  ActionReqThingTypeMrn,
  ActionReqTypeEnum,
} from "../../../shared/actionReqData"
import { LockerCell, LockerGrid, SstLocker } from "../../../shared/sst"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import posPng from "../assets/posTransparent.png"
import tabletIcon from "../assets/tablet.svg"
import {
  selectMe,
  selectMeIsReady,
  selectTenantId,
} from "../features/me/meSlice"
import {
  useCreateThingsMutation,
  useDeleteThingsMutation,
  useGetThingsQuery,
} from "../features/things/thingsApi"
import {
  selectSelectedDoorReservation,
  selectThingsByThingTypeMrnFilter,
  thingsActions,
} from "../features/things/thingsSlice"
import { OsLockerDoor } from "./OsLockerDoor"
import { Sl2Btn } from "./Sl2Btn"
import TraceTooltip from "./TraceTooltip"

const FallbackLockerGridConfig = {
  nbCol: 4,
  doorWidth: 300,
  doorHeight: 300,
  jointWidth: 5,
}
type DoorTuple = {
  door: SstLocker["doors"][0]
  // sstReservation: SstReservationThing
  reservation: Reservation
  session?: LockerDoorReservationSession
  gridName?: string
  cell?: LockerCell
}
type OsLockerDashboardProps = {
  lockerId: string
}

const lockerDimRatio = 0.6

export const OsLockerDashboard: FC<OsLockerDashboardProps> = ({ lockerId }) => {
  const me = useAppSelector(selectMe)

  const { t } = useTranslation()

  const tenantId = useAppSelector(selectTenantId)

  const isReady = useAppSelector(selectMeIsReady)

  const [deleteThings] = useDeleteThingsMutation()

  const [createThings] = useCreateThingsMutation()

  const sessionFilter = useCallback(
    (thing: LockerDoorReservationSession) =>
      thing.lockerId === lockerId && thing.tenantId === tenantId,
    [lockerId, tenantId],
  )

  const sessions = useAppSelector((s) =>
    selectThingsByThingTypeMrnFilter(
      s,
      LockerDoorReservationSessionThingTypeMrn,
      sessionFilter,
    ),
  ) as unknown as LockerDoorReservationSession[]

  const filterFn = useCallback(
    (thing: Locker) => thing.id === lockerId,
    [lockerId],
  )

  const locker = useAppSelector((s) =>
    selectThingsByThingTypeMrnFilter(s, LockerThingTypeMrn, filterFn),
  )[0] as unknown as Locker

  const l = useLockerDisplayValues(locker)

  const { isLoading, refetch } = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetLockerCurrentReservations,
        "ap.tenantId": tenantId,
        "ap.lockerId": lockerId,
        "ap.limit": 2000,
        tenantId,
      },
    },
    {
      skip: !tenantId || !isReady || !lockerId || lockerId === "-1",
    },
  )

  const reservationFilter = useCallback(
    (thing: Reservation) => thing.lockerId === lockerId,
    [lockerId],
  )

  const reservations = useAppSelector(
    (s) =>
      selectThingsByThingTypeMrnFilter(
        s,
        ReservationThingTypeMrn,
        reservationFilter,
      ),
    (a, b) => JSON.stringify(a) === JSON.stringify(b),
  ) as unknown as Reservation[]

  const [isShowingInfo, setIsShowingInfo] = useState(false)

  const lockerNotPaired = !locker?.sstLockerId || !locker?.sstLocker?._id

  const sstLocker = locker?.sstLocker as SstLocker

  const grids: LockerGrid[] = []

  if (sstLocker?.locker_grid) {
    for (let i = 0; i < sstLocker.locker_grid.length; i++) {
      const grid = {
        ...sstLocker?.locker_grid[i],
        width: lockerDimRatio * sstLocker?.locker_grid[i]?.width + 40,
        height: lockerDimRatio * sstLocker?.locker_grid[i]?.height + 40,
        name: sstLocker?.locker_grid[i]?.name,
      } as unknown as LockerGrid
      grids.push(grid)
    }
  }

  // const lockerGrid = generateLockerGrid({ doors: sstLocker?.doors || [] })
  const lockerGrid = useMemo(
    () =>
      grids
        ? (grids as LockerGrid[])
        : ([
            // use FallbackLockerGridConfig
            {
              width:
                45 +
                FallbackLockerGridConfig.nbCol *
                  (FallbackLockerGridConfig.doorWidth * lockerDimRatio +
                    FallbackLockerGridConfig.jointWidth * 2) +
                45,
              // use FallBackLockerGridConfig to compute the height. sstLocker.doors
              height:
                sstLocker?.doors && sstLocker?.doors.length > 0
                  ? 100 +
                    (sstLocker?.doors.length / FallbackLockerGridConfig.nbCol +
                      1) *
                      (FallbackLockerGridConfig.doorHeight * lockerDimRatio +
                        FallbackLockerGridConfig.jointWidth)
                  : 300,
              depth: 330,
              cells_group: null,
            } as unknown as LockerGrid,
          ] as LockerGrid[]),
    [grids, sstLocker?.doors],
  )

  const doorTuples = useMemo(() => {
    return sstLocker?.doors?.map((door) => {
      const reservation = reservations.find(
        (r) =>
          r?.sstReservation?._id ===
            (door.links &&
              door?.links[0]?.href?.split("reservations/")?.pop()) &&
          ["CONFIRMED", "EXPIRED", "DELIVERED"].includes(
            r?.sstReservation?.status || "",
          ),
      )
      const session = sessions.find(
        (s) => s?.sstDoorId === door.door_id,
      ) as LockerDoorReservationSession

      let cell: LockerCell | undefined
      let gridName: string | null | undefined

      // find cell in any group
      lockerGrid.forEach((grid) => {
        if (!grid?.cells_group) return
        grid.cells_group.forEach((c) => {
          if (c.door_id === door.door_id) {
            cell = c
            gridName = grid.name
          }
        })
      })

      return {
        door,
        reservation,
        session,
        cell,
        gridName,
      } as DoorTuple
    })
  }, [reservations, sessions, sstLocker?.doors, lockerGrid])

  const onRefresh = useCallback(async () => {
    refetch()
    // refetchReservations()
    const refreshAction = {
      thingTypeMrn: ActionReqThingTypeMrn,
      executionMode: ActionReqExecutionModeEnum.sync,
      actionType: ActionReqTypeEnum.SstLockerRefresh,
      idempotencyKey: uuid(),
      delaySeconds: 2,
      tenantId,
      inputData: {
        tenantId,
        lockerId,
        sstLockerId: sstLocker?._id,
      },
    }
    await createThings({
      body: { things: [refreshAction] },
      params: { tenantId },
    }).unwrap()
  }, [createThings, lockerId, refetch, sstLocker?._id, tenantId])

  const onRemoveReservationSession = useCallback(
    async (sessionId: string) => {
      const toDelete = {
        thingTypeMrn: LockerDoorReservationSessionThingTypeMrn,
        tenantId,
        lockerId,
        id: sessionId,
      }
      await deleteThings({
        body: { things: [toDelete] },
        params: { tenantId },
      }).unwrap()
    },
    [deleteThings, lockerId, tenantId],
  )

  const [isRemovingAllSessions, setIsRemovingAllSessions] = useState(false)

  const [isRemovingAllSessionsConfirming, setIsRemovingAllSessionsConfirming] =
    useState(false)

  const removeAllSessions = useCallback(async () => {
    setIsRemovingAllSessions(true)
    const toDelete = sessions.map((s) => ({
      thingTypeMrn: LockerDoorReservationSessionThingTypeMrn,
      tenantId,
      lockerId,
      id: s.id,
    }))
    try {
      await deleteThings({
        body: { things: toDelete },
        params: { tenantId },
      }).unwrap()
    } catch (e) {
      console.error(e)
    } finally {
      setIsRemovingAllSessionsConfirming(false)
      setIsRemovingAllSessions(false)
      setAnchorEl(null)
    }
  }, [deleteThings, sessions, lockerId, tenantId])

  const actionMenuHandleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    },
    [],
  )

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const actionMenuHandleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const open = Boolean(anchorEl)
  let maxWidth: number = 0

  // screen cell per cell_group
  let screenCells: Record<number, LockerCell> = {}

  // loop through all cell_groups
  lockerGrid.forEach((grid, i) => {
    if (!grid?.cells_group) return

    maxWidth += grid.width
    // loop through all cells in the cell_group
    grid.cells_group.forEach((cell) => {
      // if the cell is a screen, set it to screenCell
      if (cell.cell_type === "screen") {
        screenCells[i] = cell
      }
    })
  })
  const onLockerNameClick = useCallback(
    (e: React.MouseEvent) => {
      if (!locker?.id) return
      // 5 clicks
      if (e.detail === 5) {
        // open controler in new tab
        window.open(
          `/os/lockers/${locker.id}/controller?tenantId=${tenantId}`,
          "_blank",
        )
      }
    },
    [locker?.id, tenantId],
  )

  const dispatch = useAppDispatch()

  const selectedDoorReservation = useAppSelector(selectSelectedDoorReservation)
  maxWidth = Math.max(maxWidth, 500)
  return (
    <Box sx={{ width: `${maxWidth + 75}px` }}>
      {(isLoading || !isReady || !locker) && (
        <>
          <Stack direction="column" spacing={2}>
            <CircularProgress />
          </Stack>
        </>
      )}
      {lockerNotPaired && (
        <Paper variant="outlined">
          <Stack direction="column" spacing={2} p={2} pl={4} mt={4}>
            <Typography variant="h6">{t("lockerNotPaired")}</Typography>
            <Typography variant="body1">
              {t("lockerNotPairedDescription")}
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent={"space-between"}
              pt={5}
            >
              <Typography variant="caption">{locker.id}</Typography>
              <Typography variant="caption">{locker?.sstLockerId}</Typography>
            </Stack>
          </Stack>
        </Paper>
      )}
      {locker && locker?.sstLockerId && sstLocker?._id && tenantId && (
        <Box sx={{ width: "100%" }}>
          <Stack direction="column" spacing={2} p={1} pl={4}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <SpaceDashboardIcon
                  sx={{
                    color: "secondary.main",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: "primary.main",
                  }}
                >
                  {t("lockers")}
                </Typography>
                <ChevronRightIcon
                  sx={{
                    color: "primary.main",
                  }}
                />
                <TraceTooltip
                  text={locker.traceId}
                  tooltipTitle={
                    <Typography
                      variant="h6"
                      color="primary.main"
                      onClick={onLockerNameClick}
                    >
                      {l.i18n.name}
                    </Typography>
                  }
                />
                <IconButton onClick={onRefresh}>
                  <RefreshIcon />
                </IconButton>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Sl2Btn
                  variant="outlined"
                  endIcon={<KeyboardArrowDown />}
                  onClick={actionMenuHandleClick}
                >
                  {t("actions")}
                </Sl2Btn>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={actionMenuHandleClose}
                >
                  <MenuItem
                    onClick={() => {
                      setIsRemovingAllSessionsConfirming(true)
                      actionMenuHandleClose()
                    }}
                    disableRipple
                    disabled={isRemovingAllSessions}
                  >
                    {t("removeAllReservationSessions")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setIsShowingInfo(!isShowingInfo)
                      actionMenuHandleClose()
                    }}
                    disableRipple
                  >
                    {t(isShowingInfo ? "hideInfo" : "showInfo")}
                  </MenuItem>
                </Menu>
              </Stack>
            </Stack>
            {isShowingInfo && (
              <Stack direction="column" spacing={1}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    {sstLocker.is_online ? (
                      <Typography variant="body2" color="green">
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                        >
                          <WifiTetheringIcon />
                          <Box>{t("online")}</Box>
                        </Stack>
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="error">
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                        >
                          <WifiTetheringOffIcon />
                          <Box>{t("offline")}</Box>
                        </Stack>
                      </Typography>
                    )}
                    <Typography variant="body2">{`${sstLocker.doors.length} ${t(
                      "doors",
                    )} `}</Typography>
                  </Stack>

                  <Typography variant="body2">{`🕒 Last sync:  ${
                    locker.lastSstSync
                      ? moment(locker.lastSstSync).fromNow()
                      : "never"
                  } `}</Typography>
                </Stack>
                <Box>
                  <Typography variant="body2">{`📍 ${l.address1} ${l.address2} ${l.city} ${l.province} ${sstLocker.country}`}</Typography>
                </Box>
              </Stack>
            )}
            <Divider />
          </Stack>
          <Box mx={2} mt={1}>
            {map(lockerGrid, (_, i) => (
              <Box
                key={i}
                sx={{
                  width: `${lockerGrid[i]?.width}px`,
                  height: `${lockerGrid[i]?.height}px`,
                  "padding-right": "5px",
                  "padding-left": "5px",
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <Stack
                  direction="column"
                  spacing={2}
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Paper
                    variant="outlined"
                    sx={{
                      p: 0,
                      pt: 3,
                      bgcolor: "grey.100",
                      position: "relative",
                      height: "100%",
                      borderRadius: 10,
                      borderWidth: "5px",
                    }}
                  >
                    <Box p={1} pl={3}>
                      <Typography variant="body1" fontWeight={"bold"}>{`${t(
                        "module",
                      )} ${i + 1}`}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      {map(
                        doorTuples?.filter(
                          (d) => d.gridName === lockerGrid[i].name,
                        ),
                        ({ door, reservation, session, cell, gridName }, j) => {
                          if (!door) {
                            console.error("door is missing")
                            return null
                          }
                          return (
                            <Box
                              key={door.door_id}
                              sx={{
                                // relative position to the Grid container, in proportion usinh lockerGrid[0]
                                position: "absolute",
                                // top when no cell.y, compute height of nth row, row has 5 doors
                                top: isUndefined(cell?.y)
                                  ? 10 +
                                    Math.floor(
                                      j / FallbackLockerGridConfig.nbCol,
                                    ) *
                                      200
                                  : `${cell?.y * lockerDimRatio}px`,
                                left: isUndefined(cell?.x)
                                  ? 10 +
                                    (j % FallbackLockerGridConfig.nbCol) * 200
                                  : `${cell?.x * lockerDimRatio}px`,
                                width: `${
                                  lockerDimRatio * (cell?.width || 300)
                                }px`,
                                height: `${
                                  lockerDimRatio * (cell?.height || 300)
                                }px`,
                                // border: "1px solid black",
                                // bgcolor: "red",
                              }}
                            >
                              <OsLockerDoor
                                cell={cell}
                                tenantId={tenantId}
                                locker={locker}
                                door={door}
                                reservation={reservation}
                                onClick={() => {
                                  dispatch(
                                    thingsActions.setDoorReservationIds({
                                      doorId: door.door_id,
                                      reservationId: reservation?.id,
                                      lockerId,
                                    }),
                                  )
                                }}
                                isSelected={
                                  !!selectedDoorReservation?.door?.door_id &&
                                  selectedDoorReservation.door.door_id ===
                                    door.door_id
                                }
                                session={session}
                                onRemoveReservationSession={() =>
                                  session?.id &&
                                  onRemoveReservationSession(session?.id)
                                }
                              />
                            </Box>
                          )
                        },
                      )}
                      {screenCells[i] && (
                        <Box
                          sx={{
                            width: `${lockerDimRatio * screenCells[i].width}px`,
                            height: `${
                              lockerDimRatio * screenCells[i].height
                            }px`,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            top: `${lockerDimRatio * screenCells[i].y}px`,
                            left: `${lockerDimRatio * screenCells[i].x}px`,
                          }}
                        >
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            width="100%"
                            height="100%"
                            justifyContent={"center"}
                          >
                            <img
                              src={tabletIcon}
                              alt="tablet"
                              width={`${
                                lockerDimRatio * screenCells[i].width * 0.8
                              }px`}
                              height="auto"
                              //rotation 90 deg if in horizontal mode
                              style={{
                                transform:
                                  screenCells[i].width > screenCells[i].height
                                    ? "none"
                                    : "rotate(90deg)",
                              }}
                            />
                            {(locker.isVirtual ||
                              locker.sstLocker?.payment) && (
                              <img
                                src={posPng}
                                alt="tablet"
                                width={`${lockerDimRatio * 150}px`}
                                height="auto"
                              />
                            )}
                          </Stack>
                        </Box>
                      )}
                    </Box>
                  </Paper>
                </Stack>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {/* confirm dialog for removing all sessions */}
      <Dialog
        open={isRemovingAllSessionsConfirming}
        onClose={() => {
          setIsRemovingAllSessionsConfirming(false)
        }}
      >
        <Box p={2}>
          <Typography variant="h6">
            {t("removeAllReservationSessions")}
          </Typography>
          <Divider sx={{ mb: 4 }} />
          <Typography variant="body1">
            {t("removeAllReservationSessionsConfirm")}
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            sx={{ mt: 10 }}
            justifyContent={"flex-end"}
          >
            <Sl2Btn
              variant="outlined"
              onClick={() => setIsRemovingAllSessionsConfirming(false)}
            >
              {t("cancel")}
            </Sl2Btn>
            <Sl2Btn
              variant="contained"
              onClick={removeAllSessions}
              disabled={isRemovingAllSessions}
            >
              {t("removeAllReservationSessions")}
            </Sl2Btn>
          </Stack>
        </Box>
      </Dialog>
    </Box>
  )
}

export default OsLockerDashboard
