export const ReservationAudThingTypeMrn = "mrn:sl2:::reservationAud";

export const ReservationAudEventList = [
  "created",
  "updated",
  "deleted",
  "cancelled",
  "preDeposit",
  "deposit",
  "pickup",
  "postPickup",
  "expired",
  "smsSent",
  "emailSent",
  "doorOpened",
  "paymentMade",
  "markedAsDeposited",
  "adminCodeShown",
  "doorLeftOpen",
  "doorOpenedByAdminCode",
  "doorClosedByAdminCode",
  "amountUpdated",
];
// enum version
export const ReservationAudEventEnum = {
  created: "created",
  updated: "updated",
  deleted: "deleted",
  cancelled: "cancelled",
  preDeposit: "preDeposit",
  deposit: "deposit",
  pickup: "pickup",
  postPickup: "postPickup",
  expired: "expired",
  smsSent: "smsSent",
  emailSent: "emailSent",
  doorOpened: "doorOpened",
  paymentMade: "paymentMade",
  markedAsDeposited: "markedAsDeposited",
  adminCodeShown: "adminCodeShown",
  doorLeftOpen: "doorLeftOpen",
  doorOpenedByAdminCode: "doorOpenedByAdminCode",
  doorClosedByAdminCode: "doorClosedByAdminCode",
  amountUpdated: "amountUpdated",
};
