import { Close } from "@mui/icons-material"
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material"
import { noop } from "lodash"
import { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ActionReq } from "shared/actionReq"
import { ActionReqStep, ActionReqStepType } from "shared/actionReqStep"

// start, progress, success and failure
const StepTypeIconMap: Record<ActionReqStepType, string> = {
  start: "🚀",
  progress: "🔄",
  success: "✅",
  failure: "❌",
} as const

const ActionReqStatusMap: Record<ActionReq["status"], string> = {
  pending: "⏳",
  inProgress: "🚀",
  success: "✅",
  failure: "❌",
} as const

type OsActionReqListProps = {
  onClose?: () => void
  actionReqs: ActionReq[]
  actionReqSteps: ActionReqStep[]
}
export const OsActionReqList: FC<OsActionReqListProps> = ({
  onClose = noop,
  actionReqs,
  actionReqSteps,
}) => {
  const { t } = useTranslation()

  // combine actionReqSteps with actionReqs
  const actions = useMemo(() => {
    return actionReqs.map((actionReq) => {
      const steps = actionReqSteps.filter(
        (step) => step.actionReqId === actionReq.id,
      )
      return { actionReq, steps }
    })
  }, [actionReqs, actionReqSteps])
  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{
        p: 1,
        minWidth: 400,
        maxWidth: 700,
        maxHeight: 600,
        overflow: "auto",
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h6">{t("notifications")}</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>
      <Divider />
      {actions?.length > 0 ? (
        <Stack
          direction="column"
          spacing={1}
          sx={{
            height: "100%",
            overflow: "auto",
          }}
        >
          {actions.map(({ actionReq, steps }) => (
            <Stack key={actionReq.id} direction="column" spacing={1}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Typography variant="body1" fontWeight="bold">
                  {`${ActionReqStatusMap[actionReq.status]} ${t(
                    `actionReq.actionType.${actionReq.actionType}`,
                  )}`}
                </Typography>
                <Typography variant="body2">
                  {new Date(actionReq.createdAt).toLocaleString()}
                </Typography>
              </Stack>
              <Box>
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{
                    ml: "8px",
                    borderLeft: 2,
                    borderColor: "grey.300",
                    // borderRadius: 1,
                    p: 1,
                    // bgcolor: "grey.100",
                  }}
                >
                  {steps.map((step) => (
                    <Stack key={step.id} direction="row" spacing={1}>
                      <Typography variant="body2">{`${
                        StepTypeIconMap[step.type]
                      } ${step.message}`}</Typography>
                    </Stack>
                  ))}
                </Stack>
              </Box>
              <Divider />
            </Stack>
          ))}
        </Stack>
      ) : (
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Typography sx={{ fontSize: "3em" }}>
            {/* thumbs up emojoi */}
            👍
          </Typography>
          <Typography variant="body1">{t("youreUpToDate")}</Typography>
        </Stack>
      )}
    </Stack>
  )
}
