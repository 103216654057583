import { SignIn } from "@clerk/clerk-react"
import { Box } from "@mui/material"
import { FC } from "react"

export const LoginPage: FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        padding: 2,
      }}
    >
      <SignIn signUpUrl="/signup-info" />
    </Box>
  )
}

export default LoginPage
