import {
  EntityState,
  PayloadAction,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit"
import Joi, { assert } from "joi"
import { get, isArray, isString, merge } from "lodash-es"

import { Client } from "shared/client"
import { ClientThingTypeMrn } from "shared/clientData"
import { Locker } from "shared/locker"
import { LockerDoorReservationSession } from "shared/lockerDoorReservationSession"
import { LockerDoorReservationSessionThingTypeMrn } from "shared/lockerDoorReservationSessionData"
import { Reservation } from "shared/reservation"
import { SstDoor } from "shared/sst"
import { Thing } from "shared/thing"
import { type RootState } from "../../app/store"
import { mblsconnectorApi } from "../mblsConnector/mblsConenctorApi"
import { MblsConnectorClient } from "../mblsConnector/mblsConnector"
import { thingsApi } from "./thingsApi"

export const thingsAdapter = createEntityAdapter<Thing>({
  selectId: (thing) => thing.id,
})

type ThingsState = EntityState<Thing> & {
  authorizedMessage: string
  selectedReservationId?: string
  selectedDoorId?: string
  selectedLockerId?: string
  clientSelectionIds: string[]
  filteredClientSelectionIds: string[]
  clientSelectionSearchValues: Record<string, string>
  clientSelectionDefaultValues: Record<string, string | undefined | number>
  mblsClients: MblsConnectorClient[]
  // mbls delivery reservation fields
  mblsReservationClientId: string
  mblsReservationDeliveryDate: string
  mblsReservationTimeConstraintStart: string
  mblsReservationTimeConstraintEnd: string
  mblsReservationSmartLockerNotificationPhone: string
  mblsReservationSmartLockerNotificationEmail: string
  mblsReservationOrderThirdPartyID: string
  mblsReservationNotes: string
  showMblsReservationSearchBarCloseBtn: boolean
  highlightedReservationId?: string
  userInputPhone: string | null
  userInputEmail: string | null
  userInputNotes: string | null
}
// Define the initial state using that type
const initialState: ThingsState = {
  ...thingsAdapter.getInitialState(),
  authorizedMessage: "",
  clientSelectionIds: [],
  filteredClientSelectionIds: [],
  clientSelectionSearchValues: {},
  clientSelectionDefaultValues: {},
  mblsClients: [],
  mblsReservationClientId: "",
  mblsReservationDeliveryDate: "",
  mblsReservationTimeConstraintStart: "",
  mblsReservationTimeConstraintEnd: "",
  mblsReservationSmartLockerNotificationPhone: "",
  mblsReservationSmartLockerNotificationEmail: "",
  mblsReservationOrderThirdPartyID: "",
  mblsReservationNotes: "",
  showMblsReservationSearchBarCloseBtn: false,
  highlightedReservationId: "",
  selectedReservationId: "",
  selectedDoorId: "",
  selectedLockerId: "",
  userInputPhone: null,
  userInputEmail: null,
  userInputNotes: null,
}

export const thingsSlice = createSlice({
  name: "things",
  initialState,
  reducers: {
    addMany: thingsAdapter.addMany,
    addOne: thingsAdapter.addOne,
    removeOne: thingsAdapter.removeOne,
    updateOne: thingsAdapter.updateOne,
    upsertOne: thingsAdapter.upsertOne,
    upsertMany: thingsAdapter.upsertMany,
    removeMany: thingsAdapter.removeMany,
    removeAll: thingsAdapter.removeAll,
    updateMany: thingsAdapter.updateMany,
    setUnauthorizedMessage: (state, action) => {
      state.authorizedMessage = action.payload
    },
    upsertOneDeep: (state, action) => {
      const existingThing = state.entities[action.payload.id]
      if (existingThing) {
        state.entities[action.payload.id] = JSON.parse(
          JSON.stringify(action.payload),
        )
        return
      }
      thingsAdapter.addOne(state, action.payload)
    },
    setClientSelectionIds: (state, action: PayloadAction<string[]>) => {
      assert(action.payload, Joi.array().items(Joi.string()))
      state.clientSelectionIds = action.payload
    },
    setFilteredClientSelectionIds: (state, action: PayloadAction<string[]>) => {
      assert(action.payload, Joi.array().items(Joi.string()))
      state.filteredClientSelectionIds = action.payload
    },
    setClientSelectionSearchValues: (
      state,
      action: PayloadAction<Record<string, string>>,
    ) => {
      assert(action.payload, Joi.object().pattern(Joi.string(), Joi.any()))
      state.clientSelectionSearchValues = action.payload
    },
    clearClientSelectionSearchValues: (state) => {
      state.clientSelectionSearchValues = {}
    },
    setClientSelectionDefaultValues: (
      state,
      action: PayloadAction<Record<string, string | undefined | number>>,
    ) => {
      assert(action.payload, Joi.object().pattern(Joi.string(), Joi.any()))
      state.clientSelectionDefaultValues = {
        ...action.payload,
      }
    },
    updateClientSelectionDefaultValues: (
      state,
      action: PayloadAction<Record<string, string | undefined | number>>,
    ) => {
      assert(action.payload, Joi.object().pattern(Joi.string(), Joi.any()))
      state.clientSelectionDefaultValues = {
        ...state.clientSelectionDefaultValues,
        ...action.payload,
      }
      // update selected email and phone if needed
      if (action.payload.email && isString(action.payload.email)) {
        state.userInputEmail = action.payload.email
      }
      if (action.payload.phone && isString(action.payload.phone)) {
        state.userInputPhone = action.payload.phone
      }
    },
    clearClientSelectionDefaultValues: (state) => {
      state.clientSelectionDefaultValues = {}
      state.userInputPhone = null
      state.userInputEmail = null
      state.userInputNotes = null
    },
    setUserInputPhone: (state, action: PayloadAction<string>) => {
      state.userInputPhone = action.payload
    },
    setUserInputEmail: (state, action: PayloadAction<string>) => {
      state.userInputEmail = action.payload
    },
    setUserInputNotes: (state, action: PayloadAction<string>) => {
      state.userInputNotes = action.payload
    },
    setMblsReservation: (
      state,
      action: PayloadAction<Record<string, string>>,
    ) => {
      assert(action.payload, Joi.object().pattern(Joi.string(), Joi.any()))
      state.mblsReservationClientId = action.payload.mblsReservationClientId
      state.mblsReservationDeliveryDate =
        action.payload.mblsReservationDeliveryDate
      state.mblsReservationTimeConstraintStart =
        action.payload.mblsReservationTimeConstraintStart
      state.mblsReservationTimeConstraintEnd =
        action.payload.mblsReservationTimeConstraintEnd
      state.mblsReservationSmartLockerNotificationPhone =
        action.payload.mblsReservationSmartLockerNotificationPhone
      state.mblsReservationSmartLockerNotificationEmail =
        action.payload.mblsReservationSmartLockerNotificationEmail
      state.mblsReservationOrderThirdPartyID =
        action.payload.mblsReservationOrderThirdPartyID
      state.mblsReservationNotes = action.payload.mblsReservationNotes
    },

    clearMblsReservation: (state) => {
      state.mblsReservationClientId = ""
      state.mblsReservationDeliveryDate = ""
      state.mblsReservationTimeConstraintStart = ""
      state.mblsReservationTimeConstraintEnd = ""
      state.mblsReservationSmartLockerNotificationPhone = ""
      state.mblsReservationSmartLockerNotificationEmail = ""
      state.mblsReservationOrderThirdPartyID = ""
      state.mblsReservationNotes = ""
    },
    setShowMblsReservationSearchBarCloseBtn: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.showMblsReservationSearchBarCloseBtn = action.payload
    },
    setHighlightedReservationId: (state, action: PayloadAction<string>) => {
      state.highlightedReservationId = action.payload
    },
    setSelectedDoorId: (state, action: PayloadAction<string>) => {
      state.selectedDoorId = action.payload
    },
    clearSelectedDoorId: (state) => {
      state.selectedDoorId = ""
    },
    setSelectedReservationId: (state, action: PayloadAction<string>) => {
      state.selectedReservationId = action.payload
    },
    clearSelectedReservationId: (state) => {
      state.selectedReservationId = ""
    },
    clearSelectedDoorReservation: (state) => {
      state.selectedDoorId = ""
      state.selectedReservationId = ""
    },
    setSelectedLockerId: (state, action: PayloadAction<string>) => {
      state.selectedLockerId = action.payload
    },
    clearSelectedLockerId: (state) => {
      state.selectedLockerId = ""
    },
    setDoorReservationIds: (
      state,
      action: PayloadAction<{
        doorId: string
        reservationId: string
        lockerId: string
      }>,
    ) => {
      state.selectedDoorId = action.payload.doorId
      state.selectedReservationId = action.payload.reservationId
      state.selectedLockerId = action.payload.lockerId
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      thingsApi.endpoints.getThings.matchFulfilled,
      (state, { payload }) => {
        const fetchedThings = get(payload, "things", []) as Thing[]
        fetchedThings.forEach((thing) => {
          const existingThing = state.entities[thing.id]
          if (existingThing) {
            // Perform a deep merge of the existing and updated things
            const mergedThing = merge({}, existingThing, thing)
            thingsAdapter.updateOne(state, {
              id: thing.id,
              changes: mergedThing,
            })
          } else {
            // If the thing doesn't exist yet, add it to the state
            thingsAdapter.addOne(state, thing)
          }
        })
      },
    )
    // update existing things for updateThings data.things
    builder.addMatcher(
      thingsApi.endpoints.updateThings.matchFulfilled,
      (state, { payload }) => {
        const updatedThings = get(payload, "data.things", []) as Thing[]
        updatedThings.forEach((updatedThing) => {
          const existingThing = state.entities[updatedThing.id]
          if (existingThing) {
            // Perform a deep merge of the existing and updated things
            const mergedThing = merge({}, existingThing, updatedThing)
            thingsAdapter.upsertOne(state, mergedThing)
          } else {
            // If the thing doesn't exist yet, add it to the state
            thingsAdapter.addOne(state, updatedThing)
          }
        })
      },
    )
    // matcher to delete things. extract response.data.ids and remove them from state
    builder.addMatcher(
      thingsApi.endpoints.deleteThings.matchFulfilled,
      (state, { payload }) => {
        const deletedThingIds = get(payload, "data.ids", []) as string[]
        thingsAdapter.removeMany(state, deletedThingIds)
      },
    )
    builder.addMatcher(
      thingsApi.endpoints.getThings.matchRejected,
      (state, { meta }) => {
        if (get(meta, "baseQueryMeta.response.status") === 403) {
          console.log("Unauthorized request.", meta)
          state.authorizedMessage = "Unauthorized request"
        }
      },
    )
    builder.addMatcher(
      thingsApi.endpoints.createThings.matchRejected,
      (state, { meta }) => {
        if (get(meta, "baseQueryMeta.response.status") === 403) {
          console.log("Unauthorized request.", meta)
          state.authorizedMessage = "Unauthorized request"
        }
      },
    )
    builder.addMatcher(
      thingsApi.endpoints.updateThings.matchRejected,
      (state, { meta }) => {
        if (get(meta, "baseQueryMeta.response.status") === 403) {
          console.log("Unauthorized request.", meta)
          state.authorizedMessage = "Unauthorized request"
        }
      },
    )
    builder.addMatcher(
      mblsconnectorApi.endpoints.getMblsConnectorClients.matchFulfilled,
      (state, { payload }) => {
        state.mblsClients = payload.data
      },
    )
  },
})

export const thingsActions = thingsSlice.actions
export const {
  selectAll: selectAllThings,
  selectById: selectThingById,
  selectIds: selectThingIds,
  // Add other selectors here
} = thingsAdapter.getSelectors((state: RootState) => state.things)

export default thingsSlice.reducer

export const selectThingsByThingTypeMrnFilter = createSelector(
  // First, define the input selectors. These should be simple functions that return a part of the state
  [
    (state: RootState) => selectAllThings(state),
    (_state, thingTypeMrn) => thingTypeMrn,
    (_state, _thingTypeMrn, filterFn: (thing: any) => boolean) => filterFn,
  ],

  // Then, define the result function. This function will receive the outputs of the input selectors in the same order
  (things, thingTypeMrn, filterFn) =>
    things.filter(
      (thing) => thing.thingTypeMrn === thingTypeMrn && filterFn(thing),
    ),
)

export const selectThingsByThingTypeMrn = createSelector(
  // First, define the input selectors. These should be simple functions that return a part of the state
  [
    (state: RootState) => selectAllThings(state),
    (_, thingTypeMrn) => thingTypeMrn,
  ],

  // Then, define the result function. This function will receive the outputs of the input selectors in the same order
  (things, thingTypeMrn) =>
    things.filter((thing) => thing.thingTypeMrn === thingTypeMrn),
)

export const selectUnauthorizedMessage = (state: RootState) =>
  state.things.authorizedMessage

export const selectSelectedReservation = (state: RootState) =>
  state.things.entities[state.things.selectedReservationId || ""]

export const selectClientSelectionList = (state: RootState) => {
  const clients = selectThingsByThingTypeMrn(
    state,
    ClientThingTypeMrn,
  ) as unknown as Client[]
  const { clientSelectionSearchValues = {} } = state.things
  const { firstName, lastName, email, phone } = clientSelectionSearchValues

  return clients.filter((c: Client) => {
    const conditions = []
    if (firstName) {
      conditions.push(
        c.firstName?.toLowerCase().includes(firstName.toLowerCase()),
      )
    }
    if (lastName) {
      conditions.push(
        c.lastName?.toLowerCase().includes(lastName.toLowerCase()),
      )
    }
    if (email) {
      conditions.push(c.email?.toLowerCase().includes(email.toLowerCase()))
    }
    if (phone) {
      conditions.push(c.phone?.toLowerCase().includes(phone.toLowerCase()))
    }

    return conditions.some((c) => c) || conditions.length === 0
  })
}

export const selectFilteredClientSelectionList = (state: RootState) =>
  state.things.filteredClientSelectionIds.map((id) => state.things.entities[id])

export const selectClientSelectionSearchValues = (state: RootState) =>
  state.things.clientSelectionSearchValues

export const selectClientSelectionDefaultValues = (state: RootState) =>
  state.things.clientSelectionDefaultValues

export const selectMblsClients = (state: RootState) => state.things.mblsClients

export const selectMblsReservation = (state: RootState) => ({
  clientId: state.things.mblsReservationClientId,
  deliveryDate: state.things.mblsReservationDeliveryDate,
  timeConstraintStart: state.things.mblsReservationTimeConstraintStart,
  timeConstraintEnd: state.things.mblsReservationTimeConstraintEnd,
  smartLockerNotificationPhone:
    state.things.mblsReservationSmartLockerNotificationPhone,
  smartLockerNotificationEmail:
    state.things.mblsReservationSmartLockerNotificationEmail,
  orderThirdPartyID: state.things.mblsReservationOrderThirdPartyID,
  notes: state.things.mblsReservationNotes,
})

export const selectMblsReservationClient = (state: RootState) =>
  // get mblsCient with id from mblsReservationClientId
  state.things.mblsClients.find(
    (c) => c.id === state.things.mblsReservationClientId,
  )

export const selectMblsReservationClientId = (state: RootState) =>
  state.things.mblsReservationClientId

export const selectIsLoadingMblsReservationClient = (state: RootState) =>
  state.things.mblsClients.length === 0

export const selectShowMblsReservationSearchBarCloseBtn = (state: RootState) =>
  state.things.showMblsReservationSearchBarCloseBtn

export const selectHighlightedReservation = (state: RootState) =>
  state.things.entities[state.things.highlightedReservationId || ""]

export const selectHighlightedReservationId = (state: RootState) =>
  state.things.highlightedReservationId

// selected door + reservation + session
export type DoorReservation = {
  locker?: Locker
  door?: SstDoor
  reservation?: Reservation
  session?: LockerDoorReservationSession
}
export const selectSelectedDoorReservation = (state: RootState) => {
  // selectedLockerId
  const locker = selectThingById(
    state,
    state.things.selectedLockerId || "",
  ) as Locker

  const door = locker?.sstLocker?.doors?.find(
    (d: SstDoor) => d.door_id === state.things.selectedDoorId,
  )

  const reservation = selectThingById(
    state,
    state.things.selectedReservationId || "",
  ) as Thing

  const sessions = selectThingsByThingTypeMrn(
    state,
    LockerDoorReservationSessionThingTypeMrn,
  ) as unknown as LockerDoorReservationSession[]

  const session = sessions?.find(
    (s: LockerDoorReservationSession) => s.sstDoorId === door?.door_id,
  )
  return { locker, door, reservation, session } as unknown as DoorReservation
}

export type SelectedClient = {
  id: string
  firstName: string
  lastName: string
  email: string
  language?: string
  phones?: { id: string; number: string; label: string }[]
  addresses?: {
    id: string
    street: string
    city: string
    postalCode: string
    country: string
    label: string
  }[]
}

export const selectClientById = (state: RootState, clientId: string) => {
  // if mblsReservationClientId exist, return mblsClient with that id. othewise attempt to return clients with defaultValues.clientId
  const mblsClient0 = state.things.mblsClients.find((c) => c.id === clientId)
  if (mblsClient0) {
    let phones = isArray(mblsClient0?.phones) ? mblsClient0?.phones : []

    if (state.things.mblsReservationSmartLockerNotificationPhone) {
      phones = [
        {
          id: "new",
          number: state.things.mblsReservationSmartLockerNotificationPhone,
          label: "custom",
        },
        ...phones,
      ]
    }

    let email = mblsClient0?.email

    if (state.things.mblsReservationSmartLockerNotificationEmail) {
      email = state.things.mblsReservationSmartLockerNotificationEmail
    }

    return {
      id: mblsClient0?.id,
      firstName: mblsClient0?.firstName,
      lastName: mblsClient0?.lastName,
      email,
      phones,
      addresses: isArray(mblsClient0?.addresses) ? mblsClient0?.addresses : [],
      language: mblsClient0?.language,
    } as unknown as SelectedClient
  }

  const mblsClient1 = state.things.mblsClients.find((c) => c.id === clientId)

  if (mblsClient1) {
    return {
      id: mblsClient1?.id,
      firstName: mblsClient1?.firstName,
      lastName: mblsClient1?.lastName,
      email: mblsClient1?.email,
      language: mblsClient1?.language,
      phones: mblsClient1?.phones,
      addresses: mblsClient1?.addresses,
    } as unknown as SelectedClient
  }

  const client = state.things.entities[clientId]

  if (!client) return null

  return {
    id: client?.id,
    firstName: client?.firstName,
    lastName: client?.lastName,
    email: client?.email,
    phones: [
      {
        id: client?.phone,
        number: client?.phone,
        label: "default",
      },
    ],
    addresses: [],
  } as unknown as SelectedClient
}

export const selectSelectedClient = (state: RootState) => {
  const clientId =
    state.things.mblsReservationClientId ||
    state.things.clientSelectionDefaultValues?.clientId
  return selectClientById(state, clientId + "")
}

export const selectUserInputPhone = (state: RootState) =>
  state.things.userInputPhone

export const selectUserInputEmail = (state: RootState) =>
  state.things.userInputEmail

export const selectUserInputNotes = (state: RootState) =>
  state.things.userInputNotes

export const selectReservationFormDefaultValues = (state: RootState) => {
  let firstName = state.things.clientSelectionDefaultValues?.firstName || ""
  let lastName = state.things.clientSelectionDefaultValues?.lastName || ""
  let email = state.things.clientSelectionDefaultValues?.email || ""
  let phone = state.things.clientSelectionDefaultValues?.phone || ""

  let clientId = state.things.clientSelectionDefaultValues?.clientId || ""

  let notes =
    state.things.userInputNotes ||
    state.things.mblsReservationNotes ||
    state.things.clientSelectionDefaultValues?.notes ||
    ""

  const selectedClient = selectSelectedClient(state)
  if (selectedClient?.id) {
    firstName = selectedClient?.firstName
    lastName = selectedClient?.lastName
    email = selectedClient?.email || ""
    phone = selectedClient?.phones?.[0]?.number || ""
    clientId = selectedClient?.id || ""
  }

  if (
    state.things.mblsReservationSmartLockerNotificationPhone &&
    !state.things.mblsReservationSmartLockerNotificationEmail
  ) {
    phone = state.things.mblsReservationSmartLockerNotificationPhone
    email = ""
  } else if (
    !state.things.mblsReservationSmartLockerNotificationPhone &&
    state.things.mblsReservationSmartLockerNotificationEmail
  ) {
    phone = ""
    email = state.things.mblsReservationSmartLockerNotificationEmail
  } else if (
    state.things.mblsReservationSmartLockerNotificationPhone &&
    state.things.mblsReservationSmartLockerNotificationEmail
  ) {
    phone = state.things.mblsReservationSmartLockerNotificationPhone
    email = state.things.mblsReservationSmartLockerNotificationEmail
  }

  return {
    ...state.things.clientSelectionDefaultValues,
    firstName,
    lastName,
    email,
    phone,
    clientId,
    notes,
  }
}
