import { Close } from "@mui/icons-material"
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { t } from "i18next"
import moment from "moment"
import { FC, useCallback, useMemo, useState } from "react"
import { SstLocker } from "shared/sst"
import ObjectDisplayTable from "./ObjectDisplayTable"

type SstLockersProps = {
  data: SstLocker[]
  isLoading: boolean
}

export const SstLockers: FC<SstLockersProps> = ({ data, isLoading }) => {
  const [previewLId, setPreviewLId] = useState<string | null>(null)
  const [searchQuery, setSearchQuery] = useState<string>("")

  const previewL = useMemo(() => {
    return data?.find((l) => l._id === previewLId)
  }, [data, previewLId])

  const onClick = useCallback((l: any) => {
    setPreviewLId(l?._id)
  }, [])

  const filteredData = useMemo(() => {
    return data.filter(
      (l) =>
        l._id?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        l.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        l.address_1?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        l.address_2?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        l.city?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        l.client_name?.toLowerCase().includes(searchQuery.toLowerCase()),
    )
  }, [data, searchQuery])

  if (!data) {
    // outlined paper with no data message
    return (
      <Paper variant="outlined" elevation={0} sx={{ p: 2 }}>
        No data
      </Paper>
    )
  }

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="body1" fontWeight="bold">
        {filteredData?.length === data?.length && (
          <> {`${t("lockers")} (${data?.length})`}</>
        )}
        {filteredData?.length !== data?.length && (
          <> {`${t("lockers")} (${filteredData?.length}/${data?.length})`}</>
        )}
      </Typography>
      <Box
        sx={{
          width: "500px",
        }}
      >
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          size="small"
          margin="normal"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>
      {filteredData.map((l) => (
        <Stack direction="column" spacing={1} key={l._id}>
          <Paper variant="outlined" elevation={0} sx={{ p: 2 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Stack direction="column" spacing={1}>
                <Box
                  sx={{
                    // link style
                    cursor: "pointer",
                    color: "primary.main",
                  }}
                  onClick={() => onClick(l)}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    divider={<Divider orientation="vertical" flexItem />}
                  >
                    <Typography variant="body1">{l.description}</Typography>
                    <Typography variant="body1">{l.client_name}</Typography>
                    <Typography variant="body1">{l.address_1}</Typography>
                    <Typography variant="body1">
                      {l.address_2 || "-"}
                    </Typography>
                    <Typography variant="body1">{l.city}</Typography>
                  </Stack>
                </Box>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body2">{l.address_1}</Typography>
                  <Typography variant="body2">{l.address_2}</Typography>
                  <Typography variant="body2">{l.city}</Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Typography variant="caption" color="textSecondary">
                    {`${t("ID")} ${l._id}`}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {`${l.doors?.length} ${t("doors")}`}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {`${t("created")} ${moment(l._created).fromNow()}`}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      ))}
      <Drawer
        anchor="right"
        open={!!previewLId}
        onClose={() => setPreviewLId(null)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "80vw",
            maxWidth: "1500px",
            maxHeight: "calc(100vh - 64px)",
            height: "calc(100vh - 64px)",
            paddingTop: "64px",
          },
        }}
      >
        <Stack direction="column" spacing={1} p={2}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Typography variant="body1">{previewL?.description}</Typography>
                <Typography variant="body1">{previewL?.client_name}</Typography>
              </Stack>
            </Stack>
            <IconButton onClick={() => setPreviewLId(null)}>
              <Close />
            </IconButton>
          </Stack>
          <Divider />

          <ObjectDisplayTable data={previewL} />
        </Stack>
      </Drawer>
    </Stack>
  )
}

export default SstLockers
