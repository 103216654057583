import { Close, Refresh } from "@mui/icons-material"
import {
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material"
import { map } from "lodash"
import moment from "moment"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { EmailDelivery } from "shared/emailDelivery"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { useGetThingsQuery } from "../features/things/thingsApi"
import ObjectDisplayTable from "./ObjectDisplayTable"
import SaNotifDeliveriesEmailEventItem from "./SaNotifDeliveriesEmailEventItem"

type SaNotifDeliveriesEmailDrawerProps = {
  delivery: EmailDelivery
  onClose: () => void
}

export const SaNotifDeliveriesEmailDrawer: FC<
  SaNotifDeliveriesEmailDrawerProps
> = ({ delivery, onClose }) => {
  const { t } = useTranslation()

  const qEvents = useGetThingsQuery(
    {
      params: {
        "ap.name":
          SharedAccessPatternSaEnum.SaGetEmailDeliveryEventsByEmailDeliveryId,
        "ap.emailDeliveryId": delivery?.id,
      },
    },
    {
      skip: !delivery?.id,
    },
  )

  return (
    <Drawer
      anchor="right"
      open={true}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          width: "80vw",
          maxWidth: "1500px",
          maxHeight: "calc(100vh - 64px)",
          height: "calc(100vh - 64px)",
          paddingTop: "64px",
        },
      }}
    >
      <Stack direction="column" spacing={1} p={2}>
        <Stack direction="column" spacing={0}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{
                  color: "primary.main",
                }}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Typography variant="body1" fontWeight="bold">
                  {delivery.subject}
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                  {delivery.toEmail}
                </Typography>
              </Stack>
            </Stack>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="caption">
              {moment(delivery.createdAt).fromNow()}
            </Typography>
            <Typography variant="caption">
              {moment(delivery.createdAt).format("YYYY-MM-DD HH:mm:ss")}
            </Typography>
          </Stack>
        </Stack>
        <Divider />

        <ObjectDisplayTable data={delivery} isLoading={qEvents.isLoading} />
        <Stack direction="column" spacing={1} pt={4}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body1" fontWeight="bold">
                {t("emailEvents", { count: qEvents?.data?.things?.length })}
              </Typography>
              {qEvents.isFetching || qEvents.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <IconButton onClick={qEvents.refetch}>
                  <Refresh />
                </IconButton>
              )}
            </Stack>
            <Link
              href={
                "https://docs.aws.amazon.com/ses/latest/dg/event-publishing-retrieving-sns-contents.html"
              }
              target="_blank"
            >
              <Typography variant="body2">{t("sesEventTypesAws")}</Typography>
            </Link>
          </Stack>
          <Stack direction="column" spacing={1}>
            {qEvents.isLoading && <CircularProgress />}
            {qEvents.isError && (
              <Typography variant="caption" color="error">
                {t("errorFetchingEmailEvents")}
              </Typography>
            )}
            {qEvents?.data?.things?.length === 0 && (
              <Typography>{t("noData")}</Typography>
            )}
            {(qEvents?.data?.things?.length || 0) > 0 &&
              map(qEvents?.data?.things, (deliveryEvent) => (
                <SaNotifDeliveriesEmailEventItem
                  key={deliveryEvent.id}
                  deliveryEvent={deliveryEvent}
                />
              ))}
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default SaNotifDeliveriesEmailDrawer
