import { Close, Email } from "@mui/icons-material"
import {
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import moment from "moment"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import {
  ReservationAud,
  ReservationAudDetailsEmailSent,
} from "shared/reservationAud"
import { ReservationAudEventEnum } from "shared/reservationAudData"
import { EmailPreview } from "./EmailPreview"
import { Sl2Btn } from "./Sl2Btn"

type OsReservationAuditTrailDetailsEmailSentProps = {
  aud: ReservationAud
}

export const OsReservationAuditTrailDetailsEmailSent: FC<
  OsReservationAuditTrailDetailsEmailSentProps
> = ({ aud }) => {
  const { t } = useTranslation()
  let { details } = aud

  const [showPreview, setShowPreview] = React.useState(false)

  if (!details || typeof details !== "object") {
    return null
  }
  // event type must be ReservationAudEventEnum.emailSent
  if (aud.event !== ReservationAudEventEnum.emailSent) {
    return null
  }

  details = details as ReservationAudDetailsEmailSent

  return (
    <Stack direction="column" spacing={1} p={1}>
      <Stack direction="column" spacing={1}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Email fontSize="small" color="primary" />
            <Typography variant="body2" color="primary">
              {t("emailSent")}
            </Typography>
          </Stack>
          <Sl2Btn
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => setShowPreview(true)}
          >
            {t("preview")}
          </Sl2Btn>
        </Stack>
        <Divider />
        <Typography variant="body2" fontWeight="bold">
          {details?.templateName ? t(`${details?.templateName}.label`) : ""}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1} alignContent={"center"}>
        <Typography variant="caption">{t("sentFrom")}</Typography>
        <Typography variant="body2">{details.fromEmail}</Typography>
      </Stack>
      <Stack direction="row" spacing={1} alignContent={"center"}>
        <Typography variant="caption">{t("sentTo")}</Typography>
        <Typography variant="body2">{details.toEmail}</Typography>
      </Stack>
      <Dialog
        open={showPreview}
        onClose={() => setShowPreview(false)}
        maxWidth="lg"
      >
        <DialogContent>
          <Stack direction="column" spacing={1}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Stack direction="column" spacing={1}>
                <Typography variant="body2" fontWeight="bold">
                  {details.templateName
                    ? t(`${details.templateName}.label`)
                    : ""}
                </Typography>
                <Typography variant="caption">
                  {moment(aud.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                </Typography>
              </Stack>
              <IconButton size="small" onClick={() => setShowPreview(false)}>
                <Close fontSize="small" />
              </IconButton>
            </Stack>
            <EmailPreview
              toEmail={details.toEmail}
              fromEmail={details.fromEmail}
              subject={details.subject}
              body={details.body}
              fullName={details.fullName}
              deliveryId={details.deliveryId}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </Stack>
  )
}
