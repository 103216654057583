import { createApi } from "@reduxjs/toolkit/query/react"
import { ReportRequest } from "shared/report"
import { baseQuery } from "../baseQuerySl2"

export const reportApi = createApi({
  reducerPath: "reportApi",
  baseQuery,
  endpoints: (builder) => ({
    requestReportData: builder.mutation<ReportRequest, any>({
      query: ({ params = {}, body }) => ({
        url: "report-data",
        method: "POST",
        params,
        body,
      }),
    }),
  }),
})

export const { useRequestReportDataMutation } = reportApi
