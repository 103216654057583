import { ErrorMessage } from "@hookform/error-message"
import {
  Box,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import { get, noop } from "lodash"
import React, { FC, useEffect } from "react"
import {
  AutocompleteElement,
  AutocompleteElementProps,
  Controller,
  ControllerRenderProps,
  FieldValues,
  SelectElement,
  SelectElementProps,
  TextFieldElement,
  TextFieldElementProps,
  useFormContext,
} from "react-hook-form-mui"
import { useTranslation } from "react-i18next"

export type FormField = {
  name: string
  label: string
  required?: boolean
  size?: string
  fullWidth?: boolean
  gridProps?: Partial<React.ComponentProps<typeof Grid>>
  fieldType: "text" | "select" | "number" | "bool" | "autocomplete"
  visible?: boolean
  onChangePredicate?: (value: any) => boolean
  EndComponent?: React.ComponentType<{
    field: FormField
    muiField: ControllerRenderProps<FieldValues, string>
  }>
  textProps?: TextFieldElementProps
  selectProps?: SelectElementProps
  autocompleteProps?: AutocompleteElementProps
  //
  onBlur?: (value: any) => any
  onChange?: (value: any) => any
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  options?: { id: string; label: string }[]
  InputProps?: any
} & TextFieldElementProps &
  SelectElementProps

interface FormFieldsProps {
  fields: FormField[]
  onWatch?: (value: any, { name, type }: { name: string; type: string }) => void
  defaultValues?: Record<string, any>
  title?: string
  rowSpacing?: number
  columnSpacing?: number
}

export type FieldsGeneratorFn = (action: "create" | "update") => FormField[]

export const FormFields: FC<FormFieldsProps> = ({
  fields,
  onWatch = noop,
  defaultValues,
  title,
  rowSpacing = 3,
  columnSpacing = 2,
}) => {
  const {
    formState: { errors },
    watch,
    setValue,
  } = useFormContext()

  useEffect(() => {
    const subscription = watch(onWatch)
    return () => subscription.unsubscribe()
  }, [onWatch, watch])

  // // use effect setValue on fields as timestampe Date.now() to update the form
  useEffect(() => {
    const e = () => {
      for (const key in defaultValues) {
        setValue(key, defaultValues[key])
      }
    }
    e()
    // setInterval(e, 1000)
  }, [defaultValues, setValue])
  const { t } = useTranslation()
  return (
    <Box>
      <Grid
        container
        columnSpacing={columnSpacing}
        rowSpacing={rowSpacing}
        p={0}
      >
        {title && (
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold">
              {title}
            </Typography>
          </Grid>
        )}
        {fields
          .filter((f) => get(f, "visible", true))
          .map((field) => {
            const {
              gridProps,
              fieldType,
              EndComponent,
              textProps,
              selectProps,
              autocompleteProps,
              ...rest
            } = field
            return (
              <Grid item xs={12} {...field.gridProps} key={field.name}>
                {{
                  text: (
                    <Controller
                      name={field.name}
                      render={({ field: muiField }) => (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <TextFieldElement
                            sx={{ bgcolor: "white" }}
                            autoComplete="off"
                            {...muiField}
                            {...rest}
                            onBlurCapture={(
                              e: React.FocusEvent<HTMLInputElement>,
                            ) => {
                              // trim whitespaces
                              e.target.value = e.target.value.trim()
                              muiField.onChange(e)
                            }}
                            error={Boolean(errors[field.name])}
                            helperText={
                              <ErrorMessage
                                errors={errors}
                                name={field.name}
                                as="span"
                              />
                            }
                            {...textProps}
                            // helperText={errors[field.name]?.message}
                          />
                          {EndComponent && (
                            // React.createElement(field.endComponent, { field })}
                            <EndComponent field={field} muiField={muiField} />
                          )}
                        </Stack>
                      )}
                    />
                  ),
                  select: <SelectElement {...rest} {...selectProps} />,
                  autocomplete: (
                    // @ts-expect-error autocompleteProps needs refactor
                    <AutocompleteElement {...rest} {...autocompleteProps} />
                  ),
                  number: (
                    <Controller
                      name={field.name}
                      render={({ field: muiField }) => (
                        <TextField
                          autoComplete="off"
                          {...muiField}
                          {...rest}
                          onBlur={(e) => {
                            // transform value on blur if needed
                            if (field.onBlur) {
                              e.target.value = field.onBlur(e.target.value)
                            }
                            muiField.onChange(e)
                          }}
                          onChange={(e) => {
                            if (field.onChangePredicate) {
                              if (!field.onChangePredicate(e.target.value))
                                return
                            }
                            if (field.onChange) {
                              e.target.value = field.onChange(e.target.value)
                              muiField.onChange(e)
                              return
                            }
                            if (isNaN(Number(e.target.value))) {
                              // prevent if not a number
                              return
                            }
                            muiField.onChange(e)
                          }}
                          label={t(field.name)}
                          type="number"
                          fullWidth
                          error={Boolean(errors[field.name])}
                          helperText={
                            <ErrorMessage
                              errors={errors}
                              name={field.name}
                              as="span"
                            />
                          }
                        />
                      )}
                    />
                  ),
                  bool: (
                    <Controller
                      name={field.name}
                      render={({ field: muiField }) => (
                        // Switch
                        <FormControlLabel
                          control={
                            <Switch
                              checked={muiField.value}
                              onChange={muiField.onChange}
                              name={muiField.name}
                            />
                          }
                          label={t(field.label)}
                        />
                      )}
                    />
                  ),
                }[field.fieldType] || (
                  <Typography>Unknown field type</Typography>
                )}
                {/* // endComponent with props */}
              </Grid>
            )
          })}
      </Grid>
    </Box>
  )
}

export default FormFields
