import { Box, Divider, Grid, Paper, Stack, Typography } from "@mui/material"
import { t } from "i18next"
import { get, map } from "lodash"
import { FC, useCallback, useEffect, useState } from "react"
import {
  ActionReqExecutionModeEnum,
  ActionReqThingTypeMrn,
  ActionReqTypeEnum,
} from "shared/actionReqData"
import { Locker } from "shared/locker"
import { VirtualLockerActionEnum } from "shared/lockerData"
import { LockerGrid, SstDoor } from "shared/sst"
import { useCreateThingsMutation } from "src/features/things/thingsApi"
import { useCurrentTenant } from "src/hooks/useCurrentTenant"
import { useIoT } from "src/iot/MrtContext"
import { v4 as uuidv4 } from "uuid"
import { InteractiveDoor } from "./InteractiveDoor"
import { LockerControllerActions } from "./LockerControllerActions"
import { LockerNumericPad } from "./LockerNumericPad"
import { Sl2Btn } from "./Sl2Btn"
type LockerControllerProps = {
  locker: Locker
}
export const LockerController: FC<LockerControllerProps> = ({ locker }) => {
  const [highlightedDial, setHighlightedDial] = useState<string>("")
  const [createThings] = useCreateThingsMutation()
  const { tenant } = useCurrentTenant()
  const [inputCode, setInputCode] = useState<string>("")
  const [lockerLogs, setLockerLogs] = useState<Record<string, any>[]>(
    [] as Record<string, any>[],
  )

  const { lastMessageJson } = useIoT()

  useEffect(() => {
    const logToKeep =
      get(lastMessageJson, "type") === "virtualLockerLog" &&
      get(lastMessageJson, "data.lockerId") === locker.id
    if (logToKeep) {
      // keep the latest 50 logs
      // @ts-ignore
      setLockerLogs((prevLogs: Record<string, any>[]) => {
        const newLogs = [lastMessageJson, ...prevLogs]
        return newLogs.slice(0, 50)
      })
    }
  }, [lastMessageJson, locker.id])

  const onDialPress = useCallback(
    (value: string) => {
      setHighlightedDial(value)
    },
    [setHighlightedDial],
  )

  const onSubmitCode = useCallback(async () => {
    if (!tenant) {
      console.error("No tenant")
      return
    }
    try {
      const actionReq = {
        thingTypeMrn: ActionReqThingTypeMrn,
        tenantId: tenant?.id,
        idempotencyKey: uuidv4(),
        actionType: ActionReqTypeEnum.TriggerVirtualLockerAction,
        executionMode: ActionReqExecutionModeEnum.sync,
        inputData: {
          lockerId: locker.id,
          lockerAction: VirtualLockerActionEnum.SubmitCode,
          code: inputCode,
        },
      }
      await createThings({
        body: { things: [actionReq] },
        params: { tenantId: tenant?.id },
      }).unwrap()
    } catch (err) {
      console.error("Error submitting code", err)
    }
  }, [createThings, inputCode, locker.id, tenant])

  const onEnter = useCallback(() => {
    onSubmitCode()
    setHighlightedDial("")
  }, [onSubmitCode])

  const onDoorClick = useCallback(
    async (door: SstDoor) => {
      if (!tenant) {
        console.error("No tenant")
        return
      }
      const actionReq = {
        thingTypeMrn: ActionReqThingTypeMrn,
        tenantId: tenant?.id,
        idempotencyKey: uuidv4(),
        actionType: ActionReqTypeEnum.TriggerVirtualLockerAction,
        executionMode: ActionReqExecutionModeEnum.sync,
        inputData: {
          lockerId: locker.id,
          lockerAction: VirtualLockerActionEnum.CloseDoor,
          sstDoorId: door.door_id,
        },
      }
      await createThings({
        body: { things: [actionReq] },
        params: { tenantId: tenant?.id },
      }).unwrap()
    },
    [createThings, locker.id, tenant],
  )

  return (
    <Stack direction="column" spacing={1} sx={{ px: 4, pt: 4 }}>
      <LockerControllerActions locker={locker} />

      <Stack
        direction="row"
        spacing={1}
        alignContent={"center"}
        alignItems={"center"}
      >
        <Stack direction="column">
          <Paper variant="outlined" sx={{ p: 2 }}>
            <LockerNumericPad
              onPress={onDialPress}
              onEnter={onEnter}
              highlightedDial={highlightedDial}
              lockerId={locker.id}
              onChange={setInputCode}
            />
          </Paper>
        </Stack>

        <Grid container spacing={1} mx={2} maxWidth="1000px">
          {map(locker_grid, (_, i) => (
            <Grid item key={i}>
              <Stack direction="column" spacing={2}>
                <Typography variant="body1" fontWeight={"bold"}>{`${t(
                  "module",
                )} ${i + 1}`}</Typography>
                <Paper
                  variant="outlined"
                  sx={{
                    p: 1,
                    bgcolor: "rgba(0, 0, 0, 0.02)",
                  }}
                >
                  <Grid container spacing={2}>
                    {map(locker?.sstLocker?.doors, (door) => {
                      if (!door) {
                        return null
                      }
                      return (
                        <Grid key={door.door_id} item>
                          <InteractiveDoor
                            isOpen={door.latch_status}
                            label={`Door ${door.door_label}`}
                            onClick={() => onDoorClick(door)}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </Paper>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
      <Paper variant="outlined" sx={{ p: 2 }}>
        <Stack direction="column" spacing={1}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1" fontWeight={"bold"}>
              {t("logs")}
            </Typography>
            <Sl2Btn onClick={() => setLockerLogs([])}>{t("clear")}</Sl2Btn>
          </Stack>
          <Divider />
          <Stack
            direction="column"
            spacing={1}
            sx={{
              height: 400,
              overflowY: "auto",
            }}
          >
            {map(lockerLogs, (log, i) => (
              <Box
                key={i}
                sx={{
                  px: 0,
                  borderBottom: log?.data?.lockerDisplay
                    ? "1px solid rgba(0, 0, 0, 0.12)"
                    : "none",
                }}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body2">{`[${log?.timestamp}]`}</Typography>
                  {log?.data?.lockerDisplay ? (
                    <Typography
                      variant="body2"
                      // fontFamily={"monospace"}
                      fontWeight={"bold"}
                      width={200}
                    >
                      {log?.data?.lockerDisplay}
                    </Typography>
                  ) : (
                    <Box width={200} />
                  )}
                  <Typography variant="body2">{log?.data?.message}</Typography>
                </Stack>
              </Box>
            ))}
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  )
}

const locker_grid: LockerGrid[] = [
  {
    width: 343,
    height: 559,
    depth: 330,
    cells_group: [],
    name: null,
  },
  // {
  //   width: 343,
  //   height: 559,
  //   depth: 330,
  //   cells_group: [],
  // },
]
