import { Close, Expand } from "@mui/icons-material"
import Refresh from "@mui/icons-material/Refresh"
import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { DataGrid, DataGridProps } from "@mui/x-data-grid"
import { TFunction } from "i18next"
import moment from "moment"
import { FC, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { JsonView } from "react-json-view-lite"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { useGetThingsQuery } from "../features/things/thingsApi"
import SaLayout from "./SaLayout"

const defaultColWidth = 230

export const SaSeedTrackersPage: FC = () => {
  const { t } = useTranslation()

  const q = useGetThingsQuery({
    params: {
      "ap.name": SharedAccessPatternSaEnum.SaGetSeedTrackers,
    },
  })

  const [previewData, setPreviewData] = useState<Record<string, any> | null>(
    null,
  )

  const columns = useCallback(
    (t: TFunction): DataGridProps["columns"] => [
      {
        field: "preview",
        headerName: "",
        minWidth: 40,
        renderCell: (params) => {
          return (
            <IconButton onClick={() => setPreviewData(params.row)}>
              <Expand />
            </IconButton>
          )
        },
      },
      {
        field: "createdAt",
        headerName: `📅 ${t("createdAt")}`,
        minWidth: defaultColWidth,
        // render cell with moment fromNow
        renderCell: (params) => {
          return (
            <Stack direction="column" spacing={1}>
              <Typography variant="body2" noWrap>
                {params.value
                  ? moment(params.value).format("YYYY MM-DD HH:mm:ss")
                  : ""}
              </Typography>
              <Typography variant="caption" noWrap>
                {params.value ? moment(params.value).fromNow() : ""}
              </Typography>
            </Stack>
          )
        },
      },
      {
        field: "updatedAt",
        headerName: `📅 ${t("updatedAt")}`,
        minWidth: defaultColWidth,
        renderCell: (params) => {
          return (
            <Stack direction="column" spacing={1}>
              <Typography variant="body2" noWrap>
                {params.value
                  ? moment(params.value).format("YYYY MM-DD HH:mm:ss")
                  : ""}
              </Typography>
              <Typography variant="caption" noWrap>
                {params.value ? moment(params.value).fromNow() : ""}
              </Typography>
            </Stack>
          )
        },
      },
      {
        field: "status",
        headerName: "status",
        minWidth: defaultColWidth,
        // render cell with status : green for success, red for failed, orange for running
        renderCell: (params) => {
          return (
            <Stack direction="column" spacing={1}>
              <Typography
                variant="body2"
                noWrap
                sx={{
                  color:
                    params.value === "SUCCESS"
                      ? "green"
                      : params.value === "FAILED"
                      ? "red"
                      : "orange",
                }}
              >
                {params.value}
              </Typography>
              {params.row.reason && (
                <Typography variant="caption" noWrap>
                  {params.row.reason}
                </Typography>
              )}
            </Stack>
          )
        },
      },
      {
        field: "id",
        headerName: "id",
        minWidth: defaultColWidth,
      },
      {
        field: "seederVersion",
        headerName: "seederVersion",
        minWidth: defaultColWidth,
      },
      {
        field: "lastAppliedIdx",
        headerName: "lastAppliedIdx",
        minWidth: defaultColWidth,
      },
      {
        field: "lastAppliedDevIdx",
        headerName: "lastAppliedDevIdx",
        minWidth: defaultColWidth,
      },
      {
        field: "appliedIdxs",
        headerName: "appliedIdxs",
        minWidth: defaultColWidth,
      },
      {
        field: "appliedDevIdxs",
        headerName: "appliedDevIdxs",
        minWidth: defaultColWidth,
      },
    ],
    [],
  )

  return (
    <SaLayout selectedMenuItemLabelKey="seedTrackers">
      <Stack direction="column" spacing={4} p={2} pl={4} mt={4} width="100%">
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <Typography variant="h5">{t("seedTrackers")}</Typography>
          {!q.isLoading && (
            <IconButton onClick={q.refetch}>
              <Refresh />
            </IconButton>
          )}
          {q.isLoading || (q.isFetching && <CircularProgress />)}
        </Stack>
        <Stack direction="column" spacing={2} mt={2}>
          <Box sx={{}}>
            <DataGrid
              columns={columns(t)}
              rows={q.data?.things || []}
              autoHeight
              rowSelection={false}
              sx={{
                // width: "100%",
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
            />
          </Box>
        </Stack>
      </Stack>
      {previewData && (
        <Dialog
          open={true}
          onClose={() => setPreviewData(null)}
          fullWidth
          maxWidth="md"
        >
          <Stack direction="column" spacing={1}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">{t("preview")}</Typography>
              <IconButton onClick={() => setPreviewData(null)}>
                <Close />
              </IconButton>
            </Stack>
            <JsonView data={previewData} />
          </Stack>
        </Dialog>
      )}
    </SaLayout>
  )
}

export default SaSeedTrackersPage
