import { CircularProgress, Paper, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { MblsConnectorClient } from "src/features/mblsConnector/mblsConnector"
import logo from "../assets/mobilus_logo.png"

type MblsConnectorClientSelectorProps = {
  clients: MblsConnectorClient[]
  isLoading: boolean
  onSelect: (client: MblsConnectorClient) => void
}

export const MblsConnectorClientSelector: FC<
  MblsConnectorClientSelectorProps
> = ({ clients, isLoading, onSelect }) => {
  const { t } = useTranslation()
  return (
    <Stack
      spacing={1}
      direction="column"
      sx={{
        overflow: "auto",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h6">{`${t("clients")} (${
          clients.length
        })`}</Typography>
        <img src={logo} alt="Mobilus Logo" height={30} />
      </Stack>
      <Typography variant="body2">{t("createFromExistingClient")}</Typography>
      {isLoading && <CircularProgress />}
      {clients.map((client) => (
        <Paper
          variant="outlined"
          key={client.id}
          sx={{
            p: 1,
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "#FAFBFB",
            },
          }}
          onClick={() => onSelect(client)}
        >
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            {/* stack column with fullanem & email */}
            <Stack direction="column" spacing={1}>
              <Typography variant="body1" fontWeight={"bold"}>
                {client.firstName} {client.lastName}
              </Typography>
              <Typography variant="body2">{`✉️ ${
                client.email || t("noEmail")
              }`}</Typography>
              {/* phone */}
              <Typography variant="body2">{`📞 ${
                client.phones?.[0]?.number || t("noPhone")
              }`}</Typography>
            </Stack>
            {/* client id with ID emojoi */}
            <Typography
              variant="caption"
              fontFamily={"monospace"}
            >{`ID:${client.id}`}</Typography>
          </Stack>
        </Paper>
      ))}
    </Stack>
  )
}
