export const ActionReqStatusList = [
  "pending", // The request has been made but not yet processed
  "inProgress", // The request is currently being processed
  "success", // The request was processed successfully
  "failure", // The request was processed but failed
] as const;

// enum
export const ActionReqTypeEnum = {
  CreateDefaultTenantGroups: "CreateDefaultTenantGroups",
  SstLockerOpenDoor: "SstLockerOpenDoor",
  SstLockerRefresh: "SstLockerRefresh",
  SstLockerGetPackage: "SstLockerGetPackage",
  SstLockerCancelReservation: "SstLockerCancelReservation",
  CreateTenantConfiguration: "CreateTenantConfiguration",
  ProcessMblsUserLoginEvent: "ProcessMblsUserLoginEvent",
  TriggerVirtualLockerAction: "TriggerVirtualLockerAction",
  SendOrderPickupReminder: "SendOrderPickupReminder",
  AddOrgMember: "AddOrgMember",
  SendLockerFullCapacityNotification: "SendLockerFullCapacityNotification",
  ApplyTemplatePreset: "ApplyTemplatePreset",
  GetNotificationPreview: "GetNotificationPreview",
  SimulateExpiredReservation: "SimulateExpiredReservation",
  SimulatePaymentReservation: "SimulatePaymentReservation",
  AddDefaultAdminsToTenant: "AddDefaultAdminsToTenant",
  DeleteThing: "DeleteThing",
  RevertDeleteThing: "RevertDeleteThing",
} as const;

// list of supported action request
export const ActionReqTypeList = [
  "CreateDefaultTenantGroups",
  "SstLockerOpenDoor",
  "SstLockerGetPackage",
  "SstLockerRefresh",
  "SstLockerCancelReservation",
  "CreateTenantConfiguration",
  "ProcessMblsUserLoginEvent",
  "TriggerVirtualLockerAction",
  "SendOrderPickupReminder",
  "AddOrgMember",
  "SendLockerFullCapacityNotification",
  "ApplyTemplatePreset",
  "GetNotificationPreview",
  "SimulateExpiredReservation",
  "SimulatePaymentReservation",
  "AddDefaultAdminsToTenant",
  "DeleteThing",
  "RevertDeleteThing",
] as const;

export const ActionReqThingTypeMrn = "mrn:sl2:::actionReq";

// execution mode
export const ActionReqExecutionModeList = ["sync", "async"] as const;

// enum
export const ActionReqExecutionModeEnum = {
  sync: "sync",
  async: "async",
} as const;
