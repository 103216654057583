import { Paper, Stack, Typography } from "@mui/material"
import { FC, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  ActionReqExecutionModeEnum,
  ActionReqThingTypeMrn,
  ActionReqTypeEnum,
} from "shared/actionReqData"
import { Locker } from "shared/locker"
import { useCreateThingsMutation } from "src/features/things/thingsApi"
import { useCurrentTenant } from "src/hooks/useCurrentTenant"
import { v4 as uuidv4 } from "uuid"
import { LockerControllerDoorSelectorDialog } from "./LockerControllerDoorSelectorDialog"
import { Sl2Btn } from "./Sl2Btn"
type LockerControllerActionsProps = {
  locker: Locker
}
export const LockerControllerActions: FC<LockerControllerActionsProps> = ({
  locker,
}) => {
  const [createThings] = useCreateThingsMutation()
  const { tenant } = useCurrentTenant()
  const { t } = useTranslation()
  const onLockerAction = useCallback(
    (input: any) => async () => {
      if (!tenant) {
        console.error("Cannot process to locker action: tenant not found")
        return
      }
      const actionReq = {
        thingTypeMrn: ActionReqThingTypeMrn,
        executionMode: ActionReqExecutionModeEnum.sync,
        tenantId: tenant?.id,
        idempotencyKey: uuidv4(),
        ...input,
      }
      await createThings({
        body: { things: [actionReq] },
        params: { tenantId: tenant?.id },
      }).unwrap()
    },
    [createThings, tenant],
  )
  const actions = [
    {
      id: "Connect",
      label: "Connect",
      onClick: onLockerAction({
        actionType: ActionReqTypeEnum.TriggerVirtualLockerAction,
        inputData: {
          lockerId: locker.id,
          lockerAction: "Connect",
        },
      }),
    },
    {
      id: "Disconnect",
      label: "Disconnect",
      onClick: onLockerAction({
        actionType: ActionReqTypeEnum.TriggerVirtualLockerAction,
        inputData: {
          lockerId: locker.id,
          lockerAction: "Disconnect",
        },
      }),
    },
    // expire door
    {
      id: "ExpireDoor",
      label: t("ExpireDoor"),
      onClick: () => {
        setShowExpiryDoorSelector(true)
      },
    },
    // open door
    {
      id: "OpenDoor",
      label: t("OpenDoor"),
      onClick: () => {
        setShowOpenDoorSelector(true)
      },
    },
  ]
  const [showExpiryDoorSelector, setShowExpiryDoorSelector] = useState(false)
  // show OpenDoor
  const [showOpenDoorSelector, setShowOpenDoorSelector] = useState(false)

  return (
    <Paper variant="outlined" sx={{ p: 1, py: 2, bgcolor: "#F5F5F5" }}>
      <Stack direction="column" spacing={1}>
        <Typography variant="body1" fontWeight="bold">
          {t("actions")}
        </Typography>
        <Stack direction="row" spacing={1}>
          {actions.map((action) => (
            <Sl2Btn
              key={action.id}
              variant="outlined"
              onClick={action.onClick}
              sx={{ bgcolor: "white" }}
            >
              {action.label}
            </Sl2Btn>
          ))}
        </Stack>
      </Stack>
      {showExpiryDoorSelector && (
        <LockerControllerDoorSelectorDialog
          doors={locker.sstLocker?.doors || []}
          title={"triggerDoorExpiration"}
          instruction="triggerDoorExpirationInstruction"
          open={showExpiryDoorSelector}
          onClose={() => setShowExpiryDoorSelector(false)}
          onConfirm={(door) => {
            setShowExpiryDoorSelector(false)
            onLockerAction({
              actionType: ActionReqTypeEnum.TriggerVirtualLockerAction,
              inputData: {
                lockerId: locker.id,
                lockerAction: "ExpireDoorOpen",
                sstDoorId: door.door_id,
              },
            })()
          }}
        />
      )}
      {showOpenDoorSelector && (
        <LockerControllerDoorSelectorDialog
          doors={locker.sstLocker?.doors || []}
          title={"triggerOpenDoor"}
          instruction="triggerOpenDoorInstruction"
          open={showOpenDoorSelector}
          onClose={() => setShowOpenDoorSelector(false)}
          onConfirm={(door) => {
            setShowOpenDoorSelector(false)
            onLockerAction({
              actionType: ActionReqTypeEnum.TriggerVirtualLockerAction,
              inputData: {
                lockerId: locker.id,
                lockerAction: "OpenDoor",
                sstDoorId: door.door_id,
              },
            })()
          }}
        />
      )}
    </Paper>
  )
}
