import { Close } from "@mui/icons-material"
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material"
import Joi from "joi"
import { isEmpty, noop } from "lodash"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form-mui"
import { useTranslation } from "react-i18next"
import { useCreateThingsMutation } from "src/features/things/thingsApi"
import { useKeyPress } from "../hooks/useKeyPress"
import FormFields, { FormField } from "./FormFields"

import { UserThingTypeMrn } from "shared/userData"
import { Sl2Btn } from "./Sl2Btn"
import { Sl2FormContainer } from "./Sl2FormContainer"

const joiSchema = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  email: Joi.string()
    .email({
      tlds: { allow: false },
    })
    .required(),
  profilePictureUrl: Joi.string().uri().optional(),
  bio: Joi.string().optional(),
  phoneNumber: Joi.string().pattern(/^[0-9]+$/),
  address: Joi.string().optional(),
  city: Joi.string().optional(),
  language: Joi.string().optional(),
  state: Joi.string().optional(),
  country: Joi.string().optional(),
  postalCode: Joi.string().optional(),
})

interface SaUserDrawerProps {
  open: boolean
  onClose: () => void
  onSave?: () => void
  onError?: (error: any) => void
  user?: Record<string, any>
}

export const SaUserDrawer: React.FC<SaUserDrawerProps> = ({
  open,
  onClose,
  onSave = noop,
  onError = noop,
  user = {},
}) => {
  const { t } = useTranslation()
  const [isProcessing, setIsProcessing] = useState(false)

  const { fieldsGeneral, fieldsAddress } = useMemo(() => {
    const fieldsGeneral: FormField[] = [
      {
        name: "firstName",
        fieldType: "text",
        label: t("firstName"),
        required: true,
        size: "small",
        fullWidth: true,
        gridProps: { xs: 12, sm: 6 },
      },
      {
        name: "lastName",
        fieldType: "text",
        label: t("lastName"),
        required: true,
        size: "small",
        fullWidth: true,
        gridProps: { xs: 12, sm: 6 },
      },
      {
        name: "email",
        fieldType: "text",
        label: t("email"),
        required: true,
        size: "small",
        fullWidth: true,

        InputProps: {
          type: "email",
        },
        gridProps: { xs: 12, sm: 12 },
      },
      {
        name: "phoneNumber",
        label: t("phoneNumber"),
        fieldType: "text",
        size: "small",
        fullWidth: true,
        gridProps: { xs: 6 },
      },
      {
        name: "language",
        label: t("language"),
        fieldType: "text",
        size: "small",
        fullWidth: true,
        gridProps: { xs: 6 },
      },
      {
        name: "bio",
        label: t("bio"),
        size: "small",
        fieldType: "text",
        fullWidth: true,
        inputProps: { maxLength: 100 },
        multiline: true,
        rows: 4,
        gridProps: { xs: 12 },
      },
    ]
    const fieldsAddress: FormField[] = [
      {
        name: "address",
        label: t("address"),
        fieldType: "text",
        size: "small",
        fullWidth: true,
        gridProps: { xs: 12 },
      },
      {
        name: "city",
        label: t("city"),
        fieldType: "text",
        size: "small",
        fullWidth: true,
        gridProps: { xs: 6 },
      },
      {
        name: "postalCode",
        label: t("postalCode"),
        fieldType: "text",
        size: "small",
        fullWidth: true,
        gridProps: { xs: 6 },
      },
      {
        name: "state",
        label: t("state"),
        fieldType: "text",
        size: "small",
        fullWidth: true,
        gridProps: { xs: 6 },
      },
      {
        name: "country",
        label: t("country"),
        fieldType: "text",
        size: "small",
        fullWidth: true,
        gridProps: { xs: 6 },
      },
    ]

    return { fieldsGeneral, fieldsAddress }
  }, [t])

  const [createThings] = useCreateThingsMutation()

  const isCreateMode = useMemo(() => isEmpty(user), [user])

  const { reset } = useForm()

  useEffect(() => {
    if (!isEmpty(user)) {
      reset(user)
      // @ts-ignore
      setDefaultLocker(user)
    }
  }, [user, isCreateMode, reset])

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        setIsProcessing(true)
        // if (!tenant?.id) throw new Error("tenantId is not defined")
        const user = {
          ...data,
          thingTypeMrn: UserThingTypeMrn,
        }
        if (isCreateMode) {
          await createThings({
            body: { things: [user] },
            // params: { tenantId: tenant?.id },
          }).unwrap()
        }
        onSave()
      } catch (e: any) {
        console.error(e)
        onError(e)
      } finally {
        setIsProcessing(false)
      }
    },
    [createThings, isCreateMode, onError, onSave],
  )

  useKeyPress("Escape", onClose)

  return (
    <Box>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        variant="persistent"
        sx={{
          width: 800,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: 800,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          p={2}
        >
          <Stack sx={{ width: "100%", p: 2 }} direction="column" spacing={2}>
            <Box>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent={"space-between"}
                // pb={4}
              >
                <Typography variant="body1" fontWeight="bold">
                  {t(isCreateMode ? "newUser" : "modifyUser")}
                </Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Stack>
              <Divider />
            </Box>
            <Sl2FormContainer
              onError={(errors: any) => console.error(errors)}
              onSuccess={onSubmit}
              joiSchema={joiSchema}
            >
              <Stack direction="column" spacing={1} alignItems="center">
                <FormFields title={t("general")} fields={fieldsGeneral} />
                <FormFields title={t("address")} fields={fieldsAddress} />
              </Stack>
              <Divider sx={{ mt: 6 }} />
              <Stack
                direction="row"
                spacing={4}
                justifyContent="flex-end"
                pt={2}
              >
                <Sl2Btn
                  onClick={onClose}
                  disabled={isProcessing}
                  variant="outlined"
                  sx={{ width: 150 }}
                >
                  {t("cancel")}
                </Sl2Btn>
                <Sl2Btn
                  type="submit"
                  disabled={isProcessing}
                  variant="contained"
                  sx={{ width: 150 }}
                >
                  {t(isCreateMode ? "create" : "modify")}
                </Sl2Btn>
              </Stack>
            </Sl2FormContainer>
          </Stack>
        </Stack>
      </Drawer>
    </Box>
  )
}

export default SaUserDrawer
