import Info from "@mui/icons-material/Info"
import { Box, Stack, Typography } from "@mui/material"

const variantSx = {
  info: {
    borderColor: "red",
    // ultra ligth grey
    bgcolor: "#f5f5f5",
  },
  warning: {
    borderColor: "warning.main",
    bgcolor: "warning.light",
  },
  error: {
    borderColor: "error.main",
    bgcolor: "error.light",
  },
  success: {
    borderColor: "success.main",
    bgcolor: "success.light",
  },
}
const iconSx = {
  info: {
    color: "info.main",
  },
  warning: {
    color: "warning.main",
  },
  error: {
    color: "error.main",
  },
  success: {
    color: "success.main",
  },
}
export const Callout: React.FC<{
  title?: string
  message?: string
  icon?: JSX.Element
  variant?: "info" | "warning" | "error" | "success" | undefined
}> = ({ title, message, icon = <Info />, variant = "info" }) => {
  return (
    <Box
      sx={{
        ...variantSx[variant],
        border: `1px solid lightgrey`,
        p: 2,
      }}
    >
      <Stack direction="row" spacing={2}>
        <Box sx={iconSx[variant]}>{icon}</Box>
        <Stack direction="column">
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body1">{message}</Typography>
        </Stack>
      </Stack>
    </Box>
  )
}
