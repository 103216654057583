import { ContentPaste } from "@mui/icons-material"
import { Stack, Tooltip, Typography } from "@mui/material"
import { noop } from "lodash"
import React, { useCallback } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"

interface TraceTooltipProps {
  text?: string
  tooltipTitle?: string | React.ReactNode
  onCopy?: () => void
  sx?: object
}

const TraceTooltip: React.FC<TraceTooltipProps> = ({
  text,
  tooltipTitle = "Copy to clipboard",
  onCopy = noop,

  sx = {},
}) => {
  const [showToolip, setShowTooltip] = React.useState(false)

  const [copied, setCopied] = React.useState(false)

  React.useEffect(() => {
    const updateTooltipState = () => {
      const traceTooltip = localStorage.getItem("traceTooltip")
      setShowTooltip(traceTooltip === "true")
    }

    // Set initial state
    updateTooltipState()

    // Listen for localStorage changes
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "traceTooltip") {
        updateTooltipState()
      }
    }

    window.addEventListener("storage", handleStorageChange)

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  const onCopyHandler = useCallback(() => {
    setCopied(true)
    setTimeout(() => setCopied(false), 1000)
    if (onCopy) {
      onCopy()
    }
  }, [onCopy])

  return (
    <Tooltip
      open={showToolip}
      title={
        <CopyToClipboard text={text || ""} onCopy={onCopyHandler}>
          <Stack direction="row" spacing={1} alignItems="center">
            <ContentPaste fontSize="small" />
            <Typography
              variant="caption"
              sx={{
                cursor: "pointer",
                // dont brak word
                whiteSpace: "nowrap",
                ...sx,
              }}
            >
              {text}
            </Typography>
            {copied && (
              <Typography variant="caption" color="success">
                Copied!
              </Typography>
            )}
          </Stack>
        </CopyToClipboard>
      }
    >
      <span>{tooltipTitle}</span>
    </Tooltip>
  )
}

export default TraceTooltip
