import React, { useState } from "react"

export const useAnyKeyPress = (onDown: (pressedKey: string) => void) => {
  const [keyPressed, setKeyPressed] = useState(false)

  React.useEffect(() => {
    const upHandler = ({ key }: { key: string }) => {
      setKeyPressed(false)
    }
    const downHandler = ({ key }: { key: string }) => {
      setKeyPressed(true)
      onDown(key)
    }
    window.addEventListener("keydown", downHandler)
    window.addEventListener("keyup", upHandler)

    return () => {
      window.removeEventListener("keydown", downHandler)
      window.removeEventListener("keyup", upHandler)
    }
  }, [onDown])

  return keyPressed
}
