import { Refresh } from "@mui/icons-material"
import {
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Tabs,
  Typography,
} from "@mui/material"
import { map, omit } from "lodash"
import { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { SstLocker } from "shared/sst"
import { useAppSelector } from "src/app/hooks"
import {
  useGetSstApiInfoQuery,
  useGetSstLockersQuery,
  useGetSstWebhooksQuery,
} from "src/features/partners/partnersApi"
import { selectSstLockers } from "src/features/partners/sstThingsSlice"
import { useSearchParamsState } from "src/hooks/useSearchParamsState"
import SaLayout from "./SaLayout"
import SstLockers from "./SaSstLockers"
import SstReservations from "./SaSstReservations"
import SstWebhooks from "./SaSstWebhooks"
import { Sl2Tab } from "./Sl2Tab"

const TabMap: Record<string, number> = {
  lockers: 0,
  reservations: 1,
  webhooks: 2,
}
const TabMapReverse: Record<number, string> = {
  0: "lockers",
  1: "reservations",
  2: "webhooks",
}
export const SaSstPage: FC = () => {
  const { t } = useTranslation()

  // @ts-ignore
  const qLockers = useGetSstLockersQuery({})

  const _sstLockers = useAppSelector(selectSstLockers)

  const sstLockers = useMemo(() => {
    return map(_sstLockers || [], (l) => omit(l, ["sstType"])).filter(
      Boolean,
    ) as SstLocker[]
  }, [_sstLockers])

  const qApiInfo = useGetSstApiInfoQuery({})

  const qApiInfoIsloading = qApiInfo.isLoading || qApiInfo.isFetching

  const qWebhooks = useGetSstWebhooksQuery({})

  const refresh = useCallback(() => {
    qApiInfo.refetch()
    qWebhooks.refetch()
  }, [qApiInfo, qWebhooks])

  
  const [tab, setTab] = useSearchParamsState("tab", TabMap.lockers + "")

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setTab(newValue + "")
    },
    [setTab],
  )

  return (
    <SaLayout selectedMenuItemLabelKey="sstApi">
      <Stack direction="column" spacing={2} p={2} pl={4} mt={4} width="100%">
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <Typography variant="h5">{t("sst")}</Typography>
          <IconButton onClick={refresh} disabled={qApiInfoIsloading}>
            <Refresh />
          </IconButton>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="flex-end">
          <Typography variant="body2">{t("apiUrl")}</Typography>
          {qApiInfoIsloading ? (
            <Skeleton variant="text" width={200} height={20} />
          ) : (
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                variant="caption"
                fontFamily="monospace"
                sx={{
                  bgcolor: "rgba(0, 0, 0, 0.1)",
                  px: 1,
                }}
              >
                {qApiInfo.data?.data?.sstApiUrl}
              </Typography>
              {qApiInfo.data?.data?.isOnline ? (
                // green dot if online
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <>
                    <div
                      style={{
                        width: 8,
                        height: 8,
                        borderRadius: "50%",
                        backgroundColor: "green",
                      }}
                    />
                    <Typography variant="caption" color="green">
                      {t("online")}
                    </Typography>
                  </>
                  <Typography variant="caption">
                    {`${t("latency")}: ${qApiInfo.data?.data?.pingLatency}ms`}
                  </Typography>
                  <Typography variant="caption">
                    {`${t("version")}: ${qApiInfo.data?.data?.version}`}
                  </Typography>
                </Stack>
              ) : (
                // red dot if offline
                <>
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: "50%",
                      backgroundColor: "red",
                    }}
                  />
                  <Typography variant="caption" color="error">
                    {t("offline")}
                  </Typography>
                </>
              )}
            </Stack>
          )}
        </Stack>
        <Divider />
        <Stack direction="row" spacing={2}>
          {/* vertical mui tabs */}
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={Number(tab)}
            onChange={handleTabChange}
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Sl2Tab label={t("lockers")} />
            <Sl2Tab label={t("reservations")} />
            <Sl2Tab label={t("webhooks")} />
          </Tabs>
          {/* tab content */}
          {
            {
              webhooks: (
                <SstWebhooks
                  data={qWebhooks.data?.data}
                  isLoading={qWebhooks.isLoading}
                />
              ),
              lockers: (
                <SstLockers data={sstLockers} isLoading={qLockers.isLoading} />
              ),
              reservations: <SstReservations />,
            }[TabMapReverse[Number(tab)]]
          }
        </Stack>
      </Stack>
    </SaLayout>
  )
}

export default SaSstPage
