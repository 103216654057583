import { Close, Preview, Warning } from "@mui/icons-material"
import Refresh from "@mui/icons-material/Refresh"
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { DataGrid, DataGridProps } from "@mui/x-data-grid"
import { TFunction } from "i18next"
import moment from "moment"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Locker } from "shared/locker"
import { LockerThingTypeMrn } from "shared/lockerData"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { Tenant } from "shared/tenant"
import { TenantThingTypeMrn } from "shared/tenantData"
import { useAppSelector } from "src/app/hooks"
import { selectMeIsReady } from "src/features/me/meSlice"
import { useGetThingsQuery } from "src/features/things/thingsApi"
import { selectThingsByThingTypeMrn } from "src/features/things/thingsSlice"
import { getLockerDisplayValues } from "src/hooks/useLockerDisplayValues"
import ObjectDisplayTable from "./ObjectDisplayTable"
import SaLayout from "./SaLayout"
import SaOrgForm from "./SaOrgForm"
import { Sl2Btn } from "./Sl2Btn"

const WarningInfo: FC<{ locker: Locker }> = ({ locker }) => {
  return (
    <Stack direction="column" spacing={1} alignItems="center">
      <Typography variant="body2" fontWeight="bold" noWrap>
        Missing configuration
      </Typography>
    </Stack>
  )
}

const defaultColWidth = 230

export const SaLockersPage: FC = () => {
  const { t } = useTranslation()

  const isReady = useAppSelector(selectMeIsReady)

  const [isCreating, setIsCreating] = useState(false)

  const [searchInput, setSearchInput] = useState("")

  const lockers = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, LockerThingTypeMrn),
  ) as Locker[]

  const { isLoading, refetch } = useGetThingsQuery({
    params: {
      "ap.name": SharedAccessPatternSaEnum.SaGetLockers,
    },
  })

  const qTenants = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternSaEnum.SaGetTenants,
      },
    },
    {
      skip: !isReady,
    },
  )

  const tenants = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, TenantThingTypeMrn),
  ) as Tenant[]

  const _lockersWithTenants = useMemo(() => {
    return lockers.map((locker) => {
      const tenant = tenants.find((t) => t.id === locker.tenantId)
      return {
        ...locker,
        tenant,
      }
    })
  }, [lockers, tenants])

  const onFinish = useCallback(() => {
    refetch()
    setIsCreating(false)
  }, [refetch])

  const { lockersWithTenants, countDisplay } = useMemo(() => {
    const lockersWithTenants = _lockersWithTenants.filter((l) => {
      return (
        l.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
        l.id?.toLowerCase().includes(searchInput.toLowerCase()) ||
        l.description?.toLowerCase().includes(searchInput.toLowerCase()) ||
        l?.sstLocker?._id?.toLowerCase().includes(searchInput.toLowerCase()) ||
        l?.tenant?.id?.toLowerCase().includes(searchInput.toLowerCase()) ||
        l?.tenant?.name?.toLowerCase().includes(searchInput.toLowerCase())
      )
    })
    return {
      lockersWithTenants,
      // filter / search count if any displayed, otherwise _lockers.length
      countDisplay:
        lockersWithTenants.length !== _lockersWithTenants.length
          ? `${lockersWithTenants.length}/${_lockersWithTenants.length}`
          : lockersWithTenants.length,
    }
  }, [_lockersWithTenants, searchInput])

  const [selectedLockerId, setSelectedLockerId] = useState<string | null>(null)

  const selectedL = useMemo(
    () => lockers.find((t) => t.id === selectedLockerId),
    [lockers, selectedLockerId],
  ) as Locker

  const columns = useCallback(
    (t: TFunction): DataGridProps["columns"] => [
      {
        field: "name",
        headerName: t("name"),
        minWidth: defaultColWidth,
        renderCell: (params) => {
          const displayWarning = !params?.row?.sstLocker?.locker_grid
          const l = getLockerDisplayValues(params.row as Locker)
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <Preview
                sx={{
                  cursor: "pointer",
                  color: "gray",
                }}
                onClick={() => setSelectedLockerId(params.row.id as string)}
              />
              <Link to={`/sa/lockers/${params.row.id}`}>
                <Typography variant="body2" noWrap>
                  {params.value || l.i18n.name}
                </Typography>
              </Link>
              {displayWarning && (
                <Tooltip
                  title={<WarningInfo locker={params.row as Locker} />}
                  placement="top-start"
                  arrow
                >
                  <Warning color="warning" fontSize="small" />
                </Tooltip>
              )}
              {/* displat 'virtual' in chip if params.row.isVirtual */}
              {params.row.isVirtual && (
                <Chip
                  label="Virtual"
                  size="small"
                  color="primary"
                  variant="outlined"
                />
              )}
            </Stack>
          )
        },
      },

      {
        field: "tenant",
        headerName: t("tenant"),
        minWidth: defaultColWidth,
        renderCell: (params) => {
          return (
            <Link to={`/sa/tenants/${params.row?.tenantId}`}>
              <Typography variant="body2">
                {params.row?.tenant?.name || "-"}
              </Typography>
            </Link>
          )
        },
      },
      {
        field: "nbDoors",
        headerName: t("nbDoors"),
        minWidth: defaultColWidth,
        renderCell: (params) => {
          const nbIsEnabled =
            params.row?.sstLocker?.doors?.filter((d: any) => d.is_enable)
              ?.length || 0

          const nbHasReservation =
            params.row?.sstLocker?.doors?.filter(
              (d: any) => d?.links?.length > 0,
            )?.length || 0

          const nbDoors = params.row?.sstLocker?.doors?.length || 0

          return (
            <Typography variant="body2">
              {`${nbDoors} (${nbIsEnabled} enabled, ${nbHasReservation} reserved)`}
            </Typography>
          )
        },
      },
      {
        field: "lastSstSync",
        headerName: t("lastSync"),
        minWidth: defaultColWidth,
        renderCell: (params) => {
          return (
            <Typography variant="body2">
              {params.row?.lastSstSync
                ? moment(params.row?.lastSstSync).fromNow()
                : "never"}
            </Typography>
          )
        },
      },

      {
        field: "description",
        headerName: t("description"),
        minWidth: defaultColWidth,
      },
      {
        field: "address1",
        headerName: t("address1"),
        minWidth: defaultColWidth,
      },
      {
        field: "address2",
        headerName: t("address2"),
        minWidth: defaultColWidth,
      },
      {
        field: "city",
        headerName: t("city"),
        minWidth: defaultColWidth,
      },
      {
        field: "province",
        headerName: t("province"),
        minWidth: defaultColWidth,
      },
      {
        field: "country",
        headerName: t("country"),
        minWidth: defaultColWidth,
      },
      {
        field: "postalCode",
        headerName: t("postalCode"),
        minWidth: defaultColWidth,
      },
      {
        field: "latitude",
        headerName: t("latitude"),
        minWidth: defaultColWidth,
      },
      {
        field: "longitude",
        headerName: t("longitude"),
        minWidth: defaultColWidth,
      },
      {
        field: "lockerLocation",
        headerName: t("lockerLocation"),
        minWidth: defaultColWidth,
      },
      {
        field: "displayOrder",
        headerName: t("displayOrder"),
        minWidth: defaultColWidth,
      },
      // sstLockerId
      {
        field: "sstLockerId",
        headerName: t("sstLockerId"),
        minWidth: defaultColWidth * 1.4,
        renderCell: (params) => {
          return (
            <Typography variant="body2" fontFamily={"monospace"}>
              {params.row?.sstLockerId || "-"}
            </Typography>
          )
        },
      },

      {
        field: "createdAt",
        headerName: `📅 ${t("createdAt")}`,
        minWidth: defaultColWidth,
      },
      {
        field: "updatedAt",
        headerName: `📅 ${t("updatedAt")}`,
        minWidth: defaultColWidth,
      },
    ],
    [],
  )

  return (
    <SaLayout selectedMenuItemLabelKey="lockers">
      <Stack direction="column" spacing={4} p={2} pl={4} mt={4} width="100%">
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <Typography variant="h5">{t("lockers")}</Typography>
          {!isLoading && (
            <IconButton onClick={refetch}>
              <Refresh />
            </IconButton>
          )}
          {isLoading && <CircularProgress />}
          <Link to="/sa/lockers/new">
            <Sl2Btn variant="outlined">{t("createLocker")}</Sl2Btn>
          </Link>
        </Stack>
        <Stack direction="column" spacing={2} mt={2}>
          <Box maxWidth={800}>
            {isCreating && (
              <SaOrgForm
                isCreating={true}
                onFinish={onFinish}
                onCancel={() => setIsCreating(false)}
              />
            )}
          </Box>
          <Stack direction="column" spacing={1}>
            <Typography variant="body2">
              {`${t("lockers")} (${countDisplay})`}
            </Typography>
            <TextField
              label={t("search")}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              size="small"
              sx={{
                width: "500px",
              }}
            />
          </Stack>
          <Box sx={{}}>
            <DataGrid
              columns={columns(t)}
              rows={lockersWithTenants}
              localeText={{
                noRowsLabel: t("noLockersFound"),
              }}
              autoHeight
              // sort createAt in descending order
              // sortModel={[
              //   {
              //     field: "createdAt",
              //     sort: "desc",
              //   },
              // ]}
              rowSelection={false}
              sx={{
                // width: "100%",
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
            />
          </Box>
        </Stack>
      </Stack>
      {selectedLockerId && (
        <Drawer
          anchor="right"
          open={!!selectedLockerId}
          onClose={() => setSelectedLockerId(null)}
          sx={{
            "& .MuiDrawer-paper": {
              width: "80vw",
              maxWidth: "1500px",
              maxHeight: "calc(100vh - 64px)",
              height: "calc(100vh - 64px)",
              paddingTop: "64px",
            },
          }}
        >
          <Stack direction="column" spacing={1} p={2}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{
                    color: "primary.main",
                  }}
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Typography variant="body1" fontWeight="bold">
                    {getLockerDisplayValues(selectedL).name}
                  </Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {selectedL?.id}
                  </Typography>
                </Stack>
              </Stack>
              <IconButton onClick={() => setSelectedLockerId(null)}>
                <Close />
              </IconButton>
            </Stack>
            <Divider />

            <ObjectDisplayTable data={selectedL} />
          </Stack>
        </Drawer>
      )}
    </SaLayout>
  )
}

export default SaLockersPage
