import { Close } from "@mui/icons-material"
import {
  Box,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material"

import { get, isEmpty, noop, pick, throttle } from "lodash"
import React, { useCallback, useEffect, useMemo } from "react"
import { useForm } from "react-hook-form-mui"
import { useTranslation } from "react-i18next"
import { Client } from "shared/client"
import { ClientThingTypeMrn } from "shared/clientData"
import { sharedClientCreateSchema } from "shared/sharedClientValidators"
import { useGetMblsConnectorClientsQuery } from "src/features/mblsConnector/mblsConenctorApi"
import { MblsConnectorClient } from "src/features/mblsConnector/mblsConnector"
import {
  useCreateThingsMutation,
  useUpdateThingsMutation,
} from "src/features/things/thingsApi"
import { useCurrentTenant } from "src/hooks/useCurrentTenant"
import { useKeyPress } from "../hooks/useKeyPress"
import FormFields, { FormField } from "./FormFields"
import { MblsConnectorClientSelector } from "./MblsConnectorClientSelector"
import { Sl2Btn } from "./Sl2Btn"
import { Sl2FormContainer } from "./Sl2FormContainer"
import console from "console"

interface ClientDrawerProps {
  open: boolean
  onClose: () => void
  onSave?: () => void
  onError?: (error: any) => void
  // mode: "create" | "edit"
  client?: Client | {}
}

// const formFields

const ClientDrawer: React.FC<ClientDrawerProps> = ({
  open,
  onClose,
  onSave = noop,
  onError = noop,
  client,
}) => {
  const { t } = useTranslation()
  const [isProcessing, setIsProcessing] = React.useState(false)

  const formFields = useMemo(() => {
    // firstName, lastName, email, phone, status, mblsClientId, notes
    return [
      {
        name: "firstName",
        fieldType: "text",
        label: t("firstName"),
        required: true,
        fullWidth: true,
        size: "small",
      },
      {
        name: "lastName",
        fieldType: "text",
        label: t("lastName"),
        type: "text",
        required: true,
        fullWidth: true,
        size: "small",
      },
      {
        name: "email",
        fieldType: "text",
        label: t("email"),
        type: "email",
        required: false,
        fullWidth: true,
        size: "small",
      },
      {
        name: "phone",
        fieldType: "text",
        label: t("phone"),
        type: "tel",
        required: false,
        fullWidth: true,
        size: "small",
      },
      {
        name: "notes",
        fieldType: "text",
        label: t("notes"),
        fullWidth: true,
        required: false,
        InputProps: {
          multiline: true,
          rows: 4,
        },
      },
      {
        name: "thingTypeMrn",
        fieldType: "text",
        required: true,
        hidden: true,
        visible: false,
      },
    ] as FormField[]
  }, [t])

  const { tenant } = useCurrentTenant()
  const [defaultClient, setDefaultClient] = React.useState({
    thingTypeMrn: ClientThingTypeMrn,
    tenantId: tenant?.id,
    ...client,
  })

  const [hasAutofilled, setHasAutofilled] = React.useState(false)

  const [searchValues, setSearchValues] = React.useState({})

  const [createThings, { isLoading: isCreatingThings }] =
    useCreateThingsMutation()

  const [updateThings, { isLoading: isUpdatingThings }] =
    useUpdateThingsMutation()

  const isCreateMode = useMemo(() => isEmpty(client), [client])

  const { reset } = useForm()

  useEffect(() => {
    if (isCreateMode) {
      reset({
        thingTypeMrn: ClientThingTypeMrn,
        tenantId: tenant?.id,
      })
      setDefaultClient({
        thingTypeMrn: ClientThingTypeMrn,
        tenantId: tenant?.id,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        notes: "",
      })
    }
    if (!isEmpty(client)) {
      reset(client)
      setDefaultClient(client)
    }
  }, [client, isCreateMode, reset, tenant?.id])

  const { data, isLoading: isMblsConnectorClientsLoading } =
    useGetMblsConnectorClientsQuery(
      { params: searchValues },
      {
        skip: isEmpty(searchValues) || !isCreateMode || !tenant?.mblsProviderId,
      },
    )
  const clients = get(data, "data", [])

  const onSelect = useCallback(
    (client: MblsConnectorClient) => {
      if (isCreateMode) return

      setDefaultClient((prev) => ({
        ...prev,
        firstName: client.firstName,
        lastName: client.lastName,
        email: client.email,
        phone: client.phones?.[0]?.number,
        thingTypeMrn: ClientThingTypeMrn,
        tenantId: tenant?.id,
      }))
      setHasAutofilled(true)
    },
    [isCreateMode, tenant?.id],
  )
  const onSubmit = useCallback(
    async (data: any) => {
      try {
        setIsProcessing(true)
        if (!tenant?.id) throw new Error("tenantId is not defined")
        const client = {
          ...data,
          thingTypeMrn: ClientThingTypeMrn,
          tenantId: tenant?.id,
        }
        if (isCreateMode) {
          await createThings({
            body: { things: [client] },
            params: { tenantId: tenant?.id },
          }).unwrap()
        } else {
          await updateThings({
            body: {
              things: [
                pick(client, [
                  "id",
                  "thingTypeMrn",
                  "tenantId",
                  "firstName",
                  "lastName",
                  "email",
                  "phone",
                  "notes",
                ]),
              ],
            },
            params: { tenantId: tenant?.id },
          }).unwrap()
        }
        onSave()
      } catch (e: any) {
        console.error(e)
        onError(e)
      } finally {
        setIsProcessing(false)
      }
    },
    [createThings, isCreateMode, onError, onSave, tenant?.id, updateThings],
  )

  const onValueChange = useCallback(
    throttle((value: any) => {
      if (hasAutofilled) {
        setHasAutofilled(false)
        return
      }
      setSearchValues(value)
    }, 1000),
    [hasAutofilled],
  )
  useKeyPress("Escape", onClose)

  return (
    <Box>
      <Drawer anchor="right" open={open} onClose={onClose} variant="persistent">
        <Toolbar />
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          p={2}
        >
          {tenant?.mblsProviderId &&
            isCreateMode &&
            clients &&
            !isEmpty(searchValues) && (
              <Box
                sx={{
                  border: "1px solid #e0e0e0",
                  p: 1,
                  height: "90vh",
                  overflowY: "auto",
                  width: 400,
                }}
              >
                <MblsConnectorClientSelector
                  clients={clients as unknown as MblsConnectorClient[]}
                  isLoading={isMblsConnectorClientsLoading}
                  onSelect={onSelect}
                />
              </Box>
            )}
          <Stack sx={{ width: 400, p: 2 }} direction="column">
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent={"space-between"}
              //   p={2}
              pb={4}
            >
              <Typography variant="body1" fontWeight="bold">
                {t(isCreateMode ? "newClient" : "modifyClient")}
              </Typography>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Stack>
            <Sl2FormContainer
              defaultValues={defaultClient}
              onError={(errors: any) => console.log(errors)}
              onSuccess={onSubmit}
              joiSchema={sharedClientCreateSchema as any}
            >
              <FormFields
                fields={formFields}
                onWatch={isCreateMode ? onValueChange : noop}
                defaultValues={defaultClient}
              />
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                pt={2}
              >
                <Sl2Btn
                  onClick={onClose}
                  disabled={isProcessing}
                  variant="outlined"
                  sx={{
                    width: 150,
                  }}
                >
                  {t("cancel")}
                </Sl2Btn>
                <Sl2Btn
                  type="submit"
                  disabled={isProcessing}
                  variant="contained"
                  sx={{
                    width: 150,
                  }}
                >
                  {t(isCreateMode ? "create" : "modify")}
                </Sl2Btn>
              </Stack>
            </Sl2FormContainer>
          </Stack>
        </Stack>

        {/* Add the content of the drawer here */}
      </Drawer>
    </Box>
  )
}

export default ClientDrawer
