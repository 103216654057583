import {
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { FC, useState } from "react"

import { Search } from "@mui/icons-material"
import { t } from "i18next"
import { useGetSstReservationQuery } from "src/features/partners/partnersApi"
import ObjectDisplayTable from "./ObjectDisplayTable"

type SstReservationsByIdProps = {}

export const SstReservationsById: FC<SstReservationsByIdProps> = () => {
  const [inputValue, setInputValue] = useState<string>("")
  const [searchQuery, setSearchQuery] = useState<string>("")

  const qReservation = useGetSstReservationQuery(
    { id: searchQuery },
    {
      skip: !searchQuery,
    },
  )

  const handleSearch = () => {
    setSearchQuery(inputValue)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleSearch()
    }
  }

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="body1" fontWeight="bold">
        {t("reservation")}
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          label={t("id")}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyUp={handleKeyPress}
          size="small"
          sx={{ width: "300px" }}
          placeholder={t("enterSstIdToSearch")}
          disabled={qReservation.isLoading || qReservation.isFetching}
        />
        <IconButton
          onClick={handleSearch}
          disabled={qReservation.isLoading || qReservation.isFetching}
        >
          <Search />
        </IconButton>
      </Stack>

      <Divider />

      {qReservation?.isSuccess && (
        <ObjectDisplayTable
          data={qReservation?.data?.data}
          isLoading={qReservation.isLoading || qReservation.isFetching}
        />
      )}
      {qReservation?.isError && (
        <Stack direction="column" spacing={1} minWidth={"800px"}>
          <Typography variant="body1" color="error">
            Error fetching reservation to SST API
          </Typography>
          <ObjectDisplayTable data={qReservation?.error} />
        </Stack>
      )}
    </Stack>
  )
}

export default SstReservationsById
