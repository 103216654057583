import { FC, PropsWithChildren } from "react"
import { Outlet } from "react-router-dom"
import LoadingPage from "src/LoadingPage"

type AuthLoadedRouteProps = {
  isReady?: boolean
}

export const AuthLoadedRoute: FC<PropsWithChildren<AuthLoadedRouteProps>> = ({
  isReady = true,
  children,
}) => {
  if (!isReady) {
    return <LoadingPage />
  }

  return children ? children : <Outlet />
}
