import { Refresh } from "@mui/icons-material"
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  colors,
} from "@mui/material"
import moment from "moment"
import { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { ReportDataset } from "shared/report-datasets"

export type OsReportReservationPaymentLastMonths = {
  ds?: ReportDataset<any>
  isLoading: boolean
  refresh: () => void
  hideHeader?: boolean
  hideRefresh?: boolean
  width?: number
}
export const OsReportReservationSummaryDoorSize: FC<
  OsReportReservationPaymentLastMonths
> = ({ ds, isLoading, refresh, hideHeader, hideRefresh, width = 300 }) => {
  const { t } = useTranslation()
  const data = useMemo(() => {
    if (!ds?.data) return []
    return ds?.data?.map((d) => {
      return {
        ...d,
        week: moment(d.week).format("YYYY-MM-DD"),
      }
    })
  }, [ds?.data])
  return (
    <Stack
      direction="column"
      spacing={4}
      p={2}
      sx={{
        minWidth: "1030px",
        display: "inline-block",
      }}
    >
      {!hideHeader && (
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="column" spacing={1}>
            <Typography variant="body1" fontWeight={"bold"}>
              {t("reporting.reservationPaymentLastMonths.title")}
            </Typography>
            <Typography variant="body2">
              {t("reporting.reservationPaymentLastMonths.description")}
            </Typography>
          </Stack>
          {!hideRefresh && (
            <Stack direction="column" spacing={1} alignItems="flex-end">
              <IconButton onClick={refresh} disabled={isLoading}>
                <Refresh />
              </IconButton>
              <Typography variant="caption">
                {ds?.lastUpdated &&
                  `${t("lastUpdated")}: ${moment(ds.lastUpdated).format(
                    "YYYY-MM-DD HH:mm:ss",
                  )}`}
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
      <Box
        sx={{
          // width: "1000px",
          height: 300,
        }}
      >
        {ds?.data && ds?.data?.length > 0 ? (
          <ResponsiveContainer width={"100%"} height="100%">
            <AreaChart width={width} height={500} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="week" />
              <YAxis />
              <Tooltip />
              <Legend />

              <Area
                dataKey={`total_to_collect`}
                // stroke & fill light blue pair shades
                stroke={colors.blue[500]}
                fill={colors.blue[200]}
                fillOpacity={0.8}
                // stackId={"1"}
                name={t(`total_to_collect`)}
              />
              <Area
                dataKey={`total_collected`}
                // stroke & fill light green pair shades
                stroke={colors.orange[500]}
                fill={colors.orange[200]}
                fillOpacity={0.8}
                // stackId={"1"}
                name={t(`total_collected`)}
              />
              <Area
                dataKey={`amount_to_receive`}
                // stroke & fill light blue pair shades
                stroke={colors.green[500]}
                fill={colors.green[200]}
                fillOpacity={0.8}
                // stackId={"1"}
                name={t(`amount_to_receive`)}
              />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <Box
            sx={{
              //grey box
              width,
              height: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: "grey.100",
            }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Typography>{t("noData")}</Typography>
            )}
          </Box>
        )}
      </Box>
    </Stack>
  )
}

export default OsReportReservationSummaryDoorSize
