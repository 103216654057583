import { GridSortItem } from "@mui/x-data-grid"
import { usePrevious } from "@uidotdev/usehooks"
import Joi from "joi"
import { get, isEqual } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import { fetchSl2Api } from "src/features/baseQuerySl2"
import { selectTenantId } from "src/features/me/meSlice"
import { useRequestReportDataMutation } from "src/features/report/reportApi"
import {
  selectClientFilters,
  selectLockerIdFilters,
  selectReservationFilters,
  setReportIsLoading,
  upsertDatasets,
} from "src/features/report/reportSlice"

const useReportDatasets = (reportType: string) => {
  Joi.assert(reportType, Joi.string().required())

  const tenantId = useAppSelector(selectTenantId)

  const [requestReportData, { isLoading }] = useRequestReportDataMutation()

  const reservationFilters = useAppSelector(selectReservationFilters)

  const clientFilters = useAppSelector(selectClientFilters)

  const lockerIdFilters = useAppSelector(selectLockerIdFilters)

  const dispatch = useAppDispatch()

  const [pagination, setPagination] = useState({ page: 0, pageSize: 100 })

  const prevPagination = usePrevious(pagination)

  const shouldRefetch = !isEqual(prevPagination, pagination)

  let [hasNext, setHasNext] = useState(false)

  const [sortModel, setSortModel] = useState<GridSortItem>({
    field: "r.created_at",
    sort: "desc",
  })

  const refresh = useCallback(() => {
    const f = async () => {
      if (!tenantId) {
        return
      }
      try {
        dispatch(setReportIsLoading(true))
        const res = await requestReportData({
          body: {
            reportType,
            tenantId,
            input: {
              tenantId,
              reservationFilters,
              clientFilters,
              lockerIdFilters,
              pagination: {
                page: pagination.page + 1, // backend is 1 based
                pageSize: pagination.pageSize,
              },
              sortModel,
            },
          },
        }).unwrap()
        const datasets = get(res, "res.datasets", {})
        dispatch(upsertDatasets(datasets))
        setHasNext(get(res, "res.hasNext", false))
      } catch (e) {
        console.error("Error fetching report data", e)
      } finally {
        dispatch(setReportIsLoading(false))
      }
    }
    f()
  }, [
    clientFilters,
    dispatch,
    lockerIdFilters,
    pagination.page,
    pagination.pageSize,
    reportType,
    requestReportData,
    reservationFilters,
    sortModel,
    tenantId,
  ])

  useEffect(() => {
    if (shouldRefetch) {
      refresh()
    }
  }, [refresh, pagination, shouldRefetch])

  const [isFetchingExport, setIsFetchingExport] = useState(false)

  const fetchExportDownload = useCallback(
    async (outputFormat: string) => {
      setIsFetchingExport(true)
      try {
        const response = await fetchSl2Api("/report-data", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            reportType,
            tenantId,
            input: {
              tenantId,
              reservationFilters,
              clientFilters,
              lockerIdFilters,
              pagination: {
                page: pagination.page + 1, // backend is 1 based
                pageSize: pagination.pageSize,
              },
              output: outputFormat,
              sortModel,
            },
          }),
        })

        if (!response.ok) {
          console.error("Error fetching report data file", response.statusText)
          return
        }

        // const blob = await response.blob()
        const contentType = response.headers.get("Content-Type") || "text/csv"
        const contentDisposition = response.headers.get("Content-Disposition")
        let filename = "data.txt"
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="(.+)"/)
          if (match && match[1]) {
            filename = match[1]
          }
        }
        const base64Data = await response.text() // Assuming the response is a base64 encoded string
        // Decode base64 string
        const byteCharacters = window.atob(base64Data)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], {
          type: contentType,
        })

        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.style.display = "none"
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      } catch (e) {
        console.error("Error fetching report data file", e)
      } finally {
        setIsFetchingExport(false)
      }
    },
    [
      reportType,
      tenantId,
      reservationFilters,
      clientFilters,
      lockerIdFilters,
      pagination.page,
      pagination.pageSize,
      sortModel,
    ],
  )
  if (!tenantId) {
    console.error("TenantId is required")
    return {
      refresh: () => {},
      isLoading: false,
      pagination,
      setPagination,
      hasNext: false,
      fetchExportDownload: () => {},
      isFetchingExport,
      sortModel,
      setSortModel,
    }
  }

  return {
    refresh,
    isLoading,
    pagination,
    setPagination,
    hasNext,
    fetchExportDownload,
    isFetchingExport,
    sortModel,
    setSortModel,
  }
}

export default useReportDatasets
