import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { t } from "i18next"
import React, { useState } from "react"
import { SstDoor } from "shared/sst"

type LockerControllerDoorSelectorDialogProps = {
  doors: SstDoor[]
  open: boolean
  title: string
  instruction: string
  onClose: () => void
  onConfirm: (door: SstDoor) => void
}

export const LockerControllerDoorSelectorDialog: React.FC<
  LockerControllerDoorSelectorDialogProps
> = ({ doors, open, onClose, onConfirm, title, instruction }) => {
  const [selectedDoor, setSelectedDoor] = useState<SstDoor | null>(
    doors[0] || null,
  )

  const handleConfirm = () => {
    if (selectedDoor) {
      onConfirm(selectedDoor)
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t(title)}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t(instruction)}</DialogContentText>
        <Select
          value={selectedDoor?.door_id || ""}
          onChange={(event) => {
            const doorId = event.target.value
            const door = doors.find((door) => door.door_id === doorId)
            setSelectedDoor(door || null)
          }}
          placeholder="Select a door"
          defaultValue="-1"
          sx={{
            minWidth: 300,
            mt: 3,
          }}
        >
          {doors.map((door) => (
            <MenuItem key={door.door_id} value={door.door_id}>
              {`Door ${door.door_label} - ${door.size}`}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleConfirm} disabled={!selectedDoor}>
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
