interface PostMessageRequestPayload {
  type: string
  payload: any
}

interface PostMessageResponsePayload {
  type: string
  payload: any
  error?: string
}
/**
 * 
 *  @param message 
 * @param targetOrigin 
 * @returns 
 * 
 * 
 Example usage:
 ```
 const requestPayload: PostMessageRequestPayload = {
   type: "getArcgisAddressCandidates",
   payload: {
     singleLine: "1200 Rue Lincoln, Sherbrooke, QC, J1H 2H9, CAN",
     magicKey:
       "123",
     maxLocations: 1,
     outFields: "*",
     category: "Address,Postal",
     forStorage: true,
     langCode: "en_CA",
   },
 }

 postMessageRequest(window.parent, requestPayload)
   .then((response) => {
     console.log("Response received:", response)
   })
   .catch((error) => {
     console.error("Error:", error)
   })
     
 *
 */
export const postMessageRequest = (
  targetWindow: Window,
  message: PostMessageRequestPayload,
  targetOrigin: string = "*",
): Promise<PostMessageResponsePayload> => {
  return new Promise((resolve, reject) => {
    const messageId = Math.random().toString(36).substring(2)
    const timeout = 15 * 1000 // Timeout for the response in milliseconds

    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== targetOrigin && targetOrigin !== "*") {
        return
      }

      const { type, payload, error, id } = event.data

      if (id === messageId) {
        window.removeEventListener("message", handleMessage)
        if (error) {
          reject(new Error(error))
        } else {
          resolve({ type, payload })
        }
      }
    }

    window.addEventListener("message", handleMessage)

    targetWindow.postMessage({ ...message, id: messageId }, targetOrigin)

    setTimeout(() => {
      window.removeEventListener("message", handleMessage)
      reject(new Error("Request timed out"))
    }, timeout)
  })
}
