import { Box, Typography } from "@mui/material"
import { FC } from "react"

export const NotAccessiblePage: FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        padding: 2,
        color: "white",
        backgroundImage: "linear-gradient(60deg, #29323c 0%, #485563 100%)",
      }}
    >
      <Typography variant="h2" component="div" gutterBottom>
        This page is not accessible
      </Typography>
      <Typography variant="body1" gutterBottom>
        The page you're looking for is not accessible. Please contact the
        administrator for more information.
      </Typography>
    </Box>
  )
}

export default NotAccessiblePage
