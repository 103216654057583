import Joi from "joi";
import { SupportedReportsList } from "./reportData";

export const reportJoiSchema = Joi.object({
  reportType: Joi.string()
    .valid(...SupportedReportsList)
    .required(),
  tenantId: Joi.string().required(),
  input: Joi.object().required(),
});

export const attributeFilterSchema = Joi.object({
  attribute: Joi.string().required(),
  operator: Joi.string().required(),
  value0: Joi.string().required(),
  value1: Joi.string().optional(),
  sVals: Joi.array().items(Joi.string()),
});

export const attributesFilterSchema = Joi.array().items(attributeFilterSchema);

export const attributeSchema = Joi.object({
  name: Joi.string().required(),
  type: Joi.string().required(),
  dbAttribute: Joi.string(),
  options: Joi.array().items(Joi.object()),
});

export const dateOperandSchema = Joi.object({
  name: Joi.string().required(),
  isRelative: Joi.boolean().required(),
  associatedFieldType: Joi.string().valid("text", "number", "date"),
});
