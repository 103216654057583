export const SupportedThingTypeMrns = [
  "mrn:sl2:::tenant",
  "mrn:sl2:iam::group",
  "mrn:sl2:iam::groupTU",
  "mrn::::user",
  "mrn::::credential",
  "mrn:sl2:::tenantUser",
  "mrn:sl2:::actionReq",
  "mrn:sl2:::actionReqStep",
  "mrn:sl2:::reservation",
  "mrn:sl2:::reservationAud",
  "mrn:sl2:::locker",
  "mrn:sl2:lockerDoorReservationSession",
  "mrn::::mevent",
  "mrn:sl2:::configuration",
  "mrn:sl2:::client",
  "mrn:sl2:::mod",
  "mrn:sl2:::resource",
  "mrn:sl2:iam::iamAction",
  "mrn:sl2:iam::groupIamAction",
  "mrn:sl2:::smsExpeditor",
  "mrn:sl2:::emailExpeditor",
  "mrn:sl2:::reservationAttempt",
] as const;

export const getThingTypeLabel = (thingType?: string) => {
  if (!thingType) {
    return "-";
  }
  return thingType.replace(/:/g, "_");
};
