import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import en from "shared/locales/en.json"
import fr from "shared/locales/fr.json"

const storedLanguage =
  localStorage.getItem("language") || navigator.language.split("-")[0] || "fr"

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    fr: {
      translation: fr,
    },
  },
  lng: storedLanguage,
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
