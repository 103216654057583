import { Refresh } from "@mui/icons-material"
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import moment from "moment"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { ReportDataset } from "shared/report-datasets"
import { ChartsYearColorMap } from "src/charts/shared"

type DsRecord = {
  created_at_week: number
  created_at_month: number
  created_at_year: number
  nb_reservations: number
  locker_id: string
  sst_status: string
  tenant_id: string
}
function formatData(data: Record<string, DsRecord>[]) {
  // group data by sst_status to plot a histogram of nb_reservations
  const formattedData = data.reduce((acc, curr) => {
    // Find the object for the current sst_status
    let statusObj: any = acc.find(
      (m: Record<string, any>) => m.sst_status === curr.sst_status,
    )

    // If the object doesn't exist, create it
    if (!statusObj) {
      statusObj = { sst_status: curr.sst_status }
      acc.push(statusObj)
    }

    // Add the nb_reservations to the correct property
    const prop = `nb_reservations_${curr.created_at_year}`
    if (!statusObj[prop]) {
      statusObj[prop] = 0
    }
    statusObj[prop] += curr.nb_reservations

    return acc
  }, [] as {}[])
  return formattedData
}
export type OsReportReservationSummaryStatusProps = {
  ds?: ReportDataset<any>
  isLoading: boolean
  refresh: () => void
  hideHeader?: boolean
  hideRefresh?: boolean
  width?: number
}
export const OsReportReservationSummaryStatus: FC<
  OsReportReservationSummaryStatusProps
> = ({ ds, isLoading, refresh, hideHeader, hideRefresh, width = 300 }) => {
  const { t } = useTranslation()

  // Group data by year, month, and week, and sum nb_reservations
  let groupedData = [] as any[]
  let uniqYears = [] as any[]
  if (ds?.data) {
    groupedData = formatData(ds.data)
    // uniquer  year sorted desc
    uniqYears = Array.from(
      new Set(ds.data.map((dp: any) => dp.created_at_year)),
    ).sort((a, b) => a - b)
  }

  return (
    <Stack
      direction="column"
      spacing={4}
      p={2}
      sx={{
        width,
      }}
    >
      {!hideHeader && (
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="column" spacing={1}>
            <Typography variant="body1" fontWeight={"bold"}>
              {t("reporting.reservationSummaryStatus.title")}
            </Typography>
            <Typography variant="body2">
              {t("reporting.reservationSummaryStatus.description")}
            </Typography>
          </Stack>
          {!hideRefresh && (
            <Stack direction="column" spacing={1} alignItems="flex-end">
              <IconButton onClick={refresh} disabled={isLoading}>
                <Refresh />
              </IconButton>
              <Typography variant="caption">
                {ds?.lastUpdated &&
                  `${t("lastUpdated")}: ${moment(ds.lastUpdated).format(
                    "YYYY-MM-DD HH:mm:ss",
                  )}`}
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
      <Box
        sx={{
          width,
          height: 300,
        }}
      >
        {groupedData.length > 0 ? (
          <ResponsiveContainer width={width} height="100%">
            <BarChart
              // width='100%'
              height={width}
              data={groupedData}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="sst_status" />
              <YAxis />
              <Tooltip />
              <Legend />
              {uniqYears.map((year: any) => (
                <Bar
                  key={year}
                  dataKey={`nb_reservations_${year}`}
                  fill={ChartsYearColorMap[year]}
                  // activeDot={{ r: 8 }}
                  name={`Reservations ${year}`}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Box
            sx={{
              //grey box
              width: "100%",
              height: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: "grey.100",
            }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Typography>{t("noData")}</Typography>
            )}
          </Box>
        )}
      </Box>
    </Stack>
  )
}

export default OsReportReservationSummaryStatus
