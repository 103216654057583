import { Box, Dialog, Typography } from "@mui/material"
import { JsonView, allExpanded } from "react-json-view-lite"

import { useAppSelector } from "src/app/hooks"
import { selectCurrentTenant } from "src/features/me/meSlice"

type DebugDialogProps = {
  onClose: () => void
  version: string
  buildId: string
}

export const DebugDialog: React.FC<DebugDialogProps> = ({
  onClose,
  version,
  buildId,
}) => {
  const me = useAppSelector((state) => state.me)
  const currentTenant = useAppSelector(selectCurrentTenant)
  // env variable
  const env = import.meta.env
  return (
    <Dialog
      open={true}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "80vw",
          maxWidth: "1500px",
          maxHeight: "80vh",
          height: "80vh",
        },
      }}
    >
      <Box p={2}>
        <Typography variant="h6">Debug Info</Typography>
        <Typography variant="body1">
          {`Version: ${version} Build ID: ${buildId}`}
        </Typography>
        <JsonView
          data={{ me, currentTenant, env }}
          shouldExpandNode={allExpanded}
        />
      </Box>
    </Dialog>
  )
}
