import { Divider, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { ReservationAudDetailsPaymentMade } from "shared/reservationAud"

type OsReservationAuditTrailDetailsPaymentMadeProps = {
  details: ReservationAudDetailsPaymentMade
}

export const OsReservationAuditTrailDetailsPaymentMade: FC<
  OsReservationAuditTrailDetailsPaymentMadeProps
> = ({ details }) => {
  const { t } = useTranslation()

  const fields = ["paidAmount", "paymentReceipt"]
  return (
    <Stack direction="column" spacing={1} p={1}>
      <Typography variant="body2" fontWeight="bold">
        {t("paymentMade")}
      </Typography>
      <Divider />
      <Stack direction="column" spacing={1}>
        {fields.map((field) => (
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" fontWeight="bold">
              {t(getLastFragment(field) || "field")}:
            </Typography>
            <Stack key={field} direction="row" spacing={1}>
              <Typography variant="body2">{details[field] || "-"}</Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}

const getLastFragment = (str: string) => {
  const lastFragment = str.split(".").pop()
  return lastFragment
}
