export const IamActionThingTypeMrn = "mrn:sl2:iam::iamAction";

export const iamActionsMap = {
  ModIamAccess: "mod:modIam:access",
  ModIamConfigure: "mod:modIam:configure",
  ModLockerAccess: "mod:modLocker:access",
  ModLockerConfigure: "mod:modLocker:configure",
  ModConfigAccess: "mod:modConfig:access",
  ModConfigConfigure: "mod:modConfig:configure",
  ModReportingAccess: "mod:modReporting:access",
  ModReportingConfigure: "mod:modReporting:configure",
  ModKpiAccess: "mod:modKpi:access",
  ModKpiConfigure: "mod:modKpi:configure",
  ModSaAccess: "mod:modSa:access",
  ModSaConfigure: "mod:modSa:configure",
  ResTenantCreate: "res:tenant:create",
  ResTenantRead: "res:tenant:read",
  ResTenantUpdate: "res:tenant:update",
  ResTenantDelete: "res:tenant:delete",
  ResUserCreate: "res:user:create",
  ResUserRead: "res:user:read",
  ResUserUpdate: "res:user:update",
  ResUserDelete: "res:user:delete",
  ResGroupCreate: "res:group:create",
  ResGroupRead: "res:group:read",
  ResGroupUpdate: "res:group:update",
  ResGroupDelete: "res:group:delete",
  ResTenantUserCreate: "res:tenantUser:create",
  ResTenantUserRead: "res:tenantUser:read",
  ResTenantUserUpdate: "res:tenantUser:update",
  ResTenantUserDelete: "res:tenantUser:delete",
  ResLockerCreate: "res:locker:create",
  ResLockerRead: "res:locker:read",
  ResLockerUpdate: "res:locker:update",
  ResLockerDelete: "res:locker:delete",
  ResReservationCreate: "res:reservation:create",
  ResReservationRead: "res:reservation:read",
  ResReservationUpdate: "res:reservation:update",
  ResReservationDelete: "res:reservation:delete",
  ResClientCreate: "res:client:create",
  ResClientRead: "res:client:read",
  ResClientUpdate: "res:client:update",
  ResClientDelete: "res:client:delete",
  ResCredentialsCreate: "res:credentials:create",
  ResCredentialsRead: "res:credentials:read",
  ResCredentialsUpdate: "res:credentials:update",
  ResCredentialsDelete: "res:credentials:delete",
  ResConfigurationCreate: "res:configuration:create",
  ResConfigurationRead: "res:configuration:read",
  ResConfigurationUpdate: "res:configuration:update",
  ResConfigurationDelete: "res:configuration:delete",
};

export const allIamActions = Object.values(iamActionsMap).sort();

// all read actions
export const readIamActions = Object.values(iamActionsMap)
  .filter((a) => a.includes(":read") || a.includes(":access"))
  .sort();
