import { useCallback } from "react"
import { useSearchParams } from "react-router-dom"

export function useCombinedSearchParamsState(
  defaultValues: Record<string, string>,
) {
  const [searchParams, setSearchParams] = useSearchParams()

  const combinedSearchParams = { ...defaultValues }
  searchParams.forEach((value, key) => {
    combinedSearchParams[key] = value
  })

  const setCombinedSearchParamsState = useCallback(
    (newState: Record<string, string>) => {
      const next = { ...combinedSearchParams, ...newState }
      setSearchParams(next, { replace: true })
    },
    [combinedSearchParams, setSearchParams],
  )

  return [combinedSearchParams, setCombinedSearchParamsState] as const
}
