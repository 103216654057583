import { Box, Skeleton, Stack, TextField, Typography } from "@mui/material"
import { DataGrid, GridColDef, gridClasses } from "@mui/x-data-grid"
import { t } from "i18next"
import { map } from "lodash"
import { FC, useState } from "react"

interface ObjectDisplayTableProps {
  data?: Record<string, any>
  isLoading?: boolean
}

const flattenObject = (
  obj: Record<string, any>,
  parentKey = "",
  result: Record<string, any> = {},
) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key
      if (Array.isArray(obj[key])) {
        // @ts-ignore
        obj[key].forEach((item, index) => {
          const arrayKey = `${newKey}[${index}]`
          if (typeof item === "object" && item !== null) {
            flattenObject(item, arrayKey, result)
          } else {
            result[arrayKey] = item
          }
        })
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        flattenObject(obj[key], newKey, result)
      } else {
        result[newKey] = obj[key]
      }
    }
  }
  return result
}

export const ObjectDisplayTable: FC<ObjectDisplayTableProps> = ({
  data,
  isLoading,
}) => {
  const [searchQuery, setSearchQuery] = useState("")

  if (isLoading) {
    //v 800px width skeleton with multiple lines and 4 columns
    return (
      <Stack direction="row" spacing={3} alignItems="center">
        {map([0, 1, 3], (item, key0) => {
          return (
            <Stack
              direction="column"
              spacing={2}
              alignItems="center"
              key={key0}
            >
              {map([0, 1, 2, 3, 4, 5, 6, 7], (item, key1) => (
                <Skeleton
                  key={key1}
                  animation="wave"
                  variant="rectangular"
                  width={key0 % 2 === 0 ? 400 : 200}
                  height={40}
                  sx={{ borderRadius: 1 }}
                />
              ))}
            </Stack>
          )
        })}
      </Stack>
    )
  }
  if (!data) {
    return <Typography variant="body1">No data</Typography>
  }

  const flatData = flattenObject(data)
  const rows = Object.keys(flatData).map((key, index) => ({
    id: index,
    key: key,
    value: String(flatData[key]),
  }))

  const filteredRows = rows.filter(
    (row) =>
      row.key.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.value.toLowerCase().includes(searchQuery.toLowerCase()),
  )

  const columns: GridColDef[] = [
    {
      field: "key",
      headerName: "Key",
      width: 600,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
      // bold header
      renderHeader: (params) => <strong>{params.field}</strong>,
    },
    {
      field: "value",
      headerName: "Value",
      width: 600,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
      renderHeader: (params) => <strong>{params.field}</strong>,
    },
  ]

  return (
    <Box>
      <TextField
        label={t("searchAttribute")}
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{
          width: "400px",
        }}
        size="small"
        placeholder={t("searchAttribute")}
      />
      <DataGrid
        rows={filteredRows}
        columns={columns}
        autoHeight
        getRowHeight={() => "auto"}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}
      />
    </Box>
  )
}

export default ObjectDisplayTable
