import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit"

import { mblsconnectorApi } from "src/features/mblsConnector/mblsConenctorApi"
import { reportApi } from "src/features/report/reportApi"
import { thingsApi } from "src/features/things/thingsApi"
import thingsReducer from "src/features/things/thingsSlice"
import { meApi } from "../features/me/meApi"
import meReducer from "../features/me/meSlice"
import { partnersApi } from "../features/partners/partnersApi"
import sstThingsReducer from "../features/partners/sstThingsSlice"
import reportReducer from "../features/report/reportSlice"

export const store = configureStore({
  reducer: {
    me: meReducer,
    [meApi.reducerPath]: meApi.reducer,
    things: thingsReducer,
    [thingsApi.reducerPath]: thingsApi.reducer,
    [partnersApi.reducerPath]: partnersApi.reducer,
    sstThings: sstThingsReducer,
    [mblsconnectorApi.reducerPath]: mblsconnectorApi.reducer,
    report: reportReducer,
    [reportApi.reducerPath]: reportApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      meApi.middleware,
      thingsApi.middleware,
      partnersApi.middleware,
      mblsconnectorApi.middleware,
      reportApi.middleware,
    ),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
