import { Close, Search } from "@mui/icons-material"
import {
  Box,
  Divider,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material"

import { noop } from "lodash"
import { FC, useCallback, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Reservation } from "shared/reservation"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import { appConfig } from "src/appConfig"
import {
  selectShowMblsReservationSearchBarCloseBtn,
  thingsActions,
} from "src/features/things/thingsSlice"
import useSearchReservation from "src/hooks/useSearchReservation"
import { MblsReservationSearchBarItem } from "./MblsReservationSearchBarItem"

const highlightText = (text: string = "", highlight: string = "") => {
  if (!highlight) return [{ text, match: false }]
  const regex = new RegExp(`(${highlight})`, "gi")
  const parts = text.split(regex)
  return parts.map((part) => ({
    text: part,
    match: part.toLowerCase() === highlight.toLowerCase(),
  }))
}

type MblsReservationSearchBarProps = {
  onClose?: () => void
}

export const MblsReservationSearchBar: FC<MblsReservationSearchBarProps> = ({
  onClose = noop,
}) => {
  const { t } = useTranslation()

  const showMblsReservationSearchBarCloseBtn = useAppSelector(
    selectShowMblsReservationSearchBarCloseBtn,
  )

  const [input, setInput] = useState<string | undefined>("")

  const dispatch = useAppDispatch()

  const result = useSearchReservation({ input })

  const onResultSelect = useCallback(
    (r: Reservation) => {
      dispatch(thingsActions.setHighlightedReservationId(r.id))
      setInput("")
    },
    [dispatch],
  )

  const stackRef = useRef<HTMLDivElement>(null)
  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{
        // bgcolor: "secondary.main",
        color: "primary.main",
        p: 2,
        display: "flex",
        //   alignItems: "center",
        justifyContent: "space-between",
        width: "calc(100vw - 30px)",
        // bottom shadow
        // boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
      }}
    >
      <Stack
        ref={stackRef}
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={3} alignItems="center">
          <Box
            sx={{
              pl: 3,
              position: "relative",
            }}
          >
            <TextField
              placeholder={t("searchReservation")}
              variant="outlined"
              hiddenLabel
              sx={{ width: appConfig.searchBarWidth + 15 }}
              size="small"
              value={input}
              onBlur={(event) => {
                if (
                  stackRef.current &&
                  stackRef.current.contains(event.relatedTarget) &&
                  event.relatedTarget?.tagName === "BUTTON"
                ) {
                  // We ignore onBlur happening on the open result button (will be close by the event of that click)
                  return
                } else {
                  setInput("")
                }
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInput(e.target.value)
              }
              // search icon btn
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <Search />
                  </IconButton>
                ),
              }}
            />
          </Box>
          {result.length > 0 && (
            <Paper
              sx={{
                position: "absolute",
                top: "125px",
                // top: "120px",
                // top: "100%",
                // left: "40px",
                color: "primary.main",

                p: 1,
                width: appConfig.searchBarWidth,
                zIndex: 100,
              }}
              variant="elevation"
              elevation={5}
            >
              <Stack direction="column" spacing={1}>
                <Typography variant="body1" fontWeight="bold">{`${t(
                  "searchResult",
                )} (${result.length})`}</Typography>
                <Divider />
                {result.map((r) => (
                  <MblsReservationSearchBarItem
                    key={r.id}
                    reservation={r}
                    input={input}
                    onSelect={() => onResultSelect(r)}
                  />
                ))}
              </Stack>
            </Paper>
          )}
        </Stack>
        {showMblsReservationSearchBarCloseBtn && (
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        )}
      </Stack>
      {/* result section : Paper absolutely positoined below the search bar, with hig z-dienx */}
    </Stack>
  )
}
