import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { Sl2Btn } from "./Sl2Btn"

type OsLockerShowPickupCodeDialogProps = {
  isOpen?: boolean
  onClose: () => void
  pickupCode: string
  employeeCodeRequired?: boolean
}

export const OsLockerShowPickupCodeDialog: FC<
  OsLockerShowPickupCodeDialogProps
> = ({ isOpen = true, pickupCode, onClose }) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("showPickupCode")}</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack direction="column" spacing={4}>
          <DialogContentText id="alert-dialog-description">
            {t("showPickupCodeText")}
          </DialogContentText>
          <Box
            sx={{
              // center
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography
              variant="h6"
              color="primary.main"
              fontFamily={"monospace"}
              sx={{
                py: 1,
                px: 3,
                bgcolor: "grey.200",
              }}
            >
              {pickupCode}
            </Typography>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Sl2Btn onClick={onClose} color="primary">
          {t("close")}
        </Sl2Btn>
      </DialogActions>
    </Dialog>
  )
}
