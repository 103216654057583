import { Box, CircularProgress, Divider, Stack } from "@mui/material"
import React, { FC, useMemo } from "react"
import { Locker } from "shared/locker"
import { LockerThingTypeMrn } from "shared/lockerData"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady, selectTenantId } from "../features/me/meSlice"
import { useGetThingsQuery } from "../features/things/thingsApi"
import { selectThingsByThingTypeMrn } from "../features/things/thingsSlice"
import { MblsReservationSearchBar } from "./MblsReservationSearchBar"
import OsLayout from "./OsLayout"
import OsLockerDashboard from "./OsLockerDashboard"
import OsLockerDoorDrawerManager from "./OsLockerDoorDrawerManager"

export const OsLockersPageEmbedded: FC = () => {
  const tenantId = useAppSelector(selectTenantId)

  const isReady = useAppSelector(selectMeIsReady)

  const { isLoading } = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetLockers,
        "ap.tenantId": tenantId,
        "ap.refreshSstLockers": true,
        tenantId,
      },
    },
    {
      skip: !tenantId || !isReady,
    },
  )

  const _lockers = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, LockerThingTypeMrn),
  ) as Locker[]

  // sorted lockers by locker.order in ascending order or created at in descending order
  const lockers = useMemo(() => {
    return _lockers.sort((a, b) => {
      // parse displayOrder as number
      const order1 = a.displayOrder || 10000
      const order2 = b.displayOrder || 10000
      return order1 - order2
    })
  }, [_lockers])

  const onBannerClose = React.useCallback(() => {
    // postmessage to parent
    if (window.parent) {
      window.parent.postMessage({ event: "closeModal" }, "*")
    }
  }, [])

  return (
    <OsLayout selectedMenuItemLabelKey="lockers" hideMenu>
      <Box
        sx={{
          borderTop: "2px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        {isLoading || !isReady ? (
          <>
            <Stack direction="column" spacing={2}>
              <CircularProgress />
            </Stack>
          </>
        ) : (
          <>
            <MblsReservationSearchBar onClose={onBannerClose} />
            <Stack
              direction="row"
              spacing={1}
              sx={{
                maxHeight: "calc(100vh - 64px)",
              }}
              // vertical divier
              divider={<Divider orientation="vertical" flexItem />}
            >
              {lockers.map((locker) => (
                <Box key={locker.id}>
                  <OsLockerDashboard lockerId={locker.id} />
                </Box>
              ))}
            </Stack>
          </>
        )}
      </Box>
      <OsLockerDoorDrawerManager />
    </OsLayout>
  )
}

export default OsLockersPageEmbedded
