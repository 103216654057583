import { Box, Chip, Stack } from "@mui/material"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { UnstyledLink } from "./UnstyledLink"

import EventNoteIcon from "@mui/icons-material/EventNote"
import { Reservation } from "shared/reservation"

type ReservationChipProps = {
  reservation: Reservation
  tenantId: string
  showStatus?: boolean
}
export const ReservationChip: FC<ReservationChipProps> = ({
  reservation,
  tenantId,
  showStatus = true,
}) => {
  const { t } = useTranslation()
  const sstReservation = reservation.sstReservation
  if (!sstReservation) {
    return null
  }
  const label = `${t("reservation")} ${sstReservation.number}`
  const Label = (
    <UnstyledLink
      to={`/os/reservations/${reservation.id}?tenantId=${tenantId}`}
    >
      <Stack direction="row" spacing={2} alignItems={"center"}>
        <EventNoteIcon fontSize="small" /> <Box>{label}</Box>
        {showStatus && (
          <Box>
            <Chip
              label={sstReservation.status}
              size="small"
              sx={{
                // square
                borderRadius: 0,
                mr: 2,
              }}
            />
          </Box>
        )}
      </Stack>
    </UnstyledLink>
  )
  return <Chip label={Label} color="primary" variant="outlined" />
}
