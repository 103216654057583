export const appConfig = {
  mainMenuWidth: 250,
  userDrawerWidth: 300,
  itemPreviewDrawerWidth: 500,
  appName: "Smart Locker",
  mainAppBarHeight: 64,
  btnHeight: "50px",
  searchBarWidth: 700,
  minSearchChars: 3,
}
