import { Stack } from "@mui/material"
import { FC, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { ReservationAud } from "shared/reservationAud"
import { ReservationAudThingTypeMrn } from "shared/reservationAudData"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { Thing } from "shared/thing"
import { useAppSelector } from "src/app/hooks"
import { selectTenantId } from "src/features/me/meSlice"
import { useGetThingsQuery } from "src/features/things/thingsApi"
import { selectThingsByThingTypeMrnFilter } from "src/features/things/thingsSlice"
import { OsReservationAuditTrailList } from "./OsReservationAuditTrailList"

type OsReservationAuditTrailProps = {
  reservationId: string
}
export const OsReservationAuditTrail: FC<OsReservationAuditTrailProps> = ({
  reservationId,
}) => {
  const tenantId = useAppSelector(selectTenantId)

  const { t } = useTranslation()

  const q = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetReservationAudsById,
        "ap.reservationId": reservationId,
        "ap.tenantId": tenantId,
        tenantId,
      },
    },
    {
      skip: !tenantId,
    },
  )

  const filter = useCallback(
    (t: Thing) => t.reservationId === reservationId,
    [reservationId],
  )

  const reservationAuds = useAppSelector((s) =>
    selectThingsByThingTypeMrnFilter(s, ReservationAudThingTypeMrn, filter),
  ) as ReservationAud[] | undefined

  return (
    <Stack direction="column" spacing={1}>
      <OsReservationAuditTrailList
        data={reservationAuds || []}
        isFetching={q.isFetching}
        isLoading={q.isLoading}
        refetch={q.refetch}
      />
    </Stack>
  )
}
