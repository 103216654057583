import LockIcon from "@mui/icons-material/Lock"
import WifiTetheringIcon from "@mui/icons-material/WifiTethering"
import WifiTetheringOffIcon from "@mui/icons-material/WifiTetheringOff"
import { Box, Chip, Divider, Skeleton, Stack, Typography } from "@mui/material"
import { FC, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { Locker } from "shared/locker"
import { LockerThingTypeMrn } from "shared/lockerData"
import { SstLocker } from "../../../shared/sst"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady, selectTenantId } from "../features/me/meSlice"
import {
  useCreateThingsMutation,
  useGetThingsQuery,
} from "../features/things/thingsApi"
import { selectThingsByThingTypeMrnFilter } from "../features/things/thingsSlice"
import OsLayout from "./OsLayout"

import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import moment from "moment"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import useLockerDisplayValues from "src/hooks/useLockerDisplayValues"
import { LockerController } from "./LockerController"
import { UnstyledLink } from "./UnstyledLink"

export const OsLockerControllerPage: FC = () => {
  const { t } = useTranslation()
  const tenantId = useAppSelector(selectTenantId)
  const isReady = useAppSelector(selectMeIsReady)

  const { lockerId } = useParams()

  const [createThings] = useCreateThingsMutation()

  const filterFn = useCallback(
    (thing: Locker) => thing.id === lockerId,
    [lockerId],
  )

  const locker = useAppSelector((s) =>
    selectThingsByThingTypeMrnFilter(s, LockerThingTypeMrn, filterFn),
  )[0] as unknown as Locker

  const { isLoading, refetch } = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetLockers,
        "ap.tenantId": tenantId,
        "ap.lockers[0]": lockerId,
        "ap.refreshSstLockers": true,
        tenantId,
      },
    },
    {
      skip: !tenantId || !isReady || !lockerId,
    },
  )

  const sstLocker = locker?.sstLocker as SstLocker
  const l = useLockerDisplayValues(locker)

  return (
    <OsLayout selectedMenuItemLabelKey="lockers">
      {(isLoading || !isReady || !locker) && (
        <>
          <Stack direction="column" spacing={2}>
            <Skeleton variant="rectangular" width="100%" height={118} />
            <Skeleton variant="rectangular" width="100%" height={118 / 2} />
            <Skeleton variant="rectangular" width="100%" height={118} />
            <Skeleton variant="rectangular" width="100%" height={118 / 2} />
          </Stack>
        </>
      )}

      {locker && locker?.sstLockerId && sstLocker?._id && tenantId && (
        <Box sx={{ width: "100%" }}>
          <Stack direction="column" spacing={2} p={2} pl={4} mt={4}>
            <Stack direction="row" spacing={1} alignItems="center">
              <LockIcon
                sx={{
                  color: "grey",
                }}
              />
              <UnstyledLink to={`/os/lockers?tenantId=${tenantId}`}>
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  sx={{
                    color: "grey",
                    "&:hover": {
                      color: "inherit",
                      cursor: "pointer",
                    },
                  }}
                >
                  {t("locker")}
                </Typography>
              </UnstyledLink>

              <ChevronRightIcon
                sx={{
                  color: "grey",
                }}
              />
              <Typography variant="h6" fontWeight={"bold"}>
                {l.i18n.name}
              </Typography>
              <Chip label={t("controller")} />
            </Stack>
            <Stack direction="row" spacing={3} alignItems="center">
              {sstLocker.is_online ? (
                <Typography variant="body2" color="green">
                  <Stack direction="row" spacing={1} alignItems={"center"}>
                    <WifiTetheringIcon />
                    <Box>{t("online")}</Box>
                  </Stack>
                </Typography>
              ) : (
                <Typography variant="body2" color="error">
                  <Stack direction="row" spacing={1} alignItems={"center"}>
                    <WifiTetheringOffIcon />
                    <Box>{t("offline")}</Box>
                  </Stack>
                </Typography>
              )}
              <Typography variant="body2">{`${sstLocker.doors.length} ${t(
                "doors",
              )} `}</Typography>
              <Typography variant="body2">{`📍 ${l.address1} ${l.address2} ${l.city} ${l.province} ${l.country}`}</Typography>
              <Typography variant="body2">{`🕒 Last sync:  ${
                locker.lastSstSync
                  ? moment(locker.lastSstSync).fromNow()
                  : "never"
              } `}</Typography>
            </Stack>
            <Divider />
          </Stack>
          <LockerController locker={locker} />
        </Box>
      )}
    </OsLayout>
  )
}

export default OsLockerControllerPage
