import { Refresh, Sms } from "@mui/icons-material"
import {
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { SmsExpeditor } from "shared/smsExpeditor"
import { SmsExpeditorThingTypeMrn } from "shared/smsExpeditorData"
import { selectThingsByThingTypeMrn } from "src/features/things/thingsSlice"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady } from "../features/me/meSlice"
import { useGetThingsQuery } from "../features/things/thingsApi"
import SaLayout from "./SaLayout"
import { Sl2Btn } from "./Sl2Btn"
import SmsExpeditorCard from "./SmsExpeditorCard"

export const SaSmsExpeditorsPage: FC = () => {
  const { t } = useTranslation()
  const isReady = useAppSelector(selectMeIsReady)

  const smsExpeditors = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, SmsExpeditorThingTypeMrn),
  ) as SmsExpeditor[]
  const [formMode, setFormMode] = useState<"create" | "edit">("edit")
  const { isLoading: isLoadingSmsExpeditors, refetch } = useGetThingsQuery({
    params: {
      "ap.name": SharedAccessPatternSaEnum.SaGetSmsExpeditors,
    },
  })

  const { isLoading: isLoadingTenant, refetch: refetchTenant } =
    useGetThingsQuery({
      params: {
        "ap.name": SharedAccessPatternSaEnum.SaGetTenants,
      },
    })

  const refresh = useCallback(() => {
    refetch()
    refetchTenant()
  }, [refetch, refetchTenant])

  const isLoading = isLoadingSmsExpeditors || isLoadingTenant
  const [selectedSmsExpeditorId, setSelectedSmsExpeditorId] = useState<string>()
  const selectedSmsExpeditor = useMemo(
    () =>
      smsExpeditors?.find(
        (smsExpeditor) => smsExpeditor.id === selectedSmsExpeditorId,
      ),
    [smsExpeditors, selectedSmsExpeditorId],
  )
  return (
    <SaLayout selectedMenuItemLabelKey="smsExpeditors">
      <Stack direction="column" spacing={2} sx={{ p: 2 }}>
        <Stack direction="row" spacing={2} sx={{}}>
          <Stack direction="column" spacing={2}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
               <Typography variant="h6">{t("smsExpeditors")}</Typography> 
              <IconButton onClick={refresh}>
                <Refresh />
              </IconButton>
            </Stack>
            <Typography variant="body2">
              {t("smsExpeditorsDescription1")}
            </Typography>
            <Typography variant="body2">
              {t("smsExpeditorsDescription2")}
            </Typography>
          </Stack>
        </Stack>
        <Divider />

        <Stack direction="row" spacing={2}>
          <Stack direction="column" spacing={2}>
            <Paper
              variant="outlined"
              sx={{ p: 1, width: 400, borderTop: "3px solid #3A5163" }}
            >
              <Stack
                direction="row"
                spacing={2}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{ py: 2 }}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body1" fontWeight={"bold"}>
                    {t("smsExpeditors")}
                  </Typography>
                  {!isLoading && (
                    <IconButton onClick={refetch}>
                      <Refresh />
                    </IconButton>
                  )}
                  {isLoading && <CircularProgress />}
                </Stack>
                <Sl2Btn
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setFormMode("create")
                  }}
                >
                  {t("add")}
                </Sl2Btn>
              </Stack>
              <Divider />
              {/* column stack, render smsExpeditors item */}
              <Stack direction="column" spacing={0} sx={{ pt: 2 }}>
                {smsExpeditors &&
                  smsExpeditors.map((smsExpeditor) => (
                    <MenuItem
                      key={smsExpeditor.id}
                      selected={smsExpeditor.id === selectedSmsExpeditorId}
                      onClick={() => {
                        setSelectedSmsExpeditorId(smsExpeditor.id)
                        setFormMode("edit")
                      }}
                    >
                      <Stack
                        key={smsExpeditor.id}
                        direction="column"
                        justifyContent={"center"}
                        height={50}
                        width={"100%"}
                        sx={{
                          borderBottom: "1px solid lightgrey",
                        }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          width={"100%"}
                        >
                          <Typography variant="body2" fontWeight={"bold"}>
                            {smsExpeditor.name}
                          </Typography>
                          <Typography variant="body2">
                            {smsExpeditor.fromNumber}
                          </Typography>
                        </Stack>
                      </Stack>
                    </MenuItem>
                  ))}
              </Stack>
            </Paper>
          </Stack>
          {!!selectedSmsExpeditor?.id || formMode === "create" ? (
            <Stack
              direction="column"
              spacing={2}
              sx={{
                width: "95%",
                maxWidth: 1000,
              }}
            >
              <SmsExpeditorCard
                smsExpeditor={
                  formMode === "create" ? undefined : selectedSmsExpeditor
                }
                mode={formMode}
                onComplete={refetch}
              />
            </Stack>
          ) : (
            <Stack
              // center the content
              direction="column"
              sx={{
                width: "95%",
                pt: 6,
              }}
              alignItems={"center"}
            >
              <Sms style={{ fontSize: "4em", color: "slategray" }} />
              <Typography>{t("pleaseSelectASmsExpeditor")}</Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </SaLayout>
  )
}

export default SaSmsExpeditorsPage
