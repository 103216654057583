import { useCallback, useEffect, useState } from "react"

interface Suggestion {
  text: string
  magicKey: string
  isCollection: boolean
}

interface ArcgisSuggestionsHook {
  suggestions: Suggestion[]
  isLoading: boolean
  error: string | null
  refresh: (payload: { text: string; maxSuggestions: number }) => void
}

const useArcgisSuggestions = (): ArcgisSuggestionsHook => {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleMessage = useCallback((event: MessageEvent) => {
    const { type, suggestions, error } = event.data

    if (type === "arcgisSuggestions") {
      setSuggestions(suggestions)
      setIsLoading(false)

      setError(error)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("message", handleMessage)
    return () => {
      window.removeEventListener("message", handleMessage)
    }
  }, [handleMessage])

  const refresh = useCallback(
    (payload: { text: string; maxSuggestions: number }) => {
      setIsLoading(true)
      setError(null)
      if (payload.text.length < 3) {
        setIsLoading(false)
        setSuggestions([])
        return
      }
      window.parent.postMessage(
        {
          type: "getArcgisSuggestions",
          payload,
        },
        "*",
      )
    },
    [],
  )

  return {
    suggestions,
    isLoading,
    error,
    refresh,
  }
}

export default useArcgisSuggestions
