import { Box, Stack } from "@mui/material"
import { noop } from "lodash"
import { FC } from "react"
import { Sl2Btn } from "./Sl2Btn"

type InteractiveDoorProps = {
  width?: number
  height?: number
  isOpen: boolean
  color?: string
  label?: string
  onClick?: () => void
  selectMode?: boolean
  onSelect?: () => void
}
export const InteractiveDoor: FC<InteractiveDoorProps> = ({
  width = 150,
  height = 150,
  isOpen,
  color = "lightblue",
  label = "",
  onClick = noop,
  selectMode = false,
  onSelect = noop,
}) => {
  const selectModeStyle = {
    backgroundColor: color,
    width: `${width}px`,
    height: `${height}px`,
    transition: "transform 0.5s ease-in-out",
    textAlign: "center",
  }
  const doorStyle = {
    backgroundColor: color,
    width: `${width}px`,
    height: `${height}px`,
    transition: "transform 0.5s ease-in-out",
    cursor: "pointer",
    transform: isOpen ? "rotateY(-30deg) skewY(10deg)" : "none",
    transformOrigin: "left",
    boxShadow: isOpen ? "5px 5px 5px 0px rgba(0,0,0,0.25)" : "none",
    lineHeight: `${height}px`,
    textAlign: "center",
  }
  const holeStyle = {
    width: `${width}px`,
    height: `${height}px`,
    backgroundColor: "lightgrey",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "inset 0 0 10px  grey",
  }
  const pulseStyle = {
    position: "absolute",
    top: "5px",
    right: "5px",
    width: "10px",
    height: "10px",
    boxShadow: "0 0 10px 5px rgba(0,0,255,0.3)",
    backgroundColor: "blue",
    borderRadius: "50%",
    animation: isOpen ? "pulse 1s infinite" : "none",
    "@keyframes pulse": {
      "0%": {
        transform: "scale(0.8)",
      },
      "50%": {
        transform: "scale(1.1)",
      },
      "100%": {
        transform: "scale(0.8)",
      },
    },
  }

  if (selectMode) {
    return (
      <Stack
        direction="column"
        sx={selectModeStyle}
        onClick={onClick}
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        {/* <Box>{label}</Box> */}
        <Sl2Btn
          onClick={onSelect}
          variant="outlined"
          size="small"
          sx={{ bgcolor: "white" }}
        >
          {label}
        </Sl2Btn>
      </Stack>
    )
  }

  return (
    <Box sx={holeStyle}>
      <Box sx={doorStyle} onClick={isOpen ? onClick : noop}>
        {label}
        {isOpen && <Box sx={pulseStyle} />}
      </Box>
    </Box>
  )
}
