import { useCallback, useEffect, useMemo, useState } from "react"
import { Configuration } from "shared/configuration"
import { ConfigurationThingTypeMrn } from "shared/configurationData"
import { LockerDoorReservationSession } from "shared/lockerDoorReservationSession"
import { Reservation } from "shared/reservation"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { SstDoor } from "shared/sst"
import { useAppSelector } from "src/app/hooks"
import { useGetThingsQuery } from "src/features/things/thingsApi"
import { selectThingsByThingTypeMrnFilter } from "src/features/things/thingsSlice"

type UseReservationProps = {
  reservation?: Reservation
  remainingTimeWarningThreshold?: number
  session?: LockerDoorReservationSession
  door?: SstDoor
  hasDoorLink?: boolean
}
export const useReservation = ({
  reservation,
  session,
  door,
  hasDoorLink,
}: UseReservationProps) => {
  const [now, setNow] = useState(new Date())

  useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(new Date())
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const { isLoading: isLoadingConfig, refetch: refetchConfig } =
    useGetThingsQuery(
      {
        params: {
          "ap.name": SharedAccessPatternOsEnum.GetConfigurationsByTenant,
          "ap.tenantId": reservation?.tenantId,
          "ap.refNames[0]": "configAboutToExpirationReminderDelayMinutes",
          tenantId: reservation?.tenantId,
        },
      },
      {
        skip: !reservation?.tenantId,
      },
    )

  const configFilter = useCallback(
    (thing: Configuration) =>
      thing.tenantId === reservation?.tenantId &&
      thing.refName === "configAboutToExpirationReminderDelayMinutes",
    [reservation?.tenantId],
  )
  const configAboutToExpirationReminderDelayMinutes = useAppSelector((s) =>
    selectThingsByThingTypeMrnFilter(
      s,
      ConfigurationThingTypeMrn,
      configFilter,
    ),
  )[0] as unknown as Configuration

  const {
    expiresIn,
    showExpirationWarning,
    hasExpired,
    expirationDate,
    hasReservation,
    statusColor,
    isReserving,
    statusTextColor,
    hasPayment,
    isCompleted,
    hasDeposited,
    hasPickedUp,
    createdSince,
  } = useMemo(() => {
    let expiresIn = "N/A"

    let showExpirationWarning = false

    let hasExpired = false

    let hasReservation =
      hasDoorLink &&
      reservation &&
      reservation.sstReservation &&
      !["DELETED", "CLOSE", ""].includes(
        reservation?.sstReservation?.status || "",
      )

    let isReserving = false

    // dont show the reservation session if there is a reservation
    if (session && !hasReservation) {
      // iis reserving is sessionStart is in the past and sessionEnd is in the future or undefined

      isReserving =
        session.sessionStart < Date.now() &&
        (!session.sessionEnd || session.sessionEnd > Date.now())
    }

    const expirationDate = new Date(
      reservation?.sstReservation?.expiration_time ?? 8640000000000000,
    )

    const diffInSeconds = (expirationDate.getTime() - now.getTime()) / 1000
    const unsignedDiffInSeconds = Math.abs(diffInSeconds)

    const days = Math.floor(unsignedDiffInSeconds / 86400)
    const hours = Math.floor((unsignedDiffInSeconds % 86400) / 3600)
    const minutes = Math.floor((unsignedDiffInSeconds % 3600) / 60)
    // expiresIn 'smart' formatting. display only days & hours if more than 1 day left
    if (days > 0) {
      expiresIn = `${days}j ${hours}h`
    } else if (hours > 0) {
      // display hour and minutes if less than 1 day left
      expiresIn = `${hours}h ${minutes}m`
    } else {
      // display only minutes and seconds  if less than 1 hour left
      expiresIn = `${minutes}m`
    }

    let createdSince = "-"
    const diff = Math.abs(
      now.getTime() -
        new Date(
          (reservation?.sstReservation?._created as string) ||
            (reservation?.createdAt as string),
        ).getTime(),
    )
    const diffDays = Math.floor(diff / 86400000)
    const diffHours = Math.floor((diff % 86400000) / 3600000)
    const diffMinutes = Math.floor((diff % 3600000) / 60000)

    if (diffDays > 0) {
      createdSince = `${diffDays}j ${diffHours}h`
    } else if (diffHours > 0) {
      createdSince = `${diffHours}h ${diffMinutes}m`
    } else {
      createdSince = `${diffMinutes}m`
    }

    showExpirationWarning =
      diffInSeconds <
      (configAboutToExpirationReminderDelayMinutes?.nVal || 15) * 60
    hasExpired = diffInSeconds <= 0
    // if (hasExpired) {
    //   expiresIn = `-${expiresIn}`
    // }

    const isCompleted = reservation?.sstReservation?.reception_time
      ? true
      : false

    let statusColor = isCompleted
      ? "#43A047"
      : isReserving
      ? "lightblue"
      : hasExpired
      ? "#FF6B6B"
      : showExpirationWarning
      ? "orange"
      : !hasReservation
      ? "#43A047"
      : "white"

    const statusTextColor = isCompleted
      ? "whitesmoke"
      : isReserving
      ? "primary.main"
      : hasExpired
      ? "whitesmoke"
      : showExpirationWarning
      ? "whitesmoke"
      : !hasReservation
      ? "whitesmoke"
      : "primary.main"
    return {
      expiresIn,
      expirationDate,
      showExpirationWarning,
      hasExpired,
      hasReservation,
      statusColor,
      isReserving,
      statusTextColor,
      hasPayment: !!reservation?.amountToCollect
        ? reservation?.amountToCollect > 0
        : false,
      isCompleted,
      hasDeposited:
        reservation?.sstReservation?.status === "DELIVERED" ||
        reservation?.sstReservation?.delivery_time
          ? true
          : false,
      hasPickedUp: reservation?.sstReservation?.reception_time ? true : false,
      createdSince,
    }
  }, [
    session,
    hasDoorLink,
    reservation,
    now,
    configAboutToExpirationReminderDelayMinutes?.nVal,
  ])

  return {
    expiresIn,
    expirationDate,
    showExpirationWarning,
    hasExpired,
    statusColor,
    hasReservation,
    isReserving,
    statusTextColor,
    hasPayment,
    isCompleted,
    hasDeposited,
    hasPickedUp,
    createdSince,
  }
}
