import CloseIcon from "@mui/icons-material/Close"
import InfoIcon from "@mui/icons-material/Info"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { noop } from "lodash"
import { FC, useCallback } from "react"
import { FormContainer } from "react-hook-form-mui"
import { useTranslation } from "react-i18next"
import { GroupThingTypeMrn } from "shared/groupData"
import { GroupTUThingTypeMrn } from "shared/groupTUData"
import { useAppSelector } from "../app/hooks"
import { selectMeTu } from "../features/me/meSlice"
import { useCreateThingsMutation } from "../features/things/thingsApi"
import { useCurrentTenant } from "../hooks/useCurrentTenant"
import FormFields, { FieldsGeneratorFn } from "./FormFields"
import { Sl2Btn } from "./Sl2Btn"

const defaultGroupCreate = {
  thingTypeMrn: GroupThingTypeMrn,
}
const fieldsGeneralFn: FieldsGeneratorFn = (action) => [
  {
    name: "name",
    fieldType: "text",
    label: "name",
    required: true,
    size: "small",
    fullWidth: true,
  },
]

export type OsGroupFormDialogProps = {
  onClose?: () => void
  onComplete?: () => void
}
export const OsGroupFormDialog: FC<OsGroupFormDialogProps> = ({
  onClose = noop,
  onComplete = noop,
}) => {
  const { t } = useTranslation()
  const tu = useAppSelector(selectMeTu)
  const [createThings, { isLoading: isCreatingThings }] =
    useCreateThingsMutation()

  const { tenant } = useCurrentTenant()

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        if (!tenant?.id) throw new Error("tenantId is not defined")
        if (!tu?.id) throw new Error("tenantUserId is not defined")
        const group = {
          ...defaultGroupCreate,
          tenantId: tenant?.id,
          ...data,
          id: "group1",
        }
        const groupTU = {
          thingTypeMrn: GroupTUThingTypeMrn,
          tenantId: tenant?.id,
          groupId: group.id,
          tenantUserId: tu?.id,
        }
        const res = await createThings({
          body: { things: [group, groupTU] },
          params: { tenantId: tenant?.id },
        }).unwrap()
        console.log(res)
        onComplete()
      } catch (e: any) {
        console.log(e)
        onComplete()
      }
    },
    [createThings, onComplete, tenant?.id, tu?.id],
  )

  const fieldsGeneral = fieldsGeneralFn("create")

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          minWidth={400}
        >
          <Typography> {t("createGroup")}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>
      <DialogContent>
        {/* callout */}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          mb={3}
          pl={1}
          pr={1}
          p={4}
          border="1px solid #e0e0e0"
          bgcolor="#f5f5f5"
        >
          <InfoIcon sx={{ mr: 1 }} color="success" />
          <Typography variant="body1">{t("createGroupDescription")}</Typography>
        </Stack>
        <FormContainer
          defaultValues={defaultGroupCreate}
          onError={(errors: any) => console.log(errors)}
          onSuccess={onSubmit}
        >
          <FormFields fields={fieldsGeneral} />
          <Box sx={{ mt: 4 }} />
          <DialogActions>
            <Sl2Btn
              onClick={onClose}
              disabled={isCreatingThings}
              variant="outlined"
            >
              {t("cancel")}
            </Sl2Btn>
            <Sl2Btn
              type="submit"
              disabled={isCreatingThings}
              variant="contained"
            >
              {t("create")}
            </Sl2Btn>
          </DialogActions>
        </FormContainer>
      </DialogContent>
    </Dialog>
  )
}
