import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material"
import { t } from "i18next"
import { chain, isEqual, map, sortBy } from "lodash"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Group } from "shared/group"
import { GroupThingTypeMrn } from "shared/groupData"
import { GroupIamAction } from "shared/groupIamAction"
import { GroupIamActionThingTypeMrn } from "shared/groupIamActionData"
import { IamAction } from "shared/iamAction"
import { IamActionThingTypeMrn } from "shared/iamActionData"
import { Mod } from "shared/mod"
import { ModThingTypeMrn } from "shared/modData"
import { Resource } from "shared/resource"
import { ResourceThingTypeMrn } from "shared/resourceData"
import {
  SharedAccessPatternOsEnum,
  SharedAccessPatternUsEnum,
} from "shared/sharedAccessPatternsData"
import { useAppSelector } from "src/app/hooks"
import { selectTenantId } from "src/features/me/meSlice"
import {
  useCreateThingsMutation,
  useDeleteThingsMutation,
  useGetThingsQuery,
} from "src/features/things/thingsApi"
import {
  selectThingsByThingTypeMrn,
  selectThingsByThingTypeMrnFilter,
} from "src/features/things/thingsSlice"
import { Sl2Btn } from "./Sl2Btn"

export type OsIamPermissionsProps = {
  groupId?: string
}
export const OsIamPermissions: FC<OsIamPermissionsProps> = ({ groupId }) => {
  const tenantId = useAppSelector(selectTenantId)
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>(
    groupId,
  )
  const [isDirty, setIsDirty] = useState(false)
  const [initialGroupIamActions, setInitialGroupIamActions] = useState(
    {} as Record<string, Boolean>,
  )
  const [currentGroupIamActions, setCurrentGroupIamActions] = useState(
    {} as Record<string, Boolean>,
  )
  const { isLoading: isLoadingMods, refetch: refetchMods } = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternUsEnum.GetMods,
        tenantId,
      },
    },
    {
      skip: !tenantId,
    },
  )
  const { isLoading: isLoadingResources, refetch: refetchResources } =
    useGetThingsQuery(
      {
        params: {
          "ap.name": SharedAccessPatternUsEnum.GetResources,
          tenantId,
        },
      },
      {
        skip: !tenantId,
      },
    )

  const { isLoading: isLoadingIamActions, refetch: refetchIamActions } =
    useGetThingsQuery(
      {
        params: {
          "ap.name": SharedAccessPatternUsEnum.GetIamActions,
          tenantId,
        },
      },
      {
        skip: !tenantId,
      },
    )
  // GetGroupsByTenant
  const { isLoading: isLoadingGroups, refetch: refetchGroups } =
    useGetThingsQuery(
      {
        params: {
          "ap.name": SharedAccessPatternOsEnum.GetGroupsByTenant,
          "ap.tenantId": tenantId,
          tenantId,
        },
      },
      {
        skip: !tenantId,
      },
    )
  // GetGroupIamActions
  const {
    isLoading: isLoadingGroupIamActions,
    refetch: refetchGroupIamActions,
  } = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetGroupIamActions,
        "ap.tenantId": tenantId,
        "ap.groupIds[0]": selectedGroupId,
        tenantId,
      },
    },
    {
      skip: !tenantId || !selectedGroupId,
    },
  )

  const [createThings, { isLoading: isCreateLoading }] =
    useCreateThingsMutation()
  const [deleteTings, { isLoading: isDeleteLoading }] =
    useDeleteThingsMutation()

  const filter = useCallback(
    (t: any) => {
      return t.tenantId === tenantId && t.groupId === selectedGroupId
    },
    [tenantId, selectedGroupId],
  )
  const groupIamActions = useAppSelector((s) =>
    selectThingsByThingTypeMrnFilter(s, GroupIamActionThingTypeMrn, filter),
  ) as GroupIamAction[]

  const prevGroupIamActionsRef = useRef<GroupIamAction[]>([])

  useEffect(() => {
    if (!isEqual(prevGroupIamActionsRef.current, groupIamActions)) {
      const newGroupIamActions = groupIamActions.reduce((acc, iamAction) => {
        acc[iamAction.iamActionRefName] = true
        return acc
      }, {} as Record<string, Boolean>)

      setCurrentGroupIamActions(newGroupIamActions)
      setInitialGroupIamActions(newGroupIamActions)
      setIsDirty(false)
    }

    prevGroupIamActionsRef.current = groupIamActions
  }, [groupIamActions])

  const groups = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, GroupThingTypeMrn),
  ) as Group[]

  const mods = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, ModThingTypeMrn),
  ) as Mod[]

  // resources
  const resources = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, ResourceThingTypeMrn),
  ) as Resource[]

  // iamActions
  const iamActions = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, IamActionThingTypeMrn),
  ) as IamAction[]

  // list of lists of mods, resources, and iamActions
  const data = useMemo(() => {
    return sortBy(mods, "order").map((mod) => {
      return {
        mod,
        modActions: iamActions.filter((iamAction) => {
          return iamAction.modRefName === mod.refName
        }),
        resources: resources
          .filter((r) => r.modRefName === mod.refName)
          .map((resource) => {
            return {
              resource,
              iamActions: iamActions.filter((iamAction) => {
                return iamAction.resourceRefName === resource.refName
              }),
            }
          }),
      }
    })
  }, [mods, resources, iamActions])

  const onPermissionsChange = ({
    iamActionRefName,
    adding,
  }: {
    iamActionRefName: string
    adding: boolean
  }) => {
    setIsDirty(true)
    setCurrentGroupIamActions((prev) => {
      return {
        ...prev,
        [iamActionRefName]: adding,
      }
    })
  }

  useEffect(() => {
    if (selectedGroupId) {
      refetchGroupIamActions()
    }
  }, [refetchGroupIamActions, selectedGroupId])

  const onSubmit = useCallback(async () => {
    const initKeys = Object.keys(initialGroupIamActions)
    const currentKeys = Object.keys(currentGroupIamActions)
    const keysToRemove = initKeys.filter(
      (key) =>
        !currentKeys.includes(key) ||
        (currentGroupIamActions[key] === false &&
          initialGroupIamActions[key] === true),
    )
    const keysToCreate = currentKeys.filter(
      (key) => !initKeys.includes(key) && currentGroupIamActions[key] === true,
    )

    // console.log("---------------")
    // console.log("keysToRemove", keysToRemove)
    // console.log("keysToCreate", keysToCreate)
    // console.log("currentGroupIamActions", currentGroupIamActions)
    // console.log("initialGroupIamActions", initialGroupIamActions)
    // console.log("---------------")
    const toCreate = keysToCreate.map((iamActionRefName) => {
      return {
        thingTypeMrn: GroupIamActionThingTypeMrn,
        tenantId,
        groupId: selectedGroupId,
        iamActionRefName,
      }
    })

    const toRemove = chain(keysToRemove)
      .map((iamActionRefName) => {
        const action = groupIamActions.find(
          (g) => g?.iamActionRefName === iamActionRefName,
        )
        if (!action || !action?.id) return null
        return {
          id: action.id,
          thingTypeMrn: GroupIamActionThingTypeMrn,
          groupId: action.groupId,
          tenantId: action.tenantId,
        }
      })
      .compact()
      .value()

    try {
      if (toCreate.length > 0) {
        await createThings({
          body: { things: toCreate },
          params: { tenantId: tenantId },
        })
      }
    } catch (e: any) {
      console.error("Error creating things", e)
    }
    try {
      if (toRemove.length > 0) {
        await deleteTings({
          body: { things: toRemove },
          params: { tenantId: tenantId },
        })
      }
    } catch (e: any) {
      console.error("Error deleting things", e)
    }
  }, [
    createThings,
    currentGroupIamActions,
    deleteTings,
    groupIamActions,
    initialGroupIamActions,
    selectedGroupId,
    tenantId,
  ])
  const isPermissionDisabled = useMemo(() => {
    const selectGroup = groups.find((group) => group.id === selectedGroupId)
    if (!selectGroup) return true
    if (selectGroup.readOnly) return true
    return (
      !selectedGroupId ||
      isLoadingGroupIamActions ||
      isCreateLoading ||
      isDeleteLoading
    )
  }, [
    groups,
    isCreateLoading,
    isDeleteLoading,
    isLoadingGroupIamActions,
    selectedGroupId,
  ])

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="h6">{t("managePermissionsForGroup")}</Typography>
        <Select
          size="small"
          value={selectedGroupId || ""}
          onChange={(e) => {
            setSelectedGroupId(e.target.value as string)
          }}
          displayEmpty
          placeholder="Select a group"
          sx={{
            minWidth: 400,
          }}
        >
          <MenuItem disabled value="">
            <em>{t("selectGroup")}</em>
          </MenuItem>
          {groups.map((group) => (
            <MenuItem key={group.id} value={group.id}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body1">{group.name}</Typography>
                {group.readOnly && (
                  <Chip
                    label={"read-only"}
                    variant="outlined"
                    size="small"
                    sx={{
                      // borderRadius: 0,
                      color: "primary.main",
                    }}
                  />
                )}
                {/* {group.name} */}
              </Stack>
            </MenuItem>
          ))}
        </Select>
        {isLoadingGroupIamActions && <CircularProgress />}
      </Stack>
      <Divider />
      <Stack
        direction="column"
        spacing={8}
        sx={{
          maxHeight: "800px",
          overflowY: "auto",
          // pb: 20,
        }}
      >
        {map(data, ({ mod, modActions, resources }) => (
          <Stack
            direction="column"
            spacing={1}
            key={mod.id}
            sx={{
              pl: 2,
              p: 2,
              border: "1px solid lightgrey",
              bgcolor: "rgba(58, 81, 99, .05)",
            }}
          >
            <Stack direction="row" spacing={3} alignItems={"center"}>
              <Typography variant="body1" fontWeight="bold">
                {mod.name}
              </Typography>

              <Chip
                label={<code>{mod.refName}</code>}
                variant="filled"
                size="small"
                sx={{
                  borderRadius: 0,
                  color: "primary.main",
                }}
              />
              <Typography variant="body2">{mod.description}</Typography>
            </Stack>
            <Divider />
            <Stack direction="column" spacing={4}>
              <Box>
                <Typography variant="body2" fontWeight="bold">
                  {t("modActions")}
                </Typography>
                <Stack direction="row" spacing={0}>
                  {map(modActions, (modAction) => (
                    <Box key={modAction.id} width={250}>
                      <Typography variant="body2">
                        <Checkbox
                          color="primary"
                          disabled={isPermissionDisabled}
                          checked={!!currentGroupIamActions[modAction.refName]}
                          onChange={(e) =>
                            onPermissionsChange({
                              iamActionRefName: modAction.refName,
                              adding: e.target.checked,
                            })
                          }
                        />
                        {modAction.displayName || modAction.refName}
                      </Typography>
                    </Box>
                  ))}
                </Stack>
              </Box>
              {map(resources, ({ resource, iamActions }) => (
                <Paper variant="outlined" key={resource.id} sx={{ p: 1 }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={3} alignItems={"center"}>
                      <Typography variant="body2" fontWeight="bold">
                        {resource.name}
                      </Typography>
                    </Stack>
                    {/* stack row with iamActions */}
                    <Stack direction="row" spacing={0}>
                      {map(iamActions, (iamAction) => (
                        <Box key={iamAction.id} width={250}>
                          <Typography variant="body2">
                            <Checkbox
                              color="primary"
                              disabled={isPermissionDisabled}
                              checked={
                                !!currentGroupIamActions[iamAction.refName]
                              }
                              onChange={(e) =>
                                onPermissionsChange({
                                  iamActionRefName: iamAction.refName,
                                  adding: e.target.checked,
                                })
                              }
                            />
                            {iamAction.displayName || iamAction.refName}
                          </Typography>
                        </Box>
                      ))}
                    </Stack>
                  </Stack>
                </Paper>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
      <Divider />
      <Stack
        direction="row"
        spacing={2}
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <Sl2Btn
          disabled={!isDirty}
          variant="outlined"
          sx={{
            minWidth: 200,
          }}
        >
          {t("cancel")}
        </Sl2Btn>
        <Sl2Btn
          onClick={onSubmit}
          disabled={!isDirty || isCreateLoading || isDeleteLoading}
          variant="contained"
          sx={{
            minWidth: 200,
          }}
        >
          {t("applyPermissions")}
        </Sl2Btn>
      </Stack>
    </Stack>
  )
}
