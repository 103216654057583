import { useUser } from "@clerk/clerk-react"
import { Avatar, Badge, IconButton, Tooltip } from "@mui/material"
import { FC, useCallback, useState } from "react"
import defaultAvatar from "../assets/default_avatar.png"
import { UserDrawer } from "./UserDrawer"

type UserAvatarProps = {
  isOnline?: boolean
}
export const UserAvatar: FC<UserAvatarProps> = ({ isOnline }) => {
  // useState isuserDrawerOpen
  const [isUserDrawerOpen, setIsUserDrawerOpen] = useState(false)

  const handleAvatarClick = useCallback(() => {
    setIsUserDrawerOpen((isUserDrawerOpen) => !isUserDrawerOpen)
  }, [])
  const onClose = useCallback(() => {
    setIsUserDrawerOpen(false)
  }, [])

  const clerkIdentity = useUser()
  return (
    <>
      <Tooltip title={clerkIdentity?.user?.primaryEmailAddress?.emailAddress}>
        <IconButton onClick={handleAvatarClick} sx={{ p: 0 }}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
            sx={{
              "& .MuiBadge-dot": {
                backgroundColor: isOnline ? "green" : "grey",
                width: 10,
                height: 10,
                borderRadius: "50%",
              },
            }}
          >
            <Avatar
              alt="User"
              src={clerkIdentity?.user?.imageUrl || defaultAvatar}
            />
          </Badge>
        </IconButton>
      </Tooltip>
      {isUserDrawerOpen && <UserDrawer onClose={onClose} />}
    </>
  )
}
