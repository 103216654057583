import { HighlightOff } from "@mui/icons-material"
import {
  Box,
  Chip,
  Divider,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material"

import InfoIcon from "@mui/icons-material/Info"
import TranslateIcon from "@mui/icons-material/Translate"
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete"
import { usePrevious } from "@uidotdev/usehooks"

import Joi from "joi"
import { noop } from "lodash"
import { FC, useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import {
  selectMblsReservationClient,
  selectReservationFormDefaultValues,
  selectSelectedClient,
  selectUserInputEmail,
  selectUserInputPhone,
  thingsActions,
} from "src/features/things/thingsSlice"

const joiSchema = Joi.object({
  email: Joi.string()
    .email({
      tlds: { allow: false },
    })
    .optional()
    .empty("")
    .messages({
      "any.required": "Please provide an email or phone number",
    }),
  phone: Joi.string()
    .optional()
    // 10 digits, no spaces, no special characters
    .pattern(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)
    .empty("")

    .messages({
      "any.required": "Please provide an email or phone number",
      "string.pattern.base": "Please provide a valid phone",
    }),
})
  .or("email", "phone")
  .messages({
    "object.missing": "Please provide an email or a phone number",
  })

type PhoneOption = {
  id: string
  number: string
  label: string
}
const filterPhone = createFilterOptions<PhoneOption>()
export type OsReservationSelectedClientProps = {
  onError?: (error: Record<string, string>) => void
  errors?: Record<string, string>
}

export const OsReservationSelectedClient: FC<
  OsReservationSelectedClientProps
> = ({ onError = noop, errors = {} }) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const mblsReservationClient = useAppSelector(selectMblsReservationClient)

  const selectedClient = useAppSelector(selectSelectedClient)

  const userInputPhone = useAppSelector(selectUserInputPhone)

  const userInputEmail = useAppSelector(selectUserInputEmail)

  const defaultValues = useAppSelector(selectReservationFormDefaultValues)

  // validate
  const validateUserInput = useCallback(() => {
    const toValidate = {
      email: userInputEmail,
      phone: userInputPhone,
    }
    const { error } = joiSchema.validate(toValidate, { abortEarly: false })
    console.log("error selected client", error, toValidate)

    if (!error) {
      onError({})
      return
    }

    const newErrors = {} as Record<string, string>

    error.details.forEach((detail) => {
      if (!detail.path) return

      newErrors[detail.path[0] || "generalSelectedClient"] = detail.message
    })

    if (typeof error === "string") {
      newErrors["generalSelectedClient"] = error
    }

    onError(newErrors)
  }, [onError, userInputEmail, userInputPhone])

  const setValuePhone = useCallback(
    (value: PhoneOption | null) => {
      dispatch(thingsActions.setUserInputPhone(value?.number || ""))
    },
    [dispatch],
  )

  const setValueEmail = useCallback(
    (value: string | null) => {
      dispatch(thingsActions.setUserInputEmail(value || ""))
    },
    [dispatch],
  )

  // reset phone value when selectedClient changes
  const prevSelectedClient = usePrevious(selectedClient)

  useEffect(() => {
    if (prevSelectedClient?.id === selectedClient?.id) return
    dispatch(
      thingsActions.setUserInputPhone(
        defaultValues?.phone ? defaultValues?.phone + "" : "",
      ),
    )
    setValueEmail(defaultValues?.email ? defaultValues?.email + "" : null)
  }, [
    selectedClient,
    prevSelectedClient,
    setValuePhone,
    setValueEmail,
    defaultValues?.phone,
    defaultValues?.email,
    dispatch,
  ])

  if (!selectedClient) return null

  return (
    <Paper
      sx={{
        // p: 2,
        p: 1,
        color: "primary.main",
        width: "415px",
        borderTop: "3px solid #234285",
      }}
      variant="elevation"
      elevation={3}
    >
      <Stack direction="column" spacing={0}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={"space-between"}
          sx={{}}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body2" fontWeight="bold">
              {`${selectedClient.firstName} ${selectedClient.lastName}`}
            </Typography>
            {selectedClient?.language && (
              <Chip
                size="small"
                variant="outlined"
                label={
                  <Stack direction="row" spacing={1} alignItems="center">
                    <TranslateIcon fontSize="small" />
                    <Typography variant="body2">
                      {t(selectedClient.language)}
                    </Typography>
                  </Stack>
                }
              />
            )}
          </Stack>
          {!mblsReservationClient?.id && (
            <IconButton
              color="primary"
              onClick={() =>
                dispatch(thingsActions.clearClientSelectionDefaultValues())
              }
            >
              <HighlightOff />
            </IconButton>
          )}
        </Stack>
        <Divider />
        <Box height={"15px"} />
        <Stack direction="column" spacing={1}>
          <Stack direction="column" spacing={1}>
            <Typography variant="body2" fontWeight="bold">{`${t(
              "email",
            )} ✉️`}</Typography>
            <Stack direction="column" spacing={1}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="top"
                justifyContent="space-between"
              >
                <TextField
                  value={userInputEmail}
                  onChange={(e) => setValueEmail(e.target.value)}
                  label={t("email")}
                  size="small"
                  onBlur={validateUserInput}
                  error={!!errors.email}
                  helperText={
                    <Typography variant="caption">{errors.email}</Typography>
                  }
                  sx={{
                    width: "100%",
                  }}
                />
              </Stack>
              {!errors.email &&
                userInputEmail !== "" &&
                userInputEmail !== selectedClient?.email && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <InfoIcon
                      sx={{
                        color: "primary.main",
                        fontSize: 16,
                      }}
                    />
                    <Typography variant="caption" color={"black"}>{`${t(
                      "customEmailValueNotUpdatingClientProfile",
                    )}`}</Typography>
                  </Stack>
                )}
            </Stack>
          </Stack>
          <Stack direction="column" spacing={1}>
            <Typography variant="body2" fontWeight="bold">{`${t(
              "phone",
            )} 📞`}</Typography>
            <Typography variant="body2">{`${t(
              "pleaseChoosePhoneNumber",
            )}`}</Typography>
            <Autocomplete
              value={userInputPhone}
              onChange={(event, newValue) => {
                console.log("phone newValue", newValue)
                if (typeof newValue === "string") {
                  setValuePhone({
                    id: "new",
                    number: newValue,
                    label: "custom",
                  })
                } else if (newValue && newValue.number) {
                  setValuePhone(newValue)
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filterPhone(options, params)

                const { inputValue } = params
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.number,
                )
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    id: "new",
                    number: inputValue,
                    label: "custom",
                  })
                }

                return filtered
              }}
              selectOnFocus
              // clearOnBlur
              handleHomeEndKeys
              id="selected-client-phone"
              options={selectedClient.phones || []}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option
                }

                if (option.id === "new") {
                  return option.number
                }

                return option.number
              }}
              renderOption={(props, option) => {
                const { ...optionProps } = props
                return (
                  <li key={option.id} {...optionProps}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      {option.id === "new" ? (
                        <Typography variant="body2">{`Add "${option.number}"`}</Typography>
                      ) : (
                        <Typography variant="body2">{`${option.number}`}</Typography>
                      )}
                      {option.label && (
                        <Chip label={option.label} size="small" />
                      )}
                    </Stack>
                  </li>
                )
              }}
              sx={{ width: 350 }}
              freeSolo
              renderInput={(params) => {
                // console.log("renderInput", params)
                // find the phone number in the selectedClient.phones using parms.inputProps.value
                const phone = selectedClient.phones?.find(
                  (p) => p.number === params.inputProps.value,
                )

                const hasDifferentPhone =
                  userInputPhone !== phone?.number && userInputPhone !== ""

                return (
                  <Stack direction="column" spacing={1}>
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="top"
                      justifyContent="space-between"
                    >
                      <TextField
                        {...params}
                        label={t("phone")}
                        size="small"
                        error={!!errors.phone}
                        // helperText={errors.phone}
                        onBlur={validateUserInput}
                      />
                      <Box sx={{ pt: 1 }}>
                        <Chip
                          label={t(phone?.label || "custom")}
                          size="small"
                        />
                      </Box>
                    </Stack>
                    {!errors.phone && hasDifferentPhone && (
                      <Stack direction="row" spacing={1} alignItems="center">
                        <InfoIcon
                          sx={{
                            color: "primary.main",
                            fontSize: 16,
                          }}
                        />
                        <Typography variant="caption" color={"black"}>{`${t(
                          "customPhoneValueNotUpdatingClientProfile",
                        )}`}</Typography>
                      </Stack>
                    )}
                  </Stack>
                )
              }}
            />
          </Stack>
        </Stack>
        {errors.generalSelectedClient && (
          <Box sx={{ p: 1 }}>
            <Typography color="error" variant="body2">
              {errors.generalSelectedClient}
            </Typography>
          </Box>
        )}
      </Stack>
    </Paper>
  )
}
