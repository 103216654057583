import { useCallback } from "react"
import { useSelector } from "react-redux"
import { SharedAccessPatternUsEnum } from "shared/sharedAccessPatternsData"
import { Tenant } from "shared/tenant"
import { TenantThingTypeMrn } from "shared/tenantData"
import { Thing } from "shared/thing"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady, selectTenantId } from "../features/me/meSlice"
import { useGetThingsQuery } from "../features/things/thingsApi"
import { selectThingsByThingTypeMrnFilter } from "../features/things/thingsSlice"

export const useCurrentTenant = () => {
  const tenantId = useSelector(selectTenantId)
  const isReady = useAppSelector(selectMeIsReady)
  const filterFn = useCallback((t: Thing) => t.id === tenantId, [tenantId])

  const tenant = useAppSelector((s) =>
    selectThingsByThingTypeMrnFilter(s, TenantThingTypeMrn, filterFn),
  )[0] as Tenant | undefined

  useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternUsEnum.GetMyTenants,
      },
    },
    {
      skip: !tenantId || !isReady,
    },
  )
  return { tenant }
}
