import {
  useClerk,
  useAuth as useClerkAuth,
  useSignIn,
} from "@clerk/clerk-react"

import { createContext, useContext, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

const AuthContext = createContext<{
  isAuthenticated: boolean
  isProcessing: boolean
  isProcessed: boolean
  isReady: boolean
}>({
  isAuthenticated: false,
  isProcessing: true,
  isProcessed: false,
  isReady: false,
})

export const useAppAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { signOut, isLoaded, sessionId, isSignedIn = false } = useClerkAuth()

  const { session } = useClerk()

  const { signIn, setActive } = useSignIn()

  const [isProcessing, setIsProcessing] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()

  const ticket = searchParams.get("_ticket")

  const externalId = searchParams.get("externalId")

  const [isProcessingTicket, setIsProcessingTicket] = useState(!!ticket)

  const [isProcessed, setIsProcessed] = useState(false)

  const navigate = useNavigate()

  // process ticket login if present
  useEffect(() => {
    if (!isLoaded) {
      console.log("Not loaded yet, returning from useEffect")
      return
    }
    if (isProcessed) {
      //   console.log("Already processed, returning from useEffect")
      //   return
    }
    if (
      isSignedIn &&
      !isProcessing &&
      ["/login", "", "/", window.baseName].includes(window.location.pathname)
    ) {
      navigate("/us/profile")
      return
    }
    const processTicketAuthentication = async () => {
      if (ticket && signIn && !isProcessed && !isProcessing && isLoaded) {
        console.log(
          `### Processing ticket authentication : externalId : sessionUserId ${externalId} : ${session?.user?.id}`,
        )
        try {
          setIsProcessingTicket(true)
          if (!externalId || externalId !== session?.user?.id) {
            await session?.remove()
            console.log("### Removed session", {
              externalId,
              sessionUserId: session?.user?.id,
            })
          }
          const res = await signIn.create({
            strategy: "ticket",
            ticket,
          })

          setActive({
            session: res.createdSessionId,
            beforeEmit: () => {
              setIsProcessed(true)
            },
          })
          console.log(
            `### Signed in with ticket : externalId : sessionUserId ${externalId} : ${session?.user?.id}`,
          )
        } catch (error: any) {
          console.error(
            "Error signing in with ticket: " + error?.message,
            error,
          )
          setIsProcessed(true)
        } finally {
          searchParams.delete("_ticket")
          setSearchParams(searchParams)
          setIsProcessingTicket(false)
        }
      }
    }
    const init = async () => {
      try {
        await processTicketAuthentication()
      } catch (error: any) {
        console.error(
          "Error processing authentication: " + error?.message,
          error,
        )
      } finally {
        setIsProcessed(true)
        setIsProcessing(false)
      }
    }

    init().then(() => {
      console.log("auth init completed")
    })
  }, [
    ticket,
    signIn,
    signOut,
    isLoaded,
    isSignedIn,
    isProcessing,
    isProcessed,
    setActive,
    searchParams,
    setSearchParams,
    navigate,
    session,
    session?.user?.id,
    isProcessingTicket,
    externalId,
  ])

  //   const isProcessing = !isLoaded
  const ctx = {
    isAuthenticated: isSignedIn,
    isProcessing,
    isProcessed,
    isReady:
      isLoaded &&
      isProcessed &&
      !isProcessing &&
      !ticket &&
      (!!sessionId || !isSignedIn),
  }
  console.log("AuthContext", ctx)
  return <AuthContext.Provider value={ctx}>{children}</AuthContext.Provider>
}
