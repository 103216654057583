import { FC } from "react"
import { Link, LinkProps } from "react-router-dom"

export const UnstyledLink: FC<{ disabled?: boolean } & LinkProps> = ({
  children,
  disabled = false,
  ...props
}) => {
  return (
    <Link
      {...props}
      style={{
        textDecoration: "none",
        color: "inherit",
        pointerEvents: disabled ? "none" : "auto",
      }}
      children={children}
    />
  )
}
