import { useAuth } from "@clerk/clerk-react"
import LaunchIcon from "@mui/icons-material/Launch"
import WindowIcon from "@mui/icons-material/Window"
import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material"
import Toolbar from "@mui/material/Toolbar"
import { mqtt5 } from "aws-iot-device-sdk-v2"
import * as React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { ModeContext } from "src/ModeContext"
import { useGetMeQuery } from "src/features/me/meApi"
import { darkTheme } from "src/theme"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import {
  selectMe,
  selectMeIsReady,
  selectTenantId,
  setTenantId,
} from "../features/me/meSlice"
import {
  selectUnauthorizedMessage,
  thingsActions,
} from "../features/things/thingsSlice"
import { MrtConnection } from "../iot/MrtConnection"
import { Sl2Btn } from "./Sl2Btn"

const col1Width = 3
const col2Width = 4
const col3Width = 4

const OsLockerWidget: React.FC = () => {
  const { t } = useTranslation()

  const { isSignedIn, isLoaded } = useAuth()

  const isReady = useAppSelector(selectMeIsReady)

  const tenantId = useSelector(selectTenantId)

  const unauthorizedMessage = useSelector(selectUnauthorizedMessage)

  const dispatch = useAppDispatch()

  const mode = React.useContext(ModeContext) || "standalone"

  useGetMeQuery(tenantId, {
    skip: !isLoaded || !isSignedIn || !tenantId,
  })

  const { user, isLoaded: isLoadedMe } = useSelector(selectMe)

  // get tenantId from search params
  const [search] = useSearchParams()

  const urlTenantId = search.get("tenantId")

  React.useEffect(() => {
    if (urlTenantId && tenantId !== urlTenantId) {
      dispatch(setTenantId(urlTenantId))
    }
  }, [dispatch, tenantId, urlTenantId])

  return (
    <Stack
      direction="column"
      sx={{
        display: "flex",
        height: "170px",
        width: "216px",
        // bgcolor: "#282930",
        bgcolor: "#1A1C20",
        color: "whitesmoke",
        overflow: "hidden",
      }}
    >
      {unauthorizedMessage && (
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: "rgba(255, 152, 0, 0.4)",
          }}
        >
          <Typography variant="body1" fontWeight="bold" noWrap>
            {t("unauthorizedMessage")}
          </Typography>
          <Box></Box>
          <Sl2Btn
            variant="outlined"
            onClick={() => dispatch(thingsActions.setUnauthorizedMessage(""))}
          >
            {t("close")}
          </Sl2Btn>
        </Toolbar>
      )}

      {!(isLoadedMe || !isReady) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {isReady && tenantId && user?.id && (
        <>
          <ThemeProvider theme={darkTheme}>
            <Stack
              direction="column"
              spacing={1}
              alignItems="center"
              sx={{
                width: "100%",
                height: "100%",

                p: 1,
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <WindowIcon />
                <Typography variant="body1" fontWeight="bold">
                  {t("lockers")}
                </Typography>
                <LaunchIcon />
              </Stack>
              <TextField
                label={t("search")}
                variant="outlined"
                size="small"
                fullWidth
                // smaller
                sx={{
                  // line height
                  "& .MuiInputBase-root": {
                    height: "30px",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "0px",
                  },
                  // font
                  "& .MuiInputBase-input": {
                    padding: "0px",
                    fontSize: "14px",
                  },
                  // contained
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "0px",
                  },
                }}
              />
              <Grid container>
                <Grid item xs={col1Width}></Grid>
                <Grid item xs={col2Width} sx={{ textAlign: "center" }}>
                  <Typography variant="caption" fontWeight="bold">
                    {t("available")}
                  </Typography>
                </Grid>
                <Grid item xs={col3Width} sx={{ textAlign: "center" }}>
                  <Typography variant="caption" fontWeight="bold">
                    {t("expired")}
                  </Typography>
                </Grid>
                <Row label={t("small")} val1="0" val2="0" />
                <Row label={t("medium")} val1="0" val2="0" />
                <Row label={t("large")} val1="0" val2="0" />
              </Grid>
            </Stack>
            <MrtConnection
              tenantId={tenantId}
              subscribePacket={{
                subscriptions: [
                  {
                    topicFilter: `mrt/dsync/${tenantId}/#`,
                    qos: mqtt5.QoS.AtLeastOnce,
                  },
                  {
                    topicFilter: `mrt/app/sl2/partners/sst/${tenantId}/#`,
                    qos: mqtt5.QoS.AtLeastOnce,
                  },
                  {
                    topicFilter: `mrt/us/${user?.id}/#`,

                    qos: mqtt5.QoS.AtLeastOnce,
                  },
                ],
              }}
            />
          </ThemeProvider>
        </>
      )}
    </Stack>
  )
}

export default OsLockerWidget

const Row = ({
  label,
  val1,
  val2,
}: {
  label: string
  val1: string
  val2: string
}) => {
  return (
    // center
    <Grid container alignItems="center">
      <Grid item xs={col1Width}>
        <Typography variant="caption" fontWeight="bold">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={col2Width} sx={{ textAlign: "center" }}>
        <Typography variant="caption">{val1}</Typography>
      </Grid>
      <Grid item xs={col3Width} sx={{ textAlign: "center" }}>
        <Typography variant="caption">{val2}</Typography>
      </Grid>
    </Grid>
  )
}
