interface AddressCandidate {
  address: string
  location: {
    x: number
    y: number
  }
  score: number
  attributes: {
    AddNum: string
    LongLabel: string
    ShortLabel: string
    City: string
    Region: string
    RegionAbbr: string
    Country: string
    Postal: string
    PostalExt: string
    District: string
    X: number
    Y: number
    DisplayX: number
    DisplayY: number
  }
}

export interface MblsAddress {
  id: number
  address_components: Array<{
    long_name: string
    short_name: string
    types: string[]
  }>
  formatted_address: string
  geometry: {
    location: {
      lat: number
      lng: number
    }
  }
}

const countries: { [key: string]: { short: string; long: string } } = {
  CAN: { short: "CA", long: "Canada" },
  USA: { short: "US", long: "United States" },
}

function getPostalCodeType(candidate: AddressCandidate): string {
  // assert candidate, must be ab object
  if (typeof candidate !== "object") {
    throw new Error("getPostalCodeType: candidate must be an object")
  }
  // assert candidate.attributes, must be an object
  if (typeof candidate.attributes !== "object") {
    throw new Error("getPostalCodeType: candidate.attributes must be an object")
  }

  if (candidate.attributes.Country === "USA") {
    return candidate.attributes.Postal
  } else {
    return candidate.attributes.Postal + " " + candidate.attributes.PostalExt
  }
}

export function convertToMblsAddress(candidate: AddressCandidate): MblsAddress {
  // assert candidate, must be ab object
  if (typeof candidate !== "object") {
    throw new Error("convertToMblsAddress: candidate must be an object")
  }
  // assert candidate.attributes, must be an object
  if (typeof candidate.attributes !== "object") {
    throw new Error(
      "convertToMblsAddress: candidate.attributes must be an object",
    )
  }

  const place: MblsAddress = {
    id: Math.random(),
    address_components: [
      {
        long_name: candidate.attributes.AddNum,
        short_name: candidate.attributes.AddNum,
        types: ["street_number"],
      },
      {
        long_name: candidate.attributes.LongLabel.replace(
          candidate.attributes.AddNum,
          "",
        ).trim(),
        short_name: candidate.attributes.ShortLabel.replace(
          candidate.attributes.AddNum,
          "",
        ).trim(),
        types: ["route"],
      },
      {
        long_name: candidate.attributes.City,
        short_name: candidate.attributes.City,
        types: ["locality", "political"],
      },
      {
        long_name: candidate.attributes.Region,
        short_name: candidate.attributes.RegionAbbr,
        types: ["administrative_area_level_1", "political"],
      },
      {
        long_name: countries[candidate.attributes.Country].long,
        short_name: countries[candidate.attributes.Country].short,
        types: ["country", "political"],
      },
      {
        long_name: getPostalCodeType(candidate),
        short_name: getPostalCodeType(candidate),
        types: ["postal_code"],
      },
      {
        long_name: candidate.attributes.District,
        short_name: candidate.attributes.District,
        types: ["district"],
      },
    ],
    formatted_address: candidate.attributes.LongLabel,
    geometry: {
      location: {
        lat: candidate.attributes.Y,
        lng: candidate.attributes.X,
      },
    },
  }

  // WV1-59 Address route calculations problems - Workaround to ESRI bug
  if (candidate.attributes.X > 85 || candidate.attributes.X < -85) {
    place.geometry.location.lat = candidate.attributes.DisplayY
    place.geometry.location.lng = candidate.attributes.DisplayX
  }

  return place
}

// Example usage:
// const candidate: AddressCandidate = {
//   address: "1200 Rue Lincoln, Sherbrooke, QC, J1H 2H9, CAN",
//   location: { x: -71.8962095, y: 45.4003028 },
//   score: 100,
//   attributes: {
//     AddNum: "1200",
//     LongLabel: "1200 Rue Lincoln, Sherbrooke, QC, J1H 2H9, CAN",
//     ShortLabel: "1200 Rue Lincoln",
//     City: "Sherbrooke",
//     Region: "Quebec",
//     RegionAbbr: "QC",
//     Country: "CAN",
//     Postal: "J1H 2H9",
//     PostalExt: "",
//     District: "",
//     X: -71.8962095,
//     Y: 45.4003028,
//     DisplayX: -71.8962095,
//     DisplayY: 45.4003028,
//   },
// }

// // const place = convertToMblsAddress(candidate)
// // console.log(place)
