export const ActionReqStepThingTypeMrn = "mrn:sl2:::actionReqStep";

export const ActionReqStepTypeList = [
  "start",
  "progress",
  "success",
  "failure",
] as const;

export const ActionReqStepTypeEnum = {
  start: "start",
  progress: "progress",
  success: "success",
  failure: "failure",
} as const;
