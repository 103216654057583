import { Preview, Refresh, Search, Warning } from "@mui/icons-material"
import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { DataGrid, DataGridProps } from "@mui/x-data-grid"
import { TFunction } from "i18next"
import { get } from "lodash"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { Tenant } from "shared/tenant"
import { User } from "shared/user"
import { UserThingTypeMrn } from "shared/userData"
import { selectThingsByThingTypeMrnFilter } from "src/features/things/thingsSlice"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady } from "../features/me/meSlice"
import { useGetThingsQuery } from "../features/things/thingsApi"
import { ObjectDisplayDrawer } from "./ObjectDisplay"
import SaLayout from "./SaLayout"
import SaOrgForm from "./SaOrgForm"
import { Sl2Btn } from "./Sl2Btn"

const WarningInfo: FC<{ tenant: Tenant }> = ({ tenant }) => {
  return (
    <Stack direction="column" spacing={1} alignItems="center">
      <Typography variant="body2" fontWeight="bold" noWrap>
        Missing configuration
      </Typography>
      <Typography variant="body2" noWrap>
        SMS Expeditor
      </Typography>

      <Typography variant="body2" noWrap>
        Email Expeditor
      </Typography>
    </Stack>
  )
}

const defaultColWidth = 230

export const SaTenantsPage: FC = () => {
  const { t } = useTranslation()

  const [snackMessage, setSnackMessage] = useState({
    message: "",
    severity: "success" as "success" | "error" | "warning" | "info" | undefined,
  })

  const isReady = useAppSelector(selectMeIsReady)

  const [isCreating, setIsCreating] = useState(false)

  const [localSearch, setLocalSearch] = useState<Record<string, string>>({})

  const [remoteSearch, setRemoteSearch] = useState<Record<string, string>>({})

  const qGetUsers = useGetThingsQuery({
    params: {
      "ap.name": SharedAccessPatternSaEnum.SaGetUsers,
    },
  })

  const onSearchClick = useCallback(() => {
    // prefix "ap." on all keys
    const newRemoteSearch = {} as any
    // Object.keys(localSearch).forEach((key) => {
    //   newRemoteSearch[`ap.${key}`] = localSearch[key]
    // })
    if (localSearch.tenantName) {
      newRemoteSearch["ap.tenantName"] = `${localSearch.tenantName}`
    }
    if (localSearch.id) {
      newRemoteSearch["ap.ids[0]"] = localSearch.id
    }
    if (localSearch.mblsProviderId) {
      newRemoteSearch["ap.mblsProviderIds[0]"] = localSearch.mblsProviderId
    }

    setRemoteSearch(newRemoteSearch)
    // setLocalSearch({})
    // refetch()
  }, [localSearch])

  const params = useMemo(() => {
    // qGetUsers.refetch()
    return {
      "ap.name": SharedAccessPatternSaEnum.SaGetTenants,
      "ap.scanIndexForward": false,
      "ap.tenantOnly": true,
      ...remoteSearch,
    }
  }, [remoteSearch])

  const { isLoading, isFetching, refetch, data, isSuccess, isError } =
    useGetThingsQuery({
      params,
    })

  useEffect(() => {
    if (isFetching || isLoading) return
    // handle snackbar message, error, success, etc
    if (isError) {
      setSnackMessage({ message: "Error fetching tenants", severity: "error" })
    } else if (isSuccess) {
      setSnackMessage({ message: "Tenants fetched", severity: "success" })
    }
  }, [isError, isFetching, isLoading, isSuccess])

  const tenants = useMemo(() => (data?.things || []) as any as Tenant[], [data])

  const tenantIds = useMemo(() => tenants.map((t) => t.ownerId), [tenants])

  const ownerIdFilterFn = useCallback(
    (user: User) => tenantIds.includes(user.id),
    [tenantIds],
  )

  const owners = useAppSelector((s) =>
    selectThingsByThingTypeMrnFilter(s, UserThingTypeMrn, ownerIdFilterFn),
  ) as User[]

  const tenantsWithOwners = useMemo(() => {
    return tenants.map((t) => {
      return {
        ...t,
        owner: owners.find((o) => o.id === t.ownerId),
      }
    })
  }, [owners, tenants])

  const onFinish = useCallback(() => {
    refetch()
    setIsCreating(false)
  }, [refetch])

  const [selectedTenantId, setSelectedTenantId] = useState<string | null>(null)

  const columns = useCallback(
    (t: TFunction): DataGridProps["columns"] =>
      [
        {
          field: "name",
          headerName: t("name"),
          minWidth: defaultColWidth,
          renderCell: (params) => {
            const displayWarning =
              !params?.row?.smsExpeditorId || !params?.row?.emailExpeditorId
            return (
              <Stack direction="row" spacing={1} alignItems="center">
                <Preview
                  sx={{
                    cursor: "pointer",
                    color: "gray",
                  }}
                  onClick={() => setSelectedTenantId(params.row.id as string)}
                />
                <Link to={`/sa/tenants/${params.row.id}`}>
                  <Typography variant="body2" noWrap>
                    {params.value}
                  </Typography>
                </Link>
                {displayWarning && (
                  <Tooltip
                    title={<WarningInfo tenant={params.row as Tenant} />}
                    placement="top-start"
                    arrow
                  >
                    <Warning color="warning" fontSize="small" />
                  </Tooltip>
                )}
              </Stack>
            )
          },
        },
        {
          field: "description",
          headerName: t("description"),
          minWidth: defaultColWidth,
        },
        {
          field: "status",
          headerName: t("status"),
          minWidth: defaultColWidth,
          renderCell: (params) => {
            return (
              <Chip
                label={params.value}
                variant="outlined"
                size="small"
                color={params.value === "active" ? "primary" : "warning"}
              />
            )
          },
        },
        {
          field: "notifTemplateReadOnly",
          headerName: t("notifTemplateReadOnly"),
          minWidth: defaultColWidth,
          renderCell: (params) => {
            return (
              <Chip
                label={params.value ? "Yes" : "No"}
                variant={params.value ? "filled" : "outlined"}
                color={params.value ? "primary" : "default"}
              />
            )
          },
        },
        // appliedTemplatePreset
        {
          field: "appliedTemplatePreset",
          headerName: t("appliedTemplatePreset"),
          minWidth: defaultColWidth,
          renderCell: (params) => {
            if (!params.value) {
              return "-"
            }
            return (
              <Chip label={params.value} variant={"filled"} color={"primary"} />
            )
          },
        },

        {
          field: "owner",
          headerName: t("owner"),
          minWidth: defaultColWidth,
          renderCell: (params) => {
            const id = get(params, "row.ownerId")
            if (!id) return <Stack>{t("unknown")}</Stack>
            const email = get(params, "row.owner.email")
            const firstName = get(params, "row.owner.firstName")
            const lastName = get(params, "row.owner.lastName")
            return (
              <Stack direction="column" spacing={0}>
                <Typography variant="body1">{`${firstName} ${lastName}`}</Typography>
                <Typography variant="body2">
                  {" "}
                  <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
                    {email}
                  </a>
                </Typography>
              </Stack>
            )
          },
        },
        {
          field: "partnerName",
          headerName: t("partnerName"),
          minWidth: defaultColWidth,
        },
        {
          field: "mblsProviderId",
          headerName: t("mblsProviderId"),
          minWidth: defaultColWidth,
        },
        {
          field: "contactName",
          headerName: t("contactName"),
          minWidth: defaultColWidth,
        },
        {
          field: "contactEmail",
          headerName: `✉️ ${t("contactEmail")}`,
          minWidth: defaultColWidth,
          renderCell: (params) => {
            return (
              <a
                href={`mailto:${params.value}`}
                target="_blank"
                rel="noreferrer"
              >
                {params.value}
              </a>
            )
          },
        },

        {
          field: "createdAt",
          headerName: `📅 ${t("createdAt")}`,
          minWidth: defaultColWidth,
        },
        {
          field: "updatedAt",
          headerName: `📅 ${t("updatedAt")}`,
          minWidth: defaultColWidth,
        },
      ] as DataGridProps["columns"],
    [],
  )
  const handleKeyPress = useCallback(
    (
      e:
        | React.KeyboardEvent<HTMLDivElement>
        | React.KeyboardEvent<HTMLTextAreaElement>
        | React.KeyboardEvent<HTMLInputElement>,
    ) => {
      if (e.key === "Enter") {
        onSearchClick()
      }
    },
    [onSearchClick],
  )

  return (
    <SaLayout selectedMenuItemLabelKey="organizations">
      <Stack direction="column" spacing={4} p={2} pl={4} mt={4} width="100%">
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <Typography variant="h5">{`${t("tenants")} (${
            tenants?.length
          })`}</Typography>
          {!(isLoading || isFetching) && (
            <IconButton onClick={refetch}>
              <Refresh />
            </IconButton>
          )}
          {(isLoading || isFetching) && <CircularProgress />}
          <Sl2Btn variant="outlined" onClick={() => setIsCreating(true)}>
            {t("createTenant")}
          </Sl2Btn>
        </Stack>

        <Stack
          direction="row"
          spacing={1}
          sx={{
            maxWidth: 1000,
          }}
        >
          <TextField
            label={t("searchByName")}
            variant="outlined"
            fullWidth
            size={"small"}
            value={localSearch.tenantName}
            onChange={(e) =>
              setLocalSearch((prev) => ({
                ...prev,
                tenantName: e.target.value,
              }))
            }
            onKeyUp={handleKeyPress}
          />
          <TextField
            label={t("searchById")}
            variant="outlined"
            fullWidth
            size={"small"}
            value={localSearch.id}
            onChange={(e) =>
              setLocalSearch((prev) => ({
                ...prev,
                id: e.target.value,
              }))
            }
            onKeyUp={handleKeyPress}
          />
          <TextField
            label={t("searchByMblsProviderId")}
            variant="outlined"
            fullWidth
            size={"small"}
            value={localSearch.mblsProviderId}
            onChange={(e) =>
              setLocalSearch((prev) => ({
                ...prev,
                mblsProviderId: e.target.value,
              }))
            }
            onKeyUp={handleKeyPress}
          />
          <IconButton onClick={onSearchClick}>
            <Search />
          </IconButton>
        </Stack>
        <Stack direction="column" spacing={2} mt={2}>
          <Box maxWidth={800}>
            {isCreating && (
              <SaOrgForm
                isCreating={true}
                onFinish={onFinish}
                onCancel={() => setIsCreating(false)}
              />
            )}
          </Box>
          <Box sx={{}}>
            <DataGrid
              columns={columns(t)}
              rows={tenantsWithOwners}
              localeText={{
                noRowsLabel: t("noTenantsFound"),
              }}
              autoHeight
              rowSelection={false}
              sx={{
                // width: "100%",
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
            />
          </Box>
        </Stack>
      </Stack>
      {selectedTenantId && (
        <ObjectDisplayDrawer
          data={tenants.find((t) => t.id === selectedTenantId)}
          title={tenants.find((t) => t.id === selectedTenantId)?.name}
          onClose={() => setSelectedTenantId(null)}
        />
      )}
      <Snackbar
        open={!!snackMessage?.message}
        autoHideDuration={6000}
        onClose={() => setSnackMessage({ message: "", severity: undefined })}
      >
        <Alert severity={snackMessage.severity}>{snackMessage.message}</Alert>
      </Snackbar>
    </SaLayout>
  )
}

export default SaTenantsPage
