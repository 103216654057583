import { Check, CheckCircle } from "@mui/icons-material"
import {
  Box,
  Chip,
  CircularProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import { t } from "i18next"
import moment from "moment"
import { FC, PropsWithChildren, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { ActionReq } from "shared/actionReq"
import {
  ActionReqExecutionModeEnum,
  ActionReqThingTypeMrn,
  ActionReqTypeEnum,
} from "shared/actionReqData"
import { LockerDoorReservationSession } from "shared/lockerDoorReservationSession"
import { Reservation } from "shared/reservation"
import { SstDoor } from "shared/sst"
import { useCreateThingsMutation } from "src/features/things/thingsApi"
import { useReservation } from "src/hooks/useReservation"
import { v4 as uuid } from "uuid"
import { Sl2Btn } from "./Sl2Btn"

type ReservationStepProps = PropsWithChildren<{
  title: string
  status?: string
  id: number
  isLast?: boolean
}>
const ReservationStep: FC<ReservationStepProps> = ({
  title,
  status,
  id,
  children,
  isLast,
}) => {
  return (
    <Stack key={id} direction="column" spacing={0} alignItems="center">
      <Paper
        variant="outlined"
        sx={{
          p: 1,
          width: "100%",
          borderColor: status === "pending" ? "secondary.main" : "grey.300",
          borderWidth: status === "pending" ? "2px" : "1px",
        }}
      >
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box
              sx={{
                bgcolor: status === "done" ? "#43A047" : "grey.400",
                borderRadius: "50%",
                display: "flex",
                color: "white",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Check
                sx={{
                  fontSize: 18,
                }}
              />
            </Box>
            <Typography variant="body1" color="primary.main">
              {t(title)}
            </Typography>
            {/* {status && (
          <Box>
            <Chip label={status} />
          </Box>
        )} */}
          </Stack>
          {!isLast && (
            <Box
              sx={{
                pl: "13px",
              }}
            >
              <Box>
                {children && (
                  <Paper
                    sx={{
                      p: 1,
                      borderColor: "grey.100",
                      bgcolor: "grey.100",
                    }}
                    variant="outlined"
                  >
                    {children}
                  </Paper>
                )}
              </Box>
            </Box>
          )}
        </Stack>
      </Paper>
      {/* backgronud banner  */}
      <Box
        sx={{
          bgcolor: status === "done" ? "secondary.main" : "grey.100",
          width: "100px",
          height: isLast ? 50 : 10,
          borderRadius: isLast ? "0 0 50px 50px" : "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isLast && (
          <CheckCircle
            sx={{ color: status === "done" ? "whitesmoke" : "grey.400" }}
          />
        )}
      </Box>
    </Stack>
  )
}
export type OsLockerReservationStepperProps = {
  door: SstDoor
  reservation?: Reservation
  session?: LockerDoorReservationSession
}

export const OsLockerReservationStepper: FC<
  OsLockerReservationStepperProps
> = ({ door, reservation, session }) => {
  const { t } = useTranslation()
  const { isCompleted, hasExpired, hasDeposited, hasPickedUp } = useReservation(
    {
      reservation,
      session,
      door,
      hasDoorLink: !!door.links?.find((l) => l.rel === "reservation"),
    },
  )
  const [createThings] = useCreateThingsMutation()

  const onSendReminderClicked = useCallback(async () => {
    try {
      if (!reservation?.id) {
        console.error("No reservation id")
        return
      }
      const actionReq: Partial<ActionReq> = {
        thingTypeMrn: ActionReqThingTypeMrn,
        executionMode: ActionReqExecutionModeEnum.sync,
        tenantId: reservation.tenantId,
        idempotencyKey: uuid(),
        actionType: ActionReqTypeEnum.SendOrderPickupReminder,
        inputData: {
          tenantId: reservation.tenantId,
          reservationId: reservation?.id,
        },
      }

      await createThings({
        body: { things: [actionReq] },
        params: { tenantId: reservation.tenantId },
      }).unwrap()
    } catch (e: any) {
      console.error(e)
    }
  }, [createThings, reservation?.id, reservation?.tenantId])
  // sanity check
  if (!door) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  const sstReservation = reservation?.sstReservation
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Stack direction="column" spacing={0} px={2}>
        <ReservationStep
          title={"reservationCreation"}
          id={1}
          status={sstReservation?._created ? "done" : "pending"}
        >
          {sstReservation?._created && (
            <Stack direction="column" spacing={1}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2" color="primary.main">
                  {t("reservationCreatedAt")}
                </Typography>
                <Typography variant="body2" color="primary.main">
                  {"🕒  " +
                    moment(sstReservation?._created).format("YYYY-MM-DD HH:mm")}
                </Typography>
              </Stack>
              {reservation?.employeeCode && (
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body2" color="primary.main">
                    {t("employeeCode")}
                  </Typography>
                  <Chip label={reservation?.employeeCode} variant="filled" />
                </Stack>
              )}
            </Stack>
          )}
        </ReservationStep>
        <ReservationStep
          title={"parcelDeposit"}
          id={3}
          status={hasDeposited ? "done" : "pending"}
        >
          {sstReservation?.delivery_time && (
            <Stack direction="column" spacing={2}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body1" color="primary.main">
                  {t("parcelDeposited")}
                </Typography>
                <Typography variant="body1" color="primary.main">
                  {"🕒  " +
                    moment(sstReservation?.delivery_time).format(
                      "YYYY-MM-DD HH:mm",
                    )}
                </Typography>
              </Stack>
            </Stack>
          )}
        </ReservationStep>
        <ReservationStep
          title={"parcelPickUp"}
          id={4}
          status={
            hasPickedUp
              ? "done"
              : !hasExpired && hasDeposited
              ? "pending"
              : undefined
          }
        >
          {sstReservation?.reception_time ? (
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body1" color="primary.main">
                {t("parcelPickedUp")}
              </Typography>
              <Typography variant="body1" color="primary.main">
                {"🕒  " +
                  moment(sstReservation?.reception_time).format(
                    "YYYY-MM-DD HH:mm",
                  )}
              </Typography>
            </Stack>
          ) : !isCompleted && !hasExpired && hasDeposited ? (
            <Stack
              direction="column"
              spacing={2}
              alignItems="center"
              // justifyContent="space-between"
            >
              {(reservation?.nbReminderSent || 0) < 2 && (
                <Sl2Btn variant="outlined" onClick={onSendReminderClicked}>
                  {t("sendReminder")}
                </Sl2Btn>
              )}

              {reservation?.reminderLastSent && (
                <Typography variant="caption" color="primary.main">
                  {`${
                    t("reminderLastSent") +
                    " " +
                    moment(reservation?.reminderLastSent).format(
                      "YYYY-MM-DD HH:mm:ss",
                    )
                  }`}
                </Typography>
              )}
            </Stack>
          ) : null}
        </ReservationStep>
        {/* reveraiontFinished */}
        <ReservationStep
          title={"reservationFinished"}
          id={5}
          status={hasPickedUp ? "done" : undefined}
          isLast
        >
          {sstReservation?.status === "CLOSE" && (
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body1" color="primary.main">
                {t("reservationFinished")}
              </Typography>
              <Typography variant="body1" color="primary.main">
                {"🕒  " +
                  moment(sstReservation?.reception_time).format(
                    "YYYY-MM-DD HH:mm",
                  )}
              </Typography>
            </Stack>
          )}
        </ReservationStep>
      </Stack>
    </Box>
  )
}

export default OsLockerReservationStepper
