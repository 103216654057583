import TranslateIcon from "@mui/icons-material/Translate"
import {
  Box,
  Checkbox,
  Chip,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { isBoolean, noop } from "lodash"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { Configuration } from "shared/configuration"
import { OsConfigurationItemTemplate } from "./OsConfigurationItemTemplate"

type OsConfigurationItemProps = {
  config: Configuration
  onChange?: (config: Configuration) => void
}

export const OsConfigurationItem: FC<OsConfigurationItemProps> = ({
  config,
  onChange = noop,
}) => {
  const { t } = useTranslation()
  let value
  let content = (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography variant="body2">Value: {value}</Typography>
    </Stack>
  )

  switch (config.type) {
    case "number":
      value = config.nVal
      break
    case "string":
      value = config.sVal
      if (config.optionValues) {
        content = (
          <Box
            sx={{
              width: "300px",
            }}
          >
            <Select
              fullWidth
              size="small"
              // label="Select"
              required={config.isRequired}
              value={config.sVal}
              onChange={(e) => {
                onChange({
                  ...config,
                  sVal: e.target.value,
                })
              }}
            >
              {config.optionValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {t(option.label || "")}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )
      }
      break
    case "boolean":
      value = config.bVal ? "True" : "False"

      content = (
        <Box>
          <Checkbox
            color="primary"
            checked={isBoolean(config.bVal) ? config.bVal : false}
            onChange={(e) => {
              onChange({
                ...config,
                bVal: e.target.checked,
              })
            }}
          />
        </Box>
      )

      break
    case "object":
      value = JSON.stringify(config.oVal)
      break
    // Add more cases as needed...
    default:
      value = "Unknown type"
  }
  if (config.category === "template") {
    content = (
      <OsConfigurationItemTemplate config={config} onChange={onChange} />
    )
    // number
  } else if (config.type === "number") {
    content = (
      // react mui TextField
      <Box
        sx={{
          width: "300px",
        }}
      >
        <TextField
          fullWidth
          size="small"
          label="Number"
          type="number"
          required={config.isRequired}
          // min max
          inputProps={{
            inputMode: "numeric",
          }}
          value={
            config.nVal === null || config.nVal === undefined ? "" : config.nVal
          }
          onChange={(e) => {
            // check if config.min is defined
            if (config.min !== undefined) {
              if (Number(e.target.value) < config.min) {
                e.target.value = config.min.toString()
              }
            }
            // check if config.max is defined
            if (config.max !== undefined) {
              if (Number(e.target.value) > config.max) {
                e.target.value = config.max.toString()
              }
            }
            onChange({
              ...config,
              nVal: e.target.value === "" ? null : Number(e.target.value),
            })
          }}
        />
      </Box>
    )
  }

  return (
    <Box padding={2} borderBottom={1} borderColor="divider" marginBottom={2}>
      <Stack direction="row" spacing={2} alignItems="flex-start">
        <Box
          sx={{
            width: 600,
          }}
        >
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="body1" fontWeight="bold">
                {t(`${config.refName}.label`)}
              </Typography>
              <Chip
                label={t(`configType_${config.type}`)}
                variant="outlined"
                size="small"
              />
              {config.language && (
                <Chip
                  size="small"
                  variant="outlined"
                  label={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <TranslateIcon
                        sx={{
                          fontSize: 16,
                        }}
                      />
                      <Typography variant="body2">
                        {t(config.language)}
                      </Typography>
                    </Stack>
                  }
                />
              )}
              {/* <Typography variant="subtitle2" fontFamily="monospace">
        🔗 {config.refName}
      </Typography> */}
            </Stack>

            <Typography variant="body2">
              {t(`${config.refName}.description`)}
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            width: 800,
          }}
        >
          <Stack direction="column" spacing={2}>
            {content}
          </Stack>
        </Box>
      </Stack>
    </Box>
  )
}
