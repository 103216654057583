import { Chip } from "@mui/material"
import React from "react"

interface RectangularChipProps extends React.ComponentProps<typeof Chip> {}

const RectangularChip: React.FC<RectangularChipProps> = (props) => {
  return (
    <Chip
      {...props}
      sx={{
        ...props.sx,
        borderRadius: 1, // This makes the chip rectangular
      }}
    />
  )
}

export default RectangularChip
