import { Refresh, Search } from "@mui/icons-material"
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { DataGrid, DataGridProps } from "@mui/x-data-grid"
import { TFunction } from "i18next"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { EmailDelivery } from "shared/emailDelivery"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { useSearchParamsState } from "src/hooks/useSearchParamsState"
import { useGetThingsQuery } from "../features/things/thingsApi"
import SaNotifDeliveriesEmailDrawer from "./SaNotifDeliveriesEmailDrawer"

const defaultColWidth = 230

type SaNotifDeliveriesEmailProps = {}

export const SaNotifDeliveriesEmail: FC<SaNotifDeliveriesEmailProps> = ({}) => {
  const { t } = useTranslation()

  const [email, _setEmail] = useSearchParamsState("email", "")

  const [localEmail, setLocalEmail] = useState(email)

  const setEmail = useCallback(
    (val: string) => {
      setLocalEmail(val)
      _setEmail(val)
    },
    [_setEmail],
  )

  const params = useMemo(() => {
    // qGetUsers.refetch()
    return {
      "ap.name": SharedAccessPatternSaEnum.SaGetEmailDeliveries,
      "ap.scanIndexForward": true,
      "ap.email": email,
    }
  }, [email])

  const { isLoading, isFetching, refetch, data } = useGetThingsQuery(
    {
      params,
    },
    {
      skip: !email,
    },
  )

  const emailDeliveries = useMemo(
    () => (data?.things || []) as any as EmailDelivery[],
    [data],
  )

  const [selectedDeliveryId, setSelectedDeliveryId] = useSearchParamsState(
    "selectedDeliveryId",
    "",
  )
  const selectedDelivery = useMemo(
    () =>
      emailDeliveries.find((d) => d.id === selectedDeliveryId) as EmailDelivery,
    [selectedDeliveryId, emailDeliveries],
  )

  const columns = useCallback(
    (t: TFunction): DataGridProps["columns"] =>
      [
        // id
        {
          field: "id",
          headerName: t("id"),
          minWidth: 250,
          // rendercell for link style and setSelectedDeliveryId on click
          renderCell: (params) => {
            return (
              <Typography
                variant="caption"
                fontFamily={"monospace"}
                onClick={() => setSelectedDeliveryId(params.value as string)}
                sx={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {params.value}
              </Typography>
            )
          },
        },

        {
          field: "subject",
          headerName: t("subject"),
          minWidth: defaultColWidth,
        },
        {
          field: "body",
          headerName: t("body"),
          minWidth: defaultColWidth,
        },
        {
          field: "status",
          headerName: t("status"),
          minWidth: defaultColWidth,
          renderCell: (params) => {
            return (
              <Chip
                label={params.value}
                variant="outlined"
                size="small"
                color={"primary"}
              />
            )
          },
        },

        {
          field: "isSent",
          headerName: t("isSent"),
          minWidth: defaultColWidth,
        },
        {
          // sentAt
          field: "sentAt",
          headerName: t("sentAt"),
          minWidth: defaultColWidth,
          renderCell: (params) => {
            return params.value ? new Date(params.value).toLocaleString() : ""
          },
        },
        // fromEmail
        {
          field: "fromEmail",
          headerName: t("fromEmail"),
          minWidth: defaultColWidth,
        },
        // toEmail
        {
          field: "toEmail",
          headerName: t("toEmail"),
          minWidth: defaultColWidth,
        },
        // senderId
        {
          field: "senderId",
          headerName: t("senderId"),
          minWidth: defaultColWidth,
        },
        // receiveId
        {
          field: "receiverId",
          headerName: t("receiverId"),
          minWidth: defaultColWidth,
        },
        // retries
        {
          field: "retries",
          headerName: t("retries"),
          minWidth: defaultColWidth,
        },
        // emailProviderReponse
        {
          field: "emailProviderReponse",
          headerName: t("emailProviderReponse"),
          minWidth: defaultColWidth,
        },
      ] as DataGridProps["columns"],
    [setSelectedDeliveryId],
  )
  const handleKeyPress = useCallback(
    (
      e:
        | React.KeyboardEvent<HTMLDivElement>
        | React.KeyboardEvent<HTMLTextAreaElement>
        | React.KeyboardEvent<HTMLInputElement>,
    ) => {
      if (e.key === "Enter") {
        setEmail(localEmail)
      }
    },
    [localEmail, setEmail],
  )

  return (
    <Box>
      <Stack direction="column" spacing={4} p={2}>
        <Stack
          direction="column"
          spacing={1}
          // alignItems="center"
          // justifyContent="space-between"
        >
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <Typography variant="h6">{`${t("emailDeliveries")} (${
              emailDeliveries?.length
            })`}</Typography>
            {!(isLoading || isFetching) && (
              <IconButton onClick={refetch}>
                <Refresh />
              </IconButton>
            )}
            {(isLoading || isFetching) && <CircularProgress />}
          </Stack>
        </Stack>

        <Stack
          direction="row"
          spacing={1}
          sx={{
            maxWidth: 1000,
          }}
        >
          <TextField
            label={t("searchByEmail")}
            variant="outlined"
            fullWidth
            size={"small"}
            value={localEmail}
            onChange={(e) => setLocalEmail(e.target.value)}
            onBlur={() => setEmail(localEmail)}
            onKeyUp={handleKeyPress}
          />
          <IconButton onClick={refetch}>
            <Search />
          </IconButton>
        </Stack>
        <Stack direction="column" spacing={2} mt={2}>
          <Box sx={{}}>
            <DataGrid
              columns={columns(t)}
              rows={emailDeliveries}
              localeText={{
                noRowsLabel: t("noData"),
              }}
              autoHeight
              rowSelection={false}
              sx={{
                // width: "100%",
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
            />
          </Box>
        </Stack>
      </Stack>
      {selectedDelivery?.id && (
        <SaNotifDeliveriesEmailDrawer
          delivery={selectedDelivery}
          onClose={() => setSelectedDeliveryId("")}
        />
      )}
    </Box>
  )
}

export default SaNotifDeliveriesEmail
