import { useUser } from "@clerk/clerk-react"
import { Box, Button, Divider, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import defaultAvatar from "../assets/default_avatar.png"
import { Sl2Label } from "./Sl2Label"
import UsLayout from "./UsLayout"
import UserProfileForm from "./UserProfileForm"

export const UserProfilePage: FC = () => {
  const { user: clerkUser } = useUser()
  const { t } = useTranslation()
  return (
    <UsLayout selectedMenuItemLabelKey="Profile">
      <Stack direction="column" width="100%" p={1}>
        <Stack direction="column">
          <Typography variant="h4" noWrap>
            {t("profile")}
          </Typography>
          <Divider sx={{ mb: 2 }} />
        </Stack>
        <Stack direction="row" spacing={10} pt={3}>
          <Box width="600px">
            <UserProfileForm />
          </Box>
          {/* img circle */}
          <Stack direction="column" spacing={1} alignItems="center">
            <Sl2Label>{t("profilePicture")}</Sl2Label>
            <img
              alt="User"
              src={clerkUser?.imageUrl || defaultAvatar}
              style={{
                width: "240px",
                height: "240px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
            <Button
              variant="outlined"
              sx={{ textTransform: "none" }}
              size="small"
              disabled
            >
              Edit
            </Button>
          </Stack>
          {/* <Typography variant="h5" noWrap>
            {fullName}
            <Typography variant="body1" noWrap color="grey">
              {user?.email}
            </Typography>
          </Typography> */}
        </Stack>
      </Stack>
    </UsLayout>
  )
}

export default UserProfilePage
