import { Close, ExpandLess, ExpandMore, Fullscreen } from "@mui/icons-material"
import ListAltIcon from "@mui/icons-material/ListAlt"
import {
  Box,
  Chip,
  Divider,
  IconButton,
  Paper,
  Stack,
  SxProps,
  Typography,
} from "@mui/material"
import { t } from "i18next"
import { get } from "lodash"
import moment from "moment"
import { FC, useMemo, useState } from "react"
import { JsonView } from "react-json-view-lite"
import { MEvent } from "shared/mevent"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { getThingTypeLabel } from "shared/thingTypeData"
import { useGetThingsQuery } from "src/features/things/thingsApi"

type MeventsListProps = {
  mevents: MEvent[]
  sx?: SxProps
  onTraceIdClick?: (traceId: string) => void
}
export const MeventsList: FC<MeventsListProps> = ({
  mevents,
  sx,
  onTraceIdClick,
}) => {
  const [showDetailsId, setShowDetailsId] = useState<string | null>(null)

  const [selectedKeys, setSelectedKeys] = useState<string>("")

  const [selectedItemId, setSelectedItemId] = useState<any>(null)

  const qItem = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternSaEnum.GetItemByKeys,
        "ap.keys": selectedKeys,
      },
    },
    { skip: !selectedKeys },
  )
  const item = useMemo(() => qItem.data?.things[0], [qItem.data])

  return (
    <Paper
      sx={{
        p: 2,
        // maxHeight: "1000px",
        overflow: "auto",
        minWidth: "400px",
        ...sx,
      }}
      variant="outlined"
    >
      {mevents?.length > 0 ? (
        mevents.map((mevent) => (
          <Stack
            key={mevent.id}
            direction="row"
            spacing={0}
            alignItems={"flext-start"}
            width={"100%"}
          >
            <Box>
              <IconButton
                size="small"
                onClick={() =>
                  setShowDetailsId(
                    showDetailsId === mevent.id ? null : mevent.id,
                  )
                }
              >
                {showDetailsId === mevent.id ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Box>
            <Stack
              direction="column"
              spacing={0}
              width={"fit-content"}
              flexGrow={1}
            >
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                pt={1}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <Chip
                    label={t(getThingTypeLabel(mevent.targetThingTypeMrn))}
                    size="small"
                  />
                  <Typography variant="body2">
                    {moment(mevent.createdAt).fromNow()}
                  </Typography>
                </Stack>

                <Typography variant="body2">{t(mevent.type)}</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="caption" fontWeight="boldg">
                  Trace ID
                </Typography>
                <Typography
                  variant="caption"
                  onClick={() =>
                    mevent?.traceId && onTraceIdClick?.(mevent.traceId)
                  }
                  sx={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "darkblue",
                  }}
                >
                  {mevent.traceId && shortenString(mevent.traceId)}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center" py={2}>
                <Typography variant="body2">
                  {t(getThingTypeLabel(mevent.targetThingTypeMrn))}
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                  {get(mevent, "payload.displayName", "n/a")}
                </Typography>
                {/* suqare chip */}
                <Chip
                  label={t("more")}
                  icon={<Fullscreen />}
                  size="small"
                  variant="outlined"
                  color="primary"
                  sx={{
                    borderRadius: 1,
                    // backgroundColor: "lightgray",
                    color: "black",
                    // fontSize: "0.7rem",
                    // padding: "0.2rem",
                  }}
                  onClick={() => {
                    setSelectedKeys(
                      JSON.stringify(mevent.relatedThingKeys?.[0] || {}),
                    )
                    setSelectedItemId(mevent.id)
                  }}
                />
              </Stack>
              {showDetailsId === mevent.id && (
                <Stack direction="column" spacing={1}>
                  <Typography variant="caption">{t("details")}</Typography>
                  <Divider />
                  <JsonView data={mevent} />
                </Stack>
              )}

              {item && selectedKeys && selectedItemId === mevent.id && (
                <Stack direction="column" spacing={1}>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <IconButton
                      onClick={() => {
                        setSelectedKeys("")
                        setSelectedItemId(null)
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Stack>
                  <JsonView data={item} />
                </Stack>
              )}
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Typography variant="caption">{mevent.id}</Typography>
                <Typography variant="caption">
                  {moment(mevent.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                </Typography>
              </Stack>
              <Divider />
            </Stack>
          </Stack>
        ))
      ) : (
        <Stack direction="column" spacing={1} alignItems="center">
          <ListAltIcon
            fontSize={"large"}
            sx={{
              color: "gray",
            }}
          />
          <Typography variant="body1">{t("noEvents")}</Typography>
        </Stack>
      )}
    </Paper>
  )
}

// function that sorthen a string by taking the first 5 and last 5 characters and adding ... in between
const shortenString = (str: string, length: number = 7) => {
  if (str.length <= length * 2) {
    return str
  }
  return `${str.slice(0, length)}[...]${str.slice(-length)}`
}
