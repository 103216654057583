import CloseIcon from "@mui/icons-material/Close"
import InfoIcon from "@mui/icons-material/Info"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import Joi from "joi"
import { noop } from "lodash"
import { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { GroupTUThingTypeMrn } from "shared/groupTUData"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { TenantUser } from "shared/tenantUser"
import { TenantUserThingTypeMrn } from "shared/tenantUserData"
import { User } from "shared/user"
import { UserThingTypeMrn } from "shared/userData"
import { useAppSelector } from "../app/hooks"
import { selectTenantId } from "../features/me/meSlice"
import {
  useCreateThingsMutation,
  useGetThingsQuery,
} from "../features/things/thingsApi"
import {
  selectThingsByThingTypeMrn,
  selectThingsByThingTypeMrnFilter,
} from "../features/things/thingsSlice"
import FormFields, { FormField } from "./FormFields"
import { Sl2Btn } from "./Sl2Btn"
import { Sl2FormContainer } from "./Sl2FormContainer"

const schema = Joi.object({
  thingTypeMrn: Joi.string().required(),
  groupId: Joi.string().required(),
  tenantId: Joi.string().required(),
  userId: Joi.string().required(),
})
const defaultGroupTuCreate = {
  thingTypeMrn: GroupTUThingTypeMrn,
}
export type OsIamGroupMemberFormDialogProps = {
  groupId: string
  onClose?: () => void
  onComplete?: () => void
  existingMemberIds?: string[]
}
export const OsIamGroupMemberFormDialog: FC<
  OsIamGroupMemberFormDialogProps
> = ({
  onClose = noop,
  onComplete = noop,
  groupId,
  existingMemberIds = [],
}) => {
  const { t } = useTranslation()

  const [createThings, { isLoading: isCreatingThings }] =
    useCreateThingsMutation()

  const tenantId = useAppSelector(selectTenantId)

  const allUsers = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, UserThingTypeMrn),
  ) as User[]

  const tuFilterFn = useCallback(
    (t: TenantUser) => !existingMemberIds.includes(t?.userId),
    [existingMemberIds],
  )
  const tenantUsers = useAppSelector((s) =>
    selectThingsByThingTypeMrnFilter(s, TenantUserThingTypeMrn, tuFilterFn),
  ) as TenantUser[]

  const onSubmit = useCallback(
    async (data: any) => {
      const toCreate = {
        ...data,
        tenantUserId: tenantUsers.filter((tu) => tu.userId === data.userId)[0]
          .userId,
      }

      await createThings({
        body: { things: [toCreate] },
        params: { tenantId },
      }).unwrap()

      onComplete()
    },
    [createThings, onComplete, tenantId, tenantUsers],
  )

  const { isLoading, refetch } = useGetThingsQuery({
    params: {
      "ap.name": SharedAccessPatternOsEnum.GetUsersByTenant,
      "ap.tenantId": tenantId,
      "ap.excludeUserIds": existingMemberIds,
      tenantId,
    },
  })

  const { fields, noMemberToAdd } = useMemo(() => {
    const options = allUsers
      .filter((u) => !existingMemberIds.includes(u.id))
      .map((u) => ({
        id: u.id,
        label: (
          <Typography variant="body2">{`${u.firstName} ${u.lastName} (${u.email})`}</Typography>
        ),
      }))

    const noEmptyOptions = [{ id: "", label: t("noUsersToAdd") }]
    return {
      fields: [
        {
          name: "userId",
          fieldType: "select",
          options: options.length ? options : noEmptyOptions,
          label: t("member"),
          placeholder: t("selectMember"),
          required: true,
          size: "small",
          fullWidth: true,
          disabled: false,
          valueKey: "id",
        } as FormField,
        {
          name: "groupId",
          fieldType: "text",
          required: true,
          visible: false,
        } as FormField,
        {
          name: "tenantId",
          fieldType: "text",
          required: true,
          visible: false,
        } as FormField,
        {
          name: "thingTypeMrn",
          fieldType: "text",
          required: true,
          visible: false,
        } as FormField,
      ],
      noMemberToAdd: options.length === 0,
    }
  }, [allUsers, existingMemberIds, t])

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          minWidth={400}
        >
          <Typography> {t("addMember")}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="row"
          my={3}
          // pl={1}
          // pr={1}
          p={2}
          border="1px solid #e0e0e0"
          bgcolor="#f5f5f5"
        >
          <InfoIcon sx={{ mr: 3 }} color="success" />
          <Typography variant="body2">{t("groupDescription")}</Typography>
        </Stack>
        <Sl2FormContainer
          defaultValues={{ ...defaultGroupTuCreate, groupId, tenantId }}
          onError={(errors: any) => console.log(errors)}
          onSuccess={onSubmit}
          joiSchema={schema}
        >
          <FormFields fields={fields} />
          <Box sx={{ mt: 4 }} />
          <DialogActions>
            <Sl2Btn
              onClick={onClose}
              disabled={isCreatingThings}
              variant="outlined"
            >
              {t("cancel")}
            </Sl2Btn>
            <Sl2Btn
              type="submit"
              disabled={isCreatingThings || noMemberToAdd}
              variant="contained"
            >
              {t("add")}
            </Sl2Btn>
          </DialogActions>
        </Sl2FormContainer>
      </DialogContent>
    </Dialog>
  )
}
