export const ReservationStatusList = [
  "draft",
  "confirmed",
  "delievered",
  "closed",
  "expired",
  "cancelled",
  "deleted",
] as const;

export const ReservationThingTypeMrn = "mrn:sl2:::reservation";

// reservation language list
export const ReservationLanguageList = ["en", "es", "fr"];

export const ReservationActionList = [
  "openDoor",
  "showAdminCode",
  "showPickupCode",
  "markAsDeposited",
  "cancelReservation",
  "simulateExpiration",
  "simulatePayment",
  "amountUpdate",
] as const;

export const ReservationActionEnum = {
  openDoor: "openDoor",
  showAdminCode: "showAdminCode",
  showPickupCode: "showPickupCode",
  markAsDeposited: "markAsDeposited",
  cancelReservation: "cancelReservation",
  simulateExpiration: "simulateExpiration",
  simulatePayment: "simulatePayment",
  amountUpdate: "amountUpdate",
};

export const formatValueAmount = (value: string): string => {
  const cleanedValue = value.replace(/\D/g, "");

  if (cleanedValue.length === 0) {
    return "";
  }
  if (cleanedValue.length === 1) {
    return `0.0${cleanedValue}`;
  }
  if (cleanedValue.length === 2) {
    return `0.${cleanedValue}`;
  }

  // last 2 char
  const decimalPart = cleanedValue.slice(-2);

  // integer part
  const formattedIntegerPart = cleanedValue.slice(0, -2);

  if (formattedIntegerPart.length === 2 && formattedIntegerPart[0] === "0") {
    // remove leading 0
    return `${formattedIntegerPart[1]}.${decimalPart}`;
  }

  // Combine the integer and decimal parts
  const val = `${formattedIntegerPart}.${decimalPart}`;
  return val;
};
