import { useClerk } from "@clerk/clerk-react"
import { FC, useEffect } from "react"
import { useNavigate } from "react-router-dom"

export const SignOutPage: FC = () => {
  // sign out from clerk when navigating to this page
  const { signOut } = useClerk()
  const navigate = useNavigate()
  useEffect(() => {
    localStorage.removeItem("tenantId")
    signOut().then(() => navigate("/"))
  }, [navigate, signOut])
  return <>Signing out...</>
}
