import { Close } from "@mui/icons-material"
import { IconButton, Paper, Stack, Typography } from "@mui/material"
import { noop } from "lodash"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { Tenant } from "shared/tenant"

type SmsExpeditorTenantCardProps = {
  tenant: Tenant
  onRemove?: () => void
}

export const SmsExpeditorTenantCard: FC<SmsExpeditorTenantCardProps> = ({
  tenant,
  onRemove = noop,
}) => {
  const { t } = useTranslation()

  return (
    <Paper
      sx={{ p: 1, m: 1, borderLeft: "2px solid #3A5163" }}
      variant="outlined"
    >
      <Stack direction="column" spacing={1}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="body1" fontWeight="bold">
            {tenant.name}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="caption" color="textSecondary">
              {tenant.id}
            </Typography>
            <IconButton onClick={onRemove}>
              <Close />
            </IconButton>
          </Stack>
        </Stack>
        <Typography variant="body2">{tenant.description}</Typography>
        {tenant.mblsProviderId && (
          <Typography variant="body2">{`${t("mblsProvider")} ${
            tenant.mblsProviderId
          }`}</Typography>
        )}
      </Stack>
    </Paper>
  )
}

export default SmsExpeditorTenantCard
