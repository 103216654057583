import {
  List,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { FC, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Tenant } from "shared/tenant"

export const SaTenantList: FC<{
  tenants: Tenant[]
  isLoading: boolean
  selectedTenantId?: string
  onSelectTenant?: (tenant: Tenant) => void
}> = ({ tenants, isLoading, selectedTenantId, onSelectTenant }) => {
  const { t } = useTranslation()

  const [searchInput, setSearchInput] = useState<string>("")
  const filteredData = useMemo(() => {
    // filter by name and id
    return tenants.filter(
      (tenant) =>
        tenant.name.toLowerCase().includes(searchInput.toLowerCase()) ||
        tenant.id.toLowerCase().includes(searchInput.toLowerCase()),
    )
  }, [searchInput, tenants])
  const count = useMemo(() => {
    // return x / total if filteredata is not the same as tenants
    return filteredData.length === tenants.length
      ? `(${tenants.length})`
      : `(${filteredData.length}/${tenants.length})`
  }, [filteredData.length, tenants.length])
  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{
        borderRight: 2,
        borderColor: "divider",
        overflow: "auto",
        maxHeight: "85vh",
        minHeight: "85vh",
        // bgcolor: "red",
        minWidth: "300px",
        p: 1,
      }}
    >
      <Typography variant="body1">{t("tenants") + " " + count}</Typography>
      <TextField
        label={t("search")}
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        size="small"
        fullWidth
        sx={{
          mt: 3,
        }}
      />

      <List>
        {filteredData.map((tenant) => (
          <ListItemButton
            key={tenant.id}
            selected={selectedTenantId === tenant.id}
            onClick={() => onSelectTenant?.(tenant)}
          >
            <ListItemText primary={tenant.name} secondary={tenant.id} />
          </ListItemButton>
        ))}
      </List>
    </Stack>
  )
}

export default SaTenantList
