import AssessmentIcon from "@mui/icons-material/Assessment"
import PaymentIcon from "@mui/icons-material/Payment"
import SpeedIcon from "@mui/icons-material/Speed"
import {
  Box,
  CircularProgress,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material"
import { isUndefined } from "lodash"
import { FC, useCallback, useContext } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { ModeContext } from "src/ModeContext"
import useReportDatasets from "src/hooks/useReportDatasets"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady } from "../features/me/meSlice"
import OsLayout from "./OsLayout"
import { OsReportFilters } from "./OsReportFilters"
import OsReportingKPIs from "./OsReportingKPIs"
import OsReportingPayment from "./OsReportingPayment"
import OsReportingReservations from "./OsReportingReservations"

const SubPageTabMap: Record<string, number> = {
  kpis: 0,
  payment: 1,
  reservations: 2,
}

export const OsReportingPage: FC = () => {
  const { t } = useTranslation()

  const isReady = useAppSelector(selectMeIsReady)

  const { subPage = "kpis" } = useParams<{ subPage: string }>()

  const navigate = useNavigate()

  const location = useLocation()

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(
      `/os/reporting/${["kpis", "payment", "reservations"][newValue]}${
        location.search
      }`,
    )
  }

  const { refresh: refresh0, isLoading: isLoading0 } =
    useReportDatasets("ReservationSummary")

  const { refresh: refresh1, isLoading: isLoading1 } =
    useReportDatasets("ReservationPayment")

  const onRefresh = useCallback(() => {
    refresh0()
  }, [refresh0])

  const onRefreshPayment = useCallback(() => {
    refresh1()
  }, [refresh1])

  const mode = useContext(ModeContext)

  const isLoading = isLoading0 || isLoading1

  const onFilterChange = useCallback(() => {
    refresh0()
    refresh1()
  }, [refresh0, refresh1])

  return (
    <OsLayout
      selectedMenuItemLabelKey={subPage === "kpis" ? "KPIs" : "reporting"}
      hideMenu={mode === "embedded"}
    >
      <Stack direction="column" spacing={2}>
        <Stack
          direction="column"
          spacing={4}
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            px: 2,
            pt: 2,
          }}
        >
          <Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body1" fontWeight="bold">
                {t("reporting")}
              </Typography>
              {isLoading && <CircularProgress size={20} />}
            </Stack>
            <Typography variant="body2">
              {t("reporting.moduleDescription")}
            </Typography>
          </Box>
          <Box>
            <OsReportFilters onChange={onFilterChange} />
          </Box>
          <Tabs
            value={SubPageTabMap[subPage]}
            onChange={handleTabChange}
            sx={{
              height: 60,
            }}
          >
            <Tab
              label={t("reportKPIs")}
              icon={<SpeedIcon />}
              iconPosition="start"
              sx={{
                display: subPage === "kpis" ? "inherit" : "none",
              }}
            />

            <Tab
              label={t("payments")}
              icon={<PaymentIcon />}
              iconPosition="start"
              sx={{
                display: subPage !== "kpis" ? "inherit" : "none",
              }}
            />
            <Tab
              label={t("reservations")}
              icon={<AssessmentIcon />}
              iconPosition="start"
              sx={{
                display: subPage !== "kpis" ? "inherit" : "none",
              }}
            />
          </Tabs>
        </Stack>
        {!isReady && (
          <>
            <Stack direction="column" spacing={2}>
              <Skeleton variant="rectangular" width="100%" height={118} />
              <Skeleton variant="rectangular" width="100%" height={118 / 2} />
              <Skeleton variant="rectangular" width="100%" height={118} />
              <Skeleton variant="rectangular" width="100%" height={118 / 2} />
            </Stack>
          </>
        )}
        {isReady && (
          <Box p={3}>
            {
              {
                0: (
                  <OsReportingKPIs
                    onRefresh={onRefresh}
                    isLoading={isLoading}
                  />
                ),
                1: <OsReportingPayment onRefresh={onRefreshPayment} />,
                2: <OsReportingReservations onRefresh={onRefresh} />,
                "-1": <LoadingSpinner />,
              }[
                isUndefined(SubPageTabMap[subPage])
                  ? "-1"
                  : SubPageTabMap[subPage]
              ]
            }
          </Box>
        )}
      </Stack>
    </OsLayout>
  )
}

export default OsReportingPage

const LoadingSpinner = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 300,
      }}
    >
      <CircularProgress />
    </Box>
  )
}
