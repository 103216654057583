import { Close } from "@mui/icons-material"
import {
  Box,
  Chip,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { t } from "i18next"
import moment from "moment"
import { FC, useCallback, useMemo, useState } from "react"
import ObjectDisplayTable from "./ObjectDisplayTable"

type SstWebhooksProps = {
  data: Record<string, any>[]
  isLoading: boolean
}

export const SstWebhooks: FC<SstWebhooksProps> = ({ data = [], isLoading }) => {
  const [previewWhId, setPreviewWhId] = useState<string | null>(null)
  const [searchQuery, setSearchQuery] = useState<string>("")

  const previewWh = useMemo(() => {
    return data?.find((wh) => wh._id === previewWhId)
  }, [data, previewWhId])

  const onClick = useCallback((wh: any) => {
    setPreviewWhId(wh?._id)
  }, [])

  const filteredData = useMemo(() => {
    return data.filter(
      (wh) =>
        wh._id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        wh.url.toLowerCase().includes(searchQuery.toLowerCase()) ||
        wh.tag.toLowerCase().includes(searchQuery.toLowerCase()),
    )
  }, [data, searchQuery])

  if (!data) {
    // outlined paper with no data message
    return (
      <Paper variant="outlined" elevation={0} sx={{ p: 2 }}>
        No data
      </Paper>
    )
  }

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="body1" fontWeight="bold">
        {filteredData?.length === data?.length && (
          <> {`${t("webhooks")} (${data?.length})`}</>
        )}
        {filteredData?.length !== data?.length && (
          <> {`${t("webhooks")} (${filteredData?.length}/${data?.length})`}</>
        )}
      </Typography>
      <Box
        sx={{
          width: "500px",
        }}
      >
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          size="small"
          margin="normal"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>
      {filteredData.map((wh) => (
        <Stack direction="column" spacing={1} key={wh._id}>
          <Paper variant="outlined" elevation={0} sx={{ p: 2 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Chip label={wh.tag} size="small" />
              <Stack direction="column" spacing={1}>
                <Typography
                  variant="caption"
                  fontFamily="monospace"
                  sx={{
                    // link style
                    cursor: "pointer",
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                  onClick={() => onClick(wh)}
                >
                  {wh.url}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {`${t("created")} ${moment(wh._created).fromNow()}`}
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      ))}
      <Drawer
        anchor="right"
        open={!!previewWhId}
        onClose={() => setPreviewWhId(null)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "80vw",
            maxWidth: "1500px",
            maxHeight: "calc(100vh - 64px)",
            height: "calc(100vh - 64px)",
            paddingTop: "64px",
          },
        }}
      >
        <Stack direction="column" spacing={1} p={2}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body1" fontWeight="bold">
                Url
              </Typography>
              <Typography
                variant="body2"
                fontFamily="monospace"
                sx={{
                  color: "primary.main",
                }}
              >
                {previewWh?.url}
              </Typography>
            </Stack>
            <IconButton onClick={() => setPreviewWhId(null)}>
              <Close />
            </IconButton>
          </Stack>
          <Divider />

          <ObjectDisplayTable data={previewWh} />
        </Stack>
      </Drawer>
    </Stack>
  )
}

export default SstWebhooks
