export const LockerStatusList = ["online", "offline"] as const;

export const LockerThingTypeMrn = "mrn:sl2:::locker";

export const LockerProviders = ["sst"] as const;

export const LockerTypes = ["sst.pharma", "sst.food"] as const;

export const VirtualLockerActionList = [
  "Connect",
  "Disconnect",
  "OpenDoor",
  "CloseDoor",
  "SubmitCode",
  "ExpireDoorOpen",
  "ExpireReservation",
] as const;

export const VirtualLockerActionEnum = {
  Connect: "Connect",
  Disconnect: "Disconnect",
  OpenDoor: "OpenDoor",
  CloseDoor: "CloseDoor",
  SubmitCode: "SubmitCode",
  ExpireDoorOpen: "ExpireDoorOpen",
  ExpireReservation: "ExpireReservation",
} as const;
