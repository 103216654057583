import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople"
import GroupIcon from "@mui/icons-material/Group"
import VpnKeyIcon from "@mui/icons-material/VpnKey"
import { Box, Stack, Tabs, Typography } from "@mui/material"
import { FC, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Group } from "shared/group"
import { GroupThingTypeMrn } from "shared/groupData"
import { Thing } from "shared/thing"
import { User } from "shared/user"
import { UserThingTypeMrn } from "shared/userData"
import { useAppSelector } from "../app/hooks"
import { selectTenantId } from "../features/me/meSlice"
import { selectThingsByThingTypeMrnFilter } from "../features/things/thingsSlice"
import { OsGroupFormDialog } from "./OsGroupFormDialog"
import OsGroupPreviewDrawer from "./OsGroupPreviewDrawer"
import OsIamGroups from "./OsIamGroups"
import OsIamMembers from "./OsIamMembers"
import { OsIamPermissions } from "./OsIamPermissions"
import OsLayout from "./OsLayout"
import OsMemberPreviewDrawer from "./OsMemberPreviewDrawer"
import { Sl2Tab } from "./Sl2Tab"

export const OsIamPage: FC = () => {
  const { t } = useTranslation()
  const tenantId = useAppSelector(selectTenantId)
  const [selectedItem, setSelectedItem] = useState<{
    id: string
    type: "user" | "group"
  } | null>(null)

  const [isCreatingType, setIsCreatingType] = useState<"user" | "group" | null>(
    null,
  )

  const [selectedTab, setSelectedTab] = useState(0)
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedItem(null)
    setSelectedTab(newValue)
  }

  const filterFn = useCallback(
    (t: Thing) => t.id === selectedItem?.id,
    [selectedItem?.id],
  )

  const selectedUser = useAppSelector((s) =>
    selectThingsByThingTypeMrnFilter(s, UserThingTypeMrn, filterFn),
  )[0] as User | undefined

  const selectedGroup = useAppSelector((s) =>
    selectThingsByThingTypeMrnFilter(s, GroupThingTypeMrn, filterFn),
  )[0] as Group | undefined

  return (
    <OsLayout selectedMenuItemLabelKey="iamModule">
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          // fit-content
          // width: "fit-content",
          // width 100% vw - drawer width
          width: "calc(100vw - 140px)",
        }}
      >
        <Stack
          direction="column"
          spacing={1}
          p={2}
          pl={4}
          mt={4}
          // maxWidth={300}
          sx={{
            overflowX: "scroll",
          }}
        >
          <Stack
            direction="column"
            spacing={2}
            sx={{
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Typography variant="h5">{t("iamModule")}</Typography>
            <Typography variant="body1">{t("iamModuleDescription")}</Typography>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Sl2Tab
                label={t("members")}
                icon={<EmojiPeopleIcon />}
                iconPosition="start"
              />
              <Sl2Tab
                label={t("groups")}
                icon={<GroupIcon />}
                iconPosition="start"
              />
              <Sl2Tab
                label={t("permissions")}
                icon={<VpnKeyIcon />}
                iconPosition="start"
              />
            </Tabs>
          </Stack>
          <Box p={4} />
          <Stack direction="column" spacing={2}>
            {
              {
                0: (
                  <OsIamMembers
                    onMemberClick={(id) =>
                      setSelectedItem({ id, type: "user" })
                    }
                  />
                ),
                1: (
                  <OsIamGroups
                    onGroupClick={(id) =>
                      setSelectedItem({ id, type: "group" })
                    }
                    onCreate={() => setIsCreatingType("group")}
                  />
                ),
                2: <OsIamPermissions />,
              }[selectedTab]
            }
          </Stack>
        </Stack>

        {{
          user: selectedUser ? (
            <OsMemberPreviewDrawer
              user={selectedUser}
              onClose={() => setSelectedItem(null)}
            />
          ) : (
            <Box />
          ),
          group:
            selectedGroup && tenantId ? (
              <OsGroupPreviewDrawer
                group={selectedGroup}
                onClose={() => setSelectedItem(null)}
                tenantId={tenantId}
              />
            ) : (
              <Box />
            ),
          unknown: "",
        }[selectedItem?.type || "unknown"] || ""}
      </Stack>
      <Box>
        {{
          group: (
            <OsGroupFormDialog
              onClose={() => setIsCreatingType(null)}
              onComplete={() => {
                setIsCreatingType(null)
              }}
            />
          ),
          user: "",
          unknown: "",
        }[isCreatingType || "unknown"] || ""}
      </Box>
    </OsLayout>
  )
}

export default OsIamPage
