import { Box, Divider, Paper, Stack, Typography } from "@mui/material"
import { noop } from "lodash"
import { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Mention, MentionsInput } from "react-mentions"
import { Configuration } from "shared/configuration"
import { useAppSelector } from "src/app/hooks"
import { selectCurrentTenant } from "src/features/me/meSlice"

const getMentionInputStyle = (height: number, isReadOnly: boolean) => ({
  control: {
    backgroundColor: "#fff",
    fontSize: 14,
    fontWeight: "normal",
  },

  "&multiLine": {
    control: {
      // fontFamily: "monospace",
      minHeight: height,
      height,
      backgroundColor: isReadOnly ? "#f5f5f5" : "#fff",
    },

    highlighter: {
      padding: 9,
      border: "1px solid transparent",
    },
    input: {
      padding: 9,
      border: "1px solid silver",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      // border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
})

type OsConfigurationItemTemplateProps = {
  config: Configuration
  onChange?: (config: Configuration) => void
  showVars?: boolean
}

export const OsConfigurationItemTemplate: FC<
  OsConfigurationItemTemplateProps
> = ({ config, onChange = noop, showVars = true }) => {
  const { t } = useTranslation()
  const tenant = useAppSelector(selectCurrentTenant)
  // const [valueMessage, setMessage] = useState(config.bodyTemplate)
  // // "hello Id de commande @[Prénom](1) @[Nom de famille](2)\n\n @[Courriel](3) @[Id de commande](4) \n\n\n sdf\n ser\n\n\nMerci",

  // const [valueSubject, setSubject] = useState(
  //   // "Reçu de paiement [@[](1) @[Nom de famille](2)]",
  //   config?.objectTemplate,
  // )

  const handleChangeSubject = useCallback(
    (e: any, e2: any) => {
      // setSubject(e.target.value)
      onChange({
        ...config,
        objectTemplate: e.target.value,
      })
    },
    [config, onChange],
  )
  const handleChangeMessage = useCallback(
    (e: any, e2: any) => {
      // setMessage(e.target.value)

      onChange({
        ...config,
        bodyTemplate: e.target.value,
      })
    },
    [config, onChange],
  )

  const mentionData = useMemo(() => {
    return config.templateVars?.map((v) => ({
      id: v.id,
      display: v.name,
    }))
  }, [config.templateVars])
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        // fit content
        width: "fit-content",
      }}
    >
      <Box
        sx={{
          //fill available space
          width: "650px",
        }}
      >
        <Stack direction="column" spacing={1}>
          {config.hasObjectTemplate && (
            <>
              <Typography variant="body2" fontWeight="bold">
                {t("subject")}
              </Typography>
              <MentionsInput
                value={config.objectTemplate}
                onChange={handleChangeSubject}
                style={getMentionInputStyle(50, tenant?.notifTemplateReadOnly)}
                customSuggestionsContainer={(children) => {
                  return <Paper sx={{ p: 1 }}>{children} </Paper>
                }}
                readOnly={tenant?.notifTemplateReadOnly}
              >
                <Mention
                  trigger="@"
                  style={{ backgroundColor: "#cee4e5" }}
                  data={mentionData || []}
                  displayTransform={(id, display) => `@${display}`}
                  // renderSuggestion={}
                />
              </MentionsInput>
            </>
          )}
          <Typography variant="body2" fontWeight="bold">
            {t("message")}
          </Typography>
          <MentionsInput
            value={config.bodyTemplate}
            onChange={handleChangeMessage}
            style={getMentionInputStyle(300, tenant?.notifTemplateReadOnly)}
            customSuggestionsContainer={(children) => {
              return <Paper sx={{ p: 1 }}>{children} </Paper>
            }}
            readOnly={tenant?.notifTemplateReadOnly}
          >
            <Mention
              trigger="@"
              style={{ backgroundColor: "#cee4e5" }}
              data={mentionData || []}
              displayTransform={(id, display) => `@${display}`}
              // renderSuggestion={}
            />
          </MentionsInput>
        </Stack>
      </Box>
      {showVars && config.templateVars && (
        <Paper variant="outlined" sx={{ p: 1, borderTop: "3px solid #3A5163" }}>
          <Stack
            direction="column"
            spacing={2}
            sx={{
              width: "200px",
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              {t("availableVariables")}
              <Divider />
            </Typography>

            {config.templateVars.map((v) => (
              <Stack>
                <Typography key={v.id} variant="body2" fontWeight="bold">
                  {`@${v.name}`}
                </Typography>
                <Typography variant="caption">{v.description}</Typography>
              </Stack>
            ))}
          </Stack>
        </Paper>
      )}
    </Stack>
  )
}
