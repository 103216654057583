import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import {
  Box,
  Chip,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import moment from "moment"
import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { EmailDeliveryEvent } from "shared/emailDeliveryEvent"
import ObjectDisplayTable from "./ObjectDisplayTable"

type SaNotifDeliveriesEmailEventItemProps = {
  deliveryEvent: EmailDeliveryEvent
}

export const SaNotifDeliveriesEmailEventItem: FC<
  SaNotifDeliveriesEmailEventItemProps
> = ({ deliveryEvent }) => {
  const { t } = useTranslation()

  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Paper variant="outlined" sx={{ p: 1 }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <IconButton onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <Box
          sx={{
            width: "100px",
          }}
        >
          <Chip label={deliveryEvent.eventType} color="primary" />
        </Box>
        <Box
          sx={{
            width: "110px",
          }}
        >
          <Typography variant="body2">
            {moment(deliveryEvent.createdAt).fromNow()}
          </Typography>
        </Box>
        <Typography variant="body2">
          {moment(deliveryEvent.createdAt).format("YYYY-MM-DD HH:mm:ss")}
        </Typography>
      </Stack>
      {isExpanded && <Divider />}
      {isExpanded && <ObjectDisplayTable data={deliveryEvent} />}
    </Paper>
  )
}

export default SaNotifDeliveriesEmailEventItem
