import { Edit } from "@mui/icons-material"
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { usePrevious } from "@uidotdev/usehooks"
import Joi from "joi"
import { pick } from "lodash"
import { FC, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ConfigurationReservationActionRefNameEnum } from "shared/configurationData"
import { Reservation } from "shared/reservation"
import { formatValueAmount } from "shared/reservationData"
import { useUpdateThingsMutation } from "src/features/things/thingsApi"
import useTenantConfig from "src/hooks/useTenantConfig"
import { Sl2Btn } from "./Sl2Btn"

type OsReservationAmountEditProps = {
  reservation: Reservation
  visible?: boolean
}

const joiSchema = Joi.object({
  amountToCollect: Joi.number().required().min(0),
})

export const OsReservationAmountEdit: FC<OsReservationAmountEditProps> = ({
  reservation,
  visible = true,
}) => {
  const { t } = useTranslation()

  const configs = useTenantConfig(reservation.tenantId)

  const [isEditing, setIsEditing] = useState(false)

  const [localAmount, setLocalAmount] = useState<string>(
    reservation.amountToCollect + "",
  )

  const [updateThings, updateRes] = useUpdateThingsMutation()

  const [error, setError] = useState<string | null>(null)

  const [employeeCode, setEmployeeCode] = useState("")

  // clear amount with reservation change
  const prevReservationId = usePrevious(reservation.id)
  useEffect(() => {
    if (prevReservationId !== reservation.id) {
      setLocalAmount(reservation.amountToCollect + "")
      setError(null)
      setEmployeeCode("")
    }
  }, [prevReservationId, reservation.id, reservation.amountToCollect])

  const employeeCodeRequired =
    configs[
      ConfigurationReservationActionRefNameEnum
        .actionRequireEmployeeCode_amountUpdate
    ]?.bVal ?? true

  const handleSave = useCallback(async () => {
    // save the localAmount to the
    setError(null)
    const { error: joiError } = joiSchema.validate({
      amountToCollect: localAmount,
    })
    if (joiError) {
      setError(joiError.message)
      return
    }
    try {
      const patch = {
        ...pick(reservation, ["id", "thingTypeMrn", "tenantId"]),
        amountToCollect: parseFloat(localAmount),
      } as any

      if (employeeCode) {
        patch.updateFromEmployeeCode = employeeCode
      }
      await updateThings({
        body: {
          things: [patch],
        },
        params: { tenantId: reservation.tenantId },
      }).unwrap()

      setIsEditing(false)

      setSnackMessage({
        message: t("editAmountSuccess"),
        severity: "success",
      })
    } catch (error) {
      console.error(error)

      setError(t("editAmountError"))

      setSnackMessage({
        message: t("editAmountError"),
        severity: "error",
      })
    }
  }, [employeeCode, localAmount, reservation, t, updateThings])

  const [snackMessage, setSnackMessage] = useState({
    message: "",
    severity: undefined as "success" | "error" | undefined,
  })

  return (
    <Box
      sx={{
        display: visible ? "block" : "none",
      }}
    >
      {/* <IconButton  size="small"> */}
      <Edit
        onClick={() => setIsEditing(true)}
        sx={{
          color: "grey",
          fontSize: "1.2em",
          ":hover": {
            color: "black",
            cursor: "pointer",
          },
        }}
      />
      <Dialog
        open={isEditing}
        onClose={() => setIsEditing(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Stack direction="column" spacing={4}>
            <Box>
              <Typography variant="body1" fontWeight="bold">
                {t("editAmountTitle")}
              </Typography>
              <Divider />
            </Box>

            <Typography variant="body1">
              {t("editAmountDescription")}
            </Typography>
            {/* employee code textfield */}
            {employeeCodeRequired && (
              <Stack direction="column" spacing={1}>
                <Typography variant="body2" color={"primary"}>
                  {t("pleaseProvideEmployeeCode")}
                </Typography>
                <TextField
                  color="primary"
                  label={t("employeeCode")}
                  fullWidth
                  value={employeeCode}
                  onChange={(e) => setEmployeeCode(e.target.value)}
                  required
                  size="small"
                />
              </Stack>
            )}
            <TextField
              label={t("amountToCollect")}
              type="number"
              fullWidth
              disabled={
                updateRes.isLoading || (employeeCodeRequired && !employeeCode)
              }
              value={localAmount}
              onChange={(e) => {
                const val = formatValueAmount(e.target.value)
                setLocalAmount(val)
              }}
              onBlur={(e) => {
                // to fixed 2
                setLocalAmount(formatValueAmount(localAmount))
              }}
              required
              size="small"
              InputProps={{
                endAdornment: "CAD",
              }}
              inputProps={{
                // allow 0
                min: 0,
                step: 0.01,
              }}
              helperText={!!error && t("editAmountHelperText")}
              error={error !== null}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Sl2Btn onClick={() => setIsEditing(false)}>{t("cancel")}</Sl2Btn>
          <Sl2Btn
            variant="outlined"
            onClick={handleSave}
            color="primary"
            disabled={
              updateRes.isLoading || (employeeCodeRequired && !employeeCode)
            }
          >
            <Stack direction="row" spacing={2} alignItems="center">
              {updateRes.isLoading && (
                <CircularProgress size={16} color="inherit" />
              )}
              <span>{t("save")}</span>
            </Stack>
          </Sl2Btn>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackMessage.message !== ""}
        autoHideDuration={6000}
        onClose={() => setSnackMessage({ message: "", severity: undefined })}
      >
        <Alert severity={snackMessage.severity}>{snackMessage.message}</Alert>
      </Snackbar>
    </Box>
  )
}
