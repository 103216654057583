import { useAuth } from "@clerk/clerk-react"
import {
  Dns,
  FolderDelete,
  HistoryToggleOff,
  ListAlt,
} from "@mui/icons-material"
import BusinessIcon from "@mui/icons-material/Business"
import DatasetIcon from "@mui/icons-material/Dataset"
import EmailIcon from "@mui/icons-material/Email"
import EventAvailableIcon from "@mui/icons-material/EventAvailable"
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive"
import PeopleAtIcon from "@mui/icons-material/PeopleAlt"
import SmsIcon from "@mui/icons-material/Sms"
import WindowIcon from "@mui/icons-material/Window"
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import Toolbar from "@mui/material/Toolbar"
import { mqtt5 } from "aws-iot-device-sdk-v2"
import { t } from "i18next"
import * as React from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { appConfig } from "../appConfig"
import { useGetMeQuery } from "../features/me/meApi"
import {
  selectMe,
  selectMeIsReady,
  unSetTenantId,
} from "../features/me/meSlice"
import { MrtConnection } from "../iot/MrtConnection"
import MainAppbar from "./MainAppbar"
import { UnstyledLink } from "./UnstyledLink"

type SaMenuItem = {
  labelKey: string
  icon?: JSX.Element
  url: string
  divider?: boolean
  disabled?: boolean
  visible: boolean
}

export const SaMenuItems = [
  {
    labelKey: "organizations",
    icon: <BusinessIcon />,
    url: "/sa/tenants",
    divider: false,
    disabled: false,
    visible: true,
  },
  {
    labelKey: "users",
    icon: <PeopleAtIcon />,
    url: "/sa/users",
    divider: false,
    disabled: false,
    visible: true,
  },
  {
    labelKey: "orgMembers",
    icon: <PeopleAtIcon />,
    url: "/sa/org-members",
    divider: false,
    disabled: false,
    visible: true,
  },
  {
    labelKey: "lockers",
    icon: <WindowIcon />,
    url: "/sa/lockers",
    divider: false,
    disabled: false,
    visible: true,
  },
  {
    labelKey: "smsExpeditors",
    icon: <SmsIcon />,
    url: "/sa/sms-expeditors",
    divider: false,
    disabled: false,
    visible: true,
  },
  {
    labelKey: "emailExpeditors",
    icon: <EmailIcon />,
    url: "/sa/email-expeditors",
    divider: false,
    disabled: false,
    visible: true,
  },
  {
    labelKey: "reservations",
    icon: <EventAvailableIcon />,
    url: `/sa/reservations`,
    divider: false,
    disabled: false,
    visible: true,
  },
  {
    labelKey: "reservationAudit",
    icon: <HistoryToggleOff />,
    url: `/sa/reservation-audit?searchParams=%7B"type"%3A"byEvent"%2C"event"%3A"pickup"%7D`,
    divider: false,
    disabled: false,
    visible: true,
  },
  {
    labelKey: "meventLog",
    icon: <ListAlt />,
    url: "/sa/event-log",
    divider: false,
    disabled: false,
    visible: true,
  },
  {
    labelKey: "seedTrackers",
    icon: <DatasetIcon />,
    url: "/sa/seed-trackers",
    divider: false,
    disabled: false,
    visible: true,
  },
  {
    labelKey: "sstApi",
    icon: <Dns />,
    url: "/sa/sst",
    divider: false,
    disabled: false,
    visible: true,
  },
  {
    labelKey: "notifDeliveries",
    icon: <NotificationsActiveIcon />,
    url: "/sa/notif-deliveries?tab=0",
    divider: false,
    disabled: false,
    visible: true,
  },
  {
    labelKey: "deletedThings",
    icon: <FolderDelete />,
    url: "/sa/deleted-things",
    divider: false,
    disabled: false,
    visible: true,
  },
] as SaMenuItem[]
const SaMenu: React.FC<{ selectedMenuItemLabelKey?: string }> = ({
  selectedMenuItemLabelKey,
}) => {
  const { t } = useTranslation()

  return (
    <List sx={{ mt: 4 }}>
      {SaMenuItems.filter((i) => i.visible).map((item, index) => (
        <UnstyledLink to={item.url} key={index}>
          <ListItemButton
            divider={item.divider}
            disabled={item.disabled}
            selected={selectedMenuItemLabelKey === item.labelKey}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <ListItemIcon
                // black
                sx={{ color: "rgba(0, 0, 0, 0.74)" }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={t(item.labelKey)} />
            </Stack>
          </ListItemButton>
        </UnstyledLink>
      ))}
    </List>
  )
}

interface SaLayoutProps {
  children: React.ReactNode
  selectedMenuItemLabelKey?: string
}

const SaLayout: React.FC<SaLayoutProps> = ({
  children,
  selectedMenuItemLabelKey,
}) => {
  const { isLoaded, isSignedIn } = useAuth()

  const dispatch = useAppDispatch()

  const isReady = useAppSelector(selectMeIsReady)

  useGetMeQuery("noId", {
    skip: !isLoaded || !isSignedIn,
  })
  const { user } = useAppSelector(selectMe)

  React.useEffect(() => {
    dispatch(unSetTenantId())
  }, [dispatch])

  const selectedItem = SaMenuItems.filter(
    (item) => item.labelKey === selectedMenuItemLabelKey,
  )[0]
  return (
    <Stack
      direction="column"
      sx={{
        display: "flex",
        height: "100vh",
        width: "100vw",
      }}
    >
      <MainAppbar isSuperAdmin={true} />
      <Toolbar />

      {!(isLoaded || !isReady) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Stack direction="row">
        <Paper
          elevation={1}
          sx={{ width: appConfig.mainMenuWidth, height: "100vh" }}
        >
          <Stack direction="row" p={1} ml={1} spacing={1} alignItems="center">
            <>{selectedItem?.icon}</>
            <Typography variant="h6" noWrap p={1}>
              {t(selectedItem?.labelKey || "adminApp")}
            </Typography>
          </Stack>
          <Divider />
          <SaMenu selectedMenuItemLabelKey={selectedMenuItemLabelKey} />
        </Paper>
        <Box
          sx={{
            maxHeight: "100vh",
            overflow: "auto",

            width: `calc(100vw - ${appConfig.mainMenuWidth + 20}px)`,
          }}
        >
          {children}
        </Box>
      </Stack>
      {isReady && user?.id && (
        <MrtConnection
          tenantId={"noId"}
          subscribePacket={{
            subscriptions: [
              {
                topicFilter: `mrt/us/${user?.id}/#`,

                qos: mqtt5.QoS.AtLeastOnce,
              },
            ],
          }}
        />
      )}
    </Stack>
  )
}

export default SaLayout
