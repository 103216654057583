import { ArrowCircleRight } from "@mui/icons-material"
import { Avatar, IconButton, Stack, Typography } from "@mui/material"
import { noop } from "lodash"
import { FC } from "react"
import { Reservation } from "shared/reservation"

const highlightText = (text: string = "", highlight: string = "") => {
  if (!highlight) return [{ text, match: false }]
  const regex = new RegExp(`(${highlight})`, "gi")
  const parts = text.split(regex)
  return parts.map((part) => ({
    text: part,
    match: part.toLowerCase() === highlight.toLowerCase(),
  }))
}

type MblsReservationSearchBarItemProps = {
  reservation: Reservation
  input?: string
  onSelect?: () => void
}

export const MblsReservationSearchBarItem: FC<
  MblsReservationSearchBarItemProps
> = ({ reservation: r, input, onSelect = noop }) => {
  return (
    <Stack
      key={r.id}
      direction="row"
      alignItems={"center"}
      justifyContent={"space-between"}
      spacing={1}
      sx={{
        p: 1,
      }}
    >
      <Stack key={r.id} direction="row" alignItems={"center"} spacing={1}>
        <Avatar
          sx={{
            bgcolor: "secondary.main",
            color: "primary.main",
          }}
        >
          <Typography variant="body1">
            {r.firstName[0]}
            {r.lastName[0]}
          </Typography>
        </Avatar>
        <Stack direction="column" spacing={0}>
          <Typography variant="body1" fontWeight="bold">
            {highlightText(r.firstName, input).map((part, index) => (
              <span
                key={index}
                style={{
                  backgroundColor: part.match ? "#FFE3C8" : "inherit",
                }}
              >
                {part.text}
              </span>
            ))}{" "}
            {highlightText(r.lastName, input).map((part, index) => (
              <span
                key={index}
                style={{
                  backgroundColor: part.match ? "#FFE3C8" : "inherit",
                }}
              >
                {part.text}
              </span>
            ))}
          </Typography>
          <Typography variant="body2">
            {highlightText(r.email, input).map((part, index) => (
              <span
                key={index}
                style={{
                  backgroundColor: part.match ? "#FFE3C8" : "inherit",
                }}
              >
                {part.text}
              </span>
            ))}{" "}
            -{" "}
            {highlightText(r.phone, input).map((part, index) => (
              <span
                key={index}
                style={{
                  backgroundColor: part.match ? "#FFE3C8" : "inherit",
                }}
              >
                {part.text}
              </span>
            ))}
          </Typography>
        </Stack>
      </Stack>
      <IconButton onClick={onSelect}>
        <ArrowCircleRight
          sx={{
            color: "primary.main",
          }}
        />
      </IconButton>
    </Stack>
  )
}
