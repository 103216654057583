import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { AttributeFilter } from "shared/report"
import { ReportDataset } from "shared/report-datasets"
import type { RootState } from "../../app/store"

export interface ReportState {
  reservationFilters: AttributeFilter[]
  lockerIdFilters: string[]
  clientFilters: AttributeFilter[]
  datasets: Record<string, ReportDataset<any>>
  isLoading: boolean
}

// Define the initial state using that type
const initialState: ReportState = {
  reservationFilters: [],
  lockerIdFilters: [],
  clientFilters: [],
  datasets: {},
  isLoading: false,
}

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    updateReservationFilters: (
      state,
      action: PayloadAction<AttributeFilter[]>,
    ) => {
      state.reservationFilters = action.payload
    },
    updateLockerIdFilters: (state, action: PayloadAction<string[]>) => {
      state.lockerIdFilters = action.payload
    },
    updateClientFilters: (state, action: PayloadAction<AttributeFilter[]>) => {
      state.clientFilters = action.payload
    },
    upsertDatasets: (
      state,
      action: PayloadAction<Record<string, ReportDataset<any>>>,
    ) => {
      // upsert dataset in datasets
      state.datasets = {
        ...state.datasets,
        ...action.payload,
      }
    },
    setReportIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
})

export const {
  updateReservationFilters,
  updateLockerIdFilters,
  updateClientFilters,
  upsertDatasets,
  setReportIsLoading,
} = reportSlice.actions

export default reportSlice.reducer

// selectors
export const selectReport = (state: RootState) => state.report

export const selectReservationFilters = (state: RootState) =>
  state.report.reservationFilters

export const selectLockerIdFilters = (state: RootState) =>
  state.report.lockerIdFilters

export const selectClientFilters = (state: RootState) =>
  state.report.clientFilters

export const selectDatasetByName = (name: string) => (state: RootState) =>
  state.report.datasets[name] || { data: [] }

export const selectReportIsLoading = (state: RootState) =>
  state.report.isLoading
