import { MouseEvent, useState } from "react"

export const useDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return {
    anchorEl,
    open,
    handleClick,
    handleClose,
  }
}
