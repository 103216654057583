import { useMemo } from "react"
import { Reservation } from "shared/reservation"
import { ReservationThingTypeMrn } from "shared/reservationData"
import { useAppSelector } from "src/app/hooks"
import { selectThingsByThingTypeMrn } from "src/features/things/thingsSlice"

interface UseSearchReservationProps {
  input: string | undefined
}

const useSearchReservation = ({
  input,
}: UseSearchReservationProps): Reservation[] => {
  const reservations = useAppSelector(
    (s) => selectThingsByThingTypeMrn(s, ReservationThingTypeMrn),
    (a, b) => JSON.stringify(a) === JSON.stringify(b),
  ) as unknown as Reservation[]

  const filteredReservations = useMemo(() => {
    if (!input) return []
    return reservations.filter(
      (reservation) =>
        // filter firstName, lastName, email, phone
        reservation?.firstName
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            input
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, ""),
          ) ||
        reservation?.lastName
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            input
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, ""),
          ) ||
        reservation?.email?.toLowerCase().includes(input.toLowerCase()) ||
        reservation?.phone?.toLowerCase().includes(input.toLowerCase()),
    )
  }, [input, reservations])

  return filteredReservations
}

export default useSearchReservation
