import { Close, Refresh } from "@mui/icons-material"
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { DataGrid, DataGridProps } from "@mui/x-data-grid"
import { TFunction } from "i18next"
import { get } from "lodash"
import { FC, useCallback, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { EmailDelivery } from "shared/emailDelivery"
import { EmailDeliveryEvent } from "shared/emailDeliveryEvent"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { useSearchParamsState } from "src/hooks/useSearchParamsState"
import { useGetThingsQuery } from "../features/things/thingsApi"
import ObjectDisplayTable from "./ObjectDisplayTable"
import SaNotifDeliveriesEmailDrawer from "./SaNotifDeliveriesEmailDrawer"

const listOfEmailEventType = [
  "Bounce",
  "Complaint",
  "Delivery",
  "Send",
  "Reject",
  "Open",
  "Click",
  "RenderingFailure",
  "DeliveryDelay",
  "Subscription",
]

const defaultColWidth = 230

type SaNotifDeliveriesEmailEventsProps = {}

export const SaNotifDeliveriesEmailEvents: FC<
  SaNotifDeliveriesEmailEventsProps
> = ({}) => {
  const { t } = useTranslation()

  const [eventType, setEventType] = useSearchParamsState("eventType", "")

  useEffect(() => {
    // select Delivery if eventType is empty
    if (!eventType) {
      setEventType("Delivery")
    }
  }, [eventType])
  const params = useMemo(() => {
    // qGetUsers.refetch()
    return {
      "ap.name": SharedAccessPatternSaEnum.SaGetEmailDeliveriesByEventType,

      "ap.eventType": eventType,
    }
  }, [eventType])

  const { isLoading, isFetching, refetch, data } = useGetThingsQuery(
    {
      params,
    },
    {
      skip: !eventType,
    },
  )

  const emailDeliveryEvents = useMemo(
    () => (data?.things || []) as any as EmailDeliveryEvent[],
    [data],
  )

  const [selectedDeliveryEventId, setSelectedDeliveryEventId] =
    useSearchParamsState("selectedDeliveryEventId", "")

  const selectedDeliveryEvent = useMemo(
    () =>
      emailDeliveryEvents.find(
        (d) => d.id === selectedDeliveryEventId,
      ) as EmailDeliveryEvent,
    [emailDeliveryEvents, selectedDeliveryEventId],
  )

  // --
  const [selectedDeliveryId, setSelectedDeliveryId] = useSearchParamsState(
    "selectedDeliveryId",
    "",
  )
  const qEmailDelivery = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternSaEnum.SaGetEmailDeliverybyId,
        "ap.id": selectedDeliveryId,
      },
    },
    {
      skip: !selectedDeliveryId,
    },
  )

  const selectedDelivery = useMemo(
    () =>
      (qEmailDelivery?.data?.things || []).find(
        (d) => d.id === selectedDeliveryId,
      ) as EmailDelivery,
    [qEmailDelivery?.data?.things, selectedDeliveryId],
  )

  const columns = useCallback(
    (t: TFunction): DataGridProps["columns"] =>
      [
        // id
        {
          field: "id",
          headerName: t("id"),
          minWidth: 250,
          renderCell: (params) => {
            return (
              <Typography
                variant="caption"
                fontFamily={"monospace"}
                onClick={() =>
                  setSelectedDeliveryEventId(params.value as string)
                }
                sx={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {params.value}
              </Typography>
            )
          },
        },
        // emailDeliveryId, with clickable link
        {
          field: "emailDeliveryId",
          headerName: t("emailDeliveryId"),
          minWidth: 300,
          renderCell: (params) => {
            return (
              <Typography
                variant="caption"
                fontFamily={"monospace"}
                onClick={() => setSelectedDeliveryId(params.value as string)}
                sx={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {selectedDeliveryId === params.value &&
                (qEmailDelivery?.isFetching || qEmailDelivery?.isLoading) ? (
                  <CircularProgress />
                ) : (
                  <Typography variant="caption">{params.value}</Typography>
                )}
              </Typography>
            )
          },
        },

        {
          field: "source",
          headerName: t("source"),
          minWidth: defaultColWidth,
          renderCell: (params) => {
            return (
              <Typography variant="body2">
                {get(params, "row.detail.mail.source")}
              </Typography>
            )
          },
        },
        // destination
        {
          field: "destination",
          headerName: t("destination"),
          minWidth: defaultColWidth,
          renderCell: (params) => {
            return (
              <Typography variant="body2">
                {get(params, "row.detail.mail.destination")}
              </Typography>
            )
          },
        },
        // createdAt
        {
          field: "createdAt",
          headerName: t("createdAt"),
          minWidth: defaultColWidth,
        },
        // eventType
        {
          field: "eventType",
          headerName: t("eventType"),
          minWidth: defaultColWidth,
        },
      ] as DataGridProps["columns"],
    [setSelectedDeliveryEventId],
  )

  return (
    <Box>
      <Stack direction="column" spacing={4} p={2}>
        <Stack
          direction="column"
          spacing={1}
          // alignItems="center"
          // justifyContent="space-between"
        >
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <Typography variant="h6">{`${t("emailEvents", {
              count: emailDeliveryEvents?.length,
            })} `}</Typography>
            {!(isLoading || isFetching) && (
              <IconButton onClick={refetch}>
                <Refresh />
              </IconButton>
            )}
            {(isLoading || isFetching) && <CircularProgress />}
          </Stack>
        </Stack>

        <Stack
          direction="row"
          spacing={1}
          sx={{
            maxWidth: 1000,
          }}
        >
          {listOfEmailEventType.map((et) => (
            // Chip outlined if selected
            <Chip
              key={et}
              label={et}
              variant={et === eventType ? "filled" : "outlined"}
              onClick={() => setEventType(et)}
            />
          ))}
        </Stack>
        <Stack direction="column" spacing={2} mt={2}>
          <Box sx={{}}>
            <DataGrid
              columns={columns(t)}
              rows={emailDeliveryEvents}
              localeText={{
                noRowsLabel: t("noData"),
              }}
              autoHeight
              rowSelection={false}
              sx={{
                // width: "100%",
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
            />
          </Box>
        </Stack>
      </Stack>
      {selectedDelivery?.id && (
        <SaNotifDeliveriesEmailDrawer
          delivery={selectedDelivery}
          onClose={() => setSelectedDeliveryId("")}
        />
      )}
      {selectedDeliveryEventId && (
        <Drawer
          anchor="right"
          open={!!selectedDeliveryEventId}
          onClose={() => setSelectedDeliveryEventId("")}
          sx={{
            "& .MuiDrawer-paper": {
              width: "80vw",
              maxWidth: "1500px",
              maxHeight: "calc(100vh - 64px)",
              height: "calc(100vh - 64px)",
              paddingTop: "64px",
            },
          }}
        >
          <Stack direction="column" spacing={1} p={2}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{
                    color: "primary.main",
                  }}
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Typography variant="body1" fontWeight="bold">
                    {selectedDeliveryEvent?.id}
                  </Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {selectedDeliveryEvent?.createdAt}
                  </Typography>
                </Stack>
              </Stack>
              <IconButton onClick={() => setSelectedDeliveryEventId("")}>
                <Close />
              </IconButton>
            </Stack>
            <Divider />

            <ObjectDisplayTable data={selectedDeliveryEvent} />
          </Stack>
        </Drawer>
      )}
    </Box>
  )
}

export default SaNotifDeliveriesEmailEvents
