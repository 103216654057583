import Refresh from "@mui/icons-material/Refresh"
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { DataGrid, DataGridProps } from "@mui/x-data-grid"
import { TFunction } from "i18next"
import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { User } from "shared/user"
import { UserThingTypeMrn } from "shared/userData"
import { selectThingsByThingTypeMrn } from "src/features/things/thingsSlice"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady } from "../features/me/meSlice"
import { useGetThingsQuery } from "../features/things/thingsApi"
import SaLayout from "./SaLayout"
import SaUserDrawer from "./SaUserDrawer"
import { Sl2Btn } from "./Sl2Btn"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"

const defaultColWidth = 200
const columns = (t: TFunction): DataGridProps["columns"] => [
  { field: "firstName", headerName: t("firstName"), width: defaultColWidth },
  { field: "lastName", headerName: t("lastName"), width: defaultColWidth },
  {
    field: "email",
    headerName: `✉️ ${t("email")}`,
    width: defaultColWidth,
    renderCell: (params) => {
      return (
        <a href={`mailto:${params.value}`} target="_blank" rel="noreferrer">
          {params.value}
        </a>
      )
    },
  },
  {
    field: "status",
    headerName: t("status"),
    minWidth: defaultColWidth,
    renderCell: (params) => {
      return (
        <Chip
          label={params.value}
          variant="outlined"
          size="small"
          color={params.value === "active" ? "primary" : "warning"}
        />
      )
    },
  },
  {
    field: "externalId",
    headerName: t("externalId"),
    minWidth: defaultColWidth,
  },
  {
    field: "isSuperAdmin",
    headerName: `🔐 ${t("isSuperAdmin")}`,
    minWidth: defaultColWidth,
  },
  {
    field: "createdAt",
    headerName: `📅 ${t("createdAt")}`,
    minWidth: defaultColWidth,
  },
  {
    field: "updatedAt",
    headerName: `📅 ${t("updatedAt")}`,
    minWidth: defaultColWidth,
  },
]

export const SaUsersPage: FC = () => {
  const { t } = useTranslation()

  const isReady = useAppSelector(selectMeIsReady)

  const users = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, UserThingTypeMrn),
  ) as User[]

  const { isLoading, refetch } = useGetThingsQuery({
    params: {
      "ap.name": SharedAccessPatternSaEnum.SaGetUsers,
    },
  })

  const [createUserDrawerOpen, setCreateUserDrawerOpen] = useState(false)

  return (
    <SaLayout selectedMenuItemLabelKey="users">
      <Stack direction="column" spacing={4} p={2} pl={4} mt={4} width="100%">
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <Typography variant="h5">{t("users")}</Typography>
          {!isLoading && (
            <IconButton onClick={refetch}>
              <Refresh />
            </IconButton>
          )}
          {isLoading && <CircularProgress />}
          <Sl2Btn
            variant="outlined"
            onClick={() => setCreateUserDrawerOpen(true)}
          >
            {t("createUser")}
          </Sl2Btn>
        </Stack>
        <Stack>
          <Box sx={{}}>
            <DataGrid
              columns={columns(t)}
              rows={users}
              localeText={{
                noRowsLabel: t("noUsersFound"),
              }}
              autoHeight
              rowSelection={false}
              sx={{
                // width: "100%",
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
            />
          </Box>
        </Stack>
        {createUserDrawerOpen && (
          <SaUserDrawer
            open={true}
            onClose={() => setCreateUserDrawerOpen(false)}
          />
        )}
      </Stack>
    </SaLayout>
  )
}

export default SaUsersPage
