import { FC } from "react"

import { Container, Skeleton, Stack, Toolbar } from "@mui/material"

const LoadingPage: FC = () => {
  return (
    <Container maxWidth="md">
      <Toolbar />
      <Stack direction="row" spacing={2} width="100%">
        <Stack direction="column" spacing={2} width={"50%"}>
          <Skeleton variant="rectangular" width="100%" height={70} />
          <Skeleton variant="rectangular" width="100%" height={118} />
          <Skeleton variant="rectangular" width="100%" height={118} />
          <Skeleton variant="rectangular" width="100%" height={70} />
          <Skeleton variant="rectangular" width="100%" height={118} />
          <Skeleton variant="rectangular" width="100%" height={118} />
        </Stack>
        <Stack direction="column" spacing={2} width={"50%"}>
          <Skeleton variant="rectangular" width="100%" height={70} />
          <Skeleton variant="rectangular" width="100%" height={118} />
          <Skeleton variant="rectangular" width="100%" height={118} />
          <Skeleton variant="rectangular" width="100%" height={70} />
          <Skeleton variant="rectangular" width="100%" height={118} />
          <Skeleton variant="rectangular" width="100%" height={118} />
        </Stack>
      </Stack>
    </Container>
  )
}

export default LoadingPage
