import { ClerkProvider } from "@clerk/clerk-react"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import { ThemeProvider } from "@mui/material"
import { useCallback, useState } from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import { ModeProvider } from "./ModeProvider"
import { store } from "./app/store"
import { AuthProvider } from "./auth/AuthProvider"
import { DebugDialog } from "./components/DebugDialog"
import "./i18n/i18n"
import "./index.css"
import { MrtProvider } from "./iot/MrtContext"
import { lightTheme } from "./theme"
// Import your publishable key
const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY
if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}
window.addEventListener("beforeunload", (event) => {
  console.trace("Page is about to reload or navigate away")
})

declare global {
  interface Window {
    baseName: string
  }
}

const AppWrapper = () => {
  // @ts-ignore
  const version = import.meta.env.VITE_APP_VERSION

  // @ts-ignore
  const buildId = import.meta.env.VITE_APP_BUILD_ID

  console.log(`App version: ${version} Build ID: ${buildId}`)

  const [openDebugDialog, setOpenDebugDialog] = useState(false)

  const onClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (e?.detail === 5) setOpenDebugDialog(true)
  }, [])

  return (
    <div onClick={onClick}>
      <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
        <ThemeProvider theme={lightTheme}>
          <ModeProvider>
            <BrowserRouter basename={window.baseName}>
              <AuthProvider>
                <MrtProvider>
                  <App />

                  {openDebugDialog && (
                    <DebugDialog
                      onClose={() => setOpenDebugDialog(false)}
                      version={version}
                      buildId={buildId}
                    />
                  )}
                </MrtProvider>
              </AuthProvider>
            </BrowserRouter>
          </ModeProvider>
        </ThemeProvider>
      </ClerkProvider>
    </div>
  )
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <StrictMode>
  <Provider store={store}>
    <AppWrapper />
  </Provider>,
  // </StrictMode>,
)
