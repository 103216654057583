import {
  EntityId,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit"

import { get, merge } from "lodash-es"
import { SstReservation } from "shared/reservation"
import { SstLocker } from "shared/sst"
import { Thing } from "shared/thing"
import { type RootState } from "../../app/store"
import { partnersApi } from "./partnersApi"

export type SstThing = {
  _id: string
  sstType: "SstReservation" | "SstLocker"
}
export interface SstReservationThing extends SstReservation {
  _id: string
  sstType: "SstReservation"
}

export interface SstLockerThing extends SstLocker {
  _id: string
  sstType: "SstLocker"
}

export const sstThingsAdapter = createEntityAdapter<SstThing>({
  selectId: (thing) => thing._id,
})

const initialState = {
  ...sstThingsAdapter.getInitialState(),
}

export const sstThingsSlice = createSlice({
  name: "sstThings",
  initialState,
  reducers: {
    addMany: sstThingsAdapter.addMany,
    addOne: sstThingsAdapter.addOne,
    removeOne: sstThingsAdapter.removeOne,
    updateOne: sstThingsAdapter.updateOne,
    upsertOne: sstThingsAdapter.upsertOne,
    upsertMany: sstThingsAdapter.upsertMany,
    removeMany: sstThingsAdapter.removeMany,
    removeAll: sstThingsAdapter.removeAll,
    updateMany: sstThingsAdapter.updateMany,
    upsertOneDeep: (state, action) => {
      const existingThing = state.entities[action.payload._id]
      if (existingThing) {
        state.entities[action.payload._id] = action.payload
        return
      }
      sstThingsAdapter.addOne(state, action.payload)
    },
    setThingByType(state, action) {
      const newThings = action.payload?.things as SstThing[]
      if (!newThings) {
        return
      }
      const sstType = action.payload?.sstType

      // remove all reservations from state
      if (state?.ids?.length === 0) {
        return
      }
      const keys = state.ids.filter(
        (id) => state.entities[id]?.sstType === sstType,
      ) as EntityId[]

      sstThingsAdapter.removeMany(state, keys)
      sstThingsAdapter.addMany(state, newThings)
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      partnersApi.endpoints.getSstLockers.matchFulfilled,
      (state, { payload }) => {
        const fetchedsstThings = get(payload, "data", []) as Thing[]
        const sstThings = fetchedsstThings.map((thing) => ({
          _id: thing.id,
          sstType: "SstLocker",
          ...thing,
        })) as SstThing[]

        sstThings.forEach((thing) => {
          const existingThing = state.entities[thing._id]
          if (existingThing) {
            // Perform a deep merge of the existing and updated sstThings
            const mergedThing = merge({}, existingThing, thing)
            sstThingsAdapter.updateOne(state, {
              id: thing._id,
              changes: mergedThing,
            })
          } else {
            // If the thing doesn't exist yet, add it to the state
            sstThingsAdapter.addOne(state, thing)
          }
        })
      },
    )

    // getSstReservations
    builder.addMatcher(
      partnersApi.endpoints.getSstReservations.matchFulfilled,
      (state, { payload }) => {
        const fetchedsstThings = get(payload, "data", []) as Thing[]
        const sstThings = fetchedsstThings.map((thing) => ({
          _id: thing._id,
          sstType: "SstReservation",
          ...thing,
        })) as SstThing[]

        // remove all reservations from state
        const idsToRemove = state.ids.filter(
          (id) => state.entities[id]?.sstType === "SstReservation",
        ) as EntityId[]
        sstThingsAdapter.removeMany(state, idsToRemove)
        sstThingsAdapter.addMany(state, sstThings)
      },
    )
  },
})

export const sstThingsActions = sstThingsSlice.actions
export const {
  selectAll: selectAllsstThings,
  selectById: selectSstThingById,
  selectIds: selectThingIds,
  // Add other selectors here
} = sstThingsAdapter.getSelectors((state: RootState) => state.sstThings)

export default sstThingsSlice.reducer

export const selectSstLockers = createSelector(
  [(state: RootState) => selectAllsstThings(state)],
  (sstThings) => sstThings.filter((thing) => thing.sstType === "SstLocker"),
)

export const selectSstReservations = createSelector(
  [(state: RootState) => selectAllsstThings(state)],
  (sstThings) =>
    sstThings.filter((thing) => thing.sstType === "SstReservation"),
)
