import { useDispatch } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { thingsActions } from "src/features/things/thingsSlice"

const useClearMblsReservation = () => {
  const dispatch = useDispatch()
  const [search, setSearch] = useSearchParams()

  const clearMblsReservation = (postToParent: Boolean = true) => {
    dispatch(thingsActions.clearMblsReservation())
    // clear from url search params
    search.set("clientId", "")
    search.set("deliveryDate", "")
    search.set("timeConstraintStart", "")
    search.set("timeConstraintEnd", "")
    search.set("smartLockerNotificationPhone", "")
    search.set("smartLockerNotificationEmail", "")
    search.set("orderThirdPartyID", "")
    search.set("notes", "")
    setSearch(search)
    dispatch(thingsActions.clearClientSelectionDefaultValues())
    dispatch(thingsActions.setShowMblsReservationSearchBarCloseBtn(true))
    if (window.parent && postToParent) {
      window.parent.postMessage({ event: "closeModal" }, "*")
    }
  }

  return { clearMblsReservation }
}

export default useClearMblsReservation
