import { Box } from "@mui/material"
import { FC, useCallback, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { LockerDoorReservationSessionThingTypeMrn } from "shared/lockerDoorReservationSessionData"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import {
  selectMe,
  selectMeIsReady,
  selectTenantId,
} from "../features/me/meSlice"
import {
  useCreateThingsMutation,
  useDeleteThingsMutation,
  useGetThingsQuery,
} from "../features/things/thingsApi"
import {
  selectSelectedDoorReservation,
  thingsActions,
} from "../features/things/thingsSlice"
import OsLockerDoorDrawer from "./OsLockerDoorDrawer"

type OsLockerDoorDrawerManagerProps = {}

export const OsLockerDoorDrawerManager: FC<
  OsLockerDoorDrawerManagerProps
> = () => {
  const me = useAppSelector(selectMe)

  const { t } = useTranslation()

  const tenantId = useAppSelector(selectTenantId)

  const isReady = useAppSelector(selectMeIsReady)

  const [deleteThings] = useDeleteThingsMutation()

  const [createThings] = useCreateThingsMutation()

  const dispatch = useAppDispatch()

  const selectedDoorReservation = useAppSelector(selectSelectedDoorReservation)

  const { locker, session, reservation, door } = selectedDoorReservation

  const lockerId = locker?.id || "-1"

  useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetLockerCurrentReservations,
        "ap.tenantId": tenantId,
        "ap.lockerId": lockerId,
        "ap.limit": 2000,
        tenantId,
      },
    },
    {
      skip: !tenantId || !isReady || !lockerId || lockerId === "-1",
    },
  )

  const onRemoveReservationSession = useCallback(
    async (sessionId: string) => {
      const toDelete = {
        thingTypeMrn: LockerDoorReservationSessionThingTypeMrn,
        tenantId,
        lockerId,
        id: sessionId,
      }
      await deleteThings({
        body: { things: [toDelete] },
        params: { tenantId },
      }).unwrap()
    },
    [deleteThings, lockerId, tenantId],
  )

  const previousDoorId = useRef<string | undefined>(door?.door_id)
  useEffect(() => {
    const f = async () => {
      if (previousDoorId.current !== door?.door_id) {
        try {
          // create session for new selected door
          if (locker && door?.door_id && !reservation?.id) {
            const newSession = {
              thingTypeMrn: LockerDoorReservationSessionThingTypeMrn,
              tenantId,
              lockerId,
              sstLockerId: locker?.sstLockerId,
              sstDoorId: door?.door_id,
              sessionStart: new Date().getTime(),
              userId: me.user?.id,
              tuId: me.tu?.id,
            }
            await createThings({
              body: { things: [newSession] },
              params: { tenantId },
            }).unwrap()
          }
        } catch (e: any) {
          console.error(e)
        }
      }
      previousDoorId.current = door?.door_id
    }
    f()
  }, [
    createThings,
    door?.door_id,
    locker,
    lockerId,
    me.tu?.id,
    me.user?.id,
    onRemoveReservationSession,
    reservation?.id,
    session,
    session?.id,
    tenantId,
  ])

  const onClose = useCallback(
    async (sessionId?: string) => {
      if (sessionId) {
        try {
          await onRemoveReservationSession(sessionId)
        } catch (e: any) {
          console.error(e)
        }
      }
      dispatch(thingsActions.clearSelectedDoorReservation())
    },
    [dispatch, onRemoveReservationSession],
  )

  return (
    <Box>
      {locker?.id && door?.door_id && (
        <OsLockerDoorDrawer
          modName={`${t("module")} 1`}
          onClose={onClose}
          door={door}
          reservation={reservation}
          locker={locker}
          session={session}
          isCreateMode={!reservation?.id}
        />
      )}
    </Box>
  )
}

export default OsLockerDoorDrawerManager
