import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import Joi from "joi"
import { isEmpty } from "lodash"
import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { ReservationActionEnum } from "shared/reservationData"
import { Sl2Btn } from "./Sl2Btn"

type OsLockerOpenDoorDialogProps = {
  isOpenDoorDialogOpen?: boolean
  onClose: () => void
  onCancel: (employeeCode?: string) => void
  onOpenDoor: (employeeCode?: string) => void
  employeeCodeRequired?: boolean
}
const defaultJoiSchema = Joi.string().required().min(1).max(1000)

export const OsLockerOpenDoorDialog: FC<OsLockerOpenDoorDialogProps> = ({
  isOpenDoorDialogOpen = true,
  onClose,
  onCancel,
  onOpenDoor,
  employeeCodeRequired = true,
}) => {
  const { t } = useTranslation()

  const [code, setCode] = useState<string | undefined>()

  const [error, setError] = useState(false)

  const [touched, setTouched] = useState(false)

  return (
    <Dialog
      open={isOpenDoorDialogOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("openDoorConfirmation")}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack direction="column" spacing={4}>
          <DialogContentText id="alert-dialog-description">
            {t("openDoorConfirmationText")}
          </DialogContentText>
          {employeeCodeRequired && (
            <Stack direction="column" spacing={3}>
              <Typography variant="body1">{t("employeeCodeNeeded")}</Typography>
              <TextField
                //   inputRef={textFieldRef}
                autoFocus
                label={t("employeeCode")}
                value={code}
                onChange={(e) => {
                  setCode(e.target.value)

                  if (!touched) {
                    setTouched(true)
                  } else {
                    const { error: err } = defaultJoiSchema.validate(
                      e.target.value,
                    )

                    setError(isEmpty(err) ? false : true)
                  }
                }}
                error={touched && error}
                helperText={
                  touched && error ? t("pleaseProviderEmployeeCode") : ""
                }
              />
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Sl2Btn onClick={onClose} color="primary">
          {t("cancel")}
        </Sl2Btn>
        <Sl2Btn
          onClick={() => onCancel(code)}
          color="primary"
          autoFocus
          variant="outlined"
          disabled={employeeCodeRequired ? !!error || !touched : false}
        >
          {t(ReservationActionEnum.cancelReservation)}
        </Sl2Btn>
        <Sl2Btn
          onClick={() => onOpenDoor(code)}
          color="primary"
          autoFocus
          variant="contained"
          disabled={employeeCodeRequired ? !!error || !touched : false}
        >
          {t(ReservationActionEnum.openDoor)}
        </Sl2Btn>
      </DialogActions>
    </Dialog>
  )
}
