import Joi from "joi";

export const SharedSupportedThingTypeMrns = [
  "mrn:sl2:::tenant",
  "mrn:sl2:iam::group",
  "mrn:sl2:iam::groupTU",
  "mrn::::user",
  "mrn::::credential",
  "mrn:sl2:::tenantUser",
  "mrn:sl2:::actionReq",
  "mrn:sl2:::reservation",
  "mrn:sl2:::locker",
  "mrn:sl2:::configuration",
  "mrn:sl2:::client",
  "mrn:sl2:::smsExpeditor",
] as const;

export const sharedCreateThingsSchema = Joi.object({
  thingTypeMrn: Joi.string()
    .valid(...SharedSupportedThingTypeMrns)
    .required(),
}).unknown(true);
