import { Box, Skeleton, Stack } from "@mui/material"
import { noop } from "lodash"
import { FC, useEffect } from "react"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady } from "../features/me/meSlice"
import { selectDatasetByName } from "../features/report/reportSlice"
import OsReportReservationPaymentLastMonths from "./OsReportReservationPaymentLastMonths"
import OsReportingPaymentReservations from "./OsReportingPaymentReservations"
import OsReportingPaymentSummary from "./OsReportingPaymentSummary"

type OsReportingPaymentProps = {
  onRefresh?: () => void
  isLoading?: boolean
}
export const OsReportingPayment: FC<OsReportingPaymentProps> = ({
  onRefresh = noop,
  isLoading = false,
}) => {
  const isReady = useAppSelector(selectMeIsReady)

  const paymentSummary = useAppSelector(selectDatasetByName("paymentSummary"))
  const paymentLastXMonths = useAppSelector(
    selectDatasetByName("paymentLastXMonths"),
  )
  useEffect(() => {
    onRefresh()
  }, [onRefresh])

  return (
    <>
      {!isReady && (
        <>
          <Stack direction="column" spacing={2}>
            <Skeleton variant="rectangular" width="100%" height={118} />
            <Skeleton variant="rectangular" width="100%" height={118 / 2} />
            <Skeleton variant="rectangular" width="100%" height={118} />
            <Skeleton variant="rectangular" width="100%" height={118 / 2} />
          </Stack>
        </>
      )}
      {isReady && (
        <Stack direction={"column"} spacing={4}>
          <Box>
            <OsReportingPaymentSummary
              ds={paymentSummary}
              refresh={onRefresh}
              isLoading={isLoading}
            />
          </Box>
          <Box>
            <OsReportReservationPaymentLastMonths
              ds={paymentLastXMonths}
              refresh={onRefresh}
              isLoading={isLoading}
            />
          </Box>
          <OsReportingPaymentReservations />
        </Stack>
      )}
    </>
  )
}

export default OsReportingPayment
