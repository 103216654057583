import { Box } from "@mui/material"

const FlashingDots = () => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {[...Array(3)].map((_, index) => (
        <Box
          key={index}
          sx={{
            width: 6,
            height: 6,
            borderRadius: "50%",
            backgroundColor: "primary.main",
            margin: 0.4,
            animation: "flash 1.5s infinite",
            animationDelay: `${index * 0.5}s`,
          }}
        />
      ))}
      <style>
        {`
          @keyframes flash {
            0%, 100% { opacity: 0; }
            50% { opacity: 1; }
          }
        `}
      </style>
    </Box>
  )
}

export default FlashingDots
