import { useCallback, useEffect, useState } from "react"
import {
  MblsAddress,
  convertToMblsAddress,
} from "./convertArcgisCandidateToMblsAddress"

interface AddressCandidate {
  address: string
  location: {
    x: number
    y: number
  }
  score: number
  attributes: Record<string, any>
}

interface ArcgisFindAddressCandidatesHook {
  candidates: AddressCandidate[]
  mblsAddresses: MblsAddress[]
  isLoading: boolean
  error: string | null
  findCandidates: (singleLine: string, magicKey: string) => void
}

const useArcgisFindAddressCandidates = (): ArcgisFindAddressCandidatesHook => {
  const [candidates, setCandidates] = useState<AddressCandidate[]>([])

  const [mblsAddresses, setMblsAddresses] = useState<MblsAddress[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [error, setError] = useState<string | null>(null)

  const handleMessage = useCallback((event: MessageEvent) => {
    // assert
    if (event?.data === undefined) {
      console.error("useArcgisFindAddressCandidates: event.data is undefined")
      return
    }
    const { type, candidates, error } = event.data

    if (type === "arcgisAddressCandidates") {
      console.log("candidates", candidates)

      const mblsAddresses = candidates.map((c: any) => convertToMblsAddress(c))
      console.log("mblsAddresses", mblsAddresses)

      setCandidates(candidates)

      setMblsAddresses(mblsAddresses)

      setIsLoading(false)

      setError(error)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("message", handleMessage)
    return () => {
      window.removeEventListener("message", handleMessage)
    }
  }, [handleMessage])

  const findCandidates = useCallback((singleLine: string, magicKey: string) => {
    setIsLoading(true)

    setError(null)

    if (singleLine.length < 3) {
      setIsLoading(false)

      setCandidates([])

      setMblsAddresses([])
      return
    }
    window.parent.postMessage(
      {
        type: "getArcgisAddressCandidates",
        payload: {
          singleLine,
          magicKey,
          maxLocations: 1,
          outFields: "*",
          category: "Address,Postal",
          forStorage: true,
        },
      },
      "*",
    )
  }, [])

  return {
    candidates,
    mblsAddresses,
    isLoading,
    error,
    findCandidates,
  }
}

export default useArcgisFindAddressCandidates
