import { joiResolver } from "@hookform/resolvers/joi"
import { Typography } from "@mui/material"
import Joi from "joi"
import { useCallback, useState } from "react"
import { FormContainer, FormContainerProps } from "react-hook-form-mui"

export interface Sl2FormContainerProps extends FormContainerProps {
  joiSchema: Joi.ObjectSchema<any>
}

export const Sl2FormContainer: React.FC<Sl2FormContainerProps> = ({
  defaultValues,
  onError = console.error,
  onSuccess,
  joiSchema,
  children,
  ...rest
}) => {
  const [errors, setError] = useState<any>({})
  const [generalError, setGeneralError] = useState<string | null>(null)

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        setGeneralError(null)
        setError({})
        if (!onSuccess) return
        await onSuccess(data)
      } catch (e: any) {
        console.log(e)
        if (e?.data?.cause?.details) {
          const newErrors = {} as Record<string, any>
          e.data.cause.details.forEach((detail: any) => {
            if (!detail.path) return
            newErrors[detail.path[0]] = {
              type: detail.type,
              message: detail.message,
            }
          })
          setError(newErrors)
        } else {
          setGeneralError(
            e?.data?.cause?.message ||
              e?.data?.error ||
              e.message ||
              "An error occurred",
          )
        }
      }
    },
    [onSuccess],
  )

  return (
    <>
      <FormContainer
        defaultValues={defaultValues}
        onError={onError}
        onSuccess={onSubmit}
        resolver={joiResolver(joiSchema)}
        errors={errors}
        FormProps={{
          autoComplete: "off",
        }}
        {...rest}
      >
        {children}
      </FormContainer>
      {generalError && <Typography color="error">{generalError}</Typography>}
    </>
  )
}
