import { FC, PropsWithChildren } from "react"
import { Navigate, Outlet } from "react-router-dom"
import LoadingPage from "src/LoadingPage"

type ProtectedRouteProps = {
  isAllowed: boolean
  redirectPath?: string
  isReady?: boolean
  name?: string
}

export const ProtectedRoute: FC<PropsWithChildren<ProtectedRouteProps>> = ({
  isAllowed,
  redirectPath = "/login",
  isReady = true,
  children,
  name = "ProtectedRoute",
}) => {
  console.log(name, { isAllowed, redirectPath, isReady })
  if (!isReady) {
    return <LoadingPage />
  }
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />
  }

  return children ? children : <Outlet />
}
