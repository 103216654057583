import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material"
import { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Locker } from "shared/locker"
import { LockerThingTypeMrn } from "shared/lockerData"
import { AttributeFilter } from "shared/report"
import {
  clientFilterAttributes,
  reservationFilterAttributes,
} from "shared/reportData"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { useAppSelector } from "src/app/hooks"
import { selectTenantId } from "src/features/me/meSlice"
import { selectReportIsLoading } from "src/features/report/reportSlice"
import { useGetThingsQuery } from "src/features/things/thingsApi"
import { selectThingsByThingTypeMrn } from "src/features/things/thingsSlice"
import { getLockerDisplayValues } from "src/hooks/useLockerDisplayValues"
import { useReportFilter } from "src/hooks/useReportFilter"
import { AttributeFilterControllers } from "./AttributeFilterController"
import { Sl2Btn } from "./Sl2Btn"

type OsReportFiltersProps = {
  onChange: (value: {
    reservationFilters: AttributeFilter[]
    lockerIdFilters: string[]
    clientFilters: AttributeFilter[]
  }) => void
}

export const OsReportFilters: FC<OsReportFiltersProps> = ({
  onChange: onChangeProp,
}) => {
  const { t } = useTranslation()

  const tenantId = useAppSelector(selectTenantId)

  const {
    localFilters,
    setLocalFilters,
    applyFilters,
    addClientFilter,
    addReservationFilter,
    removeClientFilter,
    removeReservationFilter,
    errors,
    hasErrors,
    resetErrors,
  } = useReportFilter()

  const isReportLoading = useAppSelector(selectReportIsLoading)

  const onReset = useCallback(() => {
    window.location.href = `${window.location.pathname}?tenantId=${tenantId}`

    resetErrors()
  }, [resetErrors, tenantId])

  const qLockers = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetLockers,
        "ap.tenantId": tenantId,
        "ap.refreshSstLockers": true,
        tenantId,
      },
    },
    {
      skip: !tenantId,
    },
  )

  const _lockers = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, LockerThingTypeMrn),
  ) as Locker[]

  // sorted lockers by create at
  const lockers = useMemo(() => {
    return _lockers.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })
  }, [_lockers])

  const noFilterComp = useMemo(
    () => (title: string, onAdd: () => void) => {
      return (
        <Paper
          variant="outlined"
          sx={{
            p: 1,
            width: 400,
          }}
        >
          <Stack
            direction="column"
            spacing={1}
            p={1}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography variant="body2" color="textSecondary">
              {title}
            </Typography>
            <Sl2Btn onClick={onAdd}>{t("addNewFilter")}</Sl2Btn>
          </Stack>
        </Paper>
      )
    },
    [t],
  )

  const reservationFilterComp = useMemo(() => {
    return (
      <Stack direction="column" spacing={1}>
        <Typography variant="body2" fontWeight="bold">
          {t("reservation")}
        </Typography>

        {localFilters.reservationFilters.length === 0 &&
          noFilterComp(t("reservationFilterDescription"), () => {
            addReservationFilter({
              attribute: "",
              operator: "",
              value0: "",
            })
          })}
        {localFilters.reservationFilters.length > 0 && (
          <>
            {localFilters.reservationFilters.map((filter, index) => (
              <AttributeFilterControllers
                key={index}
                attributes={reservationFilterAttributes}
                value={filter}
                onChange={(value) => {
                  resetErrors()
                  const newFilters = [...localFilters.reservationFilters]
                  newFilters[index] = value
                  setLocalFilters((prev) => ({
                    ...prev,
                    reservationFilters: newFilters,
                  }))
                }}
                onDelete={() => {
                  removeReservationFilter(index)
                  resetErrors()
                }}
                error={errors.reservationFilters[index]}
              />
            ))}

            <Box>
              <Sl2Btn
                variant="outlined"
                fullWidth={false}
                size="small"
                onClick={() =>
                  addReservationFilter({
                    attribute: "",
                    operator: "",
                    value0: "",
                  })
                }
              >
                {t("add")}
              </Sl2Btn>
            </Box>
          </>
        )}
      </Stack>
    )
  }, [
    addReservationFilter,
    errors.reservationFilters,
    localFilters.reservationFilters,
    noFilterComp,
    removeReservationFilter,
    resetErrors,
    setLocalFilters,
    t,
  ])

  const clientsComp = useMemo(() => {
    return (
      <Stack direction="column" spacing={1}>
        <Typography variant="body2" fontWeight="bold">
          {t("clients")}
        </Typography>
        {localFilters.clientFilters.length === 0 &&
          noFilterComp(t("clientFilterDescription"), () => {
            addClientFilter({
              attribute: "",
              operator: "",
              value0: "",
            })
          })}
        {localFilters.clientFilters?.length > 0 && (
          <>
            {localFilters.clientFilters.map((filter, index) => (
              <AttributeFilterControllers
                key={index}
                attributes={clientFilterAttributes}
                value={filter}
                onChange={(value) => {
                  resetErrors()
                  const newFilters = [...localFilters.clientFilters]
                  newFilters[index] = value
                  setLocalFilters((prev) => ({
                    ...prev,
                    clientFilters: newFilters,
                  }))
                }}
                onDelete={() => {
                  removeClientFilter(index)
                  resetErrors()
                }}
                error={errors.clientFilters[index]}
              />
            ))}

            <Box>
              <Sl2Btn
                variant="outlined"
                fullWidth={false}
                size="small"
                onClick={() =>
                  addClientFilter({
                    attribute: "",
                    operator: "",
                    value0: "",
                  })
                }
              >
                {t("add")}
              </Sl2Btn>
            </Box>
          </>
        )}
      </Stack>
    )
  }, [
    addClientFilter,
    errors.clientFilters,
    localFilters.clientFilters,
    noFilterComp,
    removeClientFilter,
    resetErrors,
    setLocalFilters,
    t,
  ])

  const lockersComp = useMemo(() => {
    return (
      <Stack direction="column" spacing={1} className="uf-locker-filters" p={1}>
        <Typography variant="body2" fontWeight="bold">
          {`${t("lockers")} (${localFilters.lockerIdFilters.length}/${
            lockers.length
          })`}
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center" pl={1}>
          {/* 2 small typo caption  button 'all', 'none' */}
          <Typography
            variant="body2"
            sx={{
              textDecoration: "underline",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              setLocalFilters((prev) => ({
                ...prev,
                lockerIdFilters: lockers.map((locker) => locker.id),
              }))
            }}
          >
            {t("all")}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              textDecoration: "underline",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              setLocalFilters((prev) => ({
                ...prev,
                lockerIdFilters: [],
              }))
            }}
          >
            {t("none")}
          </Typography>
        </Stack>
        <Stack
          spacing={1}
          direction="column"
          sx={{
            maxHeight: 400,
            overflowY: "auto",
            bgcolor: "grey.100",
          }}
        >
          {/* skeleton if loadin */}
          {qLockers.isLoading && (
            <Stack direction="column" spacing={1} p={1}>
              <Skeleton variant="rectangular" width={200} height={20} />
              <Skeleton variant="rectangular" width={150} height={20} />
              <Skeleton variant="rectangular" width={200} height={20} />
              <Skeleton variant="rectangular" width={150} height={20} />
              <Skeleton variant="rectangular" width={200} height={20} />
              <Skeleton variant="rectangular" width={150} height={20} />
              <Skeleton variant="rectangular" width={200} height={20} />
              <Skeleton variant="rectangular" width={150} height={20} />
            </Stack>
          )}
          {/* if not loading */}

          {!qLockers.isLoading &&
            lockers?.length > 0 &&
            lockers.map((locker, index) => (
              <FormControlLabel
                key={locker.id}
                className="uf-locker-filter"
                label={
                  <Box
                    sx={{
                      width: "150px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Typography variant="body2">
                      {getLockerDisplayValues(locker).i18n.name}
                    </Typography>
                  </Box>
                }
                sx={{
                  width: 200,
                }}
                control={
                  <Checkbox
                    key={locker.id}
                    checked={localFilters.lockerIdFilters.includes(locker.id)}
                    inputProps={{ "aria-label": "select locker" }}
                    onChange={(e) => {
                      const newFilters = [...localFilters.lockerIdFilters]
                      if (e.target.checked) {
                        newFilters.push(locker.id)
                      } else {
                        const index = newFilters.indexOf(locker.id)
                        if (index > -1) {
                          newFilters.splice(index, 1)
                        }
                      }
                      setLocalFilters((prev) => ({
                        ...prev,
                        lockerIdFilters: newFilters,
                      }))
                    }}
                  />
                }
              />
            ))}
        </Stack>
      </Stack>
    )
  }, [
    localFilters.lockerIdFilters,
    lockers,
    qLockers.isLoading,
    setLocalFilters,
    t,
  ])

  const onApply = useCallback(() => {
    onChangeProp({
      reservationFilters: localFilters.reservationFilters,
      lockerIdFilters: localFilters.lockerIdFilters,
      clientFilters: localFilters.clientFilters,
    })
    applyFilters()
  }, [
    applyFilters,
    localFilters.clientFilters,
    localFilters.lockerIdFilters,
    localFilters.reservationFilters,
    onChangeProp,
  ])

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "inline-block",
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{
          bgcolor: "grey.100",
          py: 0,
          px: 2,
          width: "auto",
        }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="body1">{t("filters")}</Typography>
        {/* <Sl2Btn onClick={onReset}>{t("reset")}</Sl2Btn> */}
      </Stack>
      <Stack direction="column" spacing={1} sx={{ p: 2 }}>
        <Stack direction="row" spacing={3}>
          {lockersComp}
          {reservationFilterComp}
          {clientsComp}
          <Stack direction="column" spacing={2}></Stack>
        </Stack>

        <Box py={2} />
        <Divider />
        <Stack direction="row" spacing={1} alignItems="center">
          <Sl2Btn
            onClick={onApply}
            variant="contained"
            disabled={isReportLoading}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              {isReportLoading && <CircularProgress size={16} />}
              {t("apply")}
            </Stack>
          </Sl2Btn>
          <Sl2Btn onClick={onReset} variant="outlined">
            {t("reset")}
          </Sl2Btn>
          {hasErrors && (
            <Stack direction="column" spacing={1}>
              <Typography variant="body2" color="error">
                {t("pleaseFixFilterErrors")}
              </Typography>
              {/* <Typography variant="body2" color="error">
                {t("pleaseFixErrors")}
              </Typography> */}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Paper>
  )
}
