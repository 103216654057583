import { Refresh } from "@mui/icons-material"
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import moment from "moment"
import { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  ReservationAud,
  ReservationAudDetailsPaymentMade,
  ReservationAudDetailsUpdated,
} from "shared/reservationAud"
import { ReservationAudEventEnum } from "shared/reservationAudData"
import { OsReservationAuditTrailDetailsEmailSent } from "./OsReservationAuditTrailDetailsEmailSent"
import { OsReservationAuditTrailDetailsPaymentMade } from "./OsReservationAuditTrailDetailsPaymentMade"
import { OsReservationAuditTrailDetailsSmsSent } from "./OsReservationAuditTrailDetailsSmsSent"
import { OsReservationAuditTrailDetailsUpdated } from "./OsReservationAuditTrailDetailsUpdated"

type OsReservationAuditTrailListProps = {
  data: ReservationAud[]
  isLoading: boolean
  isFetching: boolean
  refetch: () => void
}
export const OsReservationAuditTrailList: FC<
  OsReservationAuditTrailListProps
> = ({ data = [], isLoading, isFetching, refetch }) => {
  const { t } = useTranslation()

  // sort createdAt desc
  const list = useMemo(() => {
    return data?.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })
  }, [data])

  return (
    <Paper variant="outlined" sx={{ p: 1, pb: 2 }}>
      <Stack direction="column" spacing={1}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Stack direction="column" spacing={1}>
            <Typography variant="body1">{t("auditTrail")}</Typography>
            <Typography variant="caption">{`${data?.length} ${t(
              "events",
            )}`}</Typography>
          </Stack>
          <IconButton
            size="small"
            onClick={() => refetch()}
            disabled={isLoading || isFetching}
          >
            {isLoading || isFetching ? <CircularProgress /> : <Refresh />}
          </IconButton>
        </Stack>
        <Divider />
        <Stack direction="column" spacing={0} pt={2}>
          {list?.map((ra, index) => (
            <Stack direction="row" spacing={1} alignItems="stretch">
              <Stack
                direction="column"
                spacing={1}
                alignItems="flex-end"
                width="80px"
              >
                <Typography variant="caption">
                  {moment(ra.createdAt).format("YYYY-MM-DD")}
                </Typography>
                <Typography variant="caption">
                  {moment(ra.createdAt).format("hh:mm:ss")}
                </Typography>
              </Stack>

              <Box>
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: "50%",
                    backgroundColor: "lightblue",
                    border: "1px solid lightblue",
                    mt: "3px",
                  }}
                />
                {/* line */}

                {index !== list.length - 1 && list.length !== 0 && (
                  <Box
                    sx={{
                      ml: "6px",
                      width: 0,
                      height: "100%",
                      // borderRadius: "50%",
                      // backgroundColor: "lightblue",
                      borderLeft: "1px solid lightblue",
                      position: "relative",
                    }}
                  />
                )}
              </Box>

              <Stack
                key={ra.id}
                direction="column"
                spacing={1}
                pb={6}
                width={"100%"}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body2" fontWeight="bold">
                    {t(ra.event)}
                  </Typography>
                  {ra.employeeCode && (
                    <Typography variant="body2">{`${t("employeeCode")}: ${
                      ra.employeeCode
                    }`}</Typography>
                  )}
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  // alignItems="center"
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Typography variant="caption">
                    {moment(ra.createdAt).fromNow()}
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    // height: "150px",
                    // width: "100%",
                    bgcolor: "#f9f9f9",
                    border: "1px solid #f0f0f0",
                    width: 380,
                    overflow: "auto",
                    maxHeight: 400,
                  }}
                >
                  {ra?.details?.diff && (
                    <OsReservationAuditTrailDetailsUpdated
                      detailsUpdated={
                        ra?.details?.diff as ReservationAudDetailsUpdated
                      }
                    />
                  )}

                  {ra?.event === ReservationAudEventEnum.emailSent && (
                    <OsReservationAuditTrailDetailsEmailSent aud={ra} />
                  )}

                  {ra?.event === ReservationAudEventEnum.smsSent && (
                    <OsReservationAuditTrailDetailsSmsSent aud={ra} />
                  )}
                  {ra?.event === ReservationAudEventEnum.paymentMade && (
                    <OsReservationAuditTrailDetailsPaymentMade
                      details={ra?.details as ReservationAudDetailsPaymentMade}
                    />
                  )}
                  {ra?.event === ReservationAudEventEnum.amountUpdated && (
                    <Stack direction="column" spacing={1} p={1}>
                      <Typography variant="body2" fontWeight="bold">
                        {t("amountUpdated")}
                      </Typography>
                      <Divider />
                      <Stack direction="row" spacing={1}>
                        <Typography variant="body2" fontWeight="bold">
                          {t("oldAmount")}:{" "}
                        </Typography>
                        <Typography variant="body2">
                          {ra?.details?.oldAmount || "-"}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Typography variant="body2" fontWeight="bold">
                          {t("newAmount")}:{" "}
                        </Typography>
                        <Typography variant="body2">
                          {ra?.details?.newAmount || "-"}
                        </Typography>
                      </Stack>
                    </Stack>
                  )}
                </Box>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Paper>
  )
}
