import { Close, Sms } from "@mui/icons-material"
import {
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import moment from "moment"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import {
  ReservationAud,
  ReservationAudDetailsSmsSent,
} from "shared/reservationAud"
import { ReservationAudEventEnum } from "shared/reservationAudData"
import { Sl2Btn } from "./Sl2Btn"
import { SmsPreview } from "./SmsPreview"

type OsReservationAuditTrailDetailsSmsSentProps = {
  aud: ReservationAud
}

export const OsReservationAuditTrailDetailsSmsSent: FC<
  OsReservationAuditTrailDetailsSmsSentProps
> = ({ aud }) => {
  const { t } = useTranslation()
  let { details } = aud

  const [showPreview, setShowPreview] = React.useState(false)

  if (!details || typeof details !== "object") {
    return null
  }
  // event type must be ReservationAudEventEnum.smsSent
  if (aud.event !== ReservationAudEventEnum.smsSent) {
    return null
  }

  details = details as ReservationAudDetailsSmsSent

  return (
    <Stack direction="column" spacing={1} p={1}>
      <Stack direction="column" spacing={1}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Sms fontSize="small" color="primary" />
            <Typography variant="body2" color="primary">
              {t("smsSent")}
            </Typography>
          </Stack>
          <Sl2Btn
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => setShowPreview(true)}
          >
            {t("preview")}
          </Sl2Btn>
        </Stack>
        <Divider />
        <Typography variant="body2" fontWeight="bold">
          {details?.templateName ? t(`${details?.templateName}.label`) : ""}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1} alignContent={"center"}>
        <Typography variant="caption">{t("sentFrom")}</Typography>
        <Typography variant="body2">{details?.fromPhoneNumber}</Typography>
      </Stack>
      <Stack direction="row" spacing={1} alignContent={"center"}>
        <Typography variant="caption">{t("sentTo")}</Typography>
        <Typography variant="body2">{details?.toPhoneNumber}</Typography>
      </Stack>
      <Divider />

      <Dialog
        open={showPreview}
        onClose={() => setShowPreview(false)}
        maxWidth="lg"
      >
        <DialogContent>
          <Stack direction="column" spacing={1}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Stack direction="column" spacing={1}>
                {details?.templateName && (
                  <Typography variant="body2" fontWeight="bold">
                    {details.templateName
                      ? t(`${details.templateName}.label`)
                      : ""}
                  </Typography>
                )}
                <Typography variant="caption">
                  {moment(aud.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                </Typography>
              </Stack>
              <IconButton size="small" onClick={() => setShowPreview(false)}>
                <Close fontSize="small" />
              </IconButton>
            </Stack>
            <SmsPreview
              toPhoneNumber={details?.toPhoneNumber}
              fromPhoneNumber={details?.fromPhoneNumber}
              message={details?.message}
              fullName={details?.fullName}
              deliveryId={details?.deliveryId}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </Stack>
  )
}
