import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Refresh,
} from "@mui/icons-material"
import {
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { MEvent } from "shared/mevent"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { SupportedThingTypeMrns } from "shared/thingTypeData"
import { useGetThingsQuery } from "src/features/things/thingsApi"
import { useSearchParamsState } from "src/hooks/useSearchParamsState"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady } from "../features/me/meSlice"
import { MeventsList } from "./MeventsList"
import SaLayout from "./SaLayout"

//  build list of object {value, label} with label replace : by _
const ThingTypeMrns = SupportedThingTypeMrns.map(
  (tt) =>
    ({
      value: tt,
      //replace all : by _
      label: tt.replace(/:/g, "_"),
    } as { value: string; label: string }),
)

export const SaMeventsPage: FC = () => {
  const { t } = useTranslation()

  const isReady = useAppSelector(selectMeIsReady)

  const [traceId, setTraceId] = useSearchParamsState("traceId", "")

  const [localTraceId, setLocalTraceId] = useState(traceId)

  const [thingType, setThingType] = useSearchParamsState("thingType", "")

  const qLog = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternSaEnum.SaGetMeventLogByTrace,
        "ap.traceId": traceId,
        "ap.limit": 400,
      },
    },
    { skip: !isReady || traceId === "" },
  )

  const mevents: MEvent[] = qLog.data?.things || []

  const qThingType = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternSaEnum.SaGetMeventLogByThingType,
        "ap.thingType": thingType,
        "ap.limit": 400,
      },
    },
    { skip: !isReady || !thingType },
  )

  const thingTypeEvents: MEvent[] = qThingType.data?.things || []

  const [thingTypeFilterExpanded, setThingTypeFilterExpanded] = useState(true)

  return (
    <SaLayout selectedMenuItemLabelKey="meventLog">
      <Stack
        direction="column"
        spacing={2}
        sx={{ p: 2, height: "fit-content" }}
      >
        <Stack direction="row" spacing={2} sx={{}}>
          <Stack direction="column" spacing={2}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
               <Typography variant="h6">{t("meventLog")}</Typography> 
            </Stack>
            <Typography variant="body2">{t("meventLogDescription")}</Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          spacing={1}
          alignItems="flex-start"
          justifyContent={"stretch"}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Stack direction="column" spacing={2} width={500}>
            <Typography variant="body1" fontWeight={"bold"}>
              {t("searchByTraceId")}
            </Typography>
            <Typography variant="body2">
              {t("searchByTraceIdDescription")}
            </Typography>
            <TextField
              label={t("traceId")}
              value={localTraceId}
              onChange={(e) => setLocalTraceId(e.target.value)}
              onBlur={() => {
                setTraceId(localTraceId)
              }}
              size="small"
            />

            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body1">{`${mevents?.length} events`}</Typography>
              <IconButton
                onClick={() => {
                  qLog.refetch()
                }}
              >
                {qLog.isLoading || qLog.isFetching ? (
                  <CircularProgress size={22} />
                ) : (
                  <Refresh />
                )}
              </IconButton>
            </Stack>
            <MeventsList mevents={mevents} />
          </Stack>
          <Stack direction="column" spacing={2}>
            <Typography variant="body1" fontWeight={"bold"}>
              {t("searchByThingType")}
            </Typography>
            <Typography variant="body2">
              {t("searchByThingTypeDescription")}
            </Typography>
            <Stack
              direction="column"
              spacing={1}
              bgcolor={"grey.100"}
              p={1}
              width="100%"
            >
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography variant="body2" fontWeight={"bold"}>
                  {t("thingType")}
                </Typography>
                {/* collapec expend button */}
                <IconButton
                  onClick={() => {
                    setThingTypeFilterExpanded(!thingTypeFilterExpanded)
                  }}
                >
                  {thingTypeFilterExpanded ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )}
                </IconButton>
              </Stack>
              {thingTypeFilterExpanded && (
                <FormGroup row>
                  {ThingTypeMrns.map(({ value, label }) => (
                    <FormControlLabel
                      key={value}
                      control={
                        <Checkbox
                          checked={thingType === value}
                          onChange={(e) => {
                            setThingType(e.target.checked ? value : "")
                          }}
                          name={value}
                        />
                      }
                      label={
                        <Typography variant="body2">{t(label)}</Typography>
                      }
                      sx={{
                        width: 300,
                      }}
                    />
                  ))}
                </FormGroup>
              )}
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body1">{`${thingTypeEvents?.length} events`}</Typography>
              <IconButton
                onClick={() => {
                  qThingType.refetch()
                }}
              >
                {qThingType.isLoading || qThingType.isFetching ? (
                  <CircularProgress size={22} />
                ) : (
                  <Refresh />
                )}
              </IconButton>
            </Stack>
            <MeventsList
              mevents={thingTypeEvents}
              onTraceIdClick={(traceId) => {
                setTraceId(traceId)
                setLocalTraceId(traceId)
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </SaLayout>
  )
}

export default SaMeventsPage
