import { Locker } from "shared/locker"
import i18n from "src/i18n/i18n"

const useLockerDisplayValues = (locker?: Locker) => {
  const lang = i18n.language

  const name =
    locker?.name ||
    locker?.sstLocker?.description ||
    locker?.sstLocker?._id ||
    "-"

  const displayValues = {
    name,
    description: locker?.description || locker?.sstLocker?.description || "-",
    address1: locker?.address1 || locker?.sstLocker?.address_1 || "-",
    address2: locker?.address2 || locker?.sstLocker?.address_2 || "-",
    city: locker?.city || locker?.sstLocker?.city || "-",
    province: locker?.province || locker?.sstLocker?.province || "-",
    country: locker?.country || locker?.sstLocker?.country || "-",
    postalCode: locker?.postalCode || locker?.sstLocker?.postal_code || "-",
    latitude: locker?.latitude || locker?.sstLocker?.latitude || "-",
    longitude: locker?.longitude || locker?.sstLocker?.longitude || "-",
    lockerLocation: locker?.lockerLocation || "-",
    displayOrder: locker?.displayOrder || "-",
    nbDoors: locker?.sstLocker?.doors?.length || "-",
    i18n: {
      name:
        (lang === "en"
          ? locker?.nameEn
          : lang === "fr"
          ? locker?.nameFr
          : locker?.name) ||
        name ||
        "-",
      lockerLocation:
        (lang === "en"
          ? locker?.lockerLocationEn
          : lang === "fr"
          ? locker?.lockerLocationFr
          : locker?.lockerLocation) || "-",
    },
  }

  return displayValues
}

export default useLockerDisplayValues

// standard function version
export const getLockerDisplayValues = (locker: Locker) => {
  const lang = i18n.language

  const name =
    locker?.name ||
    locker?.sstLocker?.description ||
    locker?.sstLocker?._id ||
    "-"

  const displayValues = {
    name,
    description: locker?.description || locker?.sstLocker?.description || "-",
    address1: locker?.address1 || locker?.sstLocker?.address_1 || "-",
    address2: locker?.address2 || locker?.sstLocker?.address_2 || "-",
    city: locker?.city || locker?.sstLocker?.city || "-",
    province: locker?.province || locker?.sstLocker?.province || "-",
    country: locker?.country || locker?.sstLocker?.country || "-",
    postalCode: locker?.postalCode || locker?.sstLocker?.postal_code || "-",
    latitude: locker?.latitude || locker?.sstLocker?.latitude || "-",
    longitude: locker?.longitude || locker?.sstLocker?.longitude || "-",
    lockerLocation: locker?.lockerLocation || "-",
    displayOrder: locker?.displayOrder || "-",
    nbDoors: locker?.sstLocker?.doors?.length || "-",
    i18n: {
      name:
        (lang === "en"
          ? locker?.nameEn
          : lang === "fr"
          ? locker?.nameFr
          : locker?.name) ||
        name ||
        "-",
      lockerLocation:
        (lang === "en"
          ? locker?.lockerLocationEn
          : lang === "fr"
          ? locker?.lockerLocationFr
          : locker?.lockerLocation) || "-",
    },
  }

  return displayValues
}
