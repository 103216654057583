import { Refresh } from "@mui/icons-material"
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import moment from "moment"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { ReportDataset } from "shared/report-datasets"
import { ChartsMonthNameMap, ChartsYearColorMap } from "src/charts/shared"

function formatData(data: Record<string, any>[]) {
  const formattedData = data.reduce((acc, curr) => {
    // Find the object for the current month
    let monthObj = acc.find(
      (m: Record<string, any>) => m.month === curr.created_at_month,
    )

    // If the object doesn't exist, create it
    if (!monthObj) {
      monthObj = { month: curr.created_at_month }
      acc.push(monthObj)
    }

    // Add the nb_reservations to the correct property
    const prop = `nb_reservations_${curr.created_at_year}`
    if (!monthObj[prop]) {
      monthObj[prop] = 0
    }
    monthObj[prop] += curr.nb_reservations

    return acc
  }, [])

  // sort  by month
  return formattedData
    .sort((a: { month: number }, b: { month: number }) => a.month - b.month)
    .map((d: { month: number }) => ({
      ...d,
      month: ChartsMonthNameMap[d.month],
    }))
}
export type OsReportReservationSummaryProps = {
  ds?: ReportDataset<any>
  isLoading: boolean
  refresh: () => void
}
export const OsReportReservationSummary: FC<
  OsReportReservationSummaryProps
> = ({ ds, isLoading, refresh }) => {
  const { t } = useTranslation()

  // Group data by year, month, and week, and sum nb_reservations
  let groupedData = [] as any[]
  let uniqYears = [] as any[]
  if (ds?.data) {
    groupedData = formatData(ds.data)
    // uniquer  year sorted desc
    uniqYears = Array.from(
      new Set(ds.data.map((dp: any) => dp.created_at_year)),
    ).sort((a, b) => a - b)
  }

  return (
    <Stack direction="column" spacing={4} p={2}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="column" spacing={1}>
          <Typography variant="body1" fontWeight={"bold"}>
            {t("reporting.reservationSummary.title")}
          </Typography>
          <Typography variant="body2">
            {t("reporting.reservationSummary.description")}
          </Typography>
        </Stack>
        <Stack direction="column" spacing={1} alignItems="flex-end">
          <IconButton onClick={refresh} disabled={isLoading}>
            <Refresh />
          </IconButton>
          <Typography variant="caption">
            {ds?.lastUpdated &&
              `${t("lastUpdated")}: ${moment(ds.lastUpdated).format(
                "YYYY-MM-DD HH:mm:ss",
              )}`}
          </Typography>
        </Stack>
      </Stack>
      <Box
        sx={{
          // width: "1000px",
          height: 300,
        }}
      >
        {groupedData.length > 0 ? (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart width={500} height={300} data={groupedData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              {uniqYears.map((year: any) => (
                <Line
                  key={year}
                  type="monotone"
                  dataKey={`nb_reservations_${year}`}
                  stroke={ChartsYearColorMap[year]}
                  activeDot={{ r: 8 }}
                  name={`Reservations ${year}`}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <Box
            sx={{
              //grey box
              width: "100%",
              height: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: "grey.100",
            }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Typography>{t("noData")}</Typography>
            )}
          </Box>
        )}
      </Box>
    </Stack>
  )
}

export default OsReportReservationSummary
