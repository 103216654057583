import { Box, Button, ButtonProps } from "@mui/material"
import { FC } from "react"

export const Sl2Btn: FC<
  ButtonProps & {
    boxWrapped?: boolean
  }
> = ({ sx, boxWrapped = false, ...rest }) => {
  return boxWrapped ? (
    <Box>
      <Button
        sx={{
          textTransform: "none",
          ...sx,
        }}
        {...rest}
      />
    </Box>
  ) : (
    <Button
      sx={{
        textTransform: "none",
        ...sx,
      }}
      {...rest}
    />
  )
}
