import { Skeleton, Stack } from "@mui/material"
import { FC } from "react"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady } from "../features/me/meSlice"
import OsReportingReservations from "./OsReportingReservations"

export const OsReportingPaymentReservations: FC = () => {
  const isReady = useAppSelector(selectMeIsReady)

  return (
    <>
      {!isReady && (
        <>
          <Stack direction="column" spacing={2}>
            <Skeleton variant="rectangular" width="100%" height={118} />
            <Skeleton variant="rectangular" width="100%" height={118 / 2} />
            <Skeleton variant="rectangular" width="100%" height={118} />
            <Skeleton variant="rectangular" width="100%" height={118 / 2} />
          </Stack>
        </>
      )}

      <OsReportingReservations
        reportType="PaymentReservationList"
        datasetName="paymentReservationList"
      />
    </>
  )
}

export default OsReportingPaymentReservations
