import { Refresh } from "@mui/icons-material"
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Client } from "shared/client"
import { ClientThingTypeMrn } from "shared/clientData"
import { useGetThingsQuery } from "src/features/things/thingsApi"
import { selectThingsByThingTypeMrn } from "src/features/things/thingsSlice"
import { useAppSelector } from "../app/hooks"
import { selectTenantId } from "../features/me/meSlice"
// import { OsClientItem } from "./OsClientItem"
import { DataGrid } from "@mui/x-data-grid"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import ClientDrawer from "./ClientDrawer"
import OsLayout from "./OsLayout"
import { Sl2Btn } from "./Sl2Btn"

export const OsClientPage: FC = () => {
  const { t } = useTranslation()
  const tenantId = useAppSelector(selectTenantId)

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [selectedClient, _setSelectedClient] = useState<Client | {}>({})
  const setSelectedClient = useCallback((client: Client | {}) => {
    _setSelectedClient(client)
    setDrawerOpen(true)
  }, [])

  const handleDrawerOpen = () => {
    setSelectedClient({})
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }
  const { isLoading, refetch } = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetClients,
        "ap.tenantId": tenantId,
        tenantId,
      },
    },
    {
      skip: !tenantId,
    },
  )
  const clients = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, ClientThingTypeMrn),
  ) as Client[] | undefined
  // firstName, lastName, email, phone, status, mblsClientId, notes
  const columns = useMemo(
    () => [
      {
        field: "firstName",
        headerName: t("firstName"),
        width: 150,
      },
      {
        field: "lastName",
        headerName: t("lastName"),
        width: 150,
      },
      {
        field: "email",
        headerName: t("email"),
        width: 200,
      },
      {
        field: "phone",
        headerName: t("phone"),
        width: 150,
      },
      {
        field: "status",
        headerName: t("status"),
        width: 150,
      },
      {
        field: "mblsClientId",
        headerName: t("mblsClientId"),
        width: 150,
      },
      {
        field: "notes",
        headerName: t("notes"),
        width: 150,
      },
    ],
    [t],
  )
  return (
    <OsLayout selectedMenuItemLabelKey="clientModule">
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Stack direction="column" spacing={5} p={2} pl={4} mt={4}>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="h5">{t("clientModule")}</Typography>
              {isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <IconButton
                  onClick={() => refetch()}
                  color="primary"
                  size="small"
                >
                  <Refresh />
                </IconButton>
              )}
            </Stack>
            <Typography variant="body1">
              {t("clientModuleDescription")}
            </Typography>
          </Stack>
          <Box>
            <Sl2Btn
              variant="contained"
              color="primary"
              onClick={handleDrawerOpen}
            >
              {t("addClient")}
            </Sl2Btn>
          </Box>
          <Box
            sx={{
              mt: 6,
              width: "fit-content",
              minHeight: 300,
            }}
          >
            <DataGrid
              columns={columns}
              rows={clients || []}
              localeText={{
                noRowsLabel: isLoading ? "Loading ..." : t("noClients"),
              }}
              autoHeight
              rowSelection={true}
              onRowClick={(row) => setSelectedClient(row.row as Client)}
              sx={{
                // disable cell selection style
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                // pointer cursor on ALL rows
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
            />
          </Box>
        </Stack>
        {drawerOpen && (
          <ClientDrawer
            open={true}
            onClose={handleDrawerClose}
            client={selectedClient}
          />
        )}
      </Stack>
    </OsLayout>
  )
}

export default OsClientPage
