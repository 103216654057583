import { useCallback, useMemo } from "react"
import { Configuration } from "shared/configuration"
import { ConfigurationThingTypeMrn } from "shared/configurationData"
import { useAppSelector } from "src/app/hooks"
import { RootState } from "src/app/store"
import { selectThingsByThingTypeMrnFilter } from "src/features/things/thingsSlice"

const useTenantConfig = (tenantId: string = "-1") => {
  // Create a filter to select all configurations for the tenant
  const configFilter = useCallback(
    (thing: Configuration) => thing.tenantId === tenantId,
    [tenantId],
  )

  // Select all configurations for the tenant
  const allConfigs = useAppSelector((s: RootState) =>
    selectThingsByThingTypeMrnFilter(
      s,
      ConfigurationThingTypeMrn,
      configFilter,
    ),
  ) as unknown as Configuration[]

  // Construct the resulting object
  const configs = useMemo(() => {
    return allConfigs.reduce((acc, config) => {
      acc[config.refName] = config
      return acc
    }, {} as Record<string, Configuration>)
  }, [allConfigs])

  return configs
}

export default useTenantConfig
