import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import Joi from "joi"
import { isEmpty } from "lodash"
import { FC, PropsWithChildren, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"

import { Sl2Btn } from "./Sl2Btn"

const HoritzontalDivider: FC<{ text: string }> = ({ text }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        my: 2,
      }}
    >
      <Divider sx={{ flexGrow: 1 }} />
      <Typography sx={{ mx: 2 }}>{text}</Typography>
      <Divider sx={{ flexGrow: 1 }} />
    </Box>
  )
}
const Dot: FC<
  PropsWithChildren & {
    color?: string
    backgroundColor?: string
    dim?: number
  }
> = ({
  children,
  color = "white",
  backgroundColor = "primary.main",
  dim = 20,
}) => {
  return (
    <Box
      sx={{
        width: `${dim}px`,
        height: `${dim}px`,
        borderRadius: "50%",
        backgroundColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color,
      }}
    >
      {children}
    </Box>
  )
}

type OsLockerCancelReservationDialogProps = {
  isOpenDoorDialogOpen?: boolean
  hasDeposited?: boolean
  hasExpired?: boolean
  onClose: () => void
  onCancel: (employeeCode?: string) => void
  onOpenDoor: (employeeCode?: string, shouldClose?: boolean) => Promise<void>
  employeeCodeRequired?: boolean
  adminCode: string
}
const defaultJoiSchema = Joi.string().required().min(1).max(1000)

export const OsLockerCancelReservationDialog: FC<
  OsLockerCancelReservationDialogProps
> = ({
  isOpenDoorDialogOpen = true,
  onClose,
  onCancel,
  hasDeposited,
  hasExpired,
  onOpenDoor,
  employeeCodeRequired = true,
  adminCode,
}) => {
  const { t } = useTranslation()

  const [code, setCode] = useState<string | undefined>()

  const [error, setError] = useState(false)

  const [touched, setTouched] = useState(false)

  const showWizard = hasDeposited || hasExpired

  const shouldEnableActionWhenEmployeeCodeRequired = !error && touched && code

  const [pickupConfirmed, setPickupConfirmed] = useState(false)

  const [doorOpened, setDoorOpened] = useState(false)

  const [showAdminCode, setShowAdminCode] = useState(false)

  const [showPickupConfirmedBtn, setShowPickupConfirmedBtn] = useState(false)

  const onOpenDoorWrapper = useCallback(async () => {
    try {
      await onOpenDoor(code, false)
      setDoorOpened(true)
      setShowPickupConfirmedBtn(true)
    } catch (error: any) {
      console.error("openDoor error", error)
    }
  }, [code, onOpenDoor])

  const cancelBtn = (
    <Sl2Btn
      onClick={() => onCancel(code)}
      color="warning"
      autoFocus
      variant="contained"
      sx={{
        width: "400px",
      }}
      disabled={!pickupConfirmed}
    >
      {t("cancelReservation")}
    </Sl2Btn>
  )
  return (
    <Dialog
      open={isOpenDoorDialogOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("cancelReservationConfirmation")}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack direction="column" spacing={4}>
          {employeeCodeRequired && (
            <Stack direction="column" spacing={3}>
              <Alert severity="info">
                <Stack direction="column" spacing={2} width={"100%"}>
                  <Typography variant="body1">
                    {t("employeeCodeNeeded")}
                  </Typography>
                  <Box>
                    <TextField
                      autoFocus
                      sx={{
                        width: "400px",
                      }}
                      label={t("enterEmployeeCode")}
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value)

                        if (!touched) {
                          setTouched(true)
                        } else {
                          const { error: err } = defaultJoiSchema.validate(
                            e.target.value,
                          )

                          setError(isEmpty(err) ? false : true)
                        }
                      }}
                      error={touched && error}
                      helperText={
                        touched && error ? t("pleaseProviderEmployeeCode") : ""
                      }
                    />
                  </Box>
                </Stack>
              </Alert>
            </Stack>
          )}
          {showWizard ? (
            <Stack direction="column" spacing={10}>
              <Stack direction="row" spacing={1} alignItems="flex-start">
                <Dot
                  backgroundColor={
                    employeeCodeRequired &&
                    !shouldEnableActionWhenEmployeeCodeRequired
                      ? "gray"
                      : "primary.main"
                  }
                >
                  1
                </Dot>
                <Stack direction="column" spacing={1} width="400px">
                  <Typography variant="body1">
                    {t("makeSureParcelHasBeenPickedUp")}
                  </Typography>
                  <Paper
                    sx={{
                      p: 1,
                      // width: "100%",
                    }}
                    variant="outlined"
                  >
                    <Stack direction="column" spacing={2} divider={<Divider />}>
                      <Stack
                        direction="column"
                        spacing={2}
                        // alignItems="center"
                        // justifyContent="space-between"
                      >
                        <Stack direction="column" spacing={1}>
                          <Typography variant="body2">
                            {t("openDoorImmediately")}
                          </Typography>
                          <Sl2Btn
                            onClick={onOpenDoorWrapper}
                            color="primary"
                            variant="outlined"
                            disabled={
                              employeeCodeRequired
                                ? !shouldEnableActionWhenEmployeeCodeRequired
                                : false
                            }
                          >
                            {t("openDoor")}
                          </Sl2Btn>
                          {doorOpened && (
                            <Typography variant="caption" color="green">
                              {t("doorNowOpenGetParcel")}
                            </Typography>
                          )}
                        </Stack>
                        <HoritzontalDivider text={t("or")} />
                        <Typography variant="body2">
                          {t("showCodeToOpenDoorDescription")}
                        </Typography>
                        <Sl2Btn
                          onClick={() => {
                            setShowAdminCode(true)
                            setShowPickupConfirmedBtn(true)
                          }}
                          color="primary"
                          variant="outlined"
                          disabled={
                            employeeCodeRequired
                              ? !shouldEnableActionWhenEmployeeCodeRequired
                              : false
                          }
                        >
                          {t("showCodeToOpenDoor")}
                        </Sl2Btn>
                        {showAdminCode && (
                          <Box
                            sx={{
                              // center
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <Typography
                              variant="h6"
                              color="primary.main"
                              fontFamily={"monospace"}
                              sx={{
                                py: 1,
                                px: 3,
                                bgcolor: "grey.200",
                              }}
                            >
                              {adminCode}
                            </Typography>
                          </Box>
                        )}
                      </Stack>
                      {showPickupConfirmedBtn && !pickupConfirmed && (
                        <Stack direction="column" spacing={1}>
                          <Typography variant="body2">
                            {t("pleaseConfirmParcelPickedUp")}
                          </Typography>

                          <Sl2Btn
                            onClick={() => setPickupConfirmed(true)}
                            color="primary"
                            variant="contained"
                          >
                            {t("parcelHasBeenPickedUp")}
                          </Sl2Btn>
                        </Stack>
                      )}
                      {showPickupConfirmedBtn && pickupConfirmed && (
                        <Stack direction="column" spacing={1}>
                          {/* success alert to let this use know he confirmed the parcel has been pickedup */}
                          <Alert severity="success">
                            {t("parcelPickedUpConfirmed")}
                          </Alert>
                        </Stack>
                      )}
                    </Stack>
                  </Paper>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                alignItems="stretch"
                // width="400px"
              >
                <Dot
                  backgroundColor={
                    employeeCodeRequired &&
                    !shouldEnableActionWhenEmployeeCodeRequired
                      ? "gray"
                      : "primary.main"
                  }
                >
                  2
                </Dot>
                <Stack direction="column" spacing={1}>
                  <Typography variant="body1">
                    {t("cancelReservation")}
                  </Typography>
                  {cancelBtn}
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <DialogContentText id="alert-dialog-description">
              {t("cancelReservationConfirmationText")}
            </DialogContentText>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Sl2Btn onClick={onClose} color="primary">
          {t("close")}
        </Sl2Btn>
        {!showWizard && (
          <Sl2Btn
            onClick={() => onCancel(code)}
            color="warning"
            autoFocus
            variant="contained"
            disabled={
              employeeCodeRequired
                ? !shouldEnableActionWhenEmployeeCodeRequired
                : false
            }
          >
            {t("cancelReservation")}
          </Sl2Btn>
        )}
      </DialogActions>
    </Dialog>
  )
}
