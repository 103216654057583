export const ConfigurationThingTypeMrn = "mrn:sl2:::configuration";

export const ConfigurationCategoryEnum = {
  template: "template",
  reservation: "reservation",
  preferences: "preferences",
} as const;

export const ConfigurationCategoryList = [
  "template",
  "reservation",
  "preferences",
] as const;

export const ConfigurationTypeList = [
  "number",
  "string",
  "boolean",
  "object",
  "numberList",
  "stringList",
  "booleanList",
  "objectList",
  "template",
] as const;

export const ConfigurationTypeEnum = {
  number: "number",
  string: "string",
  boolean: "boolean",
  object: "object",
  numberList: "numberList",
  stringList: "stringList",
  booleanList: "booleanList",
  objectList: "objectList",
  template: "template",
} as const;

export const ConfigurationReservationActionRefNameEnum = {
  actionRequireEmployeeCode_openDoor: "actionRequireEmployeeCode_openDoor",
  actionRequireEmployeeCode_showAdminCode:
    "actionRequireEmployeeCode_showAdminCode",
  actionRequireEmployeeCode_markAsDeposited:
    "actionRequireEmployeeCode_markAsDeposited",
  actionRequireEmployeeCode_cancelReservation:
    "actionRequireEmployeeCode_cancelReservation",
  actionRequireEmployeeCode_simulateExpiration:
    "actionRequireEmployeeCode_simulateExpiration",
  actionRequireEmployeeCode_simulatePayment:
    "actionRequireEmployeeCode_simulatePayment",
  actionRequireEmployeeCode_amountUpdate:
    "actionRequireEmployeeCode_amountUpdate",
} as const;
