import React, { useCallback, useState } from "react"

export const useKeyPress = (targetKey: string, onDown: () => void) => {
  const [keyPressed, setKeyPressed] = useState(false)

  const downHandler = useCallback(
    ({ key }: { key: string }) => {
      if (key === targetKey) {
        setKeyPressed(true)
        onDown()
      }
    },
    [onDown, targetKey],
  )

  const upHandler = useCallback(
    ({ key }: { key: string }) => {
      if (key === targetKey) setKeyPressed(false)
    },
    [targetKey],
  )

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler)
    window.addEventListener("keyup", upHandler)

    return () => {
      window.removeEventListener("keydown", downHandler)
      window.removeEventListener("keyup", upHandler)
    }
  }, [downHandler, upHandler])

  return keyPressed
}
