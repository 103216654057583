import { FC } from "react"
import { useParams } from "react-router-dom"
import OsLayout from "./OsLayout"
import OsLockerDashboard from "./OsLockerDashboard"

export const OsLockerPage: FC = () => {
  const { lockerId } = useParams()

  return (
    <OsLayout selectedMenuItemLabelKey="lockers">
      {lockerId && <OsLockerDashboard lockerId={lockerId} />}
    </OsLayout>
  )
}

export default OsLockerPage
