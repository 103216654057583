import { Refresh } from "@mui/icons-material"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Link,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material"
import { DataGrid, GridSortModel } from "@mui/x-data-grid"
import { TFunction } from "i18next"
import { isUndefined, map } from "lodash"
import moment from "moment"
import React, { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Locker } from "shared/locker"
import { LockerThingTypeMrn } from "shared/lockerData"
import { Sl2DataGridColumn } from "shared/report"
import { ReportDataset } from "shared/report-datasets"
import { Reservation } from "shared/reservation"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { SstDoor } from "shared/sst"
import { selectDatasetByName } from "src/features/report/reportSlice"
import { useGetThingsQuery } from "src/features/things/thingsApi"
import {
  selectThingById,
  selectThingsByThingTypeMrn,
} from "src/features/things/thingsSlice"
import { getLockerDisplayValues } from "src/hooks/useLockerDisplayValues"
import useReportDatasets from "src/hooks/useReportDatasets"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady, selectTenantId } from "../features/me/meSlice"
import OsLockerDoorDrawer from "./OsLockerDoorDrawer"
import RectangularChip from "./RectangularChip"

const fieldToSortKeyMap: Record<string, string> = {
  number: "r.sst_number",
  status: "r.sst_status",
  locker: "r.locker",
  firstName: "r.first_name",
  lastName: "r.last_name",
  email: "r.email",
  phone: "r.phone",
  employeeCode: "r.employee_code",
  amountToCollect: "r.amount_to_collect",
  paymentCollectedAmount: "r.sst_payment_collected_amount",
  createdAt: "r.created_at",
  deliveryTime: "r.sst_delivery_time",
  receptionTime: "r.sst_reception_time",
  doorLabel: "r.sst_door_label",
  doorSize: "r.sst_door_size",
}

const momentFormat = "YYYY-MM-DD HH:mm"

const defaultColWidth = 200
type OsReportingReservationsProps = {
  onRefresh?: () => void
  reportType?: string
  datasetName?: string
}
export const OsReportingReservations: FC<OsReportingReservationsProps> = ({
  onRefresh = () => {},
  reportType = "ReservationList",
  datasetName = "reservationList",
}) => {
  const { t } = useTranslation()

  const tenantId = useAppSelector(selectTenantId)

  const isReady = useAppSelector(selectMeIsReady)

  const reservations = useAppSelector(
    selectDatasetByName(datasetName),
  ) as ReportDataset<any>

  const lockers = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, LockerThingTypeMrn),
  ) as Locker[]

  const [selectedReservationId, setSelectedReservationId] = useState<
    string | undefined
  >()

  const qReservation = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetReservationById,
        "ap.tenantId": tenantId,
        "ap.id": selectedReservationId,
        tenantId,
      },
    },
    {
      skip: !tenantId || !selectedReservationId,
    },
  )

  const reservationThing = useAppSelector((s) =>
    selectThingById(s, selectedReservationId || ""),
  ) as Reservation | undefined

  const locker = useAppSelector((s) =>
    selectThingById(s, reservationThing?.lockerId || ""),
  ) as Locker | undefined

  const door = useMemo(() => {
    return locker?.sstLocker?.doors.find(
      (door: SstDoor) =>
        door.door_id === reservationThing?.sstReservation?.door?._id ||
        door.door_id === reservationThing?.sstReservation?.door?.door_id,
    )
  }, [locker, reservationThing])

  const {
    refresh: refresh1,
    fetchExportDownload,
    isFetchingExport,
    isLoading: isLoading1,
    setPagination,
    pagination,
    setSortModel,
  } = useReportDatasets(reportType)

  const columns = useCallback(
    (t: TFunction): Sl2DataGridColumn[] => [
      // reservation.sstReservation.number
      {
        field: "number",
        headerName: t("number"),
        width: 100,
        renderCell: (params: any) => {
          const no = params.row?.reservation?.sst_number
          if (!no) {
            return <Box>-</Box>
          }
          return (
            <Link
              onClick={() =>
                setSelectedReservationId(params.row?.reservation?.id)
              }
            >
              <Typography variant="body2" fontFamily={"monospace"}>
                {no}
              </Typography>
            </Link>
          )
        },
      },
      {
        field: "status",
        headerName: t("status"),
        width: 150,

        renderCell: (params: any) => {
          const label = params.row?.reservation?.sst_status
          if (!label) {
            return <Box>-</Box>
          }
          return (
            <Box>
              <RectangularChip
                label={t(`sstReservationStatus.${label}`)}
                variant="outlined"
                size="small"
              />
            </Box>
          )
        },
      },
      {
        field: "locker",
        headerName: t("locker"),
        sortable: false,
        width: defaultColWidth,
        renderCell: (params: any) => {
          const l = getLockerDisplayValues(params.row?.locker as Locker)

          return (
            <Box>
              <Chip
                label={
                  <Typography variant="caption">
                    {"🗄️ " + l.i18n.name}
                  </Typography>
                }
                variant="outlined"
              />
            </Box>
          )
        },
      },
      {
        field: "firstName",
        headerName: t("firstName"),
        width: defaultColWidth,
        renderCell: (params: any) => {
          const label = params.row?.reservation.first_name
          if (!label) {
            return <Box>-</Box>
          }
          return <Box>{label}</Box>
        },
      },
      // lastName
      {
        field: "lastName",
        headerName: t("lastName"),
        width: defaultColWidth,
        renderCell: (params: any) => {
          const label = params.row?.reservation.last_name
          if (!label) {
            return <Box>-</Box>
          }
          return <Box>{label}</Box>
        },
      },
      // email
      {
        field: "email",
        headerName: t("email"),
        width: defaultColWidth,
        renderCell: (params: any) => {
          const label = params.row?.reservation.email
          if (!label) {
            return <Box>-</Box>
          }
          return <Box>{label}</Box>
        },
      },
      // phone
      {
        field: "phone",
        headerName: t("phone"),
        width: defaultColWidth,
        renderCell: (params: any) => {
          const label = params.row?.reservation.phone
          if (!label) {
            return <Box>-</Box>
          }
          return <Box>{label}</Box>
        },
      },
      // employee_code
      {
        field: "employeeCode",
        headerName: t("employeeCode"),
        width: defaultColWidth,
        renderCell: (params: any) => {
          const label = params.row?.reservation.employee_code
          if (!label) {
            return <Box>-</Box>
          }
          return (
            <Box>
              <Chip label={label} variant="outlined" />
            </Box>
          )
        },
      },

      //amountToCollect
      {
        field: "amountToCollect",
        headerName: t("amountToCollect"),
        width: defaultColWidth,
        renderCell: (params: any) => {
          const label = params.row?.reservation.amount_to_collect
          if (!label) {
            return <Box>-</Box>
          }
          return <Box>{label}</Box>
        },
      },
      {
        field: "paymentCollectedAmount",
        headerName: t("paymentCollectedAmount"),
        width: defaultColWidth,
        renderCell: (params: any) => {
          const label = params.row?.reservation.sst_payment_collected_amount
          if (!label) {
            return <Box>-</Box>
          }
          return <Box>{label}</Box>
        },
      },
      {
        field: "createdAt",
        headerName: t("createdAt"),
        width: defaultColWidth,
        renderCell: (params: any) => {
          return (
            <Box>
              {moment(params.row.reservation.sst_created as string).format(
                momentFormat,
              )}
            </Box>
          )
        },
      },
      {
        field: "deliveryTime",
        headerName: t("deliveryTime"),
        width: defaultColWidth,
        renderCell: (params: any) => {
          if (!params.row?.reservation.sst_delivery_time) {
            return <Box>-</Box>
          }
          const date = new Date(
            params.row?.reservation.sst_delivery_time as string,
          ).toISOString()
          if (date === "Invalid Date") {
            return <Box>-</Box>
          }
          return <Box>{moment(date).format(momentFormat)}</Box>
        },
      },

      {
        field: "receptionTime",
        headerName: t("receptionTime"),
        width: defaultColWidth,
        renderCell: (params: any) => {
          if (!params.row?.reservation.sst_reception_time) {
            return <Box>-</Box>
          }
          const date = new Date(
            params.row?.reservation.sst_reception_time as string,
          ).toISOString()
          if (date === "Invalid Date") {
            return <Box>-</Box>
          }
          return <Box>{moment(date).format(momentFormat)}</Box>
        },
      },
      {
        field: "doorLabel",
        headerName: t("doorLabel"),
        width: defaultColWidth,
        renderCell: (params: any) => {
          const label = params.row?.reservation?.sst_door_label
          if (!label) {
            return <Box>-</Box>
          }
          return (
            <Box>
              <Chip label={`${t("door")} ${label}`} variant="outlined" />
            </Box>
          )
        },
      },
      {
        field: "doorSize",
        headerName: t("doorSize"),
        width: defaultColWidth,
        renderCell: (params: any) => {
          const label = params.row?.reservation.sst_door_size
          if (!label) {
            return <Box>-</Box>
          }
          return (
            <Box>
              <Chip label={`${t("door")} ${label}`} variant="outlined" />
            </Box>
          )
        },
      },
    ],
    [],
  )

  const handleSortModelChange = React.useCallback(
    (sortModel: GridSortModel) => {
      if (sortModel[0]) {
        const sl2Field = fieldToSortKeyMap[sortModel[0].field as string] || ""
        setSortModel({
          field: sl2Field || sortModel[0].field,
          sort: sortModel[0].sort,
        })
      }
    },
    [setSortModel],
  )

  const onPaginationModelChange = useCallback(
    (paginationModel: any) => {
      console.log("paginationModel", paginationModel)
      // setPagination({ ...paginationModel, page: paginationModel.page + 1 })
      setPagination(paginationModel)
    },
    [setPagination],
  )

  const { isLoading: isLoadingLockers, refetch: refetchLockers } =
    useGetThingsQuery(
      {
        params: {
          "ap.name": SharedAccessPatternOsEnum.GetLockers,
          "ap.tenantId": tenantId,
          "ap.refreshSstLockers": true,
          tenantId,
        },
      },
      {
        skip: !tenantId,
      },
    )

  const isLoading = useMemo(
    () => isLoadingLockers || qReservation.isLoading || qReservation.isFetching,
    [isLoadingLockers, qReservation.isFetching, qReservation.isLoading],
  )

  const refetch = useCallback(() => {
    refetchLockers()
    refresh1()
  }, [refetchLockers, refresh1])

  const reservationTuples = useMemo(() => {
    return map(reservations.data, (reservation: any) => {
      const locker = lockers.find(
        (locker) => locker.id === reservation.locker_id,
      )
      return {
        id: reservation?.sst_id,
        sstReservation: reservation.sstReservation,
        locker,
        door: locker?.sstLocker?.doors.find(
          (door: SstDoor) => door.door_id === reservation.door_id,
        ),
        reservation,
      }
    }).filter(Boolean)
  }, [reservations.data, lockers])

  const [exportType, setExportType] = useState("")

  const handleExportChange = useCallback(
    (event: any) => {
      const type = event?.target?.value
      setExportType(type)
      if (type) {
        fetchExportDownload(type)
      }
    },
    [fetchExportDownload],
  )

  return (
    <>
      {!isReady && (
        <>
          <Stack direction="column" spacing={2}>
            <Skeleton variant="rectangular" width="100%" height={118} />
            <Skeleton variant="rectangular" width="100%" height={118 / 2} />
            <Skeleton variant="rectangular" width="100%" height={118} />
            <Skeleton variant="rectangular" width="100%" height={118 / 2} />
          </Stack>
        </>
      )}
      {isReady && tenantId && (
        <Box
          sx={
            {
              // maxWidth: "90vw",
              // overflowX: "auto",
              // bgcolor: "red",
            }
          }
        >
          <Stack direction="row" spacing={2} alignItems="center" pb={2}>
            <Typography variant="h6">{`${t("reservations")} (${
              reservations?.data?.length
            } / ${
              isUndefined(reservations?.totalCount)
                ? "?"
                : reservations?.totalCount
            })`}</Typography>
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <Stack direction="row" spacing={1} alignItems="center">
                <IconButton onClick={refetch}>
                  <Refresh />
                </IconButton>
                <Box>
                  <Select
                    value={""}
                    onChange={handleExportChange}
                    displayEmpty
                    variant="outlined"
                    size="small"
                    disabled={isFetchingExport}
                  >
                    <MenuItem value="" disabled>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <FileDownloadIcon />
                        <Typography variant="body2">{t("export")}</Typography>
                      </Stack>
                    </MenuItem>
                    <MenuItem value="csv">
                      <Typography variant="body2">{t("exportCsv")}</Typography>
                    </MenuItem>
                    <MenuItem value="xlsx">
                      <Typography variant="body2">{t("exportXlsx")}</Typography>
                    </MenuItem>
                    <MenuItem value="pdf">
                      <Typography variant="body2">{t("exportPdf")}</Typography>
                    </MenuItem>
                  </Select>
                </Box>
                {isFetchingExport && <CircularProgress size={24} />}
              </Stack>
            )}
          </Stack>
          <DataGrid
            columns={columns(t)}
            rows={reservationTuples}
            localeText={{
              noRowsLabel: isLoading ? "Loading ..." : t("noReservations"),
            }}
            autoHeight
            getRowId={(row) => row.reservation?.id}
            loading={isLoading1}
            rowSelection={false}
            sx={{
              // disable cell selection style
              ".MuiDataGrid-cell:focus": {
                outline: "none",
              },
              // pointer cursor on ALL rows
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer",
              },
            }}
            rowCount={
              reservations.totalCount || `${reservations?.data?.length}`
            }
            pageSizeOptions={[10, 25, 50, 100]}
            paginationModel={pagination}
            onPaginationModelChange={onPaginationModelChange}
            paginationMode="server"
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
          />
          {reservationThing?.id && locker?.id && door && (
            <OsLockerDoorDrawer
              modName={t("module")}
              onClose={() => setSelectedReservationId(undefined)}
              reservation={reservationThing}
              locker={locker}
              isCreateMode={false}
              door={door}
            />
          )}
        </Box>
      )}
    </>
  )
}

export default OsReportingReservations
