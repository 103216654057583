import { toUtf8 } from "@aws-sdk/util-utf8-browser"
import { MessageReceivedEvent } from "aws-crt/dist/native/mqtt5"
import { iot, mqtt5 } from "aws-iot-device-sdk-v2"
import { noop } from "lodash"
import { IoTCreds } from "../features/me/meApi"

export let iotClient: mqtt5.Mqtt5Client | null = null
const MAX_RETRIES = 5
export type OnMessage = (
  topicName: string,
  messageJson: Record<string, any>,
) => void
const onMessageReceived = (
  eventData: MessageReceivedEvent,
  onMessage: OnMessage,
) => {
  const { message } = eventData
  const { topicName, payload } = message

  if (!payload) {
    console.log("payload is null")
    return
  }
  // convert payload to string
  let messageStr = toUtf8(payload as Uint8Array)
  // atempt to parse the message
  try {
    const data = JSON.parse(messageStr)
    // console.log("📨 📬 Received message on topic: ", topicName, data)
    try {
      onMessage(topicName, data)
    } catch (err) {
      console.log("Error handling message", err)
    }
    // setMessages and keep the last 40 messages
  } catch (err) {
    console.log("Error parsing message", err)
  }
}
let retryCount = 0
export const connectMrt = async (
  iotCreds: IoTCreds,
  onMessage: OnMessage = noop,
) => {
  return new Promise<{
    client: mqtt5.Mqtt5Client
    clientId: string | undefined
  }>((resolve, reject) => {
    if (retryCount > MAX_RETRIES) {
      console.log("Max retries exceeded. Giving up.")
      reject(new Error("Max retries exceeded. Giving up."))
    }
    const { token, tokenSignature, authorizerName, tokenKeyName, iotEndpoint } =
      iotCreds

    let customAuthConfig: iot.MqttConnectCustomAuthConfig = {
      authorizerName,
      tokenKeyName,
      tokenValue: token,
      tokenSignature,
    }

    let builder =
      iot.AwsIotMqtt5ClientConfigBuilder.newWebsocketMqttBuilderWithCustomAuth(
        iotEndpoint,
        customAuthConfig,
      )

    const client = new mqtt5.Mqtt5Client(builder.build())
    iotClient = client
    client.removeAllListeners()
    client.on("messageReceived", (e) => onMessageReceived(e, onMessage))

    client.on("connectionSuccess", (arg0) => {
      console.log("connectionSuccess")
      resolve({ client, clientId: arg0?.settings?.clientId })
    })

    client.on("connectionFailure", (error) => {
      console.log("connectionFailure", error)
      iotClient = null
      retryCount++
      reject(error)
    })

    client.start()
  })
}
export const subscribe = (config: mqtt5.SubscribePacket) => {
  subscribeAsync(config).catch((err) => {
    console.error("🙈💥😬 Error subscribing", err)
  })
}
export const subscribeAsync = async (config: mqtt5.SubscribePacket) => {
  const { subscriptions } = config
  const subResp = await iotClient?.subscribe({
    subscriptions,
  })
  for (let i = 0; i < subscriptions.length; i++) {
    const reasonCode = subResp?.reasonCodes[i]
    const sub = subscriptions[i]
    if (["0", "1", "2"].includes(reasonCode + "")) {
      console.log(`📨 🤝 Successfully subscribed to: [${sub.topicFilter}]`)
    } else {
      console.error(
        `🙈💥😬 Error subscribing : [${sub.topicFilter}]`,
        reasonCode,
      )
      console.error(
        "https://docs.oasis-open.org/mqtt/mqtt/v5.0/os/mqtt-v5.0-os.html#_Toc3901171",
      )
    }
  }
}

export const publishAsync = async (config: mqtt5.PublishPacket) => {
  const { topicName } = config
  const pubResp = await iotClient?.publish(config)
  if (pubResp?.reasonCode + "" !== "0") {
    console.error("🙈💥😬 Error publishing to: ", topicName, pubResp)
    console.error(
      "https://docs.oasis-open.org/mqtt/mqtt/v5.0/os/mqtt-v5.0-os.html#_Toc3901124",
    )
    return
  }

  console.log("📨 🚀 Successfully published to: ", topicName, pubResp)
}
export const publish = (config: mqtt5.PublishPacket) => {
  publishAsync(config).catch((err) => {
    console.error("🙈💥😬 Error publishing", err)
  })
}

export const unsubscribeAsync = async (config: mqtt5.UnsubscribePacket) => {
  const { topicFilters } = config

  const unsubResp = await iotClient?.unsubscribe({ topicFilters })
  console.log("🔇 Successfully unsubscribed from: ", topicFilters.join(", "))
}

export const unsubscribe = (config: mqtt5.UnsubscribePacket) => {
  unsubscribeAsync(config).catch((err) => {
    console.error("🙈💥😬 Error unsubscribing", err)
  })
}
