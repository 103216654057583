import { FC, PropsWithChildren, useEffect, useState } from "react"
import { ModeContext } from "./ModeContext"
// 'stanalon' and 'embedded'
export type Sl2Mode = "standalone" | "embedded" | "RxPro"

export const ModeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [mode, setMode] = useState<Sl2Mode>("standalone") // default mode

  useEffect(() => {
    // Detect if the app is running inside an iframe
    if (window.self !== window.top) {
      setMode("embedded")
    }
    // detect query param mode=RxPro
    const urlParams = new URLSearchParams(window.location.search)
    const modeParam = urlParams.get("mode")
    if (modeParam === "RxPro") {
      setMode("RxPro")
    }
  }, [])

  return <ModeContext.Provider value={mode}>{children}</ModeContext.Provider>
}
