import CloseIcon from "@mui/icons-material/Close"
import DeleteIcon from "@mui/icons-material/Delete"
import GroupIcon from "@mui/icons-material/Group"
import GroupAddIcon from "@mui/icons-material/GroupAdd"
import InfoIcon from "@mui/icons-material/Info"
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Group } from "shared/group"
import { GroupTU } from "shared/groupTU"
import { GroupTUThingTypeMrn } from "shared/groupTUData"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { User } from "shared/user"
import { UserThingTypeMrn } from "shared/userData"
import { useAppSelector } from "../app/hooks"
import { appConfig } from "../appConfig"
import { useGetThingsQuery } from "../features/things/thingsApi"
import { selectThingsByThingTypeMrnFilter } from "../features/things/thingsSlice"
import { OsIamGroupMemberDeleteDialog } from "./OsIamGroupMemberDeleteDialog"
import { OsIamGroupMemberFormDialog } from "./OsIamGroupMemberFormDialog"
import { Sl2Btn } from "./Sl2Btn"

export type OsGroupPreviewDrawerProps = {
  onClose: () => void
  group: Group
  tenantId: string
}

export const OsGroupPreviewDrawer: FC<OsGroupPreviewDrawerProps> = ({
  group,
  onClose,
  tenantId,
}) => {
  const { t } = useTranslation()

  const [showAddMemberForm, setShowAddMemberForm] = useState(false)

  const [deletingItem, setDeletingItem] = useState<{
    user: User
    groupTU: GroupTU
  } | null>(null)
  const { isLoading, refetch } = useGetThingsQuery({
    params: {
      "ap.name": SharedAccessPatternOsEnum.GetGroupTUsByGroupId,
      "ap.tenantId": tenantId,
      "ap.groupId": group.id,
      tenantId,
    },
  })
  const groupTusFilterFn = useCallback(
    (t: GroupTU) => t?.groupId === group.id,
    [group.id],
  )
  const groupTus = useAppSelector((s) =>
    selectThingsByThingTypeMrnFilter(s, GroupTUThingTypeMrn, groupTusFilterFn),
  ) as GroupTU[]
  const groupTusUserIds = groupTus.map((gtu) => gtu.userId)

  const usersFilterFn = useCallback(
    (t: User) => groupTusUserIds.includes(t?.id),
    [groupTusUserIds],
  )
  const users = useAppSelector((s) =>
    selectThingsByThingTypeMrnFilter(s, UserThingTypeMrn, usersFilterFn),
  ) as User[]

  const existingMemberIds = useMemo(
    () => users.map((u) => u.id) as string[],
    [users],
  )

  const onComplete = useCallback(() => {
    refetch()
    setShowAddMemberForm(false)
  }, [refetch])

  const onCompleteDelete = useCallback(() => {
    refetch()
    setDeletingItem(null)
  }, [refetch])

  const onDeleteClick = useCallback(
    (user: User) => {
      const groupTU = groupTus.find((gtu) => gtu.userId === user.id) as GroupTU
      if (!groupTU) {
        console.error("Cannot remove user from group; groupTU not found")
        return
      }
      setDeletingItem({
        user,
        groupTU,
      })
    },
    [groupTus],
  )

  // sanity check
  if (!group) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    )
  }
  return (
    <Paper
      variant="outlined"
      sx={{ p: 2, width: appConfig.itemPreviewDrawerWidth }}
    >
      <Stack direction="column">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <GroupIcon />
            <Typography variant="h6">{t("group")}</Typography>
          </Stack>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <Avatar
          variant="rounded"
          sx={{
            bgcolor: "primary.main",
            height: 100,
            width: 100,
            mt: 4,
            mb: 1,
          }}
        >
          {group.name.charAt(0)}
        </Avatar>

        <Typography variant="h6">{group.name}</Typography>

        <Stack direction="row" alignItems="center" mt={1} spacing={1}>
          <Typography variant="body2">
            {`${t("createdAt")} ${new Date(group.createdAt).toLocaleString()}`}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          border="1px solid #e0e0e0"
          bgcolor="#f5f5f5"
          py={2}
          px={1}
          my={3}
        >
          <InfoIcon sx={{ mr: 1 }} color="success" />
          <Typography variant="body2">{t("groupDescription")}</Typography>
        </Stack>
      </Stack>
      <>
        <Divider sx={{ mt: 2 }} />
        <Stack direction="column" mt={2}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body1" fontWeight="bold">
                {`${t("members")}`}
              </Typography>
              {!isLoading && (
                <Typography variant="body1" fontWeight="bold">
                  {`(${users.length})`}
                </Typography>
              )}
              {isLoading && <CircularProgress size={16} />}
            </Stack>
            <Sl2Btn
              variant="outlined"
              onClick={() => setShowAddMemberForm(true)}
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <GroupAddIcon />
                <Box>{t("add")}</Box>
              </Stack>
            </Sl2Btn>
          </Stack>
          {users.length > 0 && (
            <Stack direction="column" mt={1} spacing={3}>
              {users.map((u) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  key={u.id}
                  justifyContent="space-between"
                >
                  <Stack direction="row" alignItems="center">
                    <Avatar
                      sx={{
                        bgcolor: "primary.main",
                        height: 40,
                        width: 40,
                        mr: 2,
                      }}
                    >
                      {u.firstName?.charAt(0)}
                    </Avatar>
                    <Stack direction="column">
                      <Typography
                        variant="body1"
                        fontWeight={"bold"}
                      >{`${u.firstName} ${u.lastName}`}</Typography>
                      <Typography variant="body2">{u.email}</Typography>
                    </Stack>
                  </Stack>
                  {users?.length > 1 && (
                    <IconButton onClick={() => onDeleteClick(u)}>
                      {/* garbage icon */}
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Stack>
              ))}
            </Stack>
          )}
        </Stack>
      </>
      {showAddMemberForm && (
        <OsIamGroupMemberFormDialog
          groupId={group.id}
          onClose={onComplete}
          onComplete={onComplete}
          existingMemberIds={existingMemberIds}
        />
      )}
      {deletingItem?.user?.id && (
        <OsIamGroupMemberDeleteDialog
          group={group}
          user={deletingItem.user}
          groupTU={deletingItem.groupTU}
          onClose={onCompleteDelete}
          onComplete={onCompleteDelete}
        />
      )}
    </Paper>
  )
}

export default OsGroupPreviewDrawer
