import { Close } from "@mui/icons-material"
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { DataGrid, DataGridProps } from "@mui/x-data-grid"
import { TFunction } from "i18next"
import { chain } from "lodash"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Locker } from "shared/locker"
import { Reservation } from "shared/reservation"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { Tenant } from "shared/tenant"
import { useGetThingsQuery } from "src/features/things/thingsApi"
import { getLockerDisplayValues } from "src/hooks/useLockerDisplayValues"
import { useSearchParamsState } from "src/hooks/useSearchParamsState"
import ObjectDisplayTable from "./ObjectDisplayTable"
import RectangularChip from "./RectangularChip"

const defaultColWidth = 200
const columns = (
  t: TFunction,
  tenantId: string,
  onClick: (reservationId: string) => void,
): DataGridProps["columns"] => [
  // reservation.sstReservation.number
  {
    field: "sstReservation.number",
    headerName: t("number"),
    width: 100,
    renderCell: (params) => {
      const reservationId = params.row?.reservation?.id as string
      const no = params.row.sstReservation.number
      if (!no) {
        return <Box>-</Box>
      }
      return (
        <Typography
          sx={{
            cursor: "pointer",
            color: "primary.main",
          }}
          onClick={() => onClick(reservationId)}
        >
          {no}
        </Typography>
      )
    },
  },
  {
    field: "sstReservation.status",
    headerName: t("status"),
    width: defaultColWidth,

    renderCell: (params) => {
      const label = params.row?.sstReservation?.status
      if (!label) {
        return <Box>-</Box>
      }
      return (
        <Box>
          <RectangularChip
            label={t(`sstReservationStatus.${label}`)}
            variant="outlined"
            size="small"
          />
        </Box>
      )
    },
  },
  // firstName
  {
    field: "firstName",
    headerName: t("firstName"),
    width: defaultColWidth,
    renderCell: (params) => {
      return <Box>{params.row.reservation?.firstName}</Box>
    },
  },
  // lastName
  {
    field: "lastName",
    headerName: t("lastName"),
    width: defaultColWidth,
    renderCell: (params) => {
      return <Box>{params.row.reservation?.lastName}</Box>
    },
  },
  // email
  {
    field: "email",
    headerName: t("email"),
    width: defaultColWidth,
    renderCell: (params) => {
      return (
        <Link
          to={`/sa/notif-deliveries?tab=1&email=${encodeURIComponent(
            params.row.reservation?.email,
          )}`}
        >
          {params.row.reservation?.email}
        </Link>
      )
    },
  },
  // phone
  {
    field: "phone",
    headerName: t("phone"),
    width: defaultColWidth,
    renderCell: (params) => {
      return (
        <Link
          to={`/sa/notif-deliveries?tab=0&phoneNumber=${encodeURIComponent(
            params.row.reservation?.phone,
          )}`}
        >
          {params.row.reservation?.phone}
        </Link>
      )
    },
  },
  {
    field: "locker",
    headerName: t("locker"),
    width: defaultColWidth,
    renderCell: (params) => {
      const l = getLockerDisplayValues(params.row?.locker as Locker)

      return (
        <Box>
          <Chip
            label={
              <Link to={`/sa/lockers/${params.row?.locker?.id}`}>
                <Typography variant="caption">{"🗄️ " + l.i18n.name}</Typography>
              </Link>
            }
            variant="outlined"
          />
        </Box>
      )
    },
  },

  {
    field: "createdAt",
    headerName: t("createdAt"),
    width: defaultColWidth,
    renderCell: (params) => {
      return (
        <Box>
          {new Date(
            params.row.sstReservation._created as string,
          ).toLocaleString()}
        </Box>
      )
    },
  },

  {
    field: "sstReservation.delivery_time",
    headerName: t("deliveryTime"),
    width: defaultColWidth,
    renderCell: (params) => {
      const date = new Date(
        params.row?.sstReservation.delivery_time as string,
      ).toLocaleString()
      if (date === "Invalid Date") {
        return <Box>-</Box>
      }
      return <Box>{date}</Box>
    },
  },

  {
    field: "sstReservation.reception_time",
    headerName: t("receptionTime"),
    width: defaultColWidth,
    renderCell: (params) => {
      const date = new Date(
        params.row?.sstReservation.reception_time as string,
      ).toLocaleString()
      if (date === "Invalid Date") {
        return <Box>-</Box>
      }
      return <Box>{date}</Box>
    },
  },
  {
    field: "sstReservation.expiration_time",
    headerName: t("expirationTime"),
    width: defaultColWidth,
    renderCell: (params) => {
      const date = new Date(
        params.row?.sstReservation.expiration_time as string,
      ).toLocaleString()
      if (date === "Invalid Date") {
        return <Box>-</Box>
      }
      return <Box>{date}</Box>
    },
  },
  {
    field: "door",
    headerName: t("door"),
    width: defaultColWidth,
    renderCell: (params) => {
      const label = params.row?.door?.door_label
      if (!label) {
        return <Box>-</Box>
      }
      return (
        <Box>
          <Chip label={`${t("door")} ${label}`} variant="outlined" />
        </Box>
      )
    },
  },
]

type SaReservationsByTenantProps = {
  tenant: Tenant
}

export const SaReservationsByTenant: FC<SaReservationsByTenantProps> = ({
  tenant,
}) => {
  const { t } = useTranslation()

  const [showTenantPreview, setShowTenantPreview] = useState<boolean>(false)

  const [selectedReservationId, setSelectedReservationId] =
    useSearchParamsState("reservationId", "")

  const qReservations = useGetThingsQuery({
    params: {
      "ap.name": SharedAccessPatternOsEnum.GetReservations,
      "ap.tenantId": tenant.id,
      tenantId: tenant.id,
    },
  })

  const qLockers = useGetThingsQuery({
    params: {
      "ap.name": SharedAccessPatternOsEnum.GetLockers,
      "ap.tenantId": tenant.id,

      tenantId: tenant.id,
    },
  })

  const [searchInput, setSearchInput] = useState<string>("")

  const reservations = useMemo(() => {
    // if not an rray reutn empty array
    if (!Array.isArray(qReservations.data?.things)) {
      return []
    }
    const list = [...(qReservations.data?.things as Reservation[])]
    return (
      list
        // filter by search input on sstReservation.number, firstName, lastName, email, phone
        .filter((r) => {
          const search = searchInput.toLowerCase()
          return (
            (r?.sstReservation?.number + "").toLowerCase().includes(search) ||
            r?.id?.toLowerCase().includes(search) ||
            r?.sstReservation?._id?.toLowerCase().includes(search) ||
            r?.firstName?.toLowerCase().includes(search) ||
            r?.lastName?.toLowerCase().includes(search) ||
            r?.email?.toLowerCase().includes(search) ||
            r?.phone?.toLowerCase().includes(search)
          )
        })
        // sort by createdAt desc
        .sort(
          (a: Reservation, b: Reservation) =>
            new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime(),
        ) as Reservation[]
    )
  }, [qReservations.data?.things, searchInput])

  const lockers = useMemo(() => {
    return (qLockers.data?.things || []) as Locker[]
  }, [qLockers])

  const reservationTuples = useMemo(() => {
    return chain(reservations)
      .map((reservation) => {
        if (!reservation?.sstReservation) return null
        const door = reservation?.sstReservation?.door
        const locker = lockers.find(
          (locker) => locker.sstLockerId === door?.locker_id,
        )
        return {
          id: reservation?.sstReservation?._id,
          sstReservation: reservation.sstReservation,
          locker,
          door,
          reservation,
        }
      })
      .compact()
      .value()
  }, [reservations, lockers])

  const onReservationSelected = useCallback(
    (reservationId: string) => {
      setSelectedReservationId(reservationId)
    },
    [setSelectedReservationId],
  )

  const selectedReservation = useMemo(() => {
    return reservationTuples.find(
      (r) => r.reservation.id === selectedReservationId,
    )?.reservation
  }, [selectedReservationId, reservationTuples])

  return (
    <Box
      sx={{
        width: "fit-content",
      }}
    >
      <Stack direction="column" spacing={2} p={2}>
        <Typography variant="body2">{t("reservationsFor")}</Typography>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          divider={<Divider orientation="vertical" flexItem />}
          sx={{
            // link style
            cursor: "pointer",
            color: "primary.main",
          }}
          onClick={() => setShowTenantPreview(true)}
        >
          <Typography variant="h6">{tenant?.name}</Typography>
          <Typography variant="body2">{tenant?.id}</Typography>
          <Typography variant="body2">
            {`${t("mblsProviderId")}: ${tenant?.mblsProviderId || "n/a"}`}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <TextField
            label={t("search")}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            size="small"
            sx={{
              mt: 3,
              width: "500px",
            }}
          />
        </Stack>

        <Box>
          {qReservations?.isLoading || qReservations.isFetching ? (
            <CircularProgress size={20} />
          ) : (
            <Typography variant="body2">
              {t("reservationsCount", { count: reservationTuples.length })}
            </Typography>
          )}
        </Box>
        <Box>
          <DataGrid
            columns={columns(t, tenant.id, onReservationSelected)}
            rows={reservationTuples}
            localeText={{
              noRowsLabel:
                qReservations?.isLoading || qReservations.isFetching
                  ? "Loading ..."
                  : t("noReservations"),
            }}
            autoHeight
            getRowId={(row) => row.reservation?.id}
            rowSelection={false}
            sx={{
              // disable cell selection style
              ".MuiDataGrid-cell:focus": {
                outline: "none",
              },
              // pointer cursor on ALL rows
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer",
              },
            }}
          />
        </Box>
      </Stack>
      <Drawer
        anchor="right"
        open={!!showTenantPreview}
        onClose={() => setShowTenantPreview(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "80vw",
            maxWidth: "1500px",
            maxHeight: "calc(100vh - 64px)",
            height: "calc(100vh - 64px)",
            paddingTop: "64px",
          },
        }}
      >
        <Stack direction="column" spacing={1} p={2}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{
                  color: "primary.main",
                }}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Typography variant="body1" fontWeight="bold">
                  {tenant?.name}
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {tenant?.id}
                </Typography>
              </Stack>
            </Stack>
            <IconButton onClick={() => setShowTenantPreview(false)}>
              <Close />
            </IconButton>
          </Stack>
          <Divider />

          <ObjectDisplayTable data={tenant} />
        </Stack>
      </Drawer>
      <Drawer
        anchor="right"
        open={!!selectedReservation?.id}
        onClose={() => setSelectedReservationId("")}
        sx={{
          "& .MuiDrawer-paper": {
            width: "80vw",
            maxWidth: "1500px",
            maxHeight: "calc(100vh - 64px)",
            height: "calc(100vh - 64px)",
            paddingTop: "64px",
          },
        }}
      >
        <Stack direction="column" spacing={1} p={2}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{
                  color: "primary.main",
                }}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Typography variant="body1" fontWeight="bold">
                  {selectedReservation?.sstReservation?.number}
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {selectedReservation?.id}
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {selectedReservation?.sstReservation?.status}
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {selectedReservation?.sstReservation?._id}
                </Typography>
              </Stack>
            </Stack>
            <IconButton onClick={() => setSelectedReservationId("")}>
              <Close />
            </IconButton>
          </Stack>
          <Divider />

          <ObjectDisplayTable data={selectedReservation} />
        </Stack>
      </Drawer>
    </Box>
  )
}

export default SaReservationsByTenant
