import RightArrowAltIcon from "@mui/icons-material/ArrowRightAlt"
import CancelIcon from "@mui/icons-material/Cancel"
import InventoryIcon from "@mui/icons-material/Inventory"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import LockIcon from "@mui/icons-material/Lock"
import {
  Box,
  Chip,
  Dialog,
  Divider,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import { t } from "i18next"
import { FC, useCallback, useState } from "react"
import { Link } from "react-router-dom"
import { Locker } from "shared/locker"
import { Reservation, SstReservation } from "shared/reservation"
import { useDropdown } from "src/hooks/useDropdown"
import { useLockerActions } from "src/hooks/useLockerActions"
import useLockerDisplayValues from "src/hooks/useLockerDisplayValues"
import { useReservation } from "src/hooks/useReservation"
import { ReservationChip } from "./ReservationChip"
import { Sl2Btn } from "./Sl2Btn"

export type SstReservationProps = {
  sstReservation: SstReservation
  door: SstReservation["door"]
  locker?: Locker
  tenantId: string
  reservation: Reservation
}
export const SstReservationCard: FC<SstReservationProps> = ({
  sstReservation,
  door,
  locker,
  tenantId,
  reservation,
}) => {
  const l = useLockerDisplayValues(locker)

  const { anchorEl, open, handleClick, handleClose } = useDropdown()

  const [cancelConfirmationDialogOpen, setCancelConfirmationDialogOpen] =
    useState(false)

  const { openDoor, getPackage, cancelReservation } = useLockerActions({
    lockerId: locker?.id || "",
    sstLockerId: locker?.sstLockerId || "",
    sstDoorId: door.door_id,
    sstReservationId: sstReservation?._id,
    tenantId,
    reservationId: reservation.id,
  })
  const onOpenDoor = useCallback(() => {
    openDoor()
    handleClose()
  }, [handleClose, openDoor])

  const onGetPackage = useCallback(() => {
    getPackage()
    handleClose()
  }, [getPackage, handleClose])

  const onCancel = useCallback(() => {
    console.log("cancel")
    handleClose()
    setCancelConfirmationDialogOpen(true)
  }, [handleClose])

  const onConfirmCancel = useCallback(() => {
    console.log("confirm cancel")
    setCancelConfirmationDialogOpen(false)
  }, [])

  const onConfirm = useCallback(async () => {
    console.log("confirmed")
    setCancelConfirmationDialogOpen(false)
    await cancelReservation()
  }, [cancelReservation])

  const { expiresIn, expirationDate, showExpirationWarning, hasExpired } =
    useReservation({
      reservation,
    })

  return (
    <Paper elevation={3} sx={{ p: 2, width: 800 }}>
      <Stack direction="column" spacing={2}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h6">{t("reservation")}</Typography>
            <Typography variant="body1" fontWeight="bold" color="grey">
              {t("sst")}
            </Typography>
            <ReservationChip
              reservation={reservation}
              tenantId={tenantId}
              showStatus={false}
            />
            <Chip label={t(sstReservation.status)} />
          </Stack>
          {false && (
            <Sl2Btn
              onClick={handleClick}
              size="small"
              variant="contained"
              color={"success"}
              sx={{
                height: 25,
              }}
            >
              <Stack direction="row" spacing={1} alignItems={"center"}>
                {t("deposited")}
                <KeyboardArrowDownIcon />
              </Stack>
            </Sl2Btn>
          )}
          {false && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={onOpenDoor}>
                <LockIcon sx={{ mr: 2, color: "grey" }} />
                <>{t("openDoor")} </>
              </MenuItem>
              <MenuItem onClick={onGetPackage}>
                <InventoryIcon sx={{ mr: 2, color: "grey" }} />
                <Typography variant="body1">
                  <>{t("getPackage")} </>
                </Typography>
              </MenuItem>
              <MenuItem onClick={onCancel}>
                <CancelIcon sx={{ mr: 2, color: "grey" }} />
                <Typography variant="body1">
                  <>{t("cancelReservation")} </>
                </Typography>
              </MenuItem>
            </Menu>
          )}
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="body2">{`🕒 ${t("createdAt")} ${new Date(
            sstReservation._created,
          ).toLocaleString()}`}</Typography>
          <Typography variant="body2">
            <RightArrowAltIcon />
          </Typography>
          <Typography variant="body2">{`⏳ ${t("expirationTime")} ${
            expirationDate ? new Date(expirationDate).toLocaleString() : "N/A"
          }`}</Typography>
          {sstReservation?.status === "EXPIRED" && expirationDate && (
            <Chip
              label={expiresIn}
              size="small"
              // variant="outlined"
              color={
                hasExpired
                  ? "error"
                  : showExpirationWarning
                  ? "warning"
                  : "success"
              }
            />
          )}
        </Stack>
        {locker && (
          <Stack direction="row" spacing={2} alignItems="center">
            <Box>
              <Chip
                label={
                  <>
                    <Link
                      to={`/os/lockers/${locker.id}?tenantId=${tenantId}`}
                      style={{ marginRight: 20 }}
                    >
                      {`🗄️  ${l.i18n.name} `}
                    </Link>
                    <>{`${t("door")} ${door.door_label}`}</>
                  </>
                }
                variant="outlined"
              />
            </Box>
          </Stack>
        )}
        <Box sx={{ pt: 6 }} />
        <Divider />
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction="column"
            spacing={1}
            width={150}
            className={`uf-delivery-code-container`}
          >
            <Typography variant="body1" fontWeight="bold" color="grey">
              {t("deliveryCode")}
            </Typography>
            <Typography
              variant="body1"
              fontWeight="bold"
              fontFamily="monospace"
            >
              {sstReservation.delivery_code}
            </Typography>
          </Stack>
          <Stack direction="column" spacing={1} width={150}>
            <Typography variant="body1" fontWeight="bold" color="grey">
              {t("receptionCode")}
            </Typography>
            <Typography
              variant="body1"
              fontWeight="bold"
              fontFamily="monospace"
            >
              {sstReservation.reception_code}
            </Typography>
          </Stack>
          <Stack direction="column" spacing={1} width={150}>
            <Typography variant="body1" fontWeight="bold" color="grey">
              {t("adminCode")}
            </Typography>
            <Typography
              variant="body1"
              fontWeight="bold"
              fontFamily="monospace"
            >
              {sstReservation.admin_code}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {/* confirme reservation cancellation */}
      <Dialog open={cancelConfirmationDialogOpen}>
        <Stack direction="column" spacing={2} width={600}>
          <Box sx={{ p: 2 }}>
            <Typography variant="h6">{t("cancelReservation")}</Typography>
            <Divider />
            <Typography variant="body1" mt={3}>
              {t("confirmCancelReservation")}
            </Typography>
          </Box>
          <Stack direction="row" spacing={2} justifyContent="flex-end" p={2}>
            <Sl2Btn onClick={onConfirmCancel} size="small" variant="outlined">
              {t("cancel")}
            </Sl2Btn>
            <Sl2Btn
              onClick={onConfirm}
              size="small"
              variant="contained"
              color={"success"}
            >
              {t("confirm")}
            </Sl2Btn>
          </Stack>
        </Stack>
      </Dialog>
    </Paper>
  )
}
