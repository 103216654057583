import { Stack, Tabs } from "@mui/material"
import { FC, useState } from "react"

import SstReservationsById from "./SaSstReservationsById"
import SstReservationsByTenant from "./SaSstReservationsByTenant"
import { Sl2Tab } from "./Sl2Tab"

type SstReservationsProps = {}

export const SstReservations: FC<SstReservationsProps> = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  return (
    <Stack direction="column" spacing={2}>
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Sl2Tab label="Reservation by Tenant" />
        <Sl2Tab label="Reservation by ID" />
      </Tabs>
      {selectedTab === 0 && <SstReservationsByTenant />}
      {selectedTab === 1 && <SstReservationsById />}
    </Stack>
  )
}

export default SstReservations
