import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import Joi, { assert } from "joi"
import { get } from "lodash-es"
import { TenantUser } from "shared/tenantUser"
import { TenantUserThingTypeMrn } from "shared/tenantUserData"
import { Thing } from "shared/thing"
import { User } from "shared/user"
import { UserThingTypeMrn } from "shared/userData"
import type { RootState } from "../../app/store"
import { thingsApi } from "../things/thingsApi"
import { selectThingById } from "../things/thingsSlice"
import { meApi } from "./meApi"

export interface MeState {
  isLoaded: boolean
  user?: User
  tu?: TenantUser
  errorMessage?: string
  onlinePresence?: boolean
  isMblsAuthenticated?: boolean
  isAuthProcessed?: boolean
  isAuthProcessing?: boolean
  tenantId?: string
  isNotificationPopupOpen: boolean
}

// Define the initial state using that type
const initialState: MeState = {
  isLoaded: false,
  user: undefined,
  tu: undefined,
  onlinePresence: false,
  isAuthProcessed: false,
  isAuthProcessing: false,

  isNotificationPopupOpen: false,
}

export const meSlice = createSlice({
  name: "me",
  initialState,
  reducers: {
    updateMblsUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
    setTenantId: (state, action: PayloadAction<string>) => {
      state.tenantId = action.payload
    },
    unSetTenantId: (state) => {
      state.tenantId = undefined
      localStorage.removeItem("tenantId")
    },
    setOnlinePresence: (state, action: PayloadAction<boolean>) => {
      state.onlinePresence = action.payload
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload
    },
    unsetErrorMessage: (state) => {
      state.errorMessage = undefined
    },
    setNotificationPopupOpen: (state, action: PayloadAction<boolean>) => {
      state.isNotificationPopupOpen = action.payload
    },
    setAuthProcessed: (state, action: PayloadAction<boolean>) => {
      assert(action.payload, Joi.boolean())
      state.isAuthProcessed = action.payload
    },
    setAuthProcessing: (state, action: PayloadAction<boolean>) => {
      assert(action.payload, Joi.boolean())
      state.isAuthProcessing = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      meApi.endpoints.getMe.matchFulfilled,
      (state, { payload }) => {
        const fetchedUser = get<any[]>(payload, "things", []).filter(
          (t) => t?.thingTypeMrn === UserThingTypeMrn,
        )[0] as User | undefined
        if (!fetchedUser) throw new Error("No user found")

        // check  fetchTenantUser
        const fetchedTenantUser = get<any[]>(payload, "things", []).filter(
          (t) => t?.thingTypeMrn === TenantUserThingTypeMrn,
        )[0] as TenantUser | undefined
        if (fetchedTenantUser) {
          state.tu = fetchedTenantUser
        }

        state.isLoaded = true
        state.user = fetchedUser
        state.errorMessage = undefined
        state.isMblsAuthenticated = true
      },
    )
    // error
    builder.addMatcher(
      meApi.endpoints.getMe.matchRejected,
      (state, { payload }) => {
        state.isLoaded = true
        const isAuthError = get(payload, "status", "") + "" === "401"
        const errMessage = get(payload, "data.errorMessage", "Unknown error")
        state.isMblsAuthenticated = false
        if (isAuthError) {
        }
        state.errorMessage = isAuthError
          ? ` Authentication error : ${errMessage}`
          : errMessage
      },
    )
    // update me from thingsApi.endponts.updateThings on of updated thing the user
    builder.addMatcher(
      thingsApi.endpoints.updateThings.matchFulfilled,
      (state, { payload }) => {
        const updatedThings = get(payload, "data.things", [])
        const updatedMe = updatedThings.find(
          (thing: Thing) => thing.mrn === state.user?.mrn,
        )
        if (!updatedMe) {
          // nothing do to
          return
        }
        state.user = updatedMe
      },
    )
  },
})

export const {
  setTenantId,
  setOnlinePresence,
  unSetTenantId,
  updateMblsUser,
  setErrorMessage,
  setNotificationPopupOpen,
  setAuthProcessed,
  setAuthProcessing,
} = meSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectMe = (state: RootState) => state.me
export const selectMeTu = (state: RootState) => state.me.tu
export const selectMeIsReady = (state: RootState) => state.me.isLoaded

export const selectMeErrorMessage = (state: RootState) => state.me.errorMessage

export const selectTenantId = (state: RootState) =>
  //state.me??.user?.id ? state.me.tenantId : undefined
  state.me.tenantId

export const selectCurrentTenant = (state: RootState) =>
  // state.things?.entities?.find((t) => t.id === state.me.tenantId)
  selectThingById(state, state.me?.tenantId || "")

export const selectOnlinePresence = (state: RootState) =>
  state.me.onlinePresence

export default meSlice.reducer

export const selectIsNotificationPopupOpen = (state: RootState) =>
  state.me.isNotificationPopupOpen

export const selectisAuthProcessed = (state: RootState) =>
  state.me.isAuthProcessed

export const selectisAuthProcessing = (state: RootState) =>
  state.me.isAuthProcessing
