import Info from "@mui/icons-material/Info"
import { Box, Divider, Skeleton, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady } from "../features/me/meSlice"
import { Callout } from "./Callout"
import SaLayout, { SaMenuItems } from "./SaLayout"
import { Sl2Btn } from "./Sl2Btn"
import { UnstyledLink } from "./UnstyledLink"

export const SaHomePage: FC = () => {
  const { t } = useTranslation()
  const isReady = useAppSelector(selectMeIsReady)
  if (!isReady) {
    return (
      <SaLayout selectedMenuItemLabelKey="lockers">
        <Stack direction="column" spacing={2}>
          <Skeleton variant="rectangular" width="100%" height={118} />
          <Skeleton variant="rectangular" width="100%" height={118 / 2} />
          <Skeleton variant="rectangular" width="100%" height={118} />
          <Skeleton variant="rectangular" width="100%" height={118 / 2} />
        </Stack>
      </SaLayout>
    )
  }

  return (
    <SaLayout>
      <Stack direction="column" spacing={10} p={2} pl={4} mt={4}>
        <Stack
          direction="column"
          spacing={4}
          sx={{
            // fit content width
            width: "fit-content",
          }}
        >
          <Box>
            <Typography variant="h5">{t("adminApp")}</Typography>
            <Divider />
          </Box>
          <Callout
            title={t("adminApp")}
            message={t("adminAppDescription")}
            icon={<Info />}
            variant="info"
          />
        </Stack>
        {/* stack row with 2 Sl2Btn */}
        <Stack
          direction="row"
          spacing={0}
          maxWidth={800}
          flexWrap={"wrap"}
          rowGap={2}
        >
          {SaMenuItems.map((item) => {
            return (
              <UnstyledLink
                to={item.url}
                key={item.labelKey}
                disabled={item.disabled}
              >
                <Sl2Btn
                  variant="outlined"
                  disabled={item.disabled}
                  sx={{
                    mr: 2,
                  }}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    {item.icon}
                    <Box>{t(item.labelKey)}</Box>
                  </Stack>
                </Sl2Btn>
              </UnstyledLink>
            )
          })}
        </Stack>
      </Stack>
    </SaLayout>
  )
}

export default SaHomePage
