import { Email, Refresh } from "@mui/icons-material"
import {
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { EmailExpeditor } from "shared/emailExpeditor"
import { EmailExpeditorThingTypeMrn } from "shared/emailExpeditorData"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { selectThingsByThingTypeMrn } from "src/features/things/thingsSlice"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady } from "../features/me/meSlice"
import { useGetThingsQuery } from "../features/things/thingsApi"
import EmailExpeditorCard from "./EmailExpeditorCard"
import SaLayout from "./SaLayout"
import { Sl2Btn } from "./Sl2Btn"

export const SaEmailExpeditorsPage: FC = () => {
  const { t } = useTranslation()
  const isReady = useAppSelector(selectMeIsReady)

  const emailExpeditors = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, EmailExpeditorThingTypeMrn),
  ) as EmailExpeditor[]
  const [formMode, setFormMode] = useState<"create" | "edit">("edit")
  const { isLoading: isLoadingEmailExpeditors, refetch } = useGetThingsQuery({
    params: {
      "ap.name": SharedAccessPatternSaEnum.SaGetEmailExpeditors,
    },
  })

  const { isLoading: isLoadingTenant, refetch: refetchTenant } =
    useGetThingsQuery({
      params: {
        "ap.name": SharedAccessPatternSaEnum.SaGetTenants,
      },
    })

  const refresh = useCallback(() => {
    refetch()
    refetchTenant()
  }, [refetch, refetchTenant])

  const isLoading = isLoadingEmailExpeditors || isLoadingTenant
  const [selectedEmailExpeditorId, setSelectedEmailExpeditorId] =
    useState<string>()
  const selectedEmailExpeditor = useMemo(
    () =>
      emailExpeditors?.find(
        (emailExpeditor) => emailExpeditor.id === selectedEmailExpeditorId,
      ),
    [emailExpeditors, selectedEmailExpeditorId],
  )
  return (
    <SaLayout selectedMenuItemLabelKey="emailExpeditors">
      <Stack direction="column" spacing={2} sx={{ p: 2 }}>
        <Stack direction="row" spacing={2} sx={{}}>
          <Stack direction="column" spacing={2}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
               <Typography variant="h6">{t("emailExpeditors")}</Typography> 
              <IconButton onClick={refresh}>
                <Refresh />
              </IconButton>
            </Stack>
            <Typography variant="body2">
              {t("emailExpeditorsDescription1")}
            </Typography>
            <Typography variant="body2">
              {t("emailExpeditorsDescription2")}
            </Typography>
          </Stack>
        </Stack>
        <Divider />

        <Stack direction="row" spacing={2}>
          <Stack direction="column" spacing={2}>
            <Paper
              variant="outlined"
              sx={{ p: 1, width: 400, borderTop: "3px solid #3A5163" }}
            >
              <Stack
                direction="row"
                spacing={2}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{ py: 2 }}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body1" fontWeight={"bold"}>
                    {t("emailExpeditors")}
                  </Typography>
                  {!isLoading && (
                    <IconButton onClick={refetch}>
                      <Refresh />
                    </IconButton>
                  )}
                  {isLoading && <CircularProgress />}
                </Stack>
                <Sl2Btn
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setFormMode("create")
                  }}
                >
                  {t("add")}
                </Sl2Btn>
              </Stack>
              <Divider />
              {/* column stack, render emailExpeditors item */}
              <Stack direction="column" spacing={0} sx={{ pt: 2 }}>
                {emailExpeditors &&
                  emailExpeditors.map((emailExpeditor) => (
                    <MenuItem
                      key={emailExpeditor.id}
                      selected={emailExpeditor.id === selectedEmailExpeditorId}
                      onClick={() => {
                        setSelectedEmailExpeditorId(emailExpeditor.id)
                        setFormMode("edit")
                      }}
                    >
                      <Stack
                        key={emailExpeditor.id}
                        direction="column"
                        justifyContent={"center"}
                        height={50}
                        width={"100%"}
                        sx={{
                          borderBottom: "1px solid lightgrey",
                        }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          width={"100%"}
                        >
                          <Typography variant="body2" fontWeight={"bold"}>
                            {emailExpeditor.name}
                          </Typography>
                          <Typography variant="body2">
                            {emailExpeditor.fromEmail}
                          </Typography>
                        </Stack>
                      </Stack>
                    </MenuItem>
                  ))}
              </Stack>
            </Paper>
          </Stack>
          {!!selectedEmailExpeditor?.id || formMode === "create" ? (
            <Stack
              direction="column"
              spacing={2}
              sx={{
                width: "95%",
                maxWidth: 1000,
              }}
            >
              <EmailExpeditorCard
                emailExpeditor={
                  formMode === "create" ? undefined : selectedEmailExpeditor
                }
                mode={formMode}
                onComplete={refetch}
              />
            </Stack>
          ) : (
            <Stack
              // center the content
              direction="column"
              sx={{
                width: "95%",
                pt: 6,
              }}
              alignItems={"center"}
            >
              <Email style={{ fontSize: "4em", color: "slategray" }} />
              <Typography>{t("pleaseSelectAEmailExpeditor")}</Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </SaLayout>
  )
}

export default SaEmailExpeditorsPage
