import GridViewIcon from "@mui/icons-material/GridView"
import ViewListIcon from "@mui/icons-material/ViewList"
import WifiTetheringIcon from "@mui/icons-material/WifiTethering"
import WifiTetheringOffIcon from "@mui/icons-material/WifiTetheringOff"
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import { DataGrid, DataGridProps } from "@mui/x-data-grid"
import { TFunction } from "i18next"
import { FC, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Locker } from "shared/locker"
import { LockerThingTypeMrn } from "shared/lockerData"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { SstLocker } from "shared/sst"
import { getLockerDisplayValues } from "src/hooks/useLockerDisplayValues"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady, selectTenantId } from "../features/me/meSlice"
import { useGetThingsQuery } from "../features/things/thingsApi"
import { selectThingsByThingTypeMrn } from "../features/things/thingsSlice"
import OsLayout from "./OsLayout"
import OsLockerDashboard from "./OsLockerDashboard"
import OsLockerDoorDrawerManager from "./OsLockerDoorDrawerManager"

const defaultColWidth = 200

const columns = (t: TFunction, tenantId: string): DataGridProps["columns"] => [
  {
    field: "name",
    headerName: t("name"),
    width: defaultColWidth,
    renderCell: (params) => {
      // link to locker page /os/lockers/:lockerId?tenantId=${tenantId}
      const lockerId = params.row?.id as string

      const l = getLockerDisplayValues(params.row as Locker)

      return (
        <Link to={`/os/lockers/${lockerId}?tenantId=${tenantId}`}>
          {l.i18n.name}
        </Link>
      )
    },
  },
  {
    field: "status",
    headerName: t("status"),
    width: defaultColWidth,
    renderCell: (params) => {
      const sstLocker = params.row?.sstLocker as SstLocker
      if (!sstLocker) {
        return null
      }
      return (
        <Stack>
          {sstLocker.is_online ? (
            <Typography variant="subtitle1" color="green">
              <Stack direction="row" spacing={1} alignItems={"center"}>
                <WifiTetheringIcon />
                <Box>{t("online")}</Box>
              </Stack>
            </Typography>
          ) : (
            <Typography variant="subtitle1" color="error">
              <Stack direction="row" spacing={1} alignItems={"center"}>
                <WifiTetheringOffIcon />
                <Box>{t("offline")}</Box>
              </Stack>
            </Typography>
          )}
        </Stack>
      )
    },
  },
  {
    field: "address",
    headerName: `📍 ${t("address")}`,
    width: defaultColWidth * 2,
    renderCell: (params) => {
      const l = getLockerDisplayValues(params.row as Locker)

      if (!params.row?.sstLocker) {
        return null
      }
      return (
        <Stack>
          {`${l.address1} ${l.address2} ${l.city} ${l.province} ${l.country}`}
        </Stack>
      )
    },
  },
  {
    field: "createdAt",
    headerName: t("createdAt"),
    width: defaultColWidth,
    renderCell: (params) => {
      return <Box>{new Date(params.value as string).toLocaleString()}</Box>
    },
  },
]

export const OsLockersPage: FC = () => {
  const { t } = useTranslation()
  const tenantId = useAppSelector(selectTenantId)
  const isReady = useAppSelector(selectMeIsReady)
  const { isLoading, isFetching, isUninitialized } = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetLockers,
        "ap.tenantId": tenantId,
        "ap.refreshSstLockers": true,
        tenantId,
      },
    },
    {
      skip: !tenantId || !isReady,
    },
  )

  const _lockers = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, LockerThingTypeMrn),
  ) as Locker[]

  // sorted lockers by locker.order in ascending order or created at in descending order
  const lockers = useMemo(() => {
    return _lockers.sort((a, b) => {
      // parse displayOrder as number
      const order1 = a.displayOrder || 10000
      const order2 = b.displayOrder || 10000
      return order1 - order2
    })
  }, [_lockers])

  const [listType, setListType] = useState<"card" | "table">("card")

  return (
    <OsLayout selectedMenuItemLabelKey="lockers">
      {(isLoading || !isReady) && (
        <>
          <Stack direction="column" spacing={2}>
            <CircularProgress />
          </Stack>
        </>
      )}
      <Stack direction="column" spacing={0} p={2} pl={4}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={"flex-end"}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <IconButton
              onClick={() => setListType("card")}
              disabled={listType === "card"}
            >
              <GridViewIcon />
            </IconButton>
            <IconButton
              onClick={() => setListType("table")}
              disabled={listType === "table"}
            >
              <ViewListIcon />
            </IconButton>
          </Stack>
        </Stack>
        {listType === "table" && (
          <>
            <Box
              sx={{
                width: "100%",
                mt: 1,
                minHeight: 300,
              }}
            >
              {tenantId && (
                <DataGrid
                  columns={columns(t, tenantId)}
                  rows={lockers}
                  localeText={{
                    noRowsLabel: t("noLockers"),
                  }}
                  rowSelection={false}
                  sx={{
                    // disable cell selection style
                    ".MuiDataGrid-cell:focus": {
                      outline: "none",
                    },
                    // pointer cursor on ALL rows
                    "& .MuiDataGrid-row:hover": {
                      cursor: "pointer",
                    },
                  }}
                />
              )}
            </Box>
          </>
        )}
        {listType === "card" && (
          <Stack
            direction="row"
            spacing={1}
            // alignItems="center"
            sx={{
              // mt: 16,
              maxHeight: "calc(100vh - 64px)",
            }}
            // vertical divier
            divider={<Divider orientation="vertical" flexItem />}
          >
            {lockers.map((locker) => (
              <Box key={locker.id}>
                <OsLockerDashboard lockerId={locker.id} />
              </Box>
            ))}
          </Stack>
        )}
        {lockers?.length === 0 &&
          !isLoading &&
          !isFetching &&
          !isUninitialized && (
            <Paper
              sx={{
                p: 2,
                width: "400px",
                height: "100px",
                //center content
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "primary.main",
              }}
              variant="outlined"
            >
              <Stack direction="column" spacing={1} alignItems="center">
                <Typography variant="body1" fontWeight="bold">
                  {t("noLockers")}
                </Typography>
                <Typography variant="body2">
                  {t("pleaseContactAdmin")}
                </Typography>
              </Stack>
            </Paper>
          )}
      </Stack>
      <OsLockerDoorDrawerManager />
    </OsLayout>
  )
}

export default OsLockersPage
