import {
  Avatar,
  Box,
  Divider,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material"
import parseHtml from "html-react-parser"
import { FC } from "react"
import { useTranslation } from "react-i18next"

export const SmsPreview: FC<{
  toPhoneNumber?: string
  fromPhoneNumber?: string
  message?: string
  isFetchingPreview?: boolean
  fullName?: string
  deliveryId?: string
}> = ({
  toPhoneNumber,
  fromPhoneNumber,
  message,
  isFetchingPreview = false,
  fullName,
  deliveryId,
}) => {
  const { t } = useTranslation()

  // replace all \n with <br> in message
  const message0 = message?.replace(/\n/g, "<br>") || ""
  return (
    <Paper
      variant="outlined"
      sx={{
        p: 1,
        bgcolor: "grey.100",
        width: "100%",
        height: 500,
        overflow: "auto",
      }}
    >
      {isFetchingPreview ? (
        <Box p={3}>
          <Stack direction="column" spacing={2}>
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />

            <Skeleton variant="rectangular" width="100%" height={300} />
          </Stack>
        </Box>
      ) : !message ? (
        <Stack
          direction="column"
          spacing={1}
          alignItems="center"
          alignContent={"center"}
          justifyContent={"center"}
          height="100%"
        >
          <Typography variant="body1" color="grey">
            {t("noSmsPreview")}
          </Typography>
        </Stack>
      ) : (
        <Stack
          direction="column"
          spacing={0}
          justifyContent={"space-between"}
          sx={{
            height: "100%",
          }}
        >
          <Stack direction="column" spacing={3}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar
                sx={{
                  bgcolor: "primary.main",
                  fontSize: "small",
                  width: 20,
                  height: 20,
                }}
              >
                {/* <Email fontSize="small" /> */}
                {fullName?.charAt(0) || "-"}
              </Avatar>
              <Typography variant="body1">
                {fullName || t("noRecipientName")}
              </Typography>
              <Typography variant="body2">
                {`< ${toPhoneNumber} >` || t("noEmail")}
              </Typography>
            </Stack>
            <Divider />
            <Stack direction="row" spacing={0} alignItems={"flex-start"}>
              <Box>
                <Typography
                  variant="body1"
                  color="grey"
                  sx={{
                    width: "80px",
                  }}
                >
                  {t("message")}
                </Typography>
              </Box>

              <Box sx={{ pt: 0 }}>
                {message ? parseHtml(message0) : t("noPreview")}
              </Box>
            </Stack>
            <Divider />
          </Stack>

          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="caption" color="grey">
              {t("sentBy")}{" "}
              <code>{fromPhoneNumber || t("noPhoneNumber")} </code>
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="caption" color="grey">
                deliveryId
              </Typography>
              <Typography
                variant="caption"
                color="grey"
                fontFamily={"monospace"}
              >
                {deliveryId || "n/a"}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Paper>
  )
}
