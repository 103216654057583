import { Skeleton, Stack } from "@mui/material"
import { noop } from "lodash"
import { FC, useEffect } from "react"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady } from "../features/me/meSlice"
import { selectDatasetByName } from "../features/report/reportSlice"
import OsReportReservationSummary from "./OsReportReservationSummary"
import OsReportReservationSummaryDoorSize from "./OsReportReservationSummaryDoorSize"
import OsReportReservationSummaryOccupancy from "./OsReportReservationSummaryOccupancy"
import OsReportReservationSummaryOccupancyDoorSize from "./OsReportReservationSummaryOccupancyDoorSize"
import OsReportReservationSummaryPickupDoorSize from "./OsReportReservationSummaryPickupDoorSize"
import OsReportReservationSummaryPickupHod from "./OsReportReservationSummaryPickupHod"
import OsReportReservationSummaryStatus from "./OsReportReservationSummaryStatus"

type OsReportingKPIsProps = {
  onRefresh?: () => void
  isLoading?: boolean
}
export const OsReportingKPIs: FC<OsReportingKPIsProps> = ({
  onRefresh = noop,
  isLoading = false,
}) => {
  const isReady = useAppSelector(selectMeIsReady)

  const reservationQty = useAppSelector(selectDatasetByName("reservationQty"))
  const dreservationQtyStatus = useAppSelector(
    selectDatasetByName("reservationQtyStatus"),
  )
  const reservationQtyDoorSize = useAppSelector(
    selectDatasetByName("reservationQtyDoorSize"),
  )
  const reservationPickupHod = useAppSelector(
    selectDatasetByName("reservationPickupHod"),
  )
  const reservationPickupDoorSize = useAppSelector(
    selectDatasetByName("reservationPickupDoorSize"),
  )
  const reservationOccupancy = useAppSelector(
    selectDatasetByName("reservationOccupancy"),
  )
  const reservationOccupancyDoorSize = useAppSelector(
    selectDatasetByName("reservationOccupancyDoorSize"),
  )

  useEffect(() => {
    onRefresh()
  }, [onRefresh])

  return (
    <>
      {!isReady && (
        <>
          <Stack direction="column" spacing={2}>
            <Skeleton variant="rectangular" width="100%" height={118} />
            <Skeleton variant="rectangular" width="100%" height={118 / 2} />
            <Skeleton variant="rectangular" width="100%" height={118} />
            <Skeleton variant="rectangular" width="100%" height={118 / 2} />
          </Stack>
        </>
      )}
      {isReady && (
        <Stack
          direction={"column"}
          spacing={4}
          maxWidth="1000px"
          width="1000px"
        >
          <OsReportReservationSummary
            ds={reservationQty}
            refresh={onRefresh}
            isLoading={isLoading}
          />
          <Stack direction={"row"} spacing={4}>
            <OsReportReservationSummaryStatus
              ds={dreservationQtyStatus}
              refresh={onRefresh}
              isLoading={isLoading}
              // hideHeader={true}
              hideRefresh
              width={500}
            />
            <OsReportReservationSummaryDoorSize
              ds={reservationQtyDoorSize}
              refresh={onRefresh}
              isLoading={isLoading}
              // hideHeader={true}
              hideRefresh
              width={500}
            />
          </Stack>
          <Stack direction={"row"} spacing={4}>
            <OsReportReservationSummaryPickupHod
              ds={reservationPickupHod}
              refresh={onRefresh}
              isLoading={isLoading}
              // hideHeader={true}
              hideRefresh
              width={500}
            />
            <OsReportReservationSummaryPickupDoorSize
              ds={reservationPickupDoorSize}
              refresh={onRefresh}
              isLoading={isLoading}
              // hideHeader={true}
              hideRefresh
              width={500}
            />
          </Stack>
          <Stack direction={"row"} spacing={4}>
            <OsReportReservationSummaryOccupancy
              ds={reservationOccupancy}
              refresh={onRefresh}
              isLoading={isLoading}
              // hideHeader={true}
              hideRefresh
              width={1000}
            />
          </Stack>
          <Stack direction={"row"} spacing={4}>
            <OsReportReservationSummaryOccupancyDoorSize
              ds={reservationOccupancyDoorSize}
              refresh={onRefresh}
              isLoading={isLoading}
              // hideHeader={true}
              hideRefresh
              width={1000}
            />
          </Stack>
        </Stack>
      )}
    </>
  )
}

export default OsReportingKPIs
