import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "../baseQuerySl2"
import { MblsConnectorClient, MblsProvider } from "./mblsConnector"

type GetClientsMblsConnectorResponse = {
  data: MblsConnectorClient[]
  meta: {
    count: number
    nextToken: string | undefined
  }
}
type GetProvidersMblsConnectorResponse = {
  data: MblsProvider[]
  meta: {
    count: number
    nextToken: string | undefined
  }
}

export const mblsconnectorApi = createApi({
  reducerPath: "mblsconnectorApi",
  baseQuery,
  endpoints: (builder) => ({
    getMblsConnectorClients: builder.query<
      GetClientsMblsConnectorResponse,
      { params: Record<string, any> }
    >({
      query: ({ params }) => ({
        url: "connector/mbls/rest/clients",
        params,
      }),
    }),

    // get mbls provider (by id)
    getMblsProvider: builder.query<
      GetProvidersMblsConnectorResponse,
      { params: Record<string, any> }
    >({
      query: ({ params }) => ({
        url: "connector/mbls/rest/providers",
        params,
      }),
    }),
  }),
})

export const { useGetMblsConnectorClientsQuery } = mblsconnectorApi
