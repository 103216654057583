import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Paper,
    Stack,
    Typography,
} from "@mui/material"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { ReservationAudEventList } from "shared/reservationAudData"

interface AuditTrailEventFilterProps {
  value: string[]
  onChange: (newValue: string) => void
}

const auditTrailEvents = ReservationAudEventList

const AuditTrailEventFilter: React.FC<AuditTrailEventFilterProps> = ({
  value,
  onChange,
}) => {
  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.name)
    },
    [onChange],
  )
  const { t } = useTranslation()

  return (
    <Paper sx={{ p: 1 }} variant="outlined">
      <Stack direction="row" spacing={3}>
        <FormGroup row>
          {auditTrailEvents.map((event) => (
            <FormControlLabel
              key={event}
              control={
                <Checkbox
                  checked={value.includes(event)}
                  onChange={handleCheckboxChange}
                  name={event}
                />
              }
              label={<Typography variant="body2">{t(event)}</Typography>}
              sx={{
                width: 300,
              }}
            />
          ))}
        </FormGroup>
      </Stack>
    </Paper>
  )
}

export default AuditTrailEventFilter
