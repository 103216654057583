import { mqtt5 } from "aws-iot-device-sdk-v2"
import { FC, useEffect } from "react"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady } from "../features/me/meSlice"
import { useIoT } from "./MrtContext"

type MrtConnectionProps = {
  tenantId: string
  subscribePacket: mqtt5.SubscribePacket
}
export const MrtConnection: FC<MrtConnectionProps> = ({
  tenantId,
  subscribePacket,
}) => {
  const isReady = useAppSelector(selectMeIsReady)
  const {
    connect,
    subscribeAsync,
    unsubscribe,
    isConnected,
    isConnecting,
    lastMessageJson,
  } = useIoT()

  useEffect(() => {
    if (!isReady) return

    if (tenantId && !isConnecting) {
      connect(tenantId, () => subscribeAsync(subscribePacket))
    }

    return () => {
      if (tenantId && !isConnecting) {
        unsubscribe({
          topicFilters: subscribePacket.subscriptions.map((s) => s.topicFilter),
        })
      }
    }
  }, [tenantId, isReady, isConnected, isConnecting])

  return <></>
}
